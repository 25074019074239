import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTotalDepositStatisticQuery } from 'apollo/main/generated';
import MalinkaIcon from 'assets/images/tokens/mlnk.svg';
import getTokenLogo from 'helpers/getTokenLogo/getTokenLogo';
import { ChartIcon, CrossIcon } from 'ui/Icons/Icons';
import Card, { CardError, CardLoader } from '../Card/Card';
import FormatNumber from '../FormatNumber/FormatNumber';
import styles from './AboutCashTokensStatistics.module.scss';

function TokenCashTitle({ value }: { value?: string | null }) {
    const token = value?.toLowerCase().split('cash');

    if (!token || !token[0]) return <span>{value}</span>;

    return (
        <span className={styles.TokenCash}>
            <span className={styles.TokenCashName}>{token[0].toUpperCase()}</span>CASH
        </span>
    );
}

export default function Statistics() {
    const [t] = useTranslation();
    const { data, loading, error, refetch } = useTotalDepositStatisticQuery();

    return (
        <section id="statistics" className={styles.Component}>
            <div className={styles.Container}>
                <Card title="" className={styles.Root}>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {error ? (
                        <CardError resetError={refetch} disabled={loading} />
                    ) : loading ? (
                        <CardLoader />
                    ) : (
                        <div className={styles.Row}>
                            {data?.totalDepositStatistic?.map((item) => {
                                const {
                                    annualRelativeProfit,
                                    depositedToken,
                                    depositedMlnk,
                                    issuedCash,
                                    tokenIn,
                                    tokenOut
                                } = item ?? {};

                                return (
                                    <div className={styles.Column} key={tokenOut?.id}>
                                        <div className={styles.Card}>
                                            <div className={styles.Issued}>
                                                <img
                                                    className={styles.IssuedIcon}
                                                    src={getTokenLogo(
                                                        tokenOut?.logo,
                                                        tokenOut?.code,
                                                        tokenOut?.smartContract
                                                    )}
                                                    alt="Token"
                                                />
                                                <div className={styles.Info}>
                                                    <div className={styles.IssuedText}>
                                                        <TokenCashTitle value={tokenOut?.code} />
                                                    </div>
                                                    <div className={styles.IssuedSubtext}>
                                                        <FormatNumber
                                                            value={issuedCash}
                                                            precision={5}
                                                            floatZerosOffset={0}
                                                            compress
                                                        />{' '}
                                                        {t('pageAboutCashTokens.statistics.params.issued')}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={styles.Backing}>
                                                <div className={styles.BackingInfo}>
                                                    <div className={styles.BackingInfoItem}>
                                                        <img
                                                            className={styles.BackingIcon}
                                                            src={MalinkaIcon}
                                                            alt="MLNK"
                                                        />
                                                        <FormatNumber
                                                            className={styles.Subtext}
                                                            value={depositedMlnk}
                                                            precision={2}
                                                            floatZerosOffset={0}
                                                            compress
                                                        />
                                                    </div>
                                                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                                                    <CrossIcon className={styles.BackingInfoItemDivider} />
                                                    <div className={styles.BackingInfoItem}>
                                                        <img
                                                            className={styles.BackingIcon}
                                                            src={getTokenLogo(
                                                                tokenIn?.logo,
                                                                tokenIn?.code,
                                                                tokenIn?.smartContract
                                                            )}
                                                            alt="Token"
                                                        />
                                                        <FormatNumber
                                                            className={styles.Subtext}
                                                            value={depositedToken}
                                                            precision={4}
                                                            floatZerosOffset={0}
                                                            compress
                                                        />
                                                    </div>
                                                </div>
                                                <div className={styles.Text}>
                                                    {t('pageAboutCashTokens.statistics.params.backed')}
                                                </div>
                                            </div>
                                            <div className={styles.Yield}>
                                                <div className={styles.Info}>
                                                    <div className={styles.YieldValueWrapper}>
                                                        <ChartIcon className={styles.YieldIcon} />
                                                        <FormatNumber
                                                            className={styles.Subtext}
                                                            value={annualRelativeProfit}
                                                            precision={0}
                                                            floatZerosOffset={0}
                                                            compress
                                                            suffix="%"
                                                        />
                                                    </div>
                                                    <div className={styles.Text}>
                                                        {t('pageAboutCashTokens.statistics.params.yield')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </Card>
            </div>
        </section>
    );
}
