import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Link, matchPath, NavLink, useHistory, useLocation } from 'react-router-dom';
import Button from 'ui/Button_DEPRECATED/Button';
import {
    AboutIcon,
    ArrowTopRightIcon,
    BookmarkIcon,
    ChartIcon,
    CrossIcon,
    FileFilledIcon,
    MailFilledIcon,
    MoneyBagFilledIcon,
    MoreHorizontalIcon,
    PairsIcon,
    PlusIcon,
    SearchIcon,
    TokenIcon,
    TransactionsIcon
} from 'ui/Icons/Icons';
import { useDropdown, useWhitePaperLink, useWidthCondition } from 'helpers';
import { BREAKPOINT_IPAD, BREAKPOINT_LANDSCAPE, BREAKPOINT_PHONE, PAYCASH_LANDING, ROUTES_MAP } from 'constant';
import { useAppContext } from 'components/Helpers/AppContext';
import Background from 'components/Background/Background';
import Logo from 'assets/images/logo.svg';
import MLNKLogo from 'assets/images/tokens/mlnk.svg';
import USDCASHLogo from 'assets/images/tokens/usdcash.svg';
import RMBCASHLogo from 'assets/images/tokens/rmbcash.svg';
import RUBCASHLogo from 'assets/images/tokens/rubcash.svg';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import styles from './Header.module.scss';

const cx = classNames.bind(styles);

export default function Header() {
    const {
        bookmarks: { open: openBookmarks },
        searchAccount: { open: openSearchAccount }
    } = useAppContext();
    const [t] = useTranslation();
    const history = useHistory();
    const whitePaperLink = useWhitePaperLink();
    const location = useLocation();
    const [isIpadOrLess] = useWidthCondition((w) => w < BREAKPOINT_IPAD);
    const [isLandscapeOrLess] = useWidthCondition((w) => w < BREAKPOINT_LANDSCAPE);
    const [isPhone] = useWidthCondition((w) => w < BREAKPOINT_PHONE);
    const aboutProjectDropdown = useDropdown({
        popperOptions: {
            placement: 'bottom'
        }
    });

    const isAboutPageOpened =
        matchPath(location.pathname, {
            path: ROUTES_MAP.info,
            exact: true
        }) || matchPath(location.pathname, { path: ROUTES_MAP.aboutCashTokens });

    const BtnBookmarks = (
        <Button
            color="tertairy-white"
            onClick={openBookmarks}
            startIcon={<BookmarkIcon />}
            className={cx('BtnBookmark')}
        />
    );

    const moreMenuDropdown = useDropdown({
        handleOutsideClick: !isPhone,
        popperOptions: {
            placement: 'bottom'
        }
    });

    const AboutTokensLinks = (
        <div className={styles.AboutTokenLinks}>
            <button
                type="button"
                onClick={() => {
                    history.push(ROUTES_MAP.info);
                    aboutProjectDropdown.setOpen(false);
                    moreMenuDropdown.setOpen(false);
                }}
            >
                <div className={styles.AboutTokenLinksGroup}>
                    <div className={styles.AboutTokenLinksTitle}>{t('header.nav.mlnkInfo')}</div>
                    <div className={styles.AboutTokenLinksItem}>
                        <div className={styles.AboutTokenLinksIconWrapper}>
                            <img className={styles.AboutTokenLinksIcon} alt="Malinka" src={MLNKLogo} />
                        </div>
                        <div className={styles.AboutTokenLinksText}>MLNK</div>
                    </div>
                </div>
            </button>
            <button
                type="button"
                onClick={() => {
                    history.push(ROUTES_MAP.aboutCashTokens);
                    aboutProjectDropdown.setOpen(false);
                    moreMenuDropdown.setOpen(false);
                }}
            >
                <div className={styles.AboutTokenLinksGroup}>
                    <div className={styles.AboutTokenLinksTitle}>{t('header.nav.usdcashInfo')}</div>
                    <div className={styles.AboutTokenLinksItem}>
                        <div className={styles.AboutTokenLinksIconWrapper}>
                            <img className={styles.AboutTokenLinksIcon} alt="USDCASH" src={USDCASHLogo} />
                        </div>
                        <div className={styles.AboutTokenLinksText}>USDCASH</div>
                    </div>
                    <div className={styles.AboutTokenLinksItem}>
                        <div className={styles.AboutTokenLinksIconWrapper}>
                            <img className={styles.AboutTokenLinksIcon} alt="RMBCASH" src={RMBCASHLogo} />
                        </div>
                        <div className={styles.AboutTokenLinksText}>RMBCASH</div>
                    </div>
                    <div className={styles.AboutTokenLinksItem}>
                        <div className={styles.AboutTokenLinksIconWrapper}>
                            <img className={styles.AboutTokenLinksIcon} alt="RUBCASH" src={RUBCASHLogo} />
                        </div>
                        <div className={styles.AboutTokenLinksText}>RUBCASH</div>
                    </div>
                </div>
            </button>
        </div>
    );

    const MoreMenuLinks = (
        <div className={cx('MoreMenuContentMain')}>
            <a
                onClick={moreMenuDropdown.toggle}
                href={whitePaperLink}
                className={cx('MoreMenuLink')}
                target="_blank"
                rel="noreferrer"
            >
                <span className={cx('MoreMenuLinkIcon')}>
                    <FileFilledIcon />
                </span>

                <span className={cx('MoreMenuLinkMain')}>
                    <span className={cx('MoreMenuLinkTitle')}>
                        {t('header.nav.whitePaper')}

                        <span className={cx('MoreMenuLinkTag')}>PDF</span>
                    </span>
                    <span className={cx('MoreMenuLinkDescription')}>{t('header.nav.whitePaper_description')}</span>
                </span>
            </a>

            {isIpadOrLess && (
                <div className={cx('MoreMenuInfoLinks')}>
                    <span className={cx('MoreMenuInfoLinksTitle')}>{t('header.nav.info')}</span>
                    {AboutTokensLinks}
                </div>
            )}

            <NavLink
                onClick={moreMenuDropdown.toggle}
                to={ROUTES_MAP.openLetters}
                activeStyle={{ display: 'none' }}
                className={cx('MoreMenuLink', 'MoreMenuLinkAlone')}
            >
                <span className={cx('MoreMenuLinkIcon')}>
                    <MailFilledIcon />
                </span>

                <span className={cx('MoreMenuLinkMain')}>
                    <span className={cx('MoreMenuLinkTitle')}>{t('header.nav.openLetters')}</span>
                </span>
            </NavLink>

            <NavLink
                onClick={moreMenuDropdown.toggle}
                to={ROUTES_MAP.depositExchange}
                activeStyle={{ display: 'none' }}
                className={cx('MoreMenuLink')}
            >
                <span className={cx('MoreMenuLinkIcon')}>
                    <PlusIcon />
                </span>

                <span className={cx('MoreMenuLinkMain')}>
                    <span className={cx('MoreMenuLinkTitle')}>{t('header.nav.depositExchange')}</span>
                    <span className={cx('MoreMenuLinkDescription')}>{t('header.nav.depositExchange_description')}</span>
                </span>
            </NavLink>

            <a
                onClick={moreMenuDropdown.toggle}
                href={PAYCASH_LANDING}
                target="_blank"
                className={cx('MoreMenuLink')}
                rel="noreferrer"
            >
                <span className={cx('MoreMenuLinkIcon')}>
                    <MoneyBagFilledIcon />
                </span>

                <span className={cx('MoreMenuLinkMain')}>
                    <span className={cx('MoreMenuLinkTitle')}>
                        {t('header.nav.paycash')}
                        <ArrowTopRightIcon className={styles.ArrowIcon} />
                    </span>
                    <span className={cx('MoreMenuLinkDescription')}>{t('header.nav.paycash_description')}</span>
                </span>
            </a>
        </div>
    );

    const MoreMenuControl = (
        <div ref={moreMenuDropdown.ref} className={cx('MoreMenu')}>
            <Button
                isActive={moreMenuDropdown.open}
                color="tertairy-white"
                onClick={moreMenuDropdown.toggle}
                // eslint-disable-next-line react/jsx-pascal-case
                startIcon={moreMenuDropdown.open ? <CrossIcon /> : <MoreHorizontalIcon />}
                className={cx('MoreMenuControl')}
            />

            {!isPhone && (
                <div
                    ref={moreMenuDropdown.popperRef}
                    style={moreMenuDropdown.popper.styles.popper}
                    {...moreMenuDropdown.popper.attributes.popper}
                    className={cx('MoreMenuContent', moreMenuDropdown.open && 'open')}
                >
                    {MoreMenuLinks}
                </div>
            )}
        </div>
    );

    return (
        <>
            <header id="header" className={cx('Component')}>
                <Background className={cx('Background', isPhone && moreMenuDropdown.open && 'hide')} />

                <div className={cx('Container')}>
                    <div className={cx('LeftSide')}>
                        <Link className={cx('Logo')} to={ROUTES_MAP.home}>
                            <img alt="PayCashSwap" src={Logo} />
                        </Link>
                        <div className={cx('Actions')}>
                            <LanguageSwitcher />
                            {isLandscapeOrLess && BtnBookmarks}
                            {isIpadOrLess && MoreMenuControl}
                        </div>
                    </div>

                    <nav className={cx('Nav')}>
                        <div ref={aboutProjectDropdown.ref} className={cx('AboutProjectDropdown')}>
                            {/* eslint-disable-next-line react/button-has-type */}
                            <button
                                onClick={aboutProjectDropdown.toggle}
                                className={cx('AboutProjectDropdownControl', {
                                    active: isAboutPageOpened,
                                    open: aboutProjectDropdown.open
                                })}
                            >
                                <AboutIcon />
                                {t('header.nav.info')}
                            </button>

                            <div
                                ref={aboutProjectDropdown.popperRef}
                                style={aboutProjectDropdown.popper.styles.popper}
                                {...aboutProjectDropdown.popper.attributes.popper}
                                className={cx('AboutProjectDropdownContent', aboutProjectDropdown.open && 'visible')}
                            >
                                {AboutTokensLinks}
                            </div>
                        </div>
                        <NavLink exact to={ROUTES_MAP.home} activeClassName={cx('active')}>
                            <ChartIcon />
                            {t('header.nav.home')}
                        </NavLink>
                        <NavLink to={ROUTES_MAP.tokens} activeClassName={cx('active')}>
                            <TokenIcon />
                            {t('header.nav.tokens')}
                        </NavLink>
                        <NavLink to={ROUTES_MAP.pools} activeClassName={cx('active')}>
                            <PairsIcon />
                            {t('header.nav.pools')}
                        </NavLink>
                        <NavLink to={ROUTES_MAP.transactions} activeClassName={cx('active')}>
                            <TransactionsIcon />
                            {t('header.nav.transactions')}
                        </NavLink>
                    </nav>

                    <div className={cx('RightSide')}>
                        {!isIpadOrLess && MoreMenuControl}
                        {BtnBookmarks}
                        <Button
                            color="tertairy-white"
                            onClick={openSearchAccount}
                            startIcon={<SearchIcon />}
                            className={cx('BtnSearch')}
                        >
                            {t('header.searchAccount')}
                        </Button>
                    </div>
                </div>
            </header>

            {isPhone && (
                <div className={cx('MoreMenuContent', moreMenuDropdown.open && 'open')}>
                    {MoreMenuLinks}
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <div onClick={moreMenuDropdown.toggle} className={cx('MoreMenuContentOverlay')} />
                </div>
            )}
        </>
    );
}
