import React, { useEffect, useRef, useState } from 'react';
import Bust from 'assets/bust.json';
import LottiePlayer, { AnimationItem } from 'lottie-web';
import classNames from 'classnames/bind';
import { CheckIcon } from 'ui/Icons/Icons';
import styles from './BustAnimation.module.scss';

const cx = classNames.bind(styles);

function BustAnimation({ isOpen, close }: { isOpen: boolean; close(): void }) {
    const ref = useRef<HTMLSpanElement>(null);
    const animationItem = useRef<AnimationItem>();
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        if (!isOpen || !ref.current) return;

        animationItem.current =
            animationItem.current ||
            LottiePlayer.loadAnimation({
                container: ref.current,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                animationData: Bust,
                rendererSettings: {
                    preserveAspectRatio: 'xMinYMin slice'
                }
            });

        setShowMessage(false);
        animationItem.current!.goToAndStop(0, true);
        setTimeout(() => {
            animationItem.current!.play();
            animationItem.current!.addEventListener('complete', () => {
                setShowMessage(true);
                animationItem.current!.removeEventListener('complete');
                setTimeout(close, 3000);
            });
        }, 250);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <div className={cx('Component', isOpen && 'open')}>
            <span className={cx('Bust')} ref={ref} />
            <p className={cx('Msg', showMessage && 'show')}>
                <CheckIcon />
                {'Вся малинка\nсобрана'}
            </p>
        </div>
    );
}

export default BustAnimation;
