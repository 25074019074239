import React from 'react';
import Skeleton from 'ui/Skeleton/Skeleton';
import VirtualCardAccountSkeleton from '../VirtualCardAccount/VirtualCardAccountSkeleton';
import VirtualCardPlasticSkeleton from '../VirtualCardPlastic/VirtualCardPlasticSkeleton';
import styles from './VirtualCardInfo.module.scss';

export default function VirtualCardInfoSkeleton() {
    return (
        <div className={styles.Root}>
            <div className={styles.Account}>
                <VirtualCardAccountSkeleton />
            </div>
            <div>
                <div className={styles.BalanceContainer}>
                    <div className={styles.Balance}>
                        <div className={styles.BalanceLabel}>
                            <Skeleton variant="text" width={100} />
                        </div>
                        <div className={styles.BalanceValue}>
                            <Skeleton variant="text" width={100} />
                        </div>
                    </div>
                    <Skeleton width={120} height={42} />
                </div>
                <div className={styles.Plastic}>
                    <VirtualCardPlasticSkeleton />
                </div>
            </div>
            <div className={styles.ButtonContainer}>
                <Skeleton height={42} width="100%" />
            </div>
        </div>
    );
}
