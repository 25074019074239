export enum WalletName {
    Malinka = 'malinka',
    Kalyna = 'kalyna',
    Paycash = 'paycash'
}

export const getAppLink = (walletName: WalletName) => {
    switch (walletName) {
        case WalletName.Malinka:
            return 'https://malinka.life/';
        case WalletName.Kalyna:
            return 'https://kalyna.online/';
        default:
            return 'https://paycash.app/';
    }
};
