import React from 'react';
import { useTranslation } from 'react-i18next';
import { scrollInto } from 'helpers';
import Button from 'ui/Button_DEPRECATED/Button';
import InheritanceImage1x from 'assets/images/about-cash-tokens/inheritance@1x.png';
import InheritanceImage2x from 'assets/images/about-cash-tokens/inheritance@2x.png';
import styles from './AboutCashTokensInheritance.module.scss';

export default function AboutCashTokensInheritance() {
    const [t] = useTranslation();

    return (
        <div className={styles.Root}>
            <div className={styles.Wrapper}>
                <h2 className={styles.Title}>{t('pageAboutCashTokens.inheritance.title')}</h2>
                <p className={styles.Text}>{t('pageAboutCashTokens.inheritance.description')}</p>
                <Button
                    tag="a"
                    href="#applications"
                    color="primary"
                    onClick={(e) => {
                        scrollInto(e, 'applications', -50);
                    }}
                >
                    {t('pageAboutCashTokens.inheritance.btnDownload')}
                </Button>
            </div>
            <div className={styles.ImageWrapper}>
                <img
                    className={styles.Image}
                    srcSet={`${InheritanceImage2x} 2x`}
                    src={InheritanceImage1x}
                    alt="Inheritance"
                />
            </div>
        </div>
    );
}
