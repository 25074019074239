import React from 'react';

const highlightSubstring = (word: string, searchWindow: readonly [number, number]) =>
    searchWindow.some((n) => n < 0) ? (
        word
    ) : (
        <>
            {word.slice(0, searchWindow[0])}
            <mark>{word.slice(...searchWindow)}</mark>
            {word.slice(searchWindow[1])}
        </>
    );

export default highlightSubstring;
