import React from 'react';
import Skeleton from 'ui/Skeleton/Skeleton';
import styles from './VirtualCardPlastic.module.scss';

export default function VirtualCardPlasticSkeleton() {
    return (
        <div className={styles.Root}>
            <div className={styles.Container}>
                <div className={styles.Front}>
                    <Skeleton width="100%" height="100%" />
                </div>
            </div>
        </div>
    );
}
