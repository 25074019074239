import {
    ActionSortEnum,
    ActionsQueryVariables,
    PoolTokenDataFragment,
    TxnType,
    useActionsQuery,
    usePoolProfitChartQuery,
    usePoolQuery
} from 'apollo/main/generated';
import classNames from 'classnames/bind';
import BookmarkBtn from 'components/BookmarkBtn/BookmarkBtn';
import Button from 'ui/Button_DEPRECATED/Button';
import Card, { CardValue } from 'components/Card/Card';
import FormatNumber from 'components/FormatNumber/FormatNumber';
import { DoubleCheckIcon, RefreshIcon, CrossIcon, ChevronLeftIcon } from 'ui/Icons/Icons';
import PageState from 'components/PageState/PageState';
import SectionBloksIoInfo, { Param } from 'components/SectionBloksIoInfo/SectionBloksIoInfo';
import TokenCard from 'components/TokenCard/TokenCard';
import TransactionsTable from 'components/TransactionsTable/TransactionsTable';
import TransactionTypeFilter from 'components/TransactionTypeFilter/TransactionTypeFilter';
import { BREAKPOINT_IPAD, ROUTES_MAP } from 'constant';
import { useWidthCondition } from 'helpers';
import addThousandsSeparator from 'utils/addThousandsSeparator/addThousandsSeparator';
import update from 'immutability-helper';
import Chart, { COLOR_SCHEME } from 'components/Chart/Chart';
import React, { useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { RouteComponentProps, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import styles from './Pool.module.scss';

const cx = classNames.bind(styles);

function PoolTokenCard(pool: {
    token1: PoolTokenDataFragment;
    tokenAmount?: number | string | null;
    rate?: number | string | null;
    token2: PoolTokenDataFragment;
}) {
    const [t] = useTranslation();
    const [isIpadOrLess] = useWidthCondition((w) => w < BREAKPOINT_IPAD);
    const history = useHistory();

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
        <div
            role="link"
            // eslint-disable-next-line react/destructuring-assignment
            data-href={ROUTES_MAP.token(pool.token1.id)}
            // eslint-disable-next-line react/destructuring-assignment
            onClick={() => history.push(ROUTES_MAP.token(pool.token1.id))}
            className={cx('PoolToken')}
        >
            <div className={cx('PoolTokenDetails')}>
                <TokenCard
                    className={cx('PoolTokenCard')}
                    publicNameClassName={cx('PoolTokenCardPublicName')}
                    {...pool!.token1}
                    labelOnly={!isIpadOrLess}
                    logo={pool!.token1.logo!}
                    logoSize={42}
                    logoClassName={cx('PoolTokenCardLabel')}
                />
                <div className={cx('PoolTokenParamGroup')}>
                    <p className={cx('PoolTokenParam')}>
                        <span>{t('pagePool.token.pool')}</span>
                        <b>
                            {addThousandsSeparator(pool!.tokenAmount?.toString())} {pool!.token1.code}
                        </b>
                    </p>
                    <p className={cx('PoolTokenParam')}>
                        <span>{t('pagePool.token.price')}</span>
                        <b>${addThousandsSeparator(pool!.token1.rateToUsd)}</b>
                        <small>
                            {/* eslint-disable-next-line react/destructuring-assignment */}1 USDT ={' '}
                            {addThousandsSeparator(pool.token1.reverseRate)} {pool.token1.code}
                        </small>
                    </p>
                    <p className={cx('Tag', 'PoolTokenRate')}>
                        1 {pool!.token1.code} = {addThousandsSeparator(pool!.rate?.toString())} {pool!.token2.code}
                    </p>
                </div>
            </div>
            <TokenCard
                iconOnly
                logo={pool!.token1.logo!}
                logoSize={88}
                code={pool!.token1.code!}
                smartContract={pool!.token1.smartContract!}
                className={cx('PoolTokenIconWrapper')}
            />
        </div>
    );
}

const IncomeChart = memo(({ code }: { code: string }) => {
    const poolProfitChartQuery = usePoolProfitChartQuery({
        variables: { poolCode: code },
        fetchPolicy: 'network-only'
    });

    return (
        <div className={cx('IncomeChartWrapper')}>
            <Chart
                options={{ height: 300 }}
                loading={poolProfitChartQuery.loading}
                symbolBefore=""
                symbolAfter="%"
                data={{
                    type: 'histogram',
                    options: COLOR_SCHEME.blue.histogram,
                    data:
                        poolProfitChartQuery.data?.poolProfitChart?.points?.map((p) => ({
                            value: Number(p?.value),
                            time: p!.timestamp
                        })) ?? []
                }}
            />
        </div>
    );
});

function PagePool({
    match: {
        params: { pool: code }
    }
}: RouteComponentProps<{ pool: string }>) {
    const {
        loading,
        error,
        data: poolData,
        ...poolQuery
    } = usePoolQuery({
        variables: { code },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only'
    });

    const { t } = useTranslation();
    const { pool, poolVolumeChart, poolLiquidityChart } = poolData ?? {};
    const isEmpty = !loading && !pool && !poolLiquidityChart;

    const hasError = !!error;

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (hasError) {
            return () => {
                poolQuery.refetch();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasError, code]);

    if (error)
        return (
            <PageState.Error title={t('pagePool.error.title')} description={t('pagePool.error.description')}>
                <Button
                    disabled={loading}
                    onClick={() => poolQuery.refetch()}
                    color="tertairy-green"
                    startIcon={<RefreshIcon />}
                >
                    {t('pagePool.error.btn')}
                </Button>
            </PageState.Error>
        );

    if (loading) return <PageState.Loading />;

    if (isEmpty)
        return (
            <PageState.Error title={t('pagePool.empty.title')} description={t('pagePool.empty.description')}>
                <Button color="tertairy-green" tag="link" to={ROUTES_MAP.tokens} startIcon={<ChevronLeftIcon />}>
                    {t('pagePool.empty.btn')}
                </Button>
            </PageState.Error>
        );

    const INCOME = [
        {
            id: 'MLNK',
            code: 'MLNK',
            label: t('pagePool.income.mlnkIncome', { token: 'MLNK' }),
            value: +(pool!.profitDetails?.mlnk ?? 0),
            percent: pool!.profitDetails?.mlnkPercent,
            usd: pool!.profitDetails?.mlnkUsd
        },
        {
            id: 'token1',
            code: pool!.token1.code,
            label: t('pagePool.income.tokenTurnover', { token: pool!.token1.code }),
            value: +(pool!.profitDetails?.token1 ?? 0),
            percent: pool!.profitDetails?.token1Percent,
            usd: pool!.profitDetails?.token1Usd
        },
        {
            id: 'token2',
            code: pool!.token2.code,
            label: t('pagePool.income.tokenTurnover', { token: pool!.token2.code }),
            value: +(pool!.profitDetails?.token2 ?? 0),
            percent: pool!.profitDetails?.token2Percent,
            usd: pool!.profitDetails?.token2Usd
        }
    ];

    return (
        <main className={cx('Component')}>
            <div className={cx('HeaderRow')}>
                <Link className={cx('BtnBack')} to={ROUTES_MAP.pools}>
                    <ChevronLeftIcon />
                </Link>

                <h1 className={cx('Title')}>
                    {t('pagePool.title')}{' '}
                    <span className={cx('Tag')}>
                        {pool!.token1.code} - {pool!.token2.code}
                    </span>
                    <span className={cx('SubTitle')}>
                        {t('pagePool.liquidityToken.title')} - {code}
                        {pool!.mlnkIncomeDetails?.hasHighMlnkIncome && (
                            <span className={cx('IconChecked')}>
                                <DoubleCheckIcon />
                            </span>
                        )}
                    </span>
                </h1>
                <BookmarkBtn type="pool" poolCode={code} className={cx('BtnBookmark')} isFavorite={pool!.isFavorite} />
            </div>
            <div className={cx('ListPoolToken')}>
                <PoolTokenCard
                    token1={pool!.token1}
                    token2={pool!.token2}
                    tokenAmount={pool!.token1Amount}
                    rate={pool!.token1ToToken2Rate}
                />
                <div className={cx('ListPoolTokenDivider')}>
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    <CrossIcon className={styles.Icon} />
                </div>
                <PoolTokenCard
                    token1={pool!.token2}
                    token2={pool!.token1}
                    tokenAmount={pool!.token2Amount}
                    rate={pool!.token2ToToken1Rate}
                />
            </div>
            <div className={cx('Info')}>
                <div className={cx('BaseInfo')}>
                    <Card
                        title={t('pagePool.liquidityToken.released')}
                        helpText={t('pagePool.liquidityToken.helpText')}
                    >
                        <CardValue className={cx('LiquidityValue')}>
                            {addThousandsSeparator(pool?.liquidityTokensAmount)} {code}
                        </CardValue>
                    </Card>
                    <section className={cx('IncomeSection')}>
                        <Card
                            headerClassName={cx('CardHeader')}
                            className={cx('IncomeCard')}
                            titleClassName={cx('CardTitle')}
                            title={
                                <div className={cx('CardValues')}>
                                    <CardValue
                                        changed={{
                                            growth: pool?.profit24hGrowth,
                                            period: '24h'
                                        }}
                                    >
                                        <span className={cx('CardValueLabel')}>{t('pagePool.income.title')}</span>
                                        <small className={cx('CardValueSmall')}>
                                            <FormatNumber prefix="$" value={pool?.profit24h} />
                                        </small>
                                        <FormatNumber value={pool!.relative24hProfit} suffix="%" />
                                    </CardValue>

                                    <CardValue>
                                        <span className={cx('CardValueLabel')}>
                                            {t('pagePool.income.title', { context: 'year' })}
                                        </span>
                                        <small className={cx('CardValueSmall')}>
                                            <FormatNumber prefix="$" value={pool?.profitAnnual} />
                                        </small>
                                        <FormatNumber value={pool!.relativeAnnualProfit} suffix="%" />
                                    </CardValue>
                                </div>
                            }
                            helpText={t('pagePool.income.helpText')}
                        >
                            <div className={cx('ParamListWrapper')}>
                                <table className={cx('ParamList')}>
                                    <tbody>
                                        {INCOME.map((item) => (
                                            <tr key={item.id} className={cx('Param')}>
                                                <td valign="top">{item.label}</td>
                                                <td valign="top">
                                                    <FormatNumber value={item.value} suffix={` ${item.code}`} />
                                                </td>
                                                <td valign="top">
                                                    <FormatNumber value={item.percent} suffix="%" />
                                                    <small>
                                                        <FormatNumber value={item.usd} prefix="$" />
                                                    </small>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Card>

                        <IncomeChart code={code} />
                    </section>
                </div>

                <div className={cx('ChartGroup')}>
                    <Card
                        title={t('pagePool.liquidity.title')}
                        helpText={t('pagePool.liquidity.helpText', {
                            code1: pool!.token1.code,
                            code2: pool!.token2.code
                        })}
                    >
                        {!!poolLiquidityChart!.points.length && (
                            <CardValue
                                changed={{
                                    growth: poolLiquidityChart?.percentageChange24h,
                                    period: '24h'
                                }}
                            >
                                <FormatNumber value={poolLiquidityChart?.value24h} prefix="$" />
                            </CardValue>
                        )}
                        <Chart
                            data={{
                                type: 'area',
                                options: COLOR_SCHEME.blue.area,
                                data: poolLiquidityChart!.points.map((p) => ({
                                    value: Number(p?.value),
                                    time: p!.timestamp
                                }))
                            }}
                        />
                    </Card>
                    <Card
                        headerClassName={cx('CardHeader')}
                        titleClassName={cx('CardTitle')}
                        title={
                            <div className={cx('CardValues')}>
                                <CardValue
                                    changed={{
                                        growth: poolVolumeChart?.percentageChange24h ?? 0,
                                        period: '24h'
                                    }}
                                >
                                    <span className={cx('CardValueLabel')}>{t('pagePool.volume.title')}</span>
                                    <FormatNumber value={poolVolumeChart?.value24h ?? 0} prefix="$" />
                                </CardValue>

                                <CardValue>
                                    <span className={cx('CardValueLabel')}>
                                        {t('pagePool.volume.title', { context: 'week' })}
                                    </span>
                                    <FormatNumber value={poolVolumeChart?.lastWeekValue ?? 0} prefix="$" />
                                </CardValue>
                            </div>
                        }
                        helpText={t('pagePool.volume.helpText', {
                            code1: pool!.token1.code,
                            code2: pool!.token2.code
                        })}
                    >
                        <Chart
                            data={{
                                type: 'histogram',
                                data: poolVolumeChart!.points.map((p) => ({
                                    value: Number(p?.value),
                                    time: p!.time
                                })),
                                options: COLOR_SCHEME.green.histogram
                            }}
                        />
                    </Card>
                </div>
            </div>
            {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
            <SectionTransactions poolCode={pool!.code} />

            <SectionBloksIoInfo
                href={`https://www.bloks.io/account/swap.pcash?loadContract=true&tab=Tables&table=pools&account=swap.pcash&scope=swap.pcash&limit=1&lower_bound=${pool?.poolId}&upper_bound=${pool?.poolId}`}
                title={t('pagePool.info.title')}
            >
                <Param label={t('pagePool.liquidityToken.title')} value={code} />
                <Param label={t('pagePool.info.name')} value={`${pool!.token1.code} - ${pool!.token2.code}`} />
                <Param label={t('pagePool.info.address')} value="swap.pcash" />
                <Param
                    label={t('pagePool.info.contract', { token: pool!.token1.code })}
                    value={pool!.token1.smartContract}
                />
                <Param
                    label={t('pagePool.info.contract', { token: pool!.token2.code })}
                    value={pool!.token2.smartContract}
                />
            </SectionBloksIoInfo>
        </main>
    );
}

function SectionTransactions({ poolCode }: { poolCode: string }) {
    const STEP = 10;
    const { ref, inView } = useInView({ triggerOnce: true, threshold: 0 });

    const [t] = useTranslation();
    const initialTxnTypeFilter = [TxnType.AddLiquidity, TxnType.RemoveLiquidity, TxnType.Swap];
    const [variables, setVariables] = useState<ActionsQueryVariables>({
        skip: 0,
        limit: STEP,
        sort: [ActionSortEnum.TimestampDesc],
        filters: {
            txnType: initialTxnTypeFilter,
            poolCode
        }
    });

    const isInitialTxnTypeFilterSelected = initialTxnTypeFilter.join('') === variables.filters?.txnType?.join('');
    const txnType = variables.filters?.txnType?.[0] ?? null;

    const { loading, data, previousData, error, ...actionsQuery } = useActionsQuery({
        skip: !inView,
        notifyOnNetworkStatusChange: true,
        variables
    });
    const result = data?.actions ?? previousData?.actions;
    const { pageInfo, actions } = result ?? {};
    const totalCount = pageInfo?.totalFiltered ?? 0;
    const showErrorScreen = !!error;
    const contentReady = (!!result || !loading) && !showErrorScreen;
    const isFirstLoading = !result && loading && !showErrorScreen;

    const onChangeTxnType = (newType: TxnType | null) => {
        setVariables(
            update(variables, {
                skip: () => 0,
                filters: {
                    txnType: () => (newType ? [newType] : initialTxnTypeFilter)
                }
            })
        );
    };

    return (
        <section ref={ref} className={cx('Transactions')}>
            <header className={cx('TransactionsHeader')}>
                <h2 className={cx('TransactionsTitle')}>{t('transactionsTable.title')}</h2>

                {contentReady && (
                    <TransactionTypeFilter
                        className={cx('TransactionsFilters')}
                        includes={initialTxnTypeFilter}
                        active={isInitialTxnTypeFilterSelected || !txnType ? null : txnType}
                        onChange={onChangeTxnType}
                    />
                )}
            </header>

            <TransactionsTable
                className={cx('TransactionsTable')}
                loading={loading}
                actions={actions}
                isFirstLoading={isFirstLoading}
                onClickRefetch={() => actionsQuery.refetch()}
                showErrorScreen={showErrorScreen}
                page={variables.skip! / STEP}
                pageCount={Math.ceil(totalCount / STEP)}
                onChangePage={(page) =>
                    setVariables({
                        ...variables,
                        skip: page * STEP
                    })
                }
            />
        </section>
    );
}

export default memo(PagePool);
