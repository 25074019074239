import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import App from 'components/App/App';
import i18n from './i18n';
import reportWebVitals from './reportWebVitals';
import 'styles/index.scss';

i18n();

const history = createBrowserHistory();

async function loadPolyfills() {
    if (typeof window.IntersectionObserver === 'undefined') {
        await import('intersection-observer');
    }
}

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://c646a035d932422db03e624138133126@bugs.list.family/6',
        integrations: [
            new Integrations.BrowserTracing({
                // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
            })
        ],
        environment: process.env.REACT_APP_ENV,
        release: process.env.REACT_APP_SENTRY_RELEASE,
        tracesSampleRate: 1.0
    });
}

ReactDOM.render(
    <React.StrictMode>
        <App history={history} />
    </React.StrictMode>,
    document.getElementById('root')
);

loadPolyfills();
reportWebVitals();
