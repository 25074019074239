import React from 'react';
import { Route } from 'react-router-dom';
import { ROUTES_MAP } from 'constant';
import OpenLetters from 'components/OpenLetters/OpenLetters';

export default function OpenLettersPage() {
    return (
        <main style={{ flexGrow: 1, overflowX: 'hidden' }}>
            <Route exact component={OpenLetters} path={ROUTES_MAP.openLetters} />
        </main>
    );
}
