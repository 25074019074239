import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { ActionsQueryVariables, ActionSortEnum, useActionsQuery, TxnType } from 'apollo/main/generated';
import TransactionsTable from 'components/TransactionsTable/TransactionsTable';
import TransactionTypeFilter from 'components/TransactionTypeFilter/TransactionTypeFilter';
import update from 'immutability-helper';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import styles from './AccountDetails.module.scss';
import TransactionTokenFilter from '../TransactionTokenFilter/TransactionTokenFilter';

export default function SectionTransactions({ eosAccount = '' }: any) {
    const STEP = 10;
    const { ref, inView } = useInView({ triggerOnce: true, threshold: 0 });

    const [t] = useTranslation();
    const [variables, setVariables] = useState<ActionsQueryVariables>({
        skip: 0,
        limit: STEP,
        sort: [ActionSortEnum.TimestampDesc],
        filters: {
            eosAccount
        }
    });

    const [token, setToken] = React.useState<number | null>(null);

    const txnType = variables.filters?.txnType?.[0] ?? null;
    const { loading, data, previousData, error, ...actionsQuery } = useActionsQuery({
        notifyOnNetworkStatusChange: true,
        variables,
        skip: !inView
    });
    const result = data?.actions ?? previousData?.actions;
    const { pageInfo, actions } = result ?? {};
    const totalCount = pageInfo?.totalFiltered ?? 0;
    const showErrorScreen = !!error;
    const contentReady = (!!result || !loading) && !showErrorScreen;
    const isFirstLoading = !result && loading && !showErrorScreen;

    const onChangeTxnType = (newType: TxnType | null) => {
        if (newType !== TxnType.CashIssue && newType !== TxnType.CashRetire) {
            setVariables(
                update(variables, {
                    skip: () => 0,
                    filters: {
                        cashTokenId: () => undefined,
                        txnType: () => (newType ? [newType] : undefined)
                    }
                })
            );

            setToken(null);
        } else {
            setVariables(
                update(variables, {
                    skip: () => 0,
                    filters: {
                        cashTokenId: () => token || undefined,
                        txnType: () => (newType ? [newType] : undefined)
                    }
                })
            );
        }
    };

    const onChangeToken = (id?: number | null) => {
        setVariables(
            update(variables, {
                skip: () => 0,
                filters: {
                    cashTokenId: () => id || undefined,
                    txnType: () => (txnType ? [txnType] : undefined)
                }
            })
        );

        setToken(id ?? null);
    };

    useEffect(() => {
        setVariables({
            skip: 0,
            limit: STEP,
            sort: [ActionSortEnum.TimestampDesc],
            filters: {
                eosAccount
            }
        });
    }, [eosAccount]);

    return (
        <section ref={ref} className={styles.Transactions}>
            <header className={styles.TransactionsHeader}>
                <h2 className={styles.TransactionsTitle}>{t('transactionsTable.title')}</h2>

                {contentReady && (
                    <div className={styles.Filters}>
                        <TransactionTypeFilter
                            className={cn(styles.Filter, styles.FilterType)}
                            active={txnType}
                            onChange={onChangeTxnType}
                        />
                        {(txnType === TxnType.CashIssue || txnType === TxnType.CashRetire) && (
                            <TransactionTokenFilter
                                className={cn(styles.Filter, styles.FilterToken)}
                                active={token}
                                onChange={onChangeToken}
                            />
                        )}
                    </div>
                )}
            </header>

            <TransactionsTable
                loading={loading}
                actions={actions}
                className={styles.TransactionsTable}
                isFirstLoading={isFirstLoading}
                onClickRefetch={() => actionsQuery.refetch()}
                showErrorScreen={showErrorScreen}
                page={variables.skip! / STEP}
                pageCount={Math.ceil(totalCount / STEP)}
                onChangePage={(page) =>
                    setVariables({
                        ...variables,
                        skip: page * STEP
                    })
                }
            />
        </section>
    );
}
