import React, { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { MlnkCollectParams, useAccountMlnkBalanceQuery, useMlnkBalanceSubscription } from 'apollo/main/generated';
import BookmarkBtn from 'components/BookmarkBtn/BookmarkBtn';
import Button from 'ui/Button_DEPRECATED/Button';
import { AngleDownIcon } from 'ui/Icons/Icons';
import Loader from 'components/Loader/Loader';
import { BREAKPOINT_IPAD, BREAKPOINT_LAPTOP, PAYCASH_LANDING } from 'constant';
import { useIsTouchableDevice, useModal, useWidthCondition } from 'helpers';
import addThousandsSeparator from 'utils/addThousandsSeparator/addThousandsSeparator';
import MLNKIcon from 'assets/images/tokens/mlnk.svg';
import BustAnimation from './BustAnimation/BustAnimation';
import ModalCollectMLNK from './ModalCollectMLNK/ModalCollectMLNK';
import styles from './AccountBanner.module.scss';

const cx = classNames.bind(styles);

function Banner({
    account = '',
    isFavorite,
    mlnkCollectParams,
    onChangeLoad
}: {
    account: string;
    onChangeLoad(loading: boolean): void;
    mlnkCollectParams: MlnkCollectParams;
    isFavorite?: boolean;
}) {
    const isTouchableDevice = useIsTouchableDevice();
    const modalCollectMLNK = useModal();
    const fullSizeBanner = useModal();
    const bustAnimation = useModal();
    const asideRef = useRef<HTMLDivElement>(null);
    const [isLaptopOrLess] = useWidthCondition((w) => w < BREAKPOINT_LAPTOP);
    const [isIpadOrLess] = useWidthCondition((w) => w < BREAKPOINT_IPAD);
    const [t] = useTranslation();
    const bannerParallaxRef = useRef<HTMLDivElement>(null);
    const accountMLNKBalanceQuery = useAccountMlnkBalanceQuery({
        variables: { name: account },
        fetchPolicy: 'cache-and-network'
    });

    const loadingMlnkBalance = accountMLNKBalanceQuery.loading;
    const mlnkBalance = accountMLNKBalanceQuery.data?.eosAccount?.mlnkBalance;
    const isEmpty = Number(mlnkBalance) <= 0;

    useEffect(() => {
        onChangeLoad(accountMLNKBalanceQuery.loading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountMLNKBalanceQuery.loading]);

    useMlnkBalanceSubscription({
        variables: {
            eosAccount: account
        },
        onSubscriptionData: () =>
            // { subscriptionData }
            {
                if (
                    /**
                     * run it only if collect process started
                     * check, that balance is decreased. This mean user
                     * collected MLNK, and we have to start animation
                     */
                    (!isIpadOrLess && modalCollectMLNK.isOpen) ||
                    (isIpadOrLess && fullSizeBanner.isOpen)
                ) {
                    fullSizeBanner.close();
                    modalCollectMLNK.close();
                    bustAnimation.open();
                }
            }
    });

    useEffect(() => {
        if (isLaptopOrLess) {
            if (bannerParallaxRef.current) bannerParallaxRef.current.style.transform = '';
            return;
        }

        const parallax = (ev: MouseEvent) => {
            const center = {
                y: document.body.clientHeight / 2,
                x: document.body.clientWidth / 2
            };

            const el = bannerParallaxRef.current;
            if (!el) return;
            el.style.transform = `translate(${Math.round((center.x - ev.pageX) / 50)}px, ${Math.round(
                (center.y - (ev.pageY - window.scrollY)) / 50
            )}px)`;
        };

        window.addEventListener('mousemove', parallax);
        // eslint-disable-next-line consistent-return
        return () => {
            window.removeEventListener('mousemove', parallax);
        };
    }, [isLaptopOrLess]);

    const onClickBanner = () => {
        if (loadingMlnkBalance) return;

        if (isIpadOrLess) {
            fullSizeBanner.open();
        } else {
            modalCollectMLNK.open();
        }
    };

    return (
        <div className={cx('AsideWrapper')}>
            <div ref={asideRef} className={cx('AsideContent', fullSizeBanner.isOpen && 'full-size')}>
                <BookmarkBtn
                    isFavorite={isFavorite!}
                    type="account"
                    eosAccount={account}
                    className={cx('BtnFavorite')}
                />
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                <aside
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                    role="button"
                    className={cx('Aside', isEmpty && 'empty')}
                    onClick={isEmpty ? undefined : onClickBanner}
                >
                    {/* eslint-disable-next-line react/button-has-type */}
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            fullSizeBanner.close();
                        }}
                        className={cx('BtnClose', fullSizeBanner.isOpen && 'visible')}
                    >
                        <AngleDownIcon />
                    </button>
                    {/* Background */}
                    <div className={cx('Parallax')}>
                        <div ref={bannerParallaxRef} className={cx('ParallaxBG')} />
                    </div>
                    {/* This text renders on banner on desktop and small banner on ipad and less */}
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {loadingMlnkBalance ? (
                        <div className={cx('AsideLoader')}>
                            <Loader />
                        </div>
                    ) : !isEmpty ? (
                        <div className={cx('BaseContent')}>
                            <p className={cx('Text')}>
                                <span>{t('pageAccount.banner.receive')}</span>
                                {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
                                <DynamicMlnkBalanceText mlnkBalance={mlnkBalance} />
                                <span>{t('pageAccount.banner.mln')}</span>
                            </p>

                            <p className={cx('Account')}>
                                {t('pageAccount.banner.account', {
                                    account,
                                    context: isIpadOrLess ? 'bottom' : ''
                                })}
                            </p>

                            <span className={cx('BtnCollect')}>
                                {t('pageAccount.banner.btnCollect')}
                                <img src={MLNKIcon} alt="MLNK" />
                            </span>
                        </div>
                    ) : (
                        <div className={cx('Empty')}>
                            <p>{t('pageAccount.banner.empty')}</p>
                        </div>
                    )}

                    {/* This section is only for mobile. Became visible after clicking small banner (BaseContent) */}
                    <div className={cx('AdditionalContent')}>
                        <img className={cx('AdditionalContentTokenIcon')} src={MLNKIcon} alt="MLNK" />

                        <h2 className={cx('AdditionalContentTitle')}>{t('modalCollectMLNK.info.title')}</h2>

                        <p className={cx('AdditionalContentAmount')}>
                            {t('modalCollectMLNK.info.amount', {
                                account,
                                amount: addThousandsSeparator(mlnkBalance)
                            })}
                        </p>

                        <p className={cx('AdditionalContentDescription')}>
                            <Trans i18nKey="modalCollectMLNK.info.description">
                                <a href={PAYCASH_LANDING} target="_blank" rel="noreferrer">
                                    .
                                </a>
                            </Trans>
                        </p>

                        <div className={cx('AdditionalContentActions')}>
                            {isTouchableDevice && (
                                <Button tag="a" target="_blank" href={mlnkCollectParams.deeplink} color="primary">
                                    {t('modalCollectMLNK.btnPay')}
                                </Button>
                            )}
                            {isTouchableDevice && (
                                <Button onClick={modalCollectMLNK.open} color="link">
                                    {t('modalCollectMLNK.btnPay_details')}
                                </Button>
                            )}
                            {!isTouchableDevice && (
                                <Button onClick={modalCollectMLNK.open} color="primary">
                                    {t('modalCollectMLNK.btnPay_desktop')}
                                </Button>
                            )}
                        </div>
                    </div>
                </aside>
            </div>
            <ModalCollectMLNK
                {...modalCollectMLNK}
                mlnkCollectParams={mlnkCollectParams}
                account={account}
                mlnkBalance={mlnkBalance!}
            />

            <BustAnimation {...bustAnimation} />
        </div>
    );
}

function DynamicMlnkBalanceText({ mlnkBalance = '' }: any) {
    const ref = useRef<HTMLElement>(null);
    const [isLaptopOrLess] = useWidthCondition((w) => w < BREAKPOINT_LAPTOP);

    useEffect(() => {
        if (!ref.current) return;

        if (isLaptopOrLess) {
            ref.current.style.fontSize = '';
            return;
        }

        const MAX_SIZE = 178;

        const resize = (fontSize = 38) => {
            if (ref.current!.offsetWidth > MAX_SIZE) {
                ref.current!.style.fontSize = `${fontSize}px`;
                if (fontSize > 10) resize(fontSize - 1);
            }
        };
        resize();
    }, [mlnkBalance, isLaptopOrLess]);

    return <b ref={ref}>{addThousandsSeparator(mlnkBalance)}</b>;
}

export default Banner;
