import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import Button from 'ui/Button_DEPRECATED/Button';
import { useTranslation } from 'react-i18next';
import { PlusIcon, MinusIcon, ArrowLeftRightIcon, ChevronLeftIcon, ChevronRightIcon } from 'ui/Icons/Icons';
import { scrollInto, useWidthCondition } from 'helpers';
import { BREAKPOINT_LANDSCAPE, BREAKPOINT_TABLET } from 'constant';
import getImage from 'helpers/getImage/getImage';
import styles from './AboutCashTokensTutorials.module.scss';

const cx = classNames.bind(styles);
type Tab = 'issue' | 'burn' | 'buy';

function TutorialStepDots({
    currentStep,
    stepChanged,
    stepTimeout,
    stepsCount
}: {
    currentStep: number;
    stepChanged: number;
    stepTimeout: number;
    stepsCount: number;
}) {
    const fakeArray = useMemo(() => new Array(stepsCount).fill(null), [stepsCount]);

    return (
        <ol className={cx('TutorialStepDots')}>
            {fakeArray.map((_, i) => (
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                <TutorialStepDot
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    step={i}
                    currentStep={currentStep}
                    stepChanged={stepChanged}
                    stepTimeout={stepTimeout}
                />
            ))}
        </ol>
    );
}

function TutorialStepDot({
    currentStep,
    stepChanged,
    stepTimeout,
    step
}: {
    currentStep: number;
    stepChanged: number;
    stepTimeout: number;
    step: number;
}) {
    const ref = useRef<HTMLLIElement>(null);

    useEffect(() => {
        const calcProgress = () => {
            const element = ref.current;
            if (!element) return;

            const now = +new Date();
            const diff = now - stepChanged;
            // eslint-disable-next-line no-nested-ternary
            const progress = (currentStep > step ? 1 : currentStep < step ? 0 : Math.min(diff / stepTimeout, 1)) * 100;

            element.style.setProperty('--step-progress', `${progress}%`);
            window.requestAnimationFrame(calcProgress);
        };

        window.requestAnimationFrame(calcProgress);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep, stepChanged]);

    return <li ref={ref} />;
}

function Tutorials() {
    const [t] = useTranslation();
    const [isTabletOrLess] = useWidthCondition((w) => w < BREAKPOINT_TABLET);
    const [isLandscapeOrLess] = useWidthCondition((w) => w < BREAKPOINT_LANDSCAPE);
    const [activeTab, setActiveTab] = useState<Tab>('issue');
    const STEP_TIMEOUT = useMemo(() => 15000, []);
    const [step, setStep] = useState(0);
    const [stepChanged, setStepChanged] = useState(0);
    const tutorialSteps = t(`pageAboutCashTokens.tutorials.content.${activeTab}.steps`, {
        returnObjects: true
    }) as { title?: string; content?: string }[];

    const MIN_STEP = 0;
    const MAX_STEP = tutorialSteps.length - 1;

    const onClickPrevStep = () => {
        setStep(step - 1);
    };

    const onClickNextStep = () => {
        setStep(step + 1);
    };

    useEffect(() => {
        setStep(MIN_STEP);
    }, [activeTab]);

    useEffect(() => {
        setStepChanged(+new Date());
        const tid = setTimeout(() => {
            const nextStep = step + 1;

            setStep(nextStep > MAX_STEP ? MIN_STEP : nextStep);
        }, STEP_TIMEOUT);

        return () => {
            clearTimeout(tid);
        };
    }, [step, activeTab, STEP_TIMEOUT, MAX_STEP]);

    const MobileImages = (
        <div className={cx('MobileWrapper')}>
            {tutorialSteps.map((_, i) => (
                <img
                    // eslint-disable-next-line react/no-array-index-key
                    key={activeTab + i}
                    className={cx({ active: i === step })}
                    srcSet={getImage(`tutorial-${activeTab}`, 'ru', 2, i + 1)}
                    src={getImage(`tutorial-${activeTab}`, 'ru', 1, i + 1)}
                    alt={`Tutorial ${activeTab}`}
                />
            ))}
        </div>
    );

    const Tabs = (
        <div className={cx('Tabs')}>
            <button
                type="button"
                className={cx({ active: activeTab === 'issue' })}
                onClick={() => setActiveTab('issue')}
            >
                <PlusIcon />
                <span>{t('pageAboutCashTokens.tutorials.content.issue.title')}</span>
            </button>
            <button type="button" className={cx({ active: activeTab === 'burn' })} onClick={() => setActiveTab('burn')}>
                <MinusIcon />
                <span>{t('pageAboutCashTokens.tutorials.content.burn.title')}</span>
            </button>
            <button type="button" className={cx({ active: activeTab === 'buy' })} onClick={() => setActiveTab('buy')}>
                <ArrowLeftRightIcon />
                <span>{t('pageAboutCashTokens.tutorials.content.buy.title')}</span>
            </button>
        </div>
    );

    return (
        <section id="tutorials" className={cx('Component')}>
            <div className={cx('Container')}>
                <div className={cx('Wrapper')}>
                    {isLandscapeOrLess && MobileImages}

                    {isTabletOrLess && Tabs}
                    <div className={cx('Content')}>
                        {!isTabletOrLess && Tabs}
                        <div className={cx('Tutorial')}>
                            <p className={cx('TutorialStep')}>
                                {t('pageAboutCashTokens.tutorials.step', {
                                    stepNumber: step + 1
                                })}
                            </p>

                            <TutorialStepDots
                                currentStep={step}
                                stepsCount={tutorialSteps.length}
                                stepChanged={stepChanged}
                                stepTimeout={STEP_TIMEOUT}
                            />

                            <div className={cx('TutorialContent')}>
                                <h3 className={cx('TutorialContentTitle')}>{tutorialSteps[step]?.title}</h3>
                                <p className={cx('TutorialContentText')}>{tutorialSteps[step]?.content}</p>

                                <div className={cx('TutorialActions')}>
                                    <div className={cx('NavigationBtnGroup')}>
                                        <button
                                            onClick={onClickPrevStep}
                                            type="button"
                                            disabled={step === MIN_STEP}
                                            className={cx('NavigationBtn')}
                                        >
                                            <ChevronLeftIcon />
                                        </button>
                                        <button
                                            onClick={onClickNextStep}
                                            disabled={step === MAX_STEP}
                                            type="button"
                                            className={cx('NavigationBtn')}
                                        >
                                            <ChevronRightIcon />
                                        </button>
                                    </div>

                                    <Button
                                        color="tertairy-green"
                                        onClick={(e) => {
                                            scrollInto(e, 'applications', -50);
                                        }}
                                    >
                                        {t('pageAboutCashTokens.tutorials.btnDownload')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {!isLandscapeOrLess && MobileImages}
                </div>
            </div>
        </section>
    );
}

export default Tutorials;
