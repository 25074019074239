import React, { PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames/bind';
import { SortIcon } from '../Icons/Icons';
import styles from './Table.module.scss';

const cx = classNames.bind(styles);

type Props = PropsWithChildren<{
    className?: string;
    inactiveRows?: boolean;
    colls: {
        label: ReactNode;
        className?: string;
        key?: string;
        onClick?: () => void;
        /** if param exists - column is sortable. `null` means - it is not active sort */
        sort?: 'ASC' | 'DESC' | null;
    }[];
}>;

function Table({ children, className = '', colls, inactiveRows }: Props) {
    return (
        <table className={cx('Table', className, inactiveRows && 'inactive')}>
            <thead>
                <tr>
                    {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
                    {colls.map(({ className = '', sort, label, key, onClick }, i) => {
                        const isSortable = sort !== undefined;

                        return (
                            <th key={key || i} className={className}>
                                {isSortable ? (
                                    <button className={cx('HeaderCollLabel')} onClick={onClick} type="button">
                                        {label}

                                        <SortIcon className={cx('SortIcon', sort)} />
                                    </button>
                                ) : (
                                    <span className={cx('HeaderCollLabel')}>{label}</span>
                                )}
                            </th>
                        );
                    })}
                </tr>
            </thead>
            <tbody>{children}</tbody>
        </table>
    );
}

export default Table;
