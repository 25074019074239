import React from 'react';
import { useTranslation } from 'react-i18next';
import { useExchangeSystemStatusQuery } from 'apollo/main/generated';
import { useFormatDate, useWidthCondition } from 'helpers';
import { BREAKPOINT_PHONE } from 'constant';
import TechWorksImage2x from 'assets/images/tech-works-stub@2x.png';
import TechWorksImage1x from 'assets/images/tech-works-stub@1x.png';
import styles from './TechWorksStub.module.scss';

export default function TechWorksStub() {
    const [t] = useTranslation();
    const formatDate = useFormatDate();
    const [isMobileOrLess] = useWidthCondition((w) => w < BREAKPOINT_PHONE);

    const { data } = useExchangeSystemStatusQuery();

    const { description, endTime } = data?.exchangeSystemStatus?.maintenance ?? {};

    const time = endTime && endTime !== 'soon' ? new Date(endTime.toUpperCase()).toISOString().replace('Z', '') : '';

    return (
        <div className={styles.Root}>
            <div className={styles.ImageWrapper}>
                <img
                    className={styles.Image}
                    srcSet={`${TechWorksImage2x} 2x`}
                    src={TechWorksImage1x}
                    alt="Cryptocurrencies"
                />
            </div>
            <div className={styles.Title}>{t('pageExchange.techWorks.title')}</div>
            <div className={styles.Description}>
                {description && <div className={styles.DescriptionItem}>{description}</div>}
                {time && (
                    <span>
                        <span>{t('pageExchange.techWorks.estimatedTime')}</span>
                        {!isMobileOrLess && <span> - </span>}
                        <span className={styles.Time}>{formatDate(time, 'dd LLL. HH:mm')}</span>
                    </span>
                )}
            </div>
        </div>
    );
}
