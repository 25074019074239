import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AboutCashTokensIntro from 'components/AboutCashTokensIntro/AboutCashTokensIntro';
import AboutCashTokensCommissions from 'components/AboutCashTokensCommissions/AboutCashTokensCommissions';
import AboutCashTokensStatistics from 'components/AboutCashTokensStatistics/AboutCashTokensStatistics';
import AboutCashTokensGuarantees from 'components/AboutCashTokensGuarantees/AboutCashTokensGuarantees';
import AboutCashTokensInheritance from 'components/AboutCashTokensInheritance/AboutCashTokensInheritance';
import Applications from 'components/AboutCashTokensApplications/AboutCashTokensApplications';
import AboutCashTokensIncome from 'components/AboutCashTokensIncome/AboutCashTokensIncome';
import Tutorials from 'components/AboutCashTokensTutorials/AboutCashTokensTutorials';
import Navigation from 'components/AboutCashTokensNavigation/AboutCashTokensNavigation';
import styles from './AboutCashTokensPage.module.scss';

export default function AboutCashTokensPage({ location }: RouteComponentProps) {
    React.useEffect(() => {
        if (location.hash) {
            const target = document.getElementById(location.hash.slice(1));

            if (target) {
                setTimeout(() => {
                    window.scrollTo({
                        left: 0,
                        top: target.offsetTop - (document.getElementById('header')?.offsetHeight || 0),
                        behavior: 'smooth'
                    });
                }, 200);
            }
        }
    }, [location.hash]);

    return (
        <>
            <Navigation />
            <main className={styles.Root}>
                <AboutCashTokensIntro />
                <AboutCashTokensCommissions />
                <AboutCashTokensStatistics />
                <AboutCashTokensGuarantees />
                <section className={styles.InheritanceAndBenefits}>
                    <div className={styles.InheritanceAndBenefitsContainer}>
                        <div id="income" className={styles.InheritanceAndBenefitsColumn}>
                            <AboutCashTokensIncome />
                        </div>
                        <div id="inheritance" className={styles.InheritanceAndBenefitsColumn}>
                            <AboutCashTokensInheritance />
                        </div>
                    </div>
                </section>
                <Applications />
                <Tutorials />
            </main>
        </>
    );
}
