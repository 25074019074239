import { PoolsQueryVariables, PoolsSortEnum, usePoolsQuery } from 'apollo/main/generated';
import classNames from 'classnames/bind';
import PoolsTable from 'components/PoolsTable/PoolsTable';
import SearchField from 'components/SearchField/SearchField';
import SortDropdown from 'components/SortDropdown/SortDropdown';
import { BREAKPOINT_LAPTOP, ROUTES_MAP } from 'constant';
import { useWidthCondition } from 'helpers';
import update from 'immutability-helper';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { RouteComponentProps } from 'react-router';
import styles from './Pools.module.scss';

const cx = classNames.bind(styles);
const STEP = 10;
function PagePools({ history }: RouteComponentProps) {
    const [t] = useTranslation();
    const [isLaptopOrLess] = useWidthCondition((w) => w < BREAKPOINT_LAPTOP);

    const [variables, setVariables] = useState<PoolsQueryVariables>({
        skip: 0,
        limit: STEP,
        sort: PoolsSortEnum.LiquidityDesc,
        filters: {
            liquidity: { from: '100' },
            tokenNames: new URLSearchParams(window.location.search).get('query') ?? ''
        }
    });

    const { loading, data, previousData, error, ...poolsQuery } = usePoolsQuery({
        variables,
        notifyOnNetworkStatusChange: true
    });

    const result = data?.pools ?? previousData?.pools;
    const { pageInfo, pools } = result ?? {};
    const totalCount = pageInfo?.totalFiltered ?? 0;
    const showErrorScreen = !!error;
    const contentReady = (!!result || !loading) && !showErrorScreen;
    const isFirstLoading = !result && loading && !showErrorScreen;

    const onChangeSearch = (tokenNames: string) => {
        setVariables(
            update(variables, {
                filters: {
                    tokenNames: () => tokenNames
                }
            })
        );

        history.replace({
            pathname: ROUTES_MAP.pools,
            search: `query=${tokenNames}`
        });
    };

    const onChangeSort = (sort: PoolsSortEnum) => {
        setVariables(
            update(variables, {
                skip: () => 0,
                sort: () => sort
            })
        );
    };

    return (
        <main className={cx('Component')}>
            <div className={cx('HeaderRow')}>
                <h1 className={cx('Title')}>{t('poolsTable.title')}</h1>

                {contentReady && (
                    <div className={cx('Filters')}>
                        <SearchField
                            className={cx('SearchField')}
                            inputProps={{ placeholder: t('global.search') }}
                            value={variables.filters?.tokenNames ?? ''}
                            delay={250}
                            onChange={onChangeSearch}
                        />

                        {isLaptopOrLess && (
                            <SortDropdown
                                options={[
                                    {
                                        label: t('poolsTable.param.token.title_ASC'),
                                        value: PoolsSortEnum.Tokens
                                    },
                                    {
                                        label: t('poolsTable.param.token.title_DESC'),
                                        value: PoolsSortEnum.TokensDesc
                                    },
                                    {
                                        label: t('poolsTable.param.liquidity.title_ASC'),
                                        value: PoolsSortEnum.Liquidity
                                    },
                                    {
                                        label: t('poolsTable.param.liquidity.title_DESC'),
                                        value: PoolsSortEnum.LiquidityDesc
                                    },
                                    {
                                        label: t('poolsTable.param.volume.title_ASC'),
                                        value: PoolsSortEnum.Volume
                                    },
                                    {
                                        label: t('poolsTable.param.volume.title_DESC'),
                                        value: PoolsSortEnum.VolumeDesc
                                    },
                                    {
                                        label: t('poolsTable.param.income.title_ASC'),
                                        value: PoolsSortEnum.RelativeProfit_24h
                                    },
                                    {
                                        label: t('poolsTable.param.income.title_DESC'),
                                        value: PoolsSortEnum.RelativeProfit_24hDesc
                                    },
                                    {
                                        label: t('poolsTable.param.apy.title_ASC'),
                                        value: PoolsSortEnum.RelativeProfitAnnual
                                    },
                                    {
                                        label: t('poolsTable.param.apy.title_DESC'),
                                        value: PoolsSortEnum.RelativeProfitAnnualDesc
                                    }
                                ]}
                                label={t('global.sort')}
                                className={cx('SortDropdown')}
                                active={variables.sort!}
                                onChange={(sort) => {
                                    setVariables({
                                        ...variables,
                                        skip: 0,
                                        sort
                                    });
                                }}
                            />
                        )}
                    </div>
                )}
            </div>
            <PoolsTable
                loading={loading}
                pools={pools}
                sort={variables.sort}
                page={variables.skip! / STEP}
                pageCount={Math.ceil(totalCount / STEP)}
                isFirstLoading={isFirstLoading}
                onClickRefetch={() => poolsQuery.refetch()}
                showErrorScreen={showErrorScreen}
                searchPart={variables.filters?.tokenNames ?? ''}
                onChangePage={(page) =>
                    setVariables({
                        ...variables,
                        skip: page * STEP
                    })
                }
                onChangeSort={onChangeSort}
            />
        </main>
    );
}

export default PagePools;
