import { PoolsQuery, PoolsSortEnum } from 'apollo/main/generated';
import classNames from 'classnames/bind';
import BookmarkBtn from 'components/BookmarkBtn/BookmarkBtn';
import Button from 'ui/Button_DEPRECATED/Button';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useHistory } from 'react-router';
import Changed from 'components/Changed/Changed';
import FormatNumber from 'components/FormatNumber/FormatNumber';
import { RefreshIcon } from 'ui/Icons/Icons';
import Table from 'ui/Table/Table';
import Pagination from 'ui/Pagination/Pagination';
import PageState from 'components/PageState/PageState';
import PoolCard from 'components/PoolCard/PoolCard';
import { BREAKPOINT_LANDSCAPE, BREAKPOINT_LAPTOP, ROUTES_MAP } from 'constant';
import { useWidthCondition } from 'helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './PoolsTable.module.scss';

const cx = classNames.bind(styles);

const SORT_TYPES = {
    Token: {
        ASC: PoolsSortEnum.Tokens,
        DESC: PoolsSortEnum.TokensDesc
    },
    Liquidity: {
        ASC: PoolsSortEnum.Liquidity,
        DESC: PoolsSortEnum.LiquidityDesc
    },
    Volume: {
        ASC: PoolsSortEnum.Volume,
        DESC: PoolsSortEnum.VolumeDesc
    },
    Profit: {
        ASC: PoolsSortEnum.RelativeProfit_24h,
        DESC: PoolsSortEnum.RelativeProfit_24hDesc
    },
    APY: {
        ASC: PoolsSortEnum.RelativeProfitAnnual,
        DESC: PoolsSortEnum.RelativeProfitAnnualDesc
    }
};

type SortType = (typeof SORT_TYPES)[keyof typeof SORT_TYPES];

function PoolsTable({
    sort,
    onChangeSort,
    page,
    pageCount,
    onChangePage,
    loading,
    pools,
    className,
    onClickRefetch,
    showErrorScreen,
    isFirstLoading,
    isShortEmptyText,
    searchPart
}: {
    pools?: NonNullable<NonNullable<PoolsQuery>['pools']>['pools'];
    sort?: PoolsSortEnum | null;
    onChangeSort?(v: PoolsSortEnum): void;
    page: number;
    pageCount: number;
    loading?: boolean;
    onChangePage(v: number): void;
    className?: string;
    onClickRefetch?: () => void;
    showErrorScreen?: boolean;
    isFirstLoading?: boolean;
    isShortEmptyText?: boolean;
    searchPart?: string;
}) {
    const [t] = useTranslation();
    const history = useHistory();
    const [isLaptopOrLess] = useWidthCondition((w) => w < BREAKPOINT_LAPTOP);
    const [isLandscapeOrLess] = useWidthCondition((w) => w < BREAKPOINT_LANDSCAPE);
    const getSortType = ({ ASC, DESC }: SortType) =>
        // eslint-disable-next-line no-nested-ternary
        onChangeSort ? (sort === ASC ? 'ASC' : sort === DESC ? 'DESC' : null) : undefined;
    const createOnClickSort = ({ ASC, DESC }: SortType) =>
        // eslint-disable-next-line no-nested-ternary
        onChangeSort ? () => onChangeSort(sort === ASC ? DESC : sort === DESC ? ASC : ASC) : undefined;

    if (showErrorScreen)
        return (
            <PageState.Error title={t('poolsTable.error.title')} description={t('poolsTable.error.description')}>
                <Button disabled={loading} onClick={onClickRefetch} color="tertairy-green" startIcon={<RefreshIcon />}>
                    {t('poolsTable.error.btn')}
                </Button>
            </PageState.Error>
        );

    if (isFirstLoading) return <PageState.Loading />;

    return (
        <div className={cx('Component', className || '', loading && 'loading', !pools?.length && 'full-size')}>
            {loading && <PageState.Loading className={cx('LoaderWrapper')} />}

            {pools?.length ? (
                <>
                    {isLaptopOrLess && (
                        <ul className={cx('List')}>
                            {pools?.map((pool) => {
                                if (!pool) return null;

                                return (
                                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                                    <li
                                        onClick={() => history.push(ROUTES_MAP.pool(pool.code))}
                                        className={cx('ListItem')}
                                        key={pool.code}
                                    >
                                        <div className={cx('ListItemHeader')}>
                                            <PoolCard
                                                mlnkIncomeDetails={pool.mlnkIncomeDetails}
                                                code={pool.code}
                                                highlight={searchPart}
                                                className={cx('ListItemPairCard')}
                                                tokens={[
                                                    {
                                                        name: pool.token1.code,
                                                        smartContract: pool.token1.smartContract,
                                                        icon: pool.token1.logo ?? ''
                                                    },
                                                    {
                                                        name: pool.token2.code,
                                                        smartContract: pool.token2.smartContract,
                                                        icon: pool.token2.logo ?? ''
                                                    }
                                                ]}
                                            />
                                            {!isLandscapeOrLess && (
                                                <BookmarkBtn
                                                    isFavorite={pool.isFavorite}
                                                    type="pool"
                                                    isSmall
                                                    poolCode={pool.code}
                                                    className={cx(Boolean(pool.isFavorite) && 'BtnBookmark')}
                                                />
                                            )}
                                        </div>

                                        <div className={cx('ListItemParamGroup')}>
                                            <p className={cx('ListItemParam')}>
                                                <span className={cx('ListItemParamLabel')}>
                                                    {t('poolsTable.param.liquidity.title')}
                                                </span>
                                                <span className={cx('ListItemParamValue')}>
                                                    <FormatNumber value={pool.liquidity} prefix="$" />

                                                    <Changed period="noText" growth={pool.liquidityGrowth} />
                                                </span>
                                            </p>
                                            <p className={cx('ListItemParam')}>
                                                <span className={cx('ListItemParamLabel')}>
                                                    {t('poolsTable.param.volume.title')}
                                                </span>
                                                <span className={cx('ListItemParamValue')}>
                                                    <FormatNumber value={pool.volume24h} prefix="$" />
                                                    <Changed period="noText" growth={pool.volume24hGrowth} />
                                                </span>
                                            </p>
                                            <p className={cx('ListItemParam')}>
                                                <span className={cx('ListItemParamLabel')}>
                                                    {t('poolsTable.param.income.title')}
                                                </span>
                                                <span className={cx('ListItemParamValue')}>
                                                    <FormatNumber value={pool.relative24hProfit} suffix="%" />
                                                    <Changed period="noText" growth={pool.profit24hGrowth} />
                                                </span>
                                            </p>
                                            <p className={cx('ListItemParam')}>
                                                <span className={cx('ListItemParamLabel')}>
                                                    {t('poolsTable.param.apy.title')}
                                                </span>
                                                <span className={cx('ListItemParamValue')}>
                                                    <FormatNumber value={pool.relativeAnnualProfit} suffix="%" />
                                                </span>
                                            </p>
                                        </div>
                                        {isLandscapeOrLess && (
                                            <BookmarkBtn
                                                isFavorite={pool.isFavorite}
                                                type="pool"
                                                poolCode={pool.code}
                                                className={cx(
                                                    'ListItemBtnBookmark',
                                                    Boolean(pool.isFavorite) && 'BtnBookmark'
                                                )}
                                            />
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                    {!isLaptopOrLess && (
                        <Table
                            colls={[
                                {
                                    sort: getSortType(SORT_TYPES.Token),
                                    label: t('poolsTable.param.token.title'),
                                    onClick: createOnClickSort(SORT_TYPES.Token)
                                },
                                {
                                    sort: getSortType(SORT_TYPES.Liquidity),
                                    label: t('poolsTable.param.liquidity.title'),
                                    onClick: createOnClickSort(SORT_TYPES.Liquidity)
                                },
                                {
                                    sort: getSortType(SORT_TYPES.Volume),
                                    label: t('poolsTable.param.volume.title'),
                                    onClick: createOnClickSort(SORT_TYPES.Volume)
                                },
                                {
                                    sort: getSortType(SORT_TYPES.Profit),
                                    label: t('poolsTable.param.income.title'),
                                    onClick: createOnClickSort(SORT_TYPES.Profit)
                                },
                                {
                                    sort: getSortType(SORT_TYPES.Profit),
                                    label: t('poolsTable.param.apy.title'),
                                    onClick: createOnClickSort(SORT_TYPES.Profit)
                                },
                                {
                                    label: null
                                }
                            ]}
                        >
                            {pools.map(
                                (pool) =>
                                    pool && (
                                        <tr onClick={() => history.push(ROUTES_MAP.pool(pool.code))} key={pool.code}>
                                            <td>
                                                <PoolCard
                                                    code={pool.code}
                                                    highlight={searchPart}
                                                    mlnkIncomeDetails={pool.mlnkIncomeDetails}
                                                    tokens={[
                                                        {
                                                            name: pool.token1.code,
                                                            smartContract: pool.token1.smartContract,
                                                            icon: pool.token1.logo ?? ''
                                                        },
                                                        {
                                                            name: pool.token2.code,
                                                            smartContract: pool.token2.smartContract,
                                                            icon: pool.token2.logo ?? ''
                                                        }
                                                    ]}
                                                />
                                            </td>
                                            <td>
                                                <FormatNumber value={pool.liquidity} prefix="$" />
                                                <Changed period="noText" growth={pool.liquidityGrowth} />
                                            </td>
                                            <td>
                                                <FormatNumber value={pool.volume24h} prefix="$" />
                                                <Changed period="noText" growth={pool.volume24hGrowth} />
                                            </td>
                                            <td>
                                                <FormatNumber value={pool.relative24hProfit} suffix="%" />
                                                <Changed period="noText" growth={pool.profit24hGrowth} />
                                            </td>
                                            <td>
                                                <FormatNumber value={pool.relativeAnnualProfit} suffix="%" />
                                            </td>
                                            <td align="right">
                                                <BookmarkBtn
                                                    isSmall
                                                    poolCode={pool.code}
                                                    type="pool"
                                                    isFavorite={Boolean(pool.isFavorite)}
                                                    className={cx(Boolean(pool.isFavorite) && 'BtnBookmark')}
                                                />
                                            </td>
                                        </tr>
                                    )
                            )}
                        </Table>
                    )}

                    <Pagination
                        className={cx('Pagination')}
                        page={page}
                        pageCount={pageCount}
                        onChangePage={onChangePage}
                    />
                </>
            ) : (
                <PageState.Error
                    title={t('poolsTable.empty.title')}
                    description={t('poolsTable.empty.description', {
                        context: isShortEmptyText ? 'short' : ''
                    })}
                />
            )}
        </div>
    );
}

export default PoolsTable;
