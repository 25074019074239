import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { ChevronUpIcon, ChevronDownIcon } from 'ui/Icons/Icons';
import FormatNumber from 'components/FormatNumber/FormatNumber';
import styles from './Changed.module.scss';

const cx = classNames.bind(styles);

export type Props = {
    growth?: number | string | null;
    className?: string;
    period: 'noText' | '24h';
};

function Changed({ growth, className = '', period }: Props) {
    const [t] = useTranslation();

    // eslint-disable-next-line no-param-reassign
    growth = Number(growth ?? 0);
    const type = growth > 0 ? 'more' : 'less';

    if (period === 'noText') {
        return (
            <span className={cx(growth !== 0 && type, className, 'Component', 'noText')}>
                {type === 'less' ? (
                    <ChevronDownIcon className={styles.Icon} />
                ) : (
                    <ChevronUpIcon className={styles.Icon} />
                )}
                <FormatNumber value={Math.abs(growth)} suffix="%" />
            </span>
        );
    }

    return (
        <p className={cx('Component', growth !== 0 && type, className)}>
            {t('global.diff')} {/* eslint-disable-next-line no-nested-ternary */}
            <FormatNumber prefix={growth === 0 ? '' : growth > 0 ? '+' : '-'} value={Math.abs(growth)} suffix="%" />
        </p>
    );
}

export default Changed;
