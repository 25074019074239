import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import IntroImage1x from 'assets/images/about-cash-tokens/intro@1x.png';
import IntroImage2x from 'assets/images/about-cash-tokens/intro@2x.png';
import Button from 'ui/Button_DEPRECATED/Button';
import styles from './AboutCashTokensIntro.module.scss';

function Intro() {
    const location = useLocation();
    const [t] = useTranslation();

    return (
        <section className={styles.Component}>
            <div className={styles.Container}>
                <div className={styles.Wrapper}>
                    <div className={styles.Details}>
                        <h1 className={styles.Title}>
                            <Trans i18nKey="pageAboutCashTokens.intro.title">
                                <b />
                                <span />
                                <span className={styles.TitleHighlight} />
                            </Trans>
                        </h1>
                        <div className={styles.BtnGroup}>
                            <Button
                                tag="link"
                                to={{
                                    ...location,
                                    hash: '#statistics'
                                }}
                                color="primary"
                            >
                                {t('pageAboutCashTokens.intro.btnMore')}
                            </Button>
                        </div>
                    </div>
                    <div className={styles.ImageWrapper}>
                        <img
                            className={styles.Image}
                            srcSet={`${IntroImage2x} 2x`}
                            src={IntroImage1x}
                            alt="Cryptocurrencies"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Intro;
