import { useTotalLiquidityQuery } from 'apollo/main/generated';
import Card, { CardError, CardLoader, CardValue } from 'components/Card/Card';
import FormatNumber from 'components/FormatNumber/FormatNumber';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Chart from 'components/Chart/Chart';

const TotalLiquidity = memo(({ className = '' }: { className?: string }) => {
    const [t] = useTranslation();
    const query = useTotalLiquidityQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-only'
    });
    return (
        <Card className={className} title={t('pageHome.liquidity.title')} helpText={t('pageHome.liquidity.helpText')}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {query.error ? (
                <CardError resetError={query.refetch} disabled={query.loading} />
            ) : query.loading ? (
                <CardLoader />
            ) : (
                <>
                    {!!query.data?.totalLiquidityChart?.points.length && (
                        <CardValue
                            changed={{
                                period: '24h',
                                growth: query.data?.totalLiquidityChart?.percentageChange24h
                            }}
                        >
                            <FormatNumber value={query.data?.totalLiquidityChart?.value24h} prefix="$" />
                        </CardValue>
                    )}

                    <Chart
                        data={{
                            type: 'area',
                            data:
                                query.data?.totalLiquidityChart?.points.map((point) => ({
                                    time: point!.timestamp,
                                    value: Number(point!.value)
                                })) ?? []
                        }}
                    />
                </>
            )}
        </Card>
    );
});
export default TotalLiquidity;
