import React, { useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { ResponsivePie } from '@nivo/pie';
import { EosAccountQuery } from 'apollo/main/generated';
import FormatNumber from 'components/FormatNumber/FormatNumber';
import PoolCard from 'components/PoolCard/PoolCard';
import { BREAKPOINT_LANDSCAPE, ROUTES_MAP } from 'constant';
import { useWidthCondition, useIsTouchableDevice } from 'helpers';
import addThousandsSeparator from 'utils/addThousandsSeparator/addThousandsSeparator';
import getTokenLogo from 'helpers/getTokenLogo/getTokenLogo';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { getPieceColor } from './AccountDetails';
import { CenteredMetric } from './CenteredMetric';
import styles from './AccountDetails.module.scss';

const cx = classNames.bind(styles);

type HeldPoolsType = NonNullable<NonNullable<EosAccountQuery['eosAccount']>['heldPools']>;

type PiePiece = {
    id: string | number;
    value: number;
    data: HeldPoolsType[number][];
    color: string;
};

function HeldPools({ heldPools, account }: { account: string; heldPools: HeldPoolsType }) {
    const [t] = useTranslation();
    const [hoveredPiece, setHoveredPiece] = useState<PiePiece['id']>('');
    const [selectedPiece, setSelectedPiece] = useState<PiePiece['id']>('');
    const isTouchable = useIsTouchableDevice();
    const [isLandscapeOrLess] = useWidthCondition((w) => w < BREAKPOINT_LANDSCAPE);

    /**
     * Construct data for Chart
     * Group small pools into one piece
     */
    const pieData = useMemo(() => {
        const data: PiePiece[] = [];

        const smallPart: PiePiece = {
            id: 'small',
            value: 0,
            data: [],
            color: ''
        };

        /**
         * Get sum of all shares in list
         * Total can be 0 if user has pool without liquidity (less than 0.00001 USDCASH)
         */
        const total = heldPools.reduce((sum, item) => sum + Number(item?.shareInPortfolio ?? 0), 0);

        heldPools.forEach((pool, i) => {
            if (!pool) return;

            /**
             * Constrict pie pieces
             * If value is not small - create piece
             *
             * If total is 0 - we have to create equivalent pieces on chart
             * Example: If we have 2 pools - we have to create 2 pieces with `value=50`
             *
             * Otherwise - add it to small piece
             */
            const value = Number(pool.shareInPortfolio ?? 0);
            if (value >= 1 || !total) {
                data.push({
                    id: pool.code ?? i,
                    value: value || +(100 / heldPools.length).toFixed(2),
                    data: [pool],
                    color: getPieceColor(i, pool.code, hoveredPiece, selectedPiece)
                });
            } else {
                smallPart.data.push(pool);
                smallPart.value += value;
            }
        });

        // finalize small part if it exists
        if (smallPart.value) {
            const onePercent = Number((total / 100).toFixed(2));
            if (smallPart.value < onePercent) {
                smallPart.value = onePercent;
            }

            smallPart.color = getPieceColor(data.length, smallPart.id, hoveredPiece, selectedPiece);
            data.push(smallPart);
        }

        return {
            smallPart,
            data
        };
    }, [heldPools, hoveredPiece, selectedPiece]);

    return (
        <div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
                onClick={(e) => {
                    if (!selectedPiece) return;
                    let isDirectPathClick = false;
                    const paths = e.currentTarget.querySelectorAll('path');
                    paths.forEach((path) => {
                        if (path === e.target) {
                            isDirectPathClick = true;
                        }
                    });
                    if (!isDirectPathClick) setSelectedPiece('');
                }}
                className={cx('PieChart', selectedPiece && 'selected')}
            >
                <ResponsivePie
                    data={pieData.data}
                    isInteractive
                    padAngle={0.6}
                    margin={
                        isLandscapeOrLess
                            ? { top: 32, bottom: 32, right: 20, left: 20 }
                            : { top: 60, bottom: 60, right: 20, left: 20 }
                    }
                    cornerRadius={3}
                    innerRadius={0.6}
                    activeInnerRadiusOffset={isTouchable ? 0 : 8}
                    activeOuterRadiusOffset={isTouchable ? 0 : 8}
                    onMouseEnter={(data) => setHoveredPiece(data.id)}
                    onMouseLeave={() => setHoveredPiece('')}
                    onClick={(data) => setSelectedPiece(data.id === selectedPiece ? '' : data.id)}
                    colors={(d) => d.data.color}
                    tooltip={() => null}
                    layers={['arcs', CenteredMetric(t('pageAccount.pools'))]}
                />
            </div>

            <ul className={cx('PoolList')}>
                {pieData.data.map(({ data: pools, id, color }) => {
                    if (selectedPiece && selectedPiece !== id) return null;
                    return pools.map((pool) => {
                        // eslint-disable-next-line no-param-reassign
                        pool = pool!;
                        return (
                            <li key={pool.code} className={cx('PoolItem')}>
                                <Link className={cx('PoolItemContent')} to={ROUTES_MAP.accountPool(account, pool.code)}>
                                    <PoolCard
                                        className={cx('PoolItemPoolCard')}
                                        iconsClassName={cx('PoolItemPoolCardIcons')}
                                        tokens={[
                                            {
                                                // as because we have not clean code - we have to
                                                // get token logo ourself
                                                icon: getTokenLogo(
                                                    pool.token1.logo,
                                                    pool.token1.code,
                                                    pool.token1.smartContract
                                                ),
                                                name: (
                                                    <>
                                                        {addThousandsSeparator(pool.token1Amount)}
                                                        <span className={cx('PoolItemCode')}>{pool.token1.code}</span>
                                                    </>
                                                )
                                            },
                                            {
                                                // as because we have not clean code - we have to
                                                // get token logo ourself
                                                icon: getTokenLogo(
                                                    pool.token2.logo,
                                                    pool.token2.code,
                                                    pool.token2.smartContract
                                                ),
                                                name: (
                                                    <>
                                                        {addThousandsSeparator(pool.token2Amount)}
                                                        <span className={cx('PoolItemCode')}>{pool.token2.code}</span>
                                                    </>
                                                )
                                            }
                                        ]}
                                    />

                                    <div className={cx('PoolItemShare')}>
                                        <b className={cx('PoolItemValue')}>
                                            <FormatNumber value={pool.usdEquivalent} prefix="$" />
                                        </b>
                                        <span className={cx('PoolItemPercent')}>
                                            <FormatNumber value={pool.shareInPortfolio} suffix="%" />

                                            <span
                                                className={cx('PoolItemMark')}
                                                style={{
                                                    background: color
                                                }}
                                            />
                                        </span>
                                    </div>
                                </Link>
                            </li>
                        );
                    });
                })}
            </ul>
        </div>
    );
}

export default HeldPools;
