import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { CurrencyNode, ExchangePairNode } from 'apollo/main/generated';
import addThousandsSeparator from 'utils/addThousandsSeparator/addThousandsSeparator';
import roundNumber from 'utils/roundNumber/roundNumber';
import { UseFormField } from 'hooks/useFormField/useFormField.d';
import { TelegramIcon } from 'ui/Icons/Icons';
import Preloader from 'ui/Preloader/Preloader';
import TextField from 'ui/TextField/TextField';
import Button from 'ui/Button_DEPRECATED/Button';
import ExchangeAmount from '../ExchangeAmount/ExchangeAmount';
import styles from './ExchangeTabs.module.scss';

export enum Direction {
    Input,
    Output
}

export interface ExchangeTabsProps {
    amountGive: string;
    amountGiveLoading: boolean;
    amountGiveError: string;
    amountReceive: string;
    amountReceiveLoading: boolean;
    direction: Direction;
    exchangeLoading: boolean;
    inActive?: string;
    inOptions?: { value: string; label?: string | null }[] | null;
    inputCard: UseFormField;
    inputEmail: UseFormField;
    inputMemo: UseFormField;
    inputWalletTrx: UseFormField;
    inputWalletEos: UseFormField;
    isAmountReceiveLowError: boolean;
    isAmountReceiveHighError: boolean;
    onAmountGiveChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onAmountReceiveChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onCardChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onCryptoChange: (currencyName: string) => void;
    onEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFiatChange: (currencyName: string) => void;
    onMemoChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    onDirectionChange: (nextDirection: Direction) => () => void;
    onWalletEosChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onWalletTrxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    outActive?: string;
    outOptions?: { value: string; label?: string | null }[] | null;
    pair: ExchangePairNode | null;
    pairsLoading: boolean;
    pairsInput?: (ExchangePairNode | null)[];
    pairsOutput?: (ExchangePairNode | null)[];
}

const getRate = (direction: Direction, pair: ExchangePairNode | null) => {
    const isTronInput = pair?.inputMethod?.__typename === 'CurrencyNode' && pair?.inputMethod?.blockchain === 'TRON';
    const isTronOutput = pair?.outputMethod?.__typename === 'CurrencyNode' && pair?.outputMethod?.blockchain === 'TRON';

    const rate = direction === Direction.Output ? Number(pair?.exchangeRate) : 1 / Number(pair?.exchangeRate);
    const feePercentage = Number(Number(pair?.inputFeePercentage) + Number(pair?.outputFeePercentage)) || 0;

    if (isTronInput) {
        const outputMethod = pair?.outputMethod as CurrencyNode | undefined;

        return roundNumber(
            rate * (1 - Number(pair?.inputMethod?.feePercentage)) * (1 - Number(outputMethod?.withdrawFeePercentage)),
            5
        );
    }

    if (isTronOutput) {
        const outputMethod = pair?.outputMethod as CurrencyNode | undefined;

        return roundNumber(
            rate - rate * (Number(pair?.inputFeePercentage) + Number(outputMethod?.withdrawFeePercentage)),
            5
        );
    }

    return roundNumber(rate - rate * feePercentage, 5);
};

const getFee = (direction: Direction, pair: ExchangePairNode | null) => {
    const isTronOutput = pair?.outputMethod?.__typename === 'CurrencyNode' && pair?.outputMethod?.blockchain === 'TRON';

    const inputFeeFix = Number(pair?.inputFeeFix) || 0;
    const outputFeeFix = Number(pair?.outputFeeFix) || 0;

    if (isTronOutput) {
        return addThousandsSeparator(`${outputFeeFix + inputFeeFix}`);
    }

    return addThousandsSeparator(direction === Direction.Output ? `${outputFeeFix}` : `${inputFeeFix}`);
};

export default function ExchangeTabs({
    amountGive,
    amountGiveLoading,
    amountGiveError,
    amountReceive,
    amountReceiveLoading,
    direction,
    exchangeLoading,
    inputWalletTrx,
    inputWalletEos,
    isAmountReceiveLowError,
    isAmountReceiveHighError,
    inActive,
    inOptions,
    inputEmail,
    inputCard,
    inputMemo,
    outActive,
    outOptions,
    onAmountGiveChange,
    onAmountReceiveChange,
    onCardChange,
    onCryptoChange,
    onEmailChange,
    onFiatChange,
    onMemoChange,
    onSubmit,
    onDirectionChange,
    onWalletEosChange,
    onWalletTrxChange,
    pair,
    pairsLoading,
    pairsInput,
    pairsOutput
}: ExchangeTabsProps) {
    const [t] = useTranslation();

    return (
        <div className={styles.Root}>
            <div className={styles.Body}>
                <ul className={styles.TabList}>
                    <li
                        className={cn(
                            styles.Tab,
                            direction === Direction.Output && styles.TabActive,
                            !pairsOutput?.length && styles.TabDisabled
                        )}
                    >
                        <button
                            onClick={onDirectionChange(Direction.Output)}
                            type="button"
                            disabled={!pairsOutput?.length}
                        >
                            {t('pageExchange.sell')}
                        </button>
                    </li>
                    <li
                        className={cn(
                            styles.Tab,
                            direction === Direction.Input && styles.TabActive,
                            !pairsInput?.length && styles.TabDisabled
                        )}
                    >
                        <button
                            onClick={onDirectionChange(Direction.Input)}
                            type="button"
                            disabled={!pairsInput?.length}
                        >
                            {t('pageExchange.buy')}
                        </button>
                    </li>
                </ul>

                <form id="form-exchange" onSubmit={onSubmit}>
                    <div className={styles.Exchange}>
                        <ExchangeAmount
                            amountGiveValue={amountGive}
                            onAmountGiveChange={onAmountGiveChange}
                            amountReceiveValue={amountReceive}
                            onAmountReceiveChange={onAmountReceiveChange}
                            give={direction === Direction.Output ? inActive : outActive}
                            giveOptions={direction === Direction.Output ? inOptions : outOptions}
                            InputPropsGive={{
                                disabled: pairsLoading || amountGiveLoading,
                                isError: Boolean(amountGiveError)
                            }}
                            onGiveChange={direction === Direction.Output ? onCryptoChange : onFiatChange}
                            receive={direction === Direction.Output ? outActive : inActive}
                            receiveOptions={direction === Direction.Output ? outOptions : inOptions}
                            onReceiveChange={direction === Direction.Output ? onFiatChange : onCryptoChange}
                            InputPropsReceive={{
                                disabled: pairsLoading || amountReceiveLoading,
                                isError: Boolean(
                                    (isAmountReceiveLowError && pair?.minimalAmount) ||
                                        (isAmountReceiveHighError && pair?.maximalAmount)
                                )
                            }}
                            rate={getRate(direction, pair)}
                        />
                        <div className={styles.ExchangeInfo}>
                            {pair?.inputCurrencyName === 'AED' && (
                                <div
                                    className={cn(
                                        styles.ExchangeInfoItem,
                                        Number(amountGive) % 5 !== 0 && styles.ExchangeInfoItemError
                                    )}
                                >
                                    {t('pageExchange.divisible', { value: '5 AED' })}
                                </div>
                            )}

                            {Boolean(Number(pair?.minimalAmount)) && (
                                <div
                                    className={cn(
                                        styles.ExchangeInfoItem,
                                        Boolean(isAmountReceiveLowError && pair?.minimalAmount) &&
                                            styles.ExchangeInfoItemError
                                    )}
                                >
                                    {t('pageExchange.minAmount')}
                                    {' - '}
                                    {addThousandsSeparator(pair?.minimalAmount)} {outActive}
                                </div>
                            )}

                            {Boolean(Number(pair?.maximalAmount)) && (
                                <div
                                    className={cn(
                                        styles.ExchangeInfoItem,
                                        Boolean(isAmountReceiveHighError && pair?.maximalAmount) &&
                                            styles.ExchangeInfoItemError
                                    )}
                                >
                                    {t('pageExchange.maxAmount')}
                                    {' - '}
                                    {addThousandsSeparator(pair?.maximalAmount)} {outActive}
                                </div>
                            )}

                            {(Boolean(Number(pair?.inputFeeFix) > 0) || Boolean(Number(pair?.outputFeeFix) > 0)) && (
                                <div className={styles.ExchangeInfoItem}>
                                    {t('pageExchange.commission')}
                                    {' - '}
                                    {getFee(direction, pair)} {outActive}
                                </div>
                            )}
                        </div>
                    </div>

                    {direction === Direction.Output &&
                        (pair?.outputCurrencyName === 'USDT' ? (
                            <TextField
                                classes={{ container: styles.TextField }}
                                value={inputWalletTrx.value}
                                onChange={onWalletTrxChange}
                                placeholder={t('pageExchange.walletTrx')}
                                isError={inputWalletTrx.error}
                                helperText={inputWalletTrx.helperText}
                                maxLength={34}
                                required
                            />
                        ) : (
                            <TextField
                                classes={{ container: styles.TextField }}
                                value={inputCard.value}
                                onChange={onCardChange}
                                placeholder={t('pageExchange.cardNumber')}
                                isError={inputCard.error}
                                helperText={inputCard.helperText}
                                inputMode="numeric"
                                pattern="[0-9\s]*"
                                maxLength={19}
                                required
                                autoComplete="cc-number"
                            />
                        ))}

                    {direction === Direction.Input && (
                        <>
                            <TextField
                                classes={{ container: styles.TextField }}
                                placeholder={t('pageExchange.walletEos')}
                                value={inputWalletEos.value}
                                isError={inputWalletEos.error}
                                helperText={inputWalletEos.helperText}
                                onChange={onWalletEosChange}
                            />
                            <TextField
                                classes={{
                                    container: styles.TextField,
                                    adornmentEnd: styles.TextFieldAdornmentEnd
                                }}
                                placeholder="MEMO"
                                value={inputMemo.value}
                                isError={inputMemo.error}
                                helperText={inputMemo.helperText}
                                onChange={onMemoChange}
                                adornmentEnd={t('pageExchange.optional')}
                            />
                        </>
                    )}

                    <TextField
                        classes={{ container: styles.TextField, adornmentEnd: styles.TextFieldAdornmentEnd }}
                        placeholder={t('pageExchange.email')}
                        type="email"
                        pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                        value={inputEmail.value}
                        isError={inputEmail.error}
                        helperText={inputEmail.helperText}
                        onChange={onEmailChange}
                        inputMode="email"
                        autoComplete="email"
                        adornmentEnd={t('pageExchange.optional')}
                    />
                    <div className={styles.TextFieldDescription}>{t('pageExchange.emailDescription')}</div>
                </form>
            </div>
            <div className={styles.Footer}>
                <Button
                    className={styles.SupportButton}
                    classes={{ root: styles.SupportButton, iconStart: styles.SupportButtonIconStart }}
                    tag="a"
                    color="tertairy-white"
                    href="https://t.me/paycashchat"
                    target="_blank"
                    rel="noreferrer"
                    startIcon={<TelegramIcon className={styles.SupportIcon} />}
                >
                    {t('pageExchange.support')}
                </Button>
                <Button
                    className={styles.SubmitButton}
                    color="primary"
                    type="submit"
                    form="form-exchange"
                    disabled={
                        !amountGive ||
                        !amountReceive ||
                        exchangeLoading ||
                        (direction === Direction.Output && !(inputCard.value || inputWalletTrx.value)) ||
                        (direction === Direction.Input && !(inputWalletEos.value || inputWalletTrx.value)) ||
                        (pair?.inputCurrencyName === 'AED' && Number(amountGive) % 5 !== 0)
                    }
                >
                    {exchangeLoading ? <Preloader size={16} color="inherit" thickness={5} /> : t('pageExchange.submit')}
                </Button>
            </div>
        </div>
    );
}
