import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { MlnkCollectParams } from 'apollo/main/generated';
import Modal, { ModalProps } from 'ui/Modal_DEPRECATED/Modal';
import Param from 'components/Param/Param';
import QR from 'components/QR/QR';
import { PAYCASH_LANDING } from 'constant';
import addThousandsSeparator from 'utils/addThousandsSeparator/addThousandsSeparator';
import MLNKLogo from 'assets/images/tokens/mlnk.svg';
import styles from './ModalCollectMLNK.module.scss';

const cx = classNames.bind(styles);

function ModalCollectMLNK({
    isOpen,
    mlnkCollectParams,
    account,
    mlnkBalance,
    close
}: ModalProps & {
    mlnkCollectParams: MlnkCollectParams;
    account: string;
    mlnkBalance: string;
}) {
    const [t] = useTranslation();

    return (
        <Modal className={cx('Modal')} wrapperClassName={cx('ModalWrapper')} isOpen={isOpen} close={close}>
            <div className={cx('InfoScreen')}>
                <div className={cx('InfoScreenContent')}>
                    <img className={cx('InfoScreenImg')} src={MLNKLogo} alt="MLNK Logo" />
                    <h2 className={cx('InfoScreenTitle')}>{t('modalCollectMLNK.info.title')}</h2>

                    <p className={cx('InfoScreenAmount')}>
                        {t('modalCollectMLNK.info.amount', {
                            account,
                            amount: addThousandsSeparator(mlnkBalance)
                        })}
                    </p>

                    <p className={cx('InfoScreenDescription')}>
                        <Trans i18nKey="modalCollectMLNK.info.description">
                            <a href={PAYCASH_LANDING} target="_blank" rel="noreferrer">
                                .
                            </a>
                        </Trans>
                    </p>
                </div>
            </div>
            <div className={cx('TransactionScreen')}>
                <p className={cx('TransactionScreenQRText')}>{t('modalCollectMLNK.transaction.qr')}</p>

                <QR data={mlnkCollectParams.metaInfo} className={cx('TransactionScreenQR')} />

                <p className={cx('TransactionScreenParamsTitle')}>{t('modalCollectMLNK.transaction.params')}</p>

                <Param withCopy value={mlnkCollectParams.receiver} label={t('modalCollectMLNK.transaction.account')} />
                <Param withCopy value={mlnkCollectParams.memo} label={t('modalCollectMLNK.transaction.memo')} />
            </div>
        </Modal>
    );
}

export default ModalCollectMLNK;
