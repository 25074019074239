import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import addThousandsSeparator from 'utils/addThousandsSeparator/addThousandsSeparator';
import { TextFieldProps } from 'ui/TextField/TextField.d';
import TextField from 'ui/TextField/TextField';
import Skeleton from 'ui/Skeleton/Skeleton';
import TokensDropdown, { TokensDropdownProps } from '../TokensDropdown/TokensDropdown';
import styles from './ExchangeAmount.module.scss';

export interface ExchangeAmountProps {
    amountGiveValue: string;
    onAmountGiveChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    amountReceiveValue: string;
    onAmountReceiveChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    give?: string | null;
    giveOptions?: TokensDropdownProps<string>['options'] | null;
    onGiveChange?: TokensDropdownProps<string>['onChange'];
    receive?: string | null;
    receiveOptions?: TokensDropdownProps<string>['options'] | null;
    onReceiveChange?: TokensDropdownProps<string>['onChange'];
    rate?: number;
    InputPropsGive?: TextFieldProps;
    InputPropsReceive?: TextFieldProps;
}

export default function ExchangeAmount({
    amountGiveValue,
    onAmountGiveChange,
    amountReceiveValue,
    onAmountReceiveChange,
    give,
    giveOptions,
    onGiveChange,
    InputPropsGive,
    receive,
    receiveOptions,
    onReceiveChange,
    InputPropsReceive,
    rate
}: ExchangeAmountProps) {
    const [t] = useTranslation();

    return (
        <div className={styles.Root}>
            <div className={styles.Item}>
                <TextField
                    classes={{
                        container: styles.TextField,
                        label: styles.TextFieldLabel,
                        input: cn(styles.TextFieldInput, InputPropsGive?.isError && styles.TextFieldInputError)
                    }}
                    placeholder={t('pageExchange.amount.enterAmount')}
                    label={t('pageExchange.amount.give')}
                    value={addThousandsSeparator(amountGiveValue)}
                    onChange={onAmountGiveChange}
                    autoComplete="off"
                    {...InputPropsGive}
                />
                {giveOptions && giveOptions.length ? (
                    <TokensDropdown
                        options={giveOptions}
                        active={give ?? ''}
                        onChange={(value) => onGiveChange?.(value)}
                    />
                ) : (
                    <Skeleton className={styles.SelectSkeleton} height={37} width={140} />
                )}
            </div>

            <div className={styles.Rate}>
                {give && receive ? (
                    <div className={styles.RateContent}>
                        1 {give} = {rate} {receive}
                    </div>
                ) : (
                    <Skeleton className={styles.RateSkeleton} height={27} width={120} />
                )}
            </div>

            <div className={styles.Item}>
                <TextField
                    classes={{
                        container: styles.TextField,
                        label: styles.TextFieldLabel,
                        input: cn(
                            styles.TextFieldInput,
                            InputPropsReceive?.isError && styles.TextFieldInputError,
                            InputPropsReceive?.disabled && styles.TextFieldInputDisabled
                        )
                    }}
                    placeholder={t('pageExchange.amount.enterAmount')}
                    label={t('pageExchange.amount.receive')}
                    value={addThousandsSeparator(amountReceiveValue)}
                    onChange={onAmountReceiveChange}
                    autoComplete="off"
                    {...InputPropsReceive}
                />
                {receiveOptions && receiveOptions.length ? (
                    <TokensDropdown
                        options={receiveOptions}
                        active={receive ?? ''}
                        onChange={(value) => onReceiveChange?.(value)}
                    />
                ) : (
                    <Skeleton className={styles.SelectSkeleton} height={37} width={140} />
                )}
            </div>
        </div>
    );
}
