import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';
import { CrossIcon } from 'ui/Icons/Icons';
import styles from './DividedValues.module.scss';

const cx = classNames.bind(styles);

function DividedValues({
    value1,
    value2,
    divider,
    className = ''
}: {
    value1: ReactNode;
    value2?: ReactNode;
    divider?: ReactNode;
    className?: string;
}) {
    return (
        <div className={cx('DividedValues', className)}>
            <span>{value1}</span>
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            {value2 && (
                <span className={cx('DividedValuesDivider')}>{divider ?? <CrossIcon className={styles.Icon} />}</span>
            )}
            {value2 && <span>{value2}</span>}
        </div>
    );
}

export default DividedValues;
