import classNames from 'classnames/bind';
import { QuestionIcon } from 'ui/Icons/Icons';
import Tooltip, { useTooltip } from 'ui/Tooltip_DEPRECATED/Tooltip';
import { useIsTouchableDevice } from 'helpers';
import React, { PropsWithChildren, useRef, useEffect } from 'react';
import styles from './TooltipQuestion.module.scss';

const cx = classNames.bind(styles);

function TooltipQuestion({ children }: PropsWithChildren<{}>) {
    const tooltip = useTooltip();
    const ref = useRef<HTMLDivElement>(null);
    const isTouchable = useIsTouchableDevice();

    useEffect(() => {
        if (!isTouchable || !tooltip.isActive) return;

        const mousedownListener = (e: MouseEvent) => {
            if (ref.current && e.target && !ref.current.contains(e.target as Node)) {
                tooltip.hide();
            }
        };

        document.addEventListener('mousedown', mousedownListener);

        // eslint-disable-next-line consistent-return
        return () => {
            document.removeEventListener('mousedown', mousedownListener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTouchable, tooltip.isActive]);

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
            ref={ref}
            onMouseEnter={!isTouchable ? tooltip.show : undefined}
            onMouseLeave={!isTouchable ? tooltip.hide : undefined}
            onClick={isTouchable ? () => tooltip.setShow(!tooltip.isActive) : undefined}
            className={cx('Component', { active: tooltip.isActive })}
        >
            <span ref={tooltip.setPopperReference} className={cx('Control')}>
                <QuestionIcon />
            </span>

            <Tooltip {...tooltip}>{children}</Tooltip>
        </div>
    );
}

export default TooltipQuestion;
