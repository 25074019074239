import classNames from 'classnames/bind';
import Button from 'ui/Button_DEPRECATED/Button';
import { AboutIcon } from 'ui/Icons/Icons';
import UsdCashIcon from 'assets/images/tokens/usdcash.svg';
import RublesIcon from 'assets/images/tokens/rubles.svg';
import GooglePlayDarkIcon from 'assets/icons/google-play-dark.svg';
import AppleDarkIcon from 'assets/icons/apple-dark.svg';
import PayCashLogo from 'assets/images/logo-paycash-white.svg';
import { BREAKPOINT_IPAD, DOWNLOAD_APP, PAYCASH_LANDING, ROUTES_MAP } from 'constant';
import { getDownloadLink, isMobilePlatform, usePixelRatio, useWidthCondition } from 'helpers';
import { useExchangePairsQuery } from 'apollo/main/generated';
import { ID as StartSectionId } from 'components/AboutProjectStart/AboutProjectStart';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './HomeBanners.module.scss';

const cx = classNames.bind(styles);

function BankCardBanner() {
    const [t] = useTranslation();
    const pixelRatio = usePixelRatio();
    const [isIpadOrLess] = useWidthCondition((w) => w < BREAKPOINT_IPAD);
    const exchangeParsQuery = useExchangePairsQuery({ variables: { filters: {} } });
    const disabled = !exchangeParsQuery?.data?.exchangePairs?.length;

    const Additional = (
        <div className={cx('BankCardBannerIcons')}>
            <span>
                <img alt="USDCASH" src={UsdCashIcon} />
            </span>
            <span>
                <img alt="RUBLES" src={RublesIcon} />
            </span>
        </div>
    );

    return (
        <div className={cx('BankCardBanner', pixelRatio >= 2 && 'retina', disabled && 'BankCardBannerDisabled')}>
            <div>
                {isIpadOrLess && Additional}
                <h3 className={cx('Title')}>{t('banners.bankCard.title')}</h3>
            </div>
            <div className={cx('BtnGroup')}>
                <Button tag="link" to={ROUTES_MAP.exchange} className={cx('Btn')} color="primary">
                    {t('banners.bankCard.btn')}
                </Button>
            </div>
        </div>
    );
}

function InvestBanner() {
    const pixelRatio = usePixelRatio();
    const [t] = useTranslation();
    const [isIpadOrLess] = useWidthCondition((w) => w < BREAKPOINT_IPAD);
    const Additional = <span className={cx('InvestBannerTag')}>{t('banners.invest.tag')}</span>;

    return (
        <div className={cx('InvestBanner', pixelRatio >= 2 && 'retina')}>
            <div>
                {isIpadOrLess && Additional}
                <h3 className={cx('Title')}>{t('banners.invest.title')}</h3>
            </div>
            <div className={cx('BtnGroup')}>
                <Button className={cx('Btn')} color="primary" tag="link" to={ROUTES_MAP.info}>
                    {t('global.readMore')}
                </Button>
            </div>
        </div>
    );
}

function ExchangeBanner() {
    const [t] = useTranslation();
    return (
        <div className={cx('ExchangeBanner')}>
            <div>
                <h3 className={cx('Title')}>{t('banners.exchange.title')}</h3>
            </div>

            <div className={cx('BtnGroup')}>
                <Button className={cx('Btn')} color="primary" tag="link" to={ROUTES_MAP.depositExchange}>
                    {t('global.readMore')}
                </Button>
            </div>
        </div>
    );
}

function PaycashDownloadLinks() {
    const isMobile = isMobilePlatform();
    const [t] = useTranslation();

    if (isMobile) {
        return (
            <Button tag="a" color="primary" className={cx('Btn')} target="_blank" href={getDownloadLink()!}>
                {t('banners.paycash.btnDownload')}
            </Button>
        );
    }

    return (
        <div className={cx('DownloadLinksGroup')}>
            <a target="_blank" href={DOWNLOAD_APP.android} rel="noreferrer">
                <img src={GooglePlayDarkIcon} alt="Google Play" />
            </a>
            <a target="_blank" href={DOWNLOAD_APP.ios} rel="noreferrer">
                <img src={AppleDarkIcon} alt="Apple" />
            </a>
        </div>
    );
}

function PaycashBanner() {
    const pixelRatio = usePixelRatio();
    const [isIpadOrLess] = useWidthCondition((w) => w < BREAKPOINT_IPAD);
    const [t] = useTranslation();
    return (
        <div className={cx('PaycashBanner', pixelRatio === 2 && 'retina')}>
            <div>
                {isIpadOrLess && (
                    <a href={PAYCASH_LANDING} target="_blank" rel="noreferrer">
                        <img className={cx('PayCashLogo')} src={PayCashLogo} alt="PayCash App" />
                    </a>
                )}
                <h3 className={cx('Title')}>{t('banners.paycash.title')}</h3>
            </div>

            <div className={cx('BtnGroup')}>
                <Button
                    className={cx('Btn')}
                    color="primary"
                    tag="link"
                    startIcon={<AboutIcon />}
                    to={{
                        pathname: ROUTES_MAP.info,
                        hash: StartSectionId
                    }}
                >
                    {t('banners.paycash.btnDetails')}
                </Button>

                <PaycashDownloadLinks />
            </div>
        </div>
    );
}

type BannerType = 'bank' | 'exchange' | 'paycash' | 'invest';

function Banners({
    className = '',
    types = ['bank', 'paycash', 'exchange']
}: {
    className?: string;
    types?: BannerType[];
}) {
    return (
        <div className={cx('BannerList', className)}>
            {types.map((type) => {
                if (type === 'paycash') {
                    return <PaycashBanner key={type} />;
                }
                if (type === 'exchange') {
                    return <ExchangeBanner key={type} />;
                }
                if (type === 'invest') {
                    return <InvestBanner key={type} />;
                }
                if (type === 'bank') {
                    return <BankCardBanner key={type} />;
                }

                return undefined;
            })}
        </div>
    );
}

export default Banners;
