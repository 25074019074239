import React, { createContext, PropsWithChildren, useContext, useState } from 'react';

type AppContextType = {
    bookmarks: {
        isOpen: boolean;
        setIsOpen(v: boolean): void;
        open(): void;
        close(): void;
    };
    searchAccount: {
        isOpen: boolean;
        setIsOpen(v: boolean): void;
        open(): void;
        close(): void;
    };
};

export const AppContext = createContext({} as AppContextType);

export const useAppContext = () => useContext(AppContext);

function AppProvider({ children }: PropsWithChildren<{}>) {
    const [bookmarksIsOpen, setBookmarksIsOpen] = useState(false);
    const [searchAccountIsOpen, setSearchAccountIsOpen] = useState(false);

    return (
        <AppContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                bookmarks: {
                    isOpen: bookmarksIsOpen,
                    setIsOpen: setBookmarksIsOpen,
                    open: () => setBookmarksIsOpen(true),
                    close: () => setBookmarksIsOpen(false)
                },
                searchAccount: {
                    isOpen: searchAccountIsOpen,
                    setIsOpen: setSearchAccountIsOpen,
                    open: () => setSearchAccountIsOpen(true),
                    close: () => setSearchAccountIsOpen(false)
                }
            }}
        >
            {children}
        </AppContext.Provider>
    );
}

export default AppProvider;
