import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { ReactComponent as IconLoader } from 'assets/images/loader.svg';
import { SearchIcon, CrossIcon } from 'ui/Icons/Icons';
import styles from './SearchField.module.scss';

const cx = classNames.bind(styles);

type Props = {
    value?: string;
    onChange(v: string): void;
    controlledInputValue?: [string, (v: string) => void];
    loading?: boolean;
    /** @default 500ms */
    delay?: number;
    inputClassName?: string;
    className?: string;
    searchIconClassName?: string;
    clearClassName?: string;
    loaderClassName?: string;
    toLowerCase?: boolean;
    inputProps?: Omit<React.JSX.IntrinsicElements['input'], 'className' | 'onChange' | 'ref' | 'value' | 'type'>;
};

/**
 * Search field with delayed onChange prop
 */
function SearchField({ delay = 500, controlledInputValue, toLowerCase, ...props }: Props) {
    const inputRef = useRef<HTMLInputElement>(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [value, setValue] = controlledInputValue ?? useState('');
    const [focus, setFocus] = useState(false);

    useEffect(() => {
        const tid = setTimeout(() => {
            props.onChange(value);
        }, delay);

        return () => {
            clearTimeout(tid);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delay, value]);

    useEffect(() => {
        setValue(props.value ?? '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    useEffect(() => {
        if (!inputRef.current) return;

        const onFocus = () => setFocus(true);
        const onBlur = () => setFocus(false);
        inputRef.current.addEventListener('focus', onFocus);
        inputRef.current.addEventListener('blur', onBlur);

        // eslint-disable-next-line consistent-return
        return () => {
            inputRef.current?.removeEventListener('focus', onFocus);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            inputRef.current?.removeEventListener('blur', onBlur);
        };
    }, []);

    return (
        <div className={cx('SearchField', (focus || props.value) && 'highlight', props.className ?? '')}>
            <SearchIcon className={cx('SearchFieldIcon', props.searchIconClassName ?? '')} />
            <input
                {...props.inputProps}
                className={props.inputClassName ?? ''}
                ref={inputRef}
                value={value}
                onChange={(e) => setValue(toLowerCase ? e.target.value.toLowerCase() : e.target.value)}
                type="search"
            />
            {props.loading && (
                <span className={cx('SearchFieldLoader', props.loaderClassName ?? '')}>
                    <IconLoader />
                </span>
            )}
            <button
                className={cx('SearchFieldBtnClear', props.clearClassName ?? '')}
                disabled={!value}
                type="button"
                onClick={() => {
                    setValue('');
                    inputRef.current?.focus();
                }}
            >
                {/* eslint-disable-next-line react/jsx-pascal-case */}
                <CrossIcon />
            </button>
        </div>
    );
}

export default SearchField;
