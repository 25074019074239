import { useBookmarksQuery } from 'apollo/main/generated';
import classNames from 'classnames/bind';
import Background from 'components/Background/Background';
import BookmarkBtn from 'components/BookmarkBtn/BookmarkBtn';
import { BookmarkFilledIcon, ChevronLeftIcon } from 'ui/Icons/Icons';
import Loader from 'components/Loader/Loader';
import PoolCard from 'components/PoolCard/PoolCard';
import Sidebar, { SidebarContent } from 'components/Sidebar/Sidebar';
import TokenCard from 'components/TokenCard/TokenCard';
import { ROUTES_MAP } from 'constant';
import { useAppContext } from 'components/Helpers/AppContext';
import React, { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './Bookmarks.module.scss';

const cx = classNames.bind(styles);

type Tab = 'account' | 'pairs' | 'tokens';

function Bookmarks() {
    const [tab, setTab] = useState<Tab>('account');
    const { isOpen, close } = useAppContext().bookmarks;
    const bookmarksQuery = useBookmarksQuery();
    const [t] = useTranslation();

    const bookmarks = bookmarksQuery.data?.visitor;

    return (
        <Sidebar wrapperClassName={cx('Wrapper')} className={cx('Sidebar')} isOpen={isOpen} close={close}>
            <Background className={cx('Background', isOpen && 'active')} />
            <SidebarContent className={cx('Component')}>
                <h2 className={cx('Title')}>
                    <button className={cx('BtnClose')} type="button" onClick={close}>
                        <ChevronLeftIcon />
                    </button>
                    {t('pageBookmarks.title')}
                </h2>

                <ul className={cx('TabList')}>
                    <li className={cx('Tab', tab === 'account' && 'active')}>
                        <button onClick={() => setTab('account')} type="button">
                            {t('pageBookmarks.tabs.accounts.title')}
                        </button>
                    </li>
                    <li className={cx('Tab', tab === 'tokens' && 'active')}>
                        <button onClick={() => setTab('tokens')} type="button">
                            {t('pageBookmarks.tabs.tokens.title')}
                        </button>
                    </li>
                    <li className={cx('Tab', tab === 'pairs' && 'active')}>
                        <button onClick={() => setTab('pairs')} type="button">
                            {t('pageBookmarks.tabs.pairs.title')}
                        </button>
                    </li>
                </ul>
                {bookmarksQuery.loading && (
                    <div className={cx('Loader')}>
                        <Loader />
                    </div>
                )}
                {!bookmarksQuery.loading && tab === 'account' && (
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    <TabContent isEmpty={!bookmarks?.accounts.length}>
                        {bookmarks?.accounts.map(
                            (account) =>
                                account && (
                                    <li key={account.name} className={cx('Item')}>
                                        <Link onClick={close} to={ROUTES_MAP.account(account.name)}>
                                            {account.name}
                                        </Link>

                                        <BookmarkBtn
                                            isSmall
                                            isFavorite
                                            type="account"
                                            eosAccount={account.name}
                                            className={cx('BtnBookmark')}
                                        />
                                    </li>
                                )
                        )}
                    </TabContent>
                )}
                {!bookmarksQuery.loading && tab === 'pairs' && (
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    <TabContent isEmpty={!bookmarks?.pools.length}>
                        {bookmarks?.pools.map(
                            (pool) =>
                                pool && (
                                    <li key={pool.code} className={cx('Item')}>
                                        <Link onClick={close} to={ROUTES_MAP.pool(pool.code)}>
                                            <PoolCard
                                                code={pool.code}
                                                tokens={[
                                                    {
                                                        name: pool.token1.code,
                                                        smartContract: pool.token1.smartContract,
                                                        icon: pool.token1.logo ?? ''
                                                    },
                                                    {
                                                        name: pool.token2.code,
                                                        smartContract: pool.token2.smartContract,
                                                        icon: pool.token2.logo ?? ''
                                                    }
                                                ]}
                                            />
                                        </Link>

                                        <BookmarkBtn
                                            isSmall
                                            isFavorite
                                            type="pool"
                                            poolCode={pool.code}
                                            className={cx('BtnBookmark')}
                                        />
                                    </li>
                                )
                        )}
                    </TabContent>
                )}

                {!bookmarksQuery.loading && tab === 'tokens' && (
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    <TabContent isEmpty={!bookmarks?.tokens.length}>
                        {bookmarks?.tokens.map(
                            (token) =>
                                token && (
                                    <li key={token.id} className={cx('Item')}>
                                        <Link onClick={close} to={ROUTES_MAP.token(token.id)}>
                                            <TokenCard {...token} />
                                        </Link>

                                        <BookmarkBtn
                                            isSmall
                                            isFavorite
                                            type="token"
                                            tokenId={token.id}
                                            className={cx('BtnBookmark')}
                                        />
                                    </li>
                                )
                        )}
                    </TabContent>
                )}
            </SidebarContent>
        </Sidebar>
    );
}

function TabContent({ isEmpty, children }: PropsWithChildren<{ isEmpty: boolean }>) {
    const [t] = useTranslation();
    if (isEmpty) {
        return (
            <div className={cx('TabContent')}>
                <div className={cx('EmptyScreen')}>
                    <BookmarkFilledIcon className={styles.BookmarkIcon} />
                    <h6>{t('pageBookmarks.empty.title')}</h6>
                    <p>{t('pageBookmarks.empty.text')}</p>
                </div>
            </div>
        );
    }

    return <ul className={cx('ItemList')}>{children}</ul>;
}

export default Bookmarks;
