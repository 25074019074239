import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { Trans, useTranslation } from 'react-i18next';
import Socials from 'components/Socials/Socials';
import Button from 'ui/Button_DEPRECATED/Button';
import { CONTACT_EMAIL, PAYCASH_LANDING, PC4STORE_LANDING, ROUTES_MAP } from 'constant';
import { NavLink } from 'react-router-dom';
import { ChevronDownIcon } from 'ui/Icons/Icons';
import Logo from 'assets/images/logo.svg';
import styles from './Footer.module.scss';

const cx = classNames.bind(styles);

type ActiveNavGroup = '' | 'help' | 'more';

function Footer() {
    const [t] = useTranslation();
    const [activeNavGroup, setActiveNavGroup] = useState<ActiveNavGroup>('');

    const onClickNavGroupControl = (target: ActiveNavGroup) => {
        setActiveNavGroup(target === activeNavGroup ? '' : target);
    };

    return (
        <footer className={cx('Component')}>
            <div className={cx('Container')}>
                <div className={cx('RowWithSocials')}>
                    <img className={cx('Logo')} alt="PayCashSwap" src={Logo} />
                    <Socials className={cx('Socials')} />
                </div>

                <div className={cx('RowWithNav')}>
                    <div className={cx('NavWrapper')}>
                        <nav>
                            <button
                                className={cx('NavControl', {
                                    active: activeNavGroup === 'help'
                                })}
                                type="button"
                                onClick={() => onClickNavGroupControl('help')}
                            >
                                {t('footer.help.title')}

                                <ChevronDownIcon className={styles.Icon} />
                            </button>

                            <div
                                className={cx('NavContent', {
                                    active: activeNavGroup === 'help'
                                })}
                            >
                                <NavLink
                                    activeClassName={cx('active')}
                                    to={{
                                        pathname: ROUTES_MAP.info
                                    }}
                                >
                                    {t('footer.help.addLiquidity')}
                                </NavLink>
                                <NavLink
                                    activeClassName={cx('active')}
                                    to={{
                                        pathname: ROUTES_MAP.depositExchange
                                    }}
                                >
                                    {t('footer.help.depositToWallet')}
                                </NavLink>
                                <NavLink
                                    activeClassName={cx('active')}
                                    to={{
                                        pathname: ROUTES_MAP.aboutCashTokens
                                    }}
                                >
                                    {t('footer.help.issueUSDCASH')}
                                </NavLink>
                            </div>
                        </nav>

                        <nav>
                            <button
                                className={cx('NavControl', {
                                    active: activeNavGroup === 'more'
                                })}
                                type="button"
                                onClick={() => onClickNavGroupControl('more')}
                            >
                                {t('footer.more.title')}

                                <ChevronDownIcon className={styles.Icon} />
                            </button>
                            <div
                                className={cx('NavContent', {
                                    active: activeNavGroup === 'more'
                                })}
                            >
                                <a target="_blank" href={PAYCASH_LANDING} rel="noreferrer">
                                    {t('footer.more.paycashAppLanding')}
                                </a>
                                <a target="_blank" href={PC4STORE_LANDING} rel="noreferrer">
                                    {t('footer.more.paycashForStore')}
                                </a>
                            </div>
                        </nav>
                    </div>
                    <div className={cx('TextWrapper')}>
                        <p className={cx('Text')}>
                            <Trans i18nKey="footer.contactText" values={{ email: CONTACT_EMAIL }}>
                                <Button color="link" tag="a" href={`mailto:${CONTACT_EMAIL}`} />
                            </Trans>
                        </p>
                        <p className={cx('Text')}>© 2023</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
