import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styles from './AboutCashTokensNavigation.module.scss';

const cx = classNames.bind(styles);

const linkTypes = ['statistics', 'guarantees', 'income', 'inheritance', 'applications', 'tutorials'];

function Navigation() {
    const [t] = useTranslation();
    const activeLinkRef = useRef<HTMLAnchorElement>(null);
    const location = useLocation();
    const [activeLink, setActiveLink] = useState<string[]>([]);

    useEffect(() => {
        const sections = linkTypes.map((id) => [id, document.getElementById(id)] as const);
        const getActiveLink = () => {
            const newActiveLink = [];
            // eslint-disable-next-line no-restricted-syntax
            for (const [id, section] of sections) {
                if (!section) {
                    // eslint-disable-next-line no-continue
                    continue;
                }
                // if (window.scrollY + window.innerHeight - section.offsetHeight / 2 >= section.offsetTop) {
                //     newActiveLink = id;
                // }
                if (section.getBoundingClientRect().top <= 300 && section.getBoundingClientRect().top > -200) {
                    newActiveLink.push(id);
                }
            }
            setActiveLink(newActiveLink);
        };

        window.addEventListener('scroll', getActiveLink);

        return () => {
            window.removeEventListener('scroll', getActiveLink);
        };
    }, []);

    useEffect(() => {
        activeLinkRef.current?.parentElement?.parentElement?.scroll({
            top: 0,
            left: activeLinkRef.current.offsetLeft - 15,
            behavior: 'smooth'
        });
    }, [activeLink]);

    return (
        <nav className={cx('Component')}>
            <div className={cx('LinkList')}>
                {linkTypes.map((type) => (
                    <Link
                        key={type}
                        to={{ ...location, hash: type }}
                        className={cx({ active: activeLink.includes(type) })}
                        // ref={type === activeLink ? activeLinkRef : null}
                    >
                        {t(`pageAboutCashTokens.${type}.title`, { context: 'link' })}
                    </Link>
                ))}
            </div>
        </nav>
    );
}

export default Navigation;
