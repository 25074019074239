import React from 'react';
import { useTranslation } from 'react-i18next';
import IncomeImage1x from 'assets/images/about-cash-tokens/income@1x.png';
import IncomeImage2x from 'assets/images/about-cash-tokens/income@2x.png';
import styles from './AboutCashTokensIncome.module.scss';

export default function AboutCashTokensIncome() {
    const [t] = useTranslation();

    return (
        <div className={styles.Root}>
            <div className={styles.Wrapper}>
                <h2 className={styles.Title}>{t('pageAboutCashTokens.income.title')}</h2>
                <p className={styles.Text}>{t('pageAboutCashTokens.income.description')}</p>
            </div>
            <div className={styles.ImageWrapper}>
                <img className={styles.Image} srcSet={`${IncomeImage2x} 2x`} src={IncomeImage1x} alt="Inheritance" />
            </div>
        </div>
    );
}
