import React, { PropsWithChildren, ReactNode, useRef } from 'react';
import classNames from 'classnames/bind';
import { Link, LinkProps as LinkComponentProps } from 'react-router-dom';
import styles from './Button.module.scss';

const cx = classNames.bind(styles);

type ButtonTagProps = Omit<React.JSX.IntrinsicElements['button'], 'children' | 'ref' | 'onCopy'> & {
    tag?: 'button';
};
type LinkTagProps = LinkComponentProps & {
    tag: 'link';
};
type AnchorTagProps = Omit<React.JSX.IntrinsicElements['a'], 'children' | 'ref' | 'onCopy'> & {
    tag: 'a';
};

type TagTypedProps = ButtonTagProps | LinkTagProps | AnchorTagProps;

interface ButtonClasses {
    root: string;
    iconStart: string;
}

type Props = PropsWithChildren<{
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    isActive?: boolean;
    color: 'primary' | 'secondary' | 'tertairy-white' | 'tertairy-green' | 'link';
    classes?: Partial<ButtonClasses>;
}> &
    TagTypedProps;

function Button({ startIcon, endIcon, children, isActive, color, className = '', classes, ...props }: Props) {
    const ref = useRef<HTMLAnchorElement & HTMLButtonElement>(null);

    const { root: rootClass, iconStart: iconStartClass } = classes ?? {};

    const baseProps = {
        ref,
        className: cx('Wrapper', className, color, isActive && 'active', rootClass),
        children: (
            <span tabIndex={-1} className={cx('Component')}>
                <span className={cx('Content')}>
                    {startIcon && (
                        <span className={cx('StartIcon', children && !endIcon && 'ml', iconStartClass)}>
                            {startIcon}
                        </span>
                    )}
                    {children && <span className={cx('Children')}>{children}</span>}
                    {endIcon && <span className={cx('EndIcon', children && !startIcon && 'mr')}>{endIcon}</span>}
                </span>
                <span className={cx('CircleHover')} />
            </span>
        )
    };

    const baseLinkProps = {
        className: cx('WrapperLink', className, rootClass),
        children: <span tabIndex={-1}>{children}</span>
    };

    /**
     * @todo is this correctly for typedef?
     */
    // eslint-disable-next-line no-nested-ternary
    const Component = props.tag === 'link' ? Link : props.tag === 'a' ? 'a' : 'button';
    /**
     * Calculates ideal size for hover circle.
     *
     * @TODO: After 98% support css property `aspect-ratio` (https://caniuse.com/mdn-css_properties_aspect-ratio),
     *        replace this script to css property {aspect-ratio: 1/1}
     */
    React.useEffect(() => {
        if (ref.current) {
            const { offsetHeight, offsetWidth } = ref.current;
            const size = Math.sqrt(offsetHeight ** 2 + offsetWidth ** 2) * 1.01;

            ref.current.style.setProperty('--hoverSize', `${size}px`);
        }
    }, []);

    return <Component {...(color === 'link' ? baseLinkProps : baseProps)} {...props} />;
}

export default Button;
