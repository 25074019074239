import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { useAccountMlnkProfitChartQuery } from 'apollo/main/generated';
import Card, { CardLoader } from 'components/Card/Card';
import Chart, { COLOR_SCHEME } from 'components/Chart/Chart';
import FormatNumber from 'components/FormatNumber/FormatNumber';
import { MLNK_PRECISION, MLNK_MIN_MOVE } from 'constant';
import { useTranslation } from 'react-i18next';
import styles from './AccountMLNKIncomeSection.module.scss';

const cx = classNames.bind(styles);

const MLNKIncomeSection = memo(
    ({ account, poolCode, readyToLoad }: { account: string; poolCode?: string; readyToLoad: boolean }) => {
        const [t] = useTranslation();
        const query = useAccountMlnkProfitChartQuery({
            variables: {
                name: account,
                poolCode
            },
            fetchPolicy: 'network-only',
            skip: !readyToLoad
        });

        const data = query.data?.accountMlnkProfitChart;

        return (
            <Card title={t('pageAccount.income.title')} helpText={t('pageAccount.income.helpText')}>
                {!readyToLoad || query.loading ? (
                    <CardLoader />
                ) : (
                    <>
                        <div className={cx('CardValues')}>
                            <p className={cx('CardValueItem')}>
                                <span className={cx('CardValueItemTitle')}>{t('pageAccount.income.day')}</span>
                                <FormatNumber
                                    compress={false}
                                    className={cx('CardValueItemValue')}
                                    value={data?.value24hMlnk ?? 0}
                                    suffix=" MLNK"
                                />
                                <FormatNumber
                                    className={cx('CardValueItemAdditionalValue')}
                                    value={data?.value24hUsd ?? 0}
                                    prefix="$"
                                />
                            </p>
                            <p className={cx('CardValueItem')}>
                                <span className={cx('CardValueItemTitle')}>{t('pageAccount.income.total')}</span>
                                <FormatNumber
                                    compress={false}
                                    className={cx('CardValueItemValue')}
                                    value={data?.totalMlnk ?? 0}
                                    suffix=" MLNK"
                                />
                                <FormatNumber
                                    className={cx('CardValueItemAdditionalValue')}
                                    value={data?.totalUsd ?? 0}
                                    prefix="$"
                                />
                            </p>
                        </div>
                        <div className={cx('CardChartWrapper')}>
                            <p className={cx('CardChartLegend')}>
                                <span>{t('pageAccount.income.legend')}</span>
                            </p>
                            <Chart
                                compress={false}
                                floatZerosOffset={8}
                                symbolBefore=""
                                symbolAfter=""
                                data={{
                                    type: 'histogram',

                                    data:
                                        data?.points.map((p) => ({
                                            value: Number(p?.value),
                                            time: p!.timestamp
                                        })) ?? [],
                                    options: {
                                        ...COLOR_SCHEME.pink.histogram,
                                        priceFormat: {
                                            precision: MLNK_PRECISION,
                                            minMove: MLNK_MIN_MOVE
                                        }
                                    }
                                }}
                            />
                        </div>
                    </>
                )}
            </Card>
        );
    }
);

export default MLNKIncomeSection;
