import React from 'react';
import { debounce } from 'lodash';
import update from 'immutability-helper';
import { useTranslation } from 'react-i18next';
import {
    ExchangePairNode,
    LanguageEnum,
    useExchangeCalcLazyQuery,
    useExchangePairsQuery,
    useExchangeSystemStatusQuery,
    useMakeExchangeMutation
} from 'apollo/main/generated';
import removeSpaces from 'utils/removeSpaces/removeSpaces';
import roundNumber, { numToPrecisionStr } from 'utils/roundNumber/roundNumber';
import removeNonNumeric from 'utils/removeNonNumeric/removeNonNumeric';
import { getErrorData, useGlobalError } from 'helpers';
import { formatCard } from 'helpers/card/card';
import useFormField from 'hooks/useFormField/useFormField';
import { InfoCircledIcon } from 'ui/Icons/Icons';
import Tooltip, { useTooltip } from 'ui/Tooltip_DEPRECATED/Tooltip';
import { TokensDropdownProps } from '../TokensDropdown/TokensDropdown';
import ExchangeTabs from '../ExchangeTabs/ExchangeTabs';
import TechWorksStub from '../TechWorksStub/TechWorksStub';
import styles from './Exchange.module.scss';

export enum Direction {
    Input,
    Output
}

export const LOCAL_STORAGE_KEY_WALLET_TRX = 'walletTrx';
export const LOCAL_STORAGE_KEY_WALLET_EOS = 'walletEos';

const getInPair = (pair?: ExchangePairNode | null) =>
    pair?.inputCurrencyName === 'RUB' ||
    pair?.inputCurrencyName === 'EUR' ||
    pair?.inputCurrencyName === 'USDT' ||
    pair?.inputCurrencyName === 'AED';

const getOutPair = (pair?: ExchangePairNode | null) =>
    pair?.outputCurrencyName === 'RUB' ||
    pair?.outputCurrencyName === 'EUR' ||
    pair?.outputCurrencyName === 'USDT' ||
    pair?.outputCurrencyName === 'AED';

const getOptions = (
    pairs: (ExchangePairNode | null)[] | null,
    direction: Direction,
    activeMethod?: string
): { value: string; label?: string | null }[] => {
    if (!pairs?.length) return [];

    switch (direction) {
        case Direction.Input:
            return (
                pairs.reduce<TokensDropdownProps<string>['options']>((acc, cur) => {
                    const isActive = activeMethod ? cur?.inputCurrencyName === activeMethod : !activeMethod;
                    const blockchain = cur?.inputMethod?.__typename === 'CurrencyNode' && cur?.inputMethod?.blockchain;
                    const blockchainName = blockchain === 'TRON' ? '\u00A0(TRC\u201120)' : '';

                    if (isActive && cur && !acc?.some((item) => item.value === cur?.inputCurrencyName)) {
                        acc?.push({
                            value: cur?.inputCurrencyName ?? '',
                            label: `${cur?.inputCurrencyName}${blockchainName}`
                        });
                    }

                    return acc;
                }, []) || []
            );
        case Direction.Output:
            return (
                pairs.reduce<TokensDropdownProps<string>['options']>((acc, cur) => {
                    const isActive = activeMethod ? cur?.inputCurrencyName === activeMethod : !activeMethod;
                    const blockchain =
                        cur?.outputMethod?.__typename === 'CurrencyNode' && cur?.outputMethod?.blockchain;
                    const blockchainName = blockchain === 'TRON' ? '\u00A0(TRC\u201120)' : '';

                    if (isActive && cur && !acc?.some((item) => item.value === cur?.outputCurrencyName)) {
                        acc?.push({
                            value: cur?.outputCurrencyName ?? '',
                            label: `${cur?.outputCurrencyName}${blockchainName}`
                        });
                    }

                    return acc;
                }, []) || []
            );
        default:
            return [];
    }
};

export default function Exchange() {
    const [t, { language }] = useTranslation();
    const onGlobalError = useGlobalError();
    const tooltip = useTooltip();

    const inputCard = useFormField('');
    const inputEmail = useFormField('');
    const inputMemo = useFormField('');
    const inputWalletTrx = useFormField(localStorage.getItem(LOCAL_STORAGE_KEY_WALLET_TRX) ?? '');
    const inputWalletEos = useFormField(localStorage.getItem(LOCAL_STORAGE_KEY_WALLET_EOS) ?? '');

    const [amountGive, setAmountGive] = React.useState('');
    const [amountGiveError, setAmountGiveError] = React.useState('');
    const [amountGiveLoading, setAmountGiveLoading] = React.useState(false);
    const [amountReceive, setAmountReceive] = React.useState('');
    const [amountReceiveLoading, setAmountReceiveLoading] = React.useState(false);
    const [isAmountReceiveLowError, setIsAmountReceiveLowError] = React.useState(false);
    const [isAmountReceiveHighError, setIsAmountReceiveHighError] = React.useState(false);

    const [direction, setTab] = React.useState<Direction>(Direction.Output);
    const [pair, setPair] = React.useState<ExchangePairNode | null>(null);
    const [error, setError] = React.useState('');

    const [inOptions, setInOptions] = React.useState<TokensDropdownProps<string>['options'] | null>(null);
    const [inActive, setInActive] = React.useState<string | undefined>(undefined);
    const [outOptions, setOutOptions] = React.useState<TokensDropdownProps<string>['options'] | null>(null);
    const [outActive, setOutActive] = React.useState<string | undefined>(undefined);

    const { data } = useExchangeSystemStatusQuery();

    const { isActive } = data?.exchangeSystemStatus?.maintenance ?? {};

    const exchangeParsQuery = useExchangePairsQuery({ variables: { filters: {} } });
    const { data: pairsData, loading: pairsLoading, error: pairsError } = exchangeParsQuery;
    const exchangePairs = pairsData?.exchangePairs;

    const pairsOutput = exchangePairs?.filter(getOutPair);
    const pairsInput = exchangePairs?.filter(getInPair);

    const [exchangeCalcQuery] = useExchangeCalcLazyQuery({ fetchPolicy: 'network-only' });

    const [makeExchangeMutation, { loading: exchangeLoading }] = useMakeExchangeMutation();

    const debouncedAmountReceive = React.useMemo(
        () =>
            debounce((amount: { give?: string; receive?: string }, currentPair: ExchangePairNode) => {
                if (!currentPair.id) return;

                if (Number(amount.give) || Number(amount.receive)) {
                    if (amount.give) {
                        setAmountReceiveLoading(true);
                    }

                    if (amount.receive) {
                        setAmountGiveLoading(true);
                    }

                    exchangeCalcQuery({
                        variables: {
                            input: {
                                exchangePair: currentPair.id,
                                inputAmount: amount.give || null,
                                outputAmount: amount.receive || null
                            }
                        }
                    })
                        .then((calcData) => {
                            const exchangeCalc = calcData.data?.exchangeCalc;

                            switch (exchangeCalc?.__typename) {
                                case 'ExchangeCalcNode': {
                                    setAmountGiveError('');
                                    setIsAmountReceiveLowError(false);
                                    setIsAmountReceiveHighError(false);

                                    if (amount.give) {
                                        setAmountReceive(
                                            numToPrecisionStr(
                                                Number(exchangeCalc.amountToReceive),
                                                currentPair?.outputPrecision ?? 5
                                            ) ?? ''
                                        );
                                    }

                                    if (amount.receive) {
                                        setAmountGive(
                                            numToPrecisionStr(
                                                Number(exchangeCalc.amountToPay),
                                                currentPair?.inputPrecision ?? 5
                                            ) ?? ''
                                        );
                                    }

                                    break;
                                }
                                case 'ExchangeAmountTooLowError': {
                                    setIsAmountReceiveLowError(true);
                                    setIsAmountReceiveHighError(false);

                                    if (amount.give) {
                                        setAmountReceive('');
                                    }

                                    if (amount.receive) {
                                        setAmountGive('');
                                    }

                                    break;
                                }
                                case 'ExchangeAmountTooHighError': {
                                    setIsAmountReceiveHighError(true);
                                    setIsAmountReceiveLowError(false);

                                    if (amount.give) {
                                        setAmountReceive('');
                                    }

                                    if (amount.receive) {
                                        setAmountGive('');
                                    }

                                    break;
                                }
                                case 'ExchangePairNotPresentError': {
                                    setAmountGiveError('VirtualCard Pair Not Present Error');

                                    break;
                                }
                                default:
                                    setAmountGiveError(getErrorData(calcData.error).message);
                            }
                        })
                        .catch((err) => {
                            setIsAmountReceiveLowError(true);
                            setIsAmountReceiveHighError(true);
                            setError(err);
                        })
                        .finally(() => {
                            setAmountGiveLoading(false);
                            setAmountReceiveLoading(false);
                        });
                }
            }, 1_000),
        [exchangeCalcQuery]
    );

    const handleCardChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = e.target.value.replace(/\D/g, '');

        inputCard.change(formatCard(formattedValue));
    };

    const handleWalletTrxChange = (e: React.ChangeEvent<HTMLInputElement>) => inputWalletTrx.change(e.target.value);
    const handleWalletEosChange = (e: React.ChangeEvent<HTMLInputElement>) => inputWalletEos.change(e.target.value);
    const handleMemoChange = (e: React.ChangeEvent<HTMLInputElement>) => inputMemo.change(e.target.value);
    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => inputEmail.change(e.target.value);

    const handleDirectionChange = (nextDirection: Direction) => () => {
        const currentPair = exchangePairs?.find((p) =>
            nextDirection === Direction.Output
                ? p?.inputCurrencyName === inActive && p?.outputCurrencyName === outActive
                : p?.inputCurrencyName === outActive && p?.outputCurrencyName === inActive
        );

        const currentAmountGive = Number(amountGive)
            ? String(roundNumber(Number(amountGive), currentPair?.inputPrecision ?? 5, 'trunc'))
            : '';

        setTab(nextDirection);
        setIsAmountReceiveLowError(false);
        setIsAmountReceiveHighError(false);

        if (currentAmountGive) {
            setAmountGive(currentAmountGive);

            if (currentPair?.id) {
                setAmountReceiveLoading(true);
                debouncedAmountReceive({ give: currentAmountGive }, currentPair);
            }
        } else {
            setAmountReceive('');
        }
    };

    const handleAmountGiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const regex = new RegExp(`^(\\d+(\\.\\d{0,${pair?.inputPrecision ?? 5}})?)?$`);
        const numericValue = removeNonNumeric(e.target.value, false);

        if (!regex.test(numericValue)) return;

        setAmountGive(numericValue);

        if (numericValue) {
            if (pair?.id) {
                debouncedAmountReceive({ give: numericValue }, pair);
            }
        } else {
            setAmountReceive('');
        }
    };

    const handleAmountReceiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const regex = new RegExp(`^(\\d+(\\.\\d{0,${pair?.outputPrecision ?? 5}})?)?$`);
        const numericValue = removeNonNumeric(e.target.value, false);

        if (!regex.test(numericValue)) return;

        setAmountReceive(numericValue);

        if (numericValue) {
            if (pair?.id) {
                debouncedAmountReceive({ receive: numericValue }, pair);
            }
        } else {
            setAmountReceive('');
        }
    };

    const handleInChange = (currencyName: string) => {
        const currentPairs = exchangePairs?.filter((item) => item?.inputCurrencyName === currencyName);

        const currentPair = exchangePairs?.find((p) =>
            direction === Direction.Output
                ? p?.inputCurrencyName === currencyName && p?.outputCurrencyName === outActive
                : p?.inputCurrencyName === outActive && p?.outputCurrencyName === currencyName
        );

        setInActive(currencyName);

        if (
            !currentPair &&
            currentPairs?.length &&
            !currentPairs?.find((item) => item?.outputCurrencyName === outActive) &&
            currentPairs[0]?.outputCurrencyName
        ) {
            setOutActive(currentPairs[0].outputCurrencyName);
        }

        if (amountGive) {
            if (currentPair?.id) {
                debouncedAmountReceive({ give: amountGive }, currentPair);
            }
        } else {
            setAmountReceive('');
        }
    };

    const handleOutChange = (currencyName: string) => {
        const currentPairs = exchangePairs?.filter((item) => item?.outputCurrencyName === currencyName);

        const currentPair = exchangePairs?.find((p) =>
            direction === Direction.Output
                ? p?.inputCurrencyName === inActive && p?.outputCurrencyName === currencyName
                : p?.inputCurrencyName === currencyName && p?.outputCurrencyName === inActive
        );

        setOutActive(currencyName);

        if (
            !currentPair &&
            currentPairs?.length &&
            !currentPairs?.find((item) => item?.inputCurrencyName === inActive) &&
            currentPairs[0]?.inputCurrencyName
        ) {
            setInActive(currentPairs[0].inputCurrencyName);
        }

        if (amountGive) {
            if (currentPair?.id) {
                debouncedAmountReceive({ give: amountGive }, currentPair);
            }
        } else {
            setAmountReceive('');
        }
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setAmountGiveError('');
        setIsAmountReceiveLowError(false);
        setIsAmountReceiveHighError(false);

        if (
            Number(removeSpaces(direction === Direction.Input ? amountGive : amountReceive)) <
            Number(pair?.minimalAmount)
        ) {
            setIsAmountReceiveLowError(true);

            return;
        }

        if (
            Number(pair?.maximalAmount) &&
            Number(removeSpaces(direction === Direction.Input ? amountGive : amountReceive)) >
                Number(pair?.maximalAmount)
        ) {
            setIsAmountReceiveHighError(true);

            return;
        }

        if (!pair?.id) return;

        const getReceiver = (): string => {
            if (direction === Direction.Input) return inputWalletEos.value;

            return pair?.outputCurrencyName === 'USDT' ? inputWalletTrx.value : removeSpaces(inputCard.value);
        };

        makeExchangeMutation({
            variables: {
                input: {
                    exchangePair: pair?.id,
                    inputAmount: amountGive,
                    outputAmount: amountReceive,
                    receiver: getReceiver(),
                    memo: direction === Direction.Input && pair?.inputCurrencyName !== 'USDT' ? inputMemo.value : '',
                    email: inputEmail.value,
                    language: language === 'ru' ? LanguageEnum.Ru : LanguageEnum.En
                }
            }
        })
            .then(({ data: exchangeData }) => {
                const makeExchange = exchangeData?.makeExchange;

                if (makeExchange?.__typename === 'MakeExchangeSuccess') {
                    const { orderId } = makeExchange;

                    if (direction === Direction.Input && inputWalletEos.value) {
                        localStorage.setItem(LOCAL_STORAGE_KEY_WALLET_EOS, inputWalletEos.value);
                    } else if (pair?.outputCurrencyName === 'USDT' && inputWalletTrx.value) {
                        localStorage.setItem(LOCAL_STORAGE_KEY_WALLET_TRX, inputWalletTrx.value);
                    }

                    window.location.href = `https://${process.env.REACT_APP_STORE_DOMAIN}/payment/${orderId}`;
                } else if (makeExchange?.__typename === 'InvalidTransferParamsError') {
                    if (direction === Direction.Input) {
                        inputWalletEos.errorChange(true, t('pageExchange.error.InvalidTransferParamsError.crypto'));
                    }

                    if (direction === Direction.Output) {
                        if (pair?.outputCurrencyName === 'USDT') {
                            inputWalletTrx.errorChange(true, t('pageExchange.error.InvalidTransferParamsError.crypto'));
                        } else {
                            inputCard.errorChange(true, t('pageExchange.error.InvalidTransferParamsError.card'));
                        }
                    }
                } else if (makeExchange?.__typename === 'ExchangeAmountTooLowError') {
                    setIsAmountReceiveLowError(true);
                } else if (makeExchange?.__typename === 'ExchangeAmountTooHighError') {
                    setIsAmountReceiveHighError(true);
                } else {
                    onGlobalError(t(`pageExchange.error[${makeExchange?.__typename}]`));
                }
            })
            .catch((err) => onGlobalError(getErrorData(err).message));
    };

    React.useEffect(() => {
        if (exchangePairs && exchangePairs.length) {
            const currentPairs = exchangePairs?.filter((p) =>
                direction === Direction.Output ? getOutPair(p) : getInPair(p)
            );
            /** Initial input and output */
            if (!outActive && !inActive) {
                setOutActive(
                    Direction.Output ? currentPairs[0]?.outputCurrencyName : currentPairs[0]?.inputCurrencyName
                );
                setInActive(
                    Direction.Output ? currentPairs[0]?.inputCurrencyName : currentPairs[0]?.outputCurrencyName
                );
            } else if (direction === Direction.Output) {
                /** No crypto input for sale */
                if (!currentPairs.find((method) => method?.inputCurrencyName === inActive)) {
                    setInActive(currentPairs[0]?.inputCurrencyName);
                }
                /** No fiat output for sale */
                if (!currentPairs.find((method) => method?.outputCurrencyName === outActive)) {
                    setOutActive(currentPairs[0]?.outputCurrencyName);
                }
            } else if (direction === Direction.Input) {
                const activePair = currentPairs.find((method) => method?.inputCurrencyName === outActive);

                const currentPair = currentPairs?.find(
                    (p) => p?.inputCurrencyName === outActive && p?.outputCurrencyName === inActive
                );

                /** No crypto output for purchase */
                if (!currentPair && Boolean(activePair) && inActive !== activePair?.outputCurrencyName) {
                    setInActive(activePair?.outputCurrencyName);
                }

                /** No fiat input for purchase */
                if (!currentPairs.find((method) => method?.inputCurrencyName === outActive)) {
                    setOutActive(currentPairs[0]?.inputCurrencyName);
                }
                /** No crypto output for purchase */
                if (!currentPairs.find((method) => method?.outputCurrencyName === inActive)) {
                    setInActive(currentPairs[0]?.outputCurrencyName);
                }
            }

            setOutOptions(
                direction === Direction.Output && inActive === 'RUBCASH'
                    ? [
                          ...getOptions(
                              currentPairs,
                              direction === Direction.Output ? Direction.Output : Direction.Input,
                              direction === Direction.Output ? inActive : undefined
                          ),
                          { value: '', label: 'USDT\u00A0(TRC\u201120)', disabled: true }
                      ]
                    : getOptions(
                          currentPairs,
                          direction === Direction.Output ? Direction.Output : Direction.Input,
                          direction === Direction.Output ? inActive : undefined
                      )
            );

            setInOptions(
                direction === Direction.Input && outActive === 'USDT'
                    ? [
                          ...getOptions(
                              currentPairs,
                              direction === Direction.Input ? Direction.Output : Direction.Input,
                              direction === Direction.Input ? outActive : undefined
                          ),
                          { value: '', label: 'RUBCASH', disabled: true }
                      ]
                    : getOptions(
                          currentPairs,
                          direction === Direction.Output ? Direction.Input : Direction.Output,
                          direction === Direction.Input ? outActive : undefined
                      )
            );
        }
    }, [inActive, exchangePairs, outActive, direction]);

    React.useEffect(() => {
        if (exchangePairs && exchangePairs.length && outActive && inActive) {
            const currentPair = exchangePairs?.find((p) =>
                direction === Direction.Output
                    ? p?.inputCurrencyName === inActive && p?.outputCurrencyName === outActive
                    : p?.inputCurrencyName === outActive && p?.outputCurrencyName === inActive
            );

            setPair(currentPair || exchangePairs[0]);
        }
    }, [inActive, exchangePairs, outActive, direction]);

    React.useEffect(() => {
        /** Allows only to buy if there are no pairs for sale. */
        if (Boolean(pairsInput?.length) && !pairsOutput?.length) setTab(Direction.Input);
    }, [pairsInput?.length, pairsOutput?.length]);

    React.useEffect(() => {
        if (amountGiveError) onGlobalError(amountGiveError);
        if (pairsError) onGlobalError(pairsError.message);
        if (error) onGlobalError(error);
    }, [pairsError, error, amountGiveError, onGlobalError]);

    return (
        <div className={styles.Root}>
            {isActive ? (
                <TechWorksStub />
            ) : (
                <>
                    <div className={styles.TitleContainer}>
                        <h2 className={styles.Title}>{t('pageExchange.title')}</h2>
                        <span
                            onMouseEnter={tooltip.show}
                            onMouseLeave={tooltip.hide}
                            ref={tooltip.setPopperReference}
                            className={styles.TitleBadge}
                        >
                            <div className={styles.TitleBadgeTooltipControl}>
                                <span className={styles.TitleBadgeTooltipControlText}>
                                    {t('pageExchange.badge.label')}
                                </span>
                                <InfoCircledIcon />
                            </div>

                            <Tooltip
                                {...tooltip}
                                popperOptions={(defaultConfig) =>
                                    update(defaultConfig, {
                                        placement: () => 'top',
                                        modifiers: { 1: { options: { offset: () => [0, 8] } } }
                                    })
                                }
                            >
                                <div className={styles.TitleBadgeTooltipContainer}>
                                    {t('pageExchange.badge.content')}
                                </div>
                            </Tooltip>
                        </span>
                    </div>

                    <ExchangeTabs
                        amountGive={amountGive}
                        amountGiveLoading={amountGiveLoading}
                        amountGiveError={amountGiveError}
                        amountReceive={amountReceive}
                        amountReceiveLoading={amountReceiveLoading}
                        direction={direction}
                        exchangeLoading={exchangeLoading}
                        inActive={inActive}
                        inOptions={inOptions}
                        inputCard={inputCard}
                        inputEmail={inputEmail}
                        inputMemo={inputMemo}
                        inputWalletTrx={inputWalletTrx}
                        inputWalletEos={inputWalletEos}
                        isAmountReceiveLowError={isAmountReceiveLowError}
                        isAmountReceiveHighError={isAmountReceiveHighError}
                        onAmountGiveChange={handleAmountGiveChange}
                        onAmountReceiveChange={handleAmountReceiveChange}
                        onCardChange={handleCardChange}
                        onCryptoChange={handleInChange}
                        onEmailChange={handleEmailChange}
                        onFiatChange={handleOutChange}
                        onMemoChange={handleMemoChange}
                        onSubmit={handleSubmit}
                        onDirectionChange={handleDirectionChange}
                        onWalletEosChange={handleWalletEosChange}
                        onWalletTrxChange={handleWalletTrxChange}
                        outActive={outActive}
                        outOptions={outOptions}
                        pair={pair}
                        pairsLoading={pairsLoading}
                        pairsInput={pairsInput}
                        pairsOutput={pairsOutput}
                    />
                </>
            )}
        </div>
    );
}
