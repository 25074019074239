import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { VirtualCardBackgroundProps } from './VirtualCardBackground.d';
import styles from './VirtualCardBackground.module.scss';

export default function VirtualCardBackground({
    children,
    classes
}: React.PropsWithChildren<VirtualCardBackgroundProps>) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [t, { language }] = useTranslation();

    const { background, container, root } = classes ?? {};

    return (
        <div className={cn(styles.Root, root)}>
            <div className={cn(styles.Background, styles[`Background-${language}`], background)} />
            <div className={cn(styles.Container, container)}>{children}</div>
        </div>
    );
}
