import React from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarIcon } from 'ui/Icons/Icons';
import styles from './OpenLetters.module.scss';

export default function OpenLetters() {
    const [t] = useTranslation();

    return (
        <div className={styles.Root}>
            <h1 className={styles.Title}>{t('pageOpenLetters.title')}</h1>
            <div className={styles.Container}>
                <div className={styles.Item}>
                    <div className={styles.ItemContainer}>
                        <div className={styles.ItemDate}>
                            <CalendarIcon className={styles.ItemDateIcon} />
                            {t('pageOpenLetters.letter1.date')}
                        </div>
                        <h2 className={styles.ItemTitle}>{t('pageOpenLetters.letter1.title')}</h2>
                        <div>
                            {(t('pageOpenLetters.letter1.text', { returnObjects: true }) as string[]).map((text, i) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <p key={i}>{text}</p>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
