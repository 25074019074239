import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getAppLink, WalletName } from 'helpers/getAppLink/getAppLink';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import { useDialog } from 'ui/Dialog/Dialog';
import Link from 'ui/Link/Link';
import Button from 'ui/Button/Button';
import VirtualCardBackground from '../VirtualCardBackground/VirtualCardBackground';
import AuthModal from '../AuthModal/AuthModal';
import AuthQr from '../AuthQr/AuthQr';
import styles from './VirtualCardAuth.module.scss';

export default function VirtualCardAuth() {
    const [t] = useTranslation();
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);
    const isTabletOrLess = useMediaQuery((breakpoints) => breakpoints.down.lg);
    const authModal = useDialog();

    const [walletName, setWalletName] = React.useState(WalletName.Paycash);

    const handleShowAuthModal = () => authModal.setOpen(true);
    const handleWalletChange = (nextWalletName: WalletName) => setWalletName(nextWalletName);

    return (
        <>
            <div className={styles.Root}>
                {!isTabletOrLess && (
                    <div className={styles.Left}>
                        <div className={styles.Auth}>
                            <h2 className={styles.AuthTitle}>{t('pageVirtualCard.auth.qr.title')}</h2>
                            <div className={styles.AuthDescription}>
                                <Trans i18nKey="pageVirtualCard.auth.qr.description">
                                    <span />
                                </Trans>
                            </div>
                            <div className={styles.AuthQr}>
                                <AuthQr wallet={walletName} onWalletChange={handleWalletChange} />
                            </div>
                        </div>
                        <div className={styles.Download}>
                            {t('pageVirtualCard.app.download.title')}
                            <br />
                            <Link as="a" size="small" href={getAppLink(walletName)} target="_blank" rel="noreferrer">
                                {t('pageVirtualCard.app.download.link')}
                            </Link>
                        </div>
                    </div>
                )}

                <div className={styles.Right}>
                    <VirtualCardBackground
                        classes={{ background: styles.Background, container: styles.BackgroundContainer }}
                    >
                        <div className={styles.Content}>
                            <h1 className={styles.Title}>{t('pageVirtualCard.auth.title')}</h1>
                            <div className={styles.Text}>{t('pageVirtualCard.auth.description')}</div>

                            {isTabletOrLess && (
                                <Button fullWidth={isMobileOrLess} onClick={handleShowAuthModal}>
                                    {t('pageVirtualCard.auth.button.login')}
                                </Button>
                            )}
                        </div>
                    </VirtualCardBackground>
                </div>
            </div>

            <AuthModal wallet={walletName} onWalletChange={handleWalletChange} {...authModal} />
        </>
    );
}
