import React, { useEffect } from 'react';
import { Route, Router, Switch, useLocation } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ToastContainer } from 'react-toastify';
import { History } from 'history';
import client from 'apollo';
import { ROUTES_MAP } from 'constant';
import FeaturesDetection from 'helpers/FeaturesDetection/FeaturesDetection';
import Background from 'components/Background/Background';
import BottomBar from 'components/BottomBar/BottomBar';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import PageAboutProject from 'pages/AboutProject/AboutProject';
import PageAccount from 'pages/Account';
import PageError from 'pages/Error/Error';
import PageHome from 'pages/Home/Home';
import PagePool from 'pages/Pool/Pool';
import PagePools from 'pages/Pools/Pools';
import PageSearchAccount from 'pages/SearchAccount/SearchAccount';
import PageToken from 'pages/Token/Token';
import PageTokens from 'pages/Tokens/Tokens';
import PageTransactions from 'pages/TransactionsPage/TransactionsPage';
import PageAboutCashTokens from 'pages/AboutCashTokensPage/AboutCashTokensPage';
import PageDepositExchange from 'pages/DepositExchange/DepositExchange';
import ExchangePage from 'pages/ExchangePage';
import VirtualCardPage from 'pages/VirtualCardPage';
import OpenLettersPage from 'pages/OpenLettersPage';
import Bookmarks from '../Bookmarks/Bookmarks';
import AppProvider from '../Helpers/AppContext';
import ErrorBoundary from '../Helpers/ErrorBoundary';
import SearchAccount from '../SearchAccount/SearchAccount';
// import {InfoCircledIcon} from "ui/Icons/Icons";
// import { useTranslation } from 'react-i18next';
// import styles from './App.module.scss';
// import classNames from 'classnames/bind';

// const cx = classNames.bind(styles);

function ScrollController() {
    const { pathname } = useLocation();
    useEffect(() => window.scrollTo(0, 0), [pathname]);
    return null;
}

function AppProviders({ children, history }: React.PropsWithChildren<{ history: History }>) {
    return (
        <Router history={history}>
            <ApolloProvider client={client}>
                <AppProvider>{children}</AppProvider>
            </ApolloProvider>
        </Router>
    );
}

function SEO() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.ym?.(85186774, 'hit', window.location.href);
    }, [pathname]);

    return null;
}

// const Notification = () => {
//     const [t, { language }] = useTranslation();
//     const isInfoPage = useLocation().pathname === ROUTES_MAP.info;
//
//     if (isInfoPage) return null;
//
//     return (
//         <div className={cx('Notification')}>
//             <p>
//                 <i>
//                      <InfoCircledIcon className={styles.InfoIcon}/>
//                 </i>
//                 {language === 'ru' && (
//                     <span>
//                         25.01.2022 в 01:25 по Мск, на блокчейне EOS запущен смарт-контракт токена KLNK (KALINKA) и
//                         осуществлена полная эмиссия всех токенов KLNK. В качестве премайна вся эмиссия равномерно
//                         распределена между кошельками-владельцами всех эмиссионных Сертификатов GR, GU, GE и т.д,
//                         обеспечивших эмиссию крипто наличных по состоянию на указанное время запуска.
//                         <br />
//                         После тестирования смарт-контракта (к середине февраля) будут сформированы пулы с KLNK и токен
//                         будет запущен в свободное обращение.
//                     </span>
//                 )}
//                 {language === 'en' && (
//                     <span>
//                         On 01/25/2022 at 01:25 Moscow time, a smart contract of the KLNK token (KALINKA) was launched on
//                         the EOS blockchain and a full emission of all KLNK tokens was carried out. As a premine, the
//                         entire emission is evenly distributed among the wallets-owners of all emission Certificates GR,
//                         GU, GE, etc., which ensured the emission of crypto cash as of the specified launch time.
//                         <br />
//                         After testing the smart contract (by mid-February), pools with KLNK will be formed and the token
//                         will be released into free circulation.
//                     </span>
//                 )}
//             </p>
//         </div>
//     );
// };

function App({ withProviders = true, history }: { withProviders?: boolean; history: History }) {
    const AppContent = (
        <ErrorBoundary>
            <Background />
            <FeaturesDetection />
            <div id="app">
                <Header />
                <Switch>
                    <Route path={ROUTES_MAP.home} exact component={PageHome} />
                    <Route path={ROUTES_MAP.info} component={PageAboutProject} />
                    <Route path={ROUTES_MAP.aboutCashTokens} component={PageAboutCashTokens} />
                    <Route path={ROUTES_MAP.openLetters} component={OpenLettersPage} />
                    {/* <Route path={ROUTES_MAP.depositByBankCard} component={PageDepositByBankCard} /> */}
                    <Route path={ROUTES_MAP.virtualCard} component={VirtualCardPage} />
                    <Route path={ROUTES_MAP.depositExchange} component={PageDepositExchange} />
                    <Route path={ROUTES_MAP.exchange} component={ExchangePage} />
                    <Route path={ROUTES_MAP.exchangeStatus()} component={ExchangePage} />
                    <Route path={ROUTES_MAP.transactions} component={PageTransactions} />
                    <Route path={ROUTES_MAP.pools} component={PagePools} />
                    <Route path={ROUTES_MAP.pool()} component={PagePool} />
                    <Route path={ROUTES_MAP.tokens} component={PageTokens} />
                    <Route path={ROUTES_MAP.token()} component={PageToken} />
                    <Route path={ROUTES_MAP.searchAccount} exact component={PageSearchAccount} />
                    <Route path={ROUTES_MAP.account()} exact component={PageAccount} />
                    <Route path={ROUTES_MAP.accountPool()} exact component={PageAccount} />

                    <Route path="*" component={PageError} />
                </Switch>

                <Footer />
                <BottomBar />
            </div>
            <Bookmarks />
            <SearchAccount />
            <ScrollController />
            <ToastContainer position="top-center" autoClose={5000} hideProgressBar closeButton={false} />
            <SEO />
        </ErrorBoundary>
    );

    return withProviders ? <AppProviders history={history}>{AppContent}</AppProviders> : AppContent;
}

export default App;
