import React, { useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { ResponsivePie } from '@nivo/pie';
import { EosAccountQuery } from 'apollo/main/generated';
import FormatNumber from 'components/FormatNumber/FormatNumber';
import { BREAKPOINT_LANDSCAPE } from 'constant';
import TokenCard from 'components/TokenCard/TokenCard';
import { useIsTouchableDevice, useWidthCondition } from 'helpers';
import addThousandsSeparator from 'utils/addThousandsSeparator/addThousandsSeparator';
import getTokenLogo from 'helpers/getTokenLogo/getTokenLogo';
import { useTranslation } from 'react-i18next';
import { CenteredMetric } from './CenteredMetric';
// eslint-disable-next-line import/no-cycle
import { getPieceColor } from './AccountDetails';
import styles from './AccountDetails.module.scss';

const cx = classNames.bind(styles);

type HeldTokensType = NonNullable<NonNullable<EosAccountQuery['eosAccount']>['heldTokens']>;

type HeldTokenType = HeldTokensType[number];
type PiePiece = {
    id: string | number;
    value: number;
    data: HeldTokenType[];
    color: string;
};

function HeldTokens({ heldTokens }: { heldTokens: HeldTokensType }) {
    const [t] = useTranslation();
    const isTouchable = useIsTouchableDevice();
    const [isLandscapeOrLess] = useWidthCondition((w) => w < BREAKPOINT_LANDSCAPE);
    const [hoveredPiece, setHoveredPiece] = useState<PiePiece['id']>('');
    const [selectedPiece, setSelectedPiece] = useState<PiePiece['id']>('');

    /**
     * Construct data for Chart
     * Group small tokens into one piece
     */
    const pieData = useMemo(() => {
        const data: PiePiece[] = [];

        const smallPart: PiePiece = {
            id: 'small',
            data: [],
            value: 0,
            color: ''
        };

        /**
         * Get sum of all shares in list
         * Total can be 0 if user has token without liquidity (less than 0.00001 USDCASH)
         */
        const total = heldTokens.reduce((sum, token) => sum + Number(token?.shareInPortfolio ?? 0), 0);

        heldTokens.forEach((token, i) => {
            if (!token) return;
            /**
             * Constrict pie pieces
             * If value is not small - create piece
             *
             * If total is 0 - we have to create equivalent pieces on chart
             * Example: If we have 2 tokens - we have to create 2 pieces with `value=50`
             *
             * Otherwise - add it to small piece
             */
            const value = Number(token.shareInPortfolio ?? 0);
            if (value >= 1 || !total) {
                data.push({
                    id: token.code ?? i,
                    value: value || +(100 / heldTokens!.length).toFixed(2),
                    data: [token],
                    color: getPieceColor(i, token.code, hoveredPiece, selectedPiece)
                });
            } else {
                smallPart.data.push(token);
                smallPart.value += value;
            }
        });

        // finalize small part if it exists
        if (smallPart.value) {
            const onePercent = Number((total / 100).toFixed(2));
            if (smallPart.value < onePercent) {
                smallPart.value = onePercent;
            }

            smallPart.color = getPieceColor(data.length, smallPart.id, hoveredPiece, selectedPiece);
            data.push(smallPart);
        }

        return {
            smallPart,
            data
        };
    }, [heldTokens, hoveredPiece, selectedPiece]);

    return (
        <div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
                onClick={(e) => {
                    if (!selectedPiece) return;
                    let isDirectPathClick = false;
                    const paths = e.currentTarget.querySelectorAll('path');
                    paths.forEach((path) => {
                        if (path === e.target) {
                            isDirectPathClick = true;
                        }
                    });
                    if (!isDirectPathClick) setSelectedPiece('');
                }}
                className={cx('PieChart', selectedPiece && 'selected')}
            >
                <ResponsivePie
                    data={pieData.data}
                    isInteractive
                    padAngle={0.6}
                    margin={
                        isLandscapeOrLess
                            ? { top: 32, bottom: 32, right: 20, left: 20 }
                            : { top: 60, bottom: 60, right: 20, left: 20 }
                    }
                    cornerRadius={3}
                    innerRadius={0.6}
                    activeInnerRadiusOffset={isTouchable ? 0 : 8}
                    activeOuterRadiusOffset={isTouchable ? 0 : 8}
                    colors={(d) => d.data.color}
                    layers={['arcs', CenteredMetric(t('pageAccount.tokens'))]}
                    onMouseEnter={(data) => setHoveredPiece(data.id)}
                    onMouseLeave={() => setHoveredPiece('')}
                    onClick={(data) => setSelectedPiece(data.id === selectedPiece ? '' : data.id)}
                    tooltip={() => null}
                />
            </div>

            <ul className={cx('TokenList')}>
                {pieData.data.map(({ id, color, data: tokens }) => {
                    if (selectedPiece && selectedPiece !== id) return null;
                    return tokens.map((token) => {
                        // eslint-disable-next-line no-param-reassign
                        token = token!;
                        return (
                            <li key={token.code} className={cx('TokenItem')}>
                                <TokenCard
                                    className={cx('TokenItemTokenCard')}
                                    codeClassName={cx('TokenItemTokenCardCode')}
                                    logoSize={42}
                                    // as because we have not clean code - we have to
                                    // get token logo ourself
                                    logo={getTokenLogo(token.logo, token.code, token.smartContract)}
                                    code={`${addThousandsSeparator(token.amount)} ${token.code}`}
                                    publicName={<FormatNumber value={token.usdEquivalent} prefix="$" />}
                                />
                                <span className={cx('TokenItemPercent')}>
                                    <FormatNumber value={token.shareInPortfolio} suffix="%" />

                                    <span
                                        className={cx('TokenItemMark')}
                                        style={{
                                            background: color
                                        }}
                                    />
                                </span>
                            </li>
                        );
                    });
                })}
            </ul>
        </div>
    );
}

export default HeldTokens;
