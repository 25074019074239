import React from 'react';
import cn from 'classnames';
import useWindowResize from 'hooks/useWindowResize/useWindowResize';
import { TabsProps } from './Tabs.d';
import styles from './Tabs.module.scss';

export default function Tabs<T extends string = string>({
    align = 'start',
    ariaLabel = 'Tabs',
    classes,
    fullWidth,
    hideUnderline,
    isDark = true,
    isScrollable,
    onChange,
    size = 'large',
    tabList,
    value,
    variant = 'button'
}: TabsProps<T>) {
    const [currentValue, setCurrentValue] = React.useState(value);

    const activeTabRef = React.useRef<HTMLButtonElement>(null);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const indicatorRef = React.useRef<HTMLDivElement>(null);

    const { container, list, root: rootClass, indicator: indicatorClass, tab: tabClass } = classes ?? {};

    const classesRoot = cn(
        styles.Root,
        styles[`Align-${align}`],
        styles[`Size-${size}`],
        styles[`Variant-${variant}`],
        isDark && styles.Dark,
        isScrollable && styles.Scrollable,
        !hideUnderline && styles.Underline,
        fullWidth && styles.FullWidth,
        rootClass
    );
    /** Updates indicator position and scroll. */
    const update = () => {
        if (!indicatorRef.current || !activeTabRef.current) return;

        indicatorRef.current.style.width = `${activeTabRef.current.offsetWidth}px`;
        indicatorRef.current.style.left = `${activeTabRef.current.offsetLeft}px`;

        if (!containerRef.current) return;

        const tabsListWidth = containerRef.current.offsetWidth;
        const activeTabLeft = activeTabRef.current.offsetLeft;
        const activeTabRight = activeTabLeft + activeTabRef.current.offsetWidth;

        if (activeTabLeft < containerRef.current.scrollLeft) {
            containerRef.current.scrollLeft = activeTabLeft - 5;
        } else if (activeTabRight > tabsListWidth + containerRef.current.scrollLeft) {
            containerRef.current.scrollLeft = activeTabRight - tabsListWidth + 5;
        }
    };
    /** Updates on window resize */
    useWindowResize(update);

    const handleChange = (nextValue: T) => () => {
        if (nextValue) {
            setCurrentValue(nextValue);
            onChange?.(nextValue);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
        if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
            const currentIndex = tabList.findIndex((tab) => tab.value === currentValue);

            let nextIndex = currentIndex;

            if (e.key === 'ArrowLeft') {
                nextIndex = Math.max(0, currentIndex - 1);

                while (nextIndex >= 0 && tabList[nextIndex]?.disabled) {
                    nextIndex -= 1;
                }
            }

            if (e.key === 'ArrowRight') {
                nextIndex = Math.min(tabList.length - 1, currentIndex + 1);

                while (nextIndex < tabList.length && tabList[nextIndex]?.disabled) {
                    nextIndex += 1;
                }
            }

            if (tabList[nextIndex]) {
                const nextTabValue = tabList[nextIndex]?.value;

                setCurrentValue(nextTabValue);
                onChange?.(nextTabValue);
            }
        }
    };

    React.useEffect(() => update(), [currentValue, fullWidth, variant]);
    /** Sets initial value */
    React.useEffect(() => {
        if (value) setCurrentValue(value);
    }, [value]);

    return (
        <div className={classesRoot}>
            <div className={cn(styles.Container, container)} ref={containerRef}>
                <div className={cn(styles.List, list)} aria-label={ariaLabel} role="tablist">
                    {tabList.map((tab) => {
                        const { disabled, label, value: tabValue, ...buttonProps } = tab ?? {};
                        const isActive = tabValue === currentValue;

                        return (
                            <button
                                key={tabValue}
                                ref={isActive ? activeTabRef : null}
                                onClick={!disabled ? handleChange(tabValue) : undefined}
                                onKeyDown={handleKeyDown}
                                className={cn(
                                    styles.Item,
                                    isActive && styles.ItemActive,
                                    disabled && styles.ItemDisabled,
                                    tabClass
                                )}
                                tabIndex={disabled || !isActive ? -1 : 0}
                                type="button"
                                role="tab"
                                aria-selected={isActive}
                                {...buttonProps}
                            >
                                {label}
                            </button>
                        );
                    })}
                </div>
                <div ref={indicatorRef} className={cn(styles.Indicator, indicatorClass)} />
            </div>
        </div>
    );
}
