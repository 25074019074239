import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useOverflowController, useOverlayClickHandler } from 'helpers';
import { createPortal } from 'react-dom';
import styles from './PopupBase.module.scss';

const cx = classNames.bind(styles);

export type PopupBaseProps = PropsWithChildren<{
    close(): void;
    isOpen: boolean;
    wrapperClassName?: string;
    isOpenWrapperClassName?: string;
    className?: string;
}>;

function PopupBase({
    isOpen,
    close,
    children,
    wrapperClassName = '',
    className = '',
    isOpenWrapperClassName = ''
}: PopupBaseProps) {
    const overflowController = useOverflowController();
    const isFirstRenderRef = useRef(true);
    const portal = useRef(document.createElement('div')).current;
    const [isVisible, setIsVisible] = useState(false);

    const hidden = !isOpen && !isVisible;
    const shown = isOpen && isVisible;

    useEffect(
        () => () => portal && portal.remove(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const hide = () => {
        if (isOpen) setIsVisible(false);

        setTimeout(() => {
            if (!isOpen) setIsVisible(false);
            close();
            overflowController.unblockScroll();
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            portal && portal.remove();
        }, 200);
    };

    const overlayClickHandler = useOverlayClickHandler(hide);

    useEffect(() => {
        if (isOpen) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            portal && document.body.appendChild(portal);
            setTimeout(() => {
                setIsVisible(true);
                overflowController.blockScroll();
            });
        } else if (isFirstRenderRef.current) {
            isFirstRenderRef.current = false;
        } else {
            hide();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    if (hidden) return null;

    return createPortal(
        <div
            {...overlayClickHandler.overlayProps}
            className={cx('Wrapper', wrapperClassName, shown && ['isOpen', isOpenWrapperClassName])}
        >
            <aside {...overlayClickHandler.componentProps} className={cx(className)}>
                {children}
            </aside>
        </div>,
        portal
    );
}

export default PopupBase;
