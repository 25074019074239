import classNames from 'classnames/bind';
import { SadFaceFilledIcon } from 'ui/Icons/Icons';
import Loader from 'components/Loader/Loader';
import React, { PropsWithChildren, ReactNode } from 'react';
import styles from './PageState.module.scss';

const cx = classNames.bind(styles);

function Loading({ className = '' }: any) {
    return (
        <div className={cx('Loader', className)}>
            <Loader />
        </div>
    );
}

function Error({
    className = '',
    title,
    description,
    children
}: PropsWithChildren<{
    className?: string;
    title?: ReactNode;
    description?: ReactNode;
}>) {
    return (
        <div className={cx('ErrorScreen', className)}>
            <SadFaceFilledIcon className={styles.SadIcon} />
            <h6>{title}</h6>
            <p>{description}</p>
            {children}
        </div>
    );
}

const PageState = {
    Loading,
    Error
};

export default PageState;
