/**
 * Formats a long string into a shrink string.
 *
 * @param {string} str - Input string.
 * @param {number} start - Number of characters at the beginning of the string.
 * @param {number} end - Number of characters at the end of the string.
 * @param {string} separator - Shrink string separator.
 *
 * @returns {string}
 */
export default function formatShrinkString(
    str: string,
    start: number = 0,
    end: number = 0,
    separator: string = ' ··· '
): string {
    const prefix = str.slice(0, start);
    const suffix = str.slice(-end);

    if (prefix.length + suffix.length + separator.length >= str.length) {
        return str;
    }

    return `${prefix}${separator}${suffix}`;
}
