import React from 'react';
import { useTranslation } from 'react-i18next';
import { numToPrecisionStr } from 'utils/roundNumber/roundNumber';
import addThousandsSeparator from 'utils/addThousandsSeparator/addThousandsSeparator';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import Dialog, { DialogBody, DialogHeader, useDialog } from 'ui/Dialog/Dialog';
import styles from './VirtualCardTermsModal.module.scss';

const formatValue = (value?: string) => addThousandsSeparator(numToPrecisionStr(Number(value), 2));

export default function VirtualCardTermsModal({ open, setOpen, context, ...props }: ReturnType<typeof useDialog>) {
    const [t] = useTranslation();
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);

    const issuance = `$${formatValue(context.dataRef.current?.issueFixedFee ?? '')}`;
    const topUpMin = `$${formatValue(context.dataRef.current?.issueAmountMin ?? '')}`;
    const topUpFee = `${formatValue(context.dataRef.current?.issueFee ?? '')}%`;

    return (
        <Dialog
            open={open}
            onOpenChange={setOpen}
            fullScreen={isMobileOrLess}
            transition={isMobileOrLess ? 'slide-left' : undefined}
            {...props}
        >
            <DialogHeader title={t('pageVirtualCard.terms.title')} classes={{ title: styles.Title }} />
            <DialogBody>
                <ul className={styles.List}>
                    {(
                        t('pageVirtualCard.terms.list', { returnObjects: true }) as {
                            id: string;
                            label: string;
                            value: string;
                        }[]
                    ).map(({ id, label, value }) => {
                        let currentValue: string;

                        switch (id) {
                            case 'issuance':
                                currentValue = issuance;
                                break;
                            case 'topUpMin':
                                currentValue = topUpMin;
                                break;
                            case 'topUpFee':
                                currentValue = topUpFee;
                                break;
                            default:
                                currentValue = value;
                        }

                        return (
                            <li key={label} className={styles.Item}>
                                <div className={styles.Label}>{label}</div>
                                <div className={styles.Value}>{currentValue}</div>
                            </li>
                        );
                    })}
                </ul>
            </DialogBody>
        </Dialog>
    );
}
