import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import update from 'immutability-helper';
import { TopTokensQuery, useTopTokensQuery } from 'apollo/main/generated';
import { BREAKPOINT_LANDSCAPE, ROUTES_MAP } from 'constant';
import { formatBigNumbers, useWidthCondition } from 'helpers';
import addThousandsSeparator from 'utils/addThousandsSeparator/addThousandsSeparator';
import Tooltip, { useTooltip } from 'ui/Tooltip_DEPRECATED/Tooltip';
import Button from 'ui/Button_DEPRECATED/Button';
import Card, { CardError, CardLoader } from 'components/Card/Card';
import FormatNumber from 'components/FormatNumber/FormatNumber';
import TokenCard from 'components/TokenCard/TokenCard';
import ScrollBar from '../HomeScrollBar/HomeScrollBar';
import styles from './HomeTopTokens.module.scss';

const cx = classNames.bind(styles);

function Item({
    data,
    size
}: {
    data: NonNullable<NonNullable<TopTokensQuery['topProfitTokens']>['tokens']>[number];
    size: number;
}) {
    const [t] = useTranslation();
    const tooltip = useTooltip();

    if (!data) return null;

    return (
        <li className={cx('Item')}>
            <div className={cx('ItemContent')}>
                <Link
                    to={ROUTES_MAP.token(data.id)}
                    onMouseEnter={tooltip.show}
                    onMouseLeave={tooltip.hide}
                    ref={tooltip.setPopperReference}
                    className={cx('ItemLink')}
                    style={{ height: size }}
                >
                    <TokenCard
                        className={cx('ItemTokenCard')}
                        logoClassName={cx('ItemTokenCardLogo')}
                        smartContract={data.smartContract}
                        hidePublicName
                        logo={data.logo}
                        logoSize={46}
                        code={data.code}
                    />
                    <div className={cx('ItemPrice')}>
                        {t('pageHome.topTokens.params.price')}
                        <FormatNumber value={data.rateToUsd} prefix="$" />
                    </div>
                </Link>
                <p className={cx('ItemParam')}>
                    <span>{t('pageHome.topTokens.params.liquidity')}</span>
                    <b>${formatBigNumbers(data.liquidity)}</b>
                </p>

                <Tooltip
                    {...tooltip}
                    popperOptions={(config) =>
                        update(config, {
                            placement: () => 'top',
                            modifiers: {
                                1: {
                                    options: {
                                        offset: () => [0, 36]
                                    }
                                }
                            }
                        })
                    }
                >
                    <div className={cx('TooltipContent')}>
                        <div className={cx('Params')}>
                            <span>{t('pageHome.topTokens.params.price')}</span>
                            <span>{t('pageHome.topTokens.params.liquidity')}</span>
                            <span>{t('pageHome.topTokens.params.volume')}</span>
                            <span>{t('pageHome.topTokens.params.transactions')}</span>
                        </div>
                        <div className={cx('Value')}>
                            <span>${addThousandsSeparator(data.rateToUsd)}</span>
                            <span>${addThousandsSeparator(data.liquidity)}</span>
                            <span>${addThousandsSeparator(data.volume24h)}</span>
                            <span>{addThousandsSeparator(data.actionsNum24h.toString())}</span>
                        </div>
                    </div>
                </Tooltip>
            </div>
        </li>
    );
}

function SectionTopTokens() {
    const [t] = useTranslation();
    const listRef = useRef<HTMLUListElement>(null);
    const [isLandscapeOrLess] = useWidthCondition((w) => w < BREAKPOINT_LANDSCAPE);
    const query = useTopTokensQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-only'
    });

    const topProfitTokens = query.data?.topProfitTokens;

    const amounts = topProfitTokens?.tokens?.map((item) => +(item?.liquidity ?? 0)) ?? [];
    const min = Math.min(...amounts);
    const max = Math.max(...amounts);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const base_size = 217;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const min_size = 96;
    const range = base_size - min_size;
    const normalized = amounts.map((x) => (x - min) / (max - min));
    const sizes = normalized.map((x) =>
        // eslint-disable-next-line no-restricted-globals
        !x || isNaN(x) ? min_size : min_size + range * x
    );

    return (
        <Card
            className={cx('Component')}
            title={t('pageHome.topTokens.title')}
            helpText={t('pageHome.topTokens.helpText')}
        >
            {/* eslint-disable-next-line no-nested-ternary */}
            {query.error ? (
                <CardError resetError={query.refetch} disabled={query.loading} />
            ) : query.loading ? (
                <CardLoader />
            ) : (
                <div className={cx('ListWrapper')}>
                    <ul ref={listRef} className={cx('List')}>
                        {topProfitTokens?.tokens?.map((data, i) => <Item size={sizes[i]} data={data} key={data?.id} />)}
                    </ul>

                    {isLandscapeOrLess && <ScrollBar targetRef={listRef} />}
                </div>
            )}
            <Button
                tag="link"
                to={ROUTES_MAP.tokens}
                className={cx('BtnViewAll')}
                color={isLandscapeOrLess ? 'link' : 'secondary'}
            >
                {t('pageHome.topTokens.btnViewAll')}
            </Button>
        </Card>
    );
}

export default SectionTopTokens;
