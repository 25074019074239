import React from 'react';
import classNames from 'classnames/bind';
import Button from 'ui/Button_DEPRECATED/Button';
import Socials from 'components/Socials/Socials';
import { scrollInto } from 'helpers';
import getImage from 'helpers/getImage/getImage';
import { Trans, useTranslation } from 'react-i18next';
import { ID as AboutSectionID } from '../AboutProject/AboutProject';
import { ID as StartSectionID } from '../AboutProjectStart/AboutProjectStart';
import styles from './AboutProjectIntro.module.scss';

const cx = classNames.bind(styles);

function Intro() {
    const [t, { language }] = useTranslation();
    const goNextSection = (e: React.MouseEvent) => {
        scrollInto(e, AboutSectionID, -100);
    };
    const goToStart = (e: React.MouseEvent) => {
        scrollInto(e, StartSectionID, -100);
    };

    return (
        <section className={cx('Component')}>
            <div className={cx('Container')}>
                <div className={cx('Details')}>
                    <h1 className={cx('Title')}>
                        <Trans i18nKey="pageInfo.intro.title">
                            <b />
                            <span />
                            <span className={cx('highlight')} />
                        </Trans>
                    </h1>
                    <p className={cx('Description')}>{t('pageInfo.intro.description')}</p>
                    <div className={cx('BtnGroup')}>
                        <Button onClick={goToStart} color="primary">
                            {t('pageInfo.intro.btnStart')}
                        </Button>

                        <Button onClick={goNextSection} color="secondary">
                            {t('pageInfo.intro.btnMore')}
                        </Button>
                    </div>

                    <Socials className={cx('Social')} />
                </div>
                <div className={cx('ImgWrapper')}>
                    <img
                        srcSet={getImage('about-project_intro', language, 2)}
                        src={getImage('about-project_intro', language, 1)}
                        alt="About Project Intro"
                    />
                </div>
            </div>
        </section>
    );
}

export default Intro;
