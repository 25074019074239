import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';
import { useDropdown } from 'helpers';
import { ChevronDownIcon } from 'ui/Icons/Icons';
import styles from './SortDropdown.module.scss';

const cx = classNames.bind(styles);

type Props<T> = {
    active: T;
    label: ReactNode;
    className?: string;
    onChange(v: T): void;
    options: { value: T; label: ReactNode }[];
};

function SortDropdown<T extends string>({ active, label, onChange, options, className = '' }: Props<T>) {
    const dropdown = useDropdown({
        popperOptions: {
            placement: 'bottom-end'
        }
    });

    return (
        <div ref={dropdown.ref} className={cx('SortDropdown', className)}>
            <button
                type="button"
                onClick={dropdown.toggle}
                className={cx('SortDropdownControl', dropdown.open && 'active')}
            >
                <span>{label}</span>
                <ChevronDownIcon className={styles.Icon} />
            </button>

            <div
                ref={dropdown.popperRef}
                style={dropdown.popper.styles.popper}
                {...dropdown.popper.attributes.popper}
                className={cx('SortDropdownContent', dropdown.open && 'show')}
            >
                {options.map(({ label: optionLabel, value }) => (
                    <button
                        key={value}
                        type="button"
                        disabled={active === value}
                        onClick={() => {
                            dropdown.toggle();
                            onChange(value);
                        }}
                    >
                        {optionLabel}
                    </button>
                ))}
            </div>
        </div>
    );
}
export default SortDropdown;
