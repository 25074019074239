import React from 'react';
import cn from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { LinkPolymorphicComponent, LinkPropsWithRef, LinkRef, LinkProps } from './Link.d';
import styles from './Link.module.scss';

const Link: LinkPolymorphicComponent = React.forwardRef(
    <C extends React.ElementType = 'button'>(
        {
            as,
            children,
            className,
            color = 'primary',
            disabled,
            size = 'medium',
            underline = 'hover',
            variant,
            to,
            ...props
        }: LinkPropsWithRef<C, LinkProps>,
        ref?: LinkRef<C>
    ) => {
        const Component = as || RouterLink;

        const classes = cn(
            styles.Root,
            styles[`Color-${color}`],
            styles[`Size-${size}`],
            styles[`Underline-${underline}`],
            disabled && styles.Disabled,
            className
        );

        return (
            <Component
                className={classes}
                disabled={disabled}
                tabIndex={disabled ? -1 : undefined}
                to={to}
                {...props}
                ref={ref}
            >
                {children}
            </Component>
        );
    }
);

export default Link;
