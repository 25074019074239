import {
    useBookmarksQuery,
    useUpdateFavoriteAccountMutation,
    useUpdateFavoritePoolMutation,
    useUpdateFavoriteTokenMutation
} from 'apollo/main/generated';
import { useGlobalError, useToast, getErrorData } from 'helpers';
import React from 'react';
import classNames from 'classnames/bind';
import { BookmarkIcon, BookmarkFilledIcon } from 'ui/Icons/Icons';
import { useTranslation } from 'react-i18next';
import Button from 'ui/Button_DEPRECATED/Button';
import styles from './BookmarkBtn.module.scss';

const cx = classNames.bind(styles);

type Props = {
    isSmall?: boolean;
    isFavorite: boolean;
    className?: string;
    onUpdateFavorite?: () => void;
} & (
    | { type: 'account'; eosAccount: string }
    | { type: 'token'; tokenId: number | string }
    | { type: 'pool'; poolCode: string }
);

function BookmarkBtn({ isSmall, className = '', isFavorite, onUpdateFavorite, ...props }: Props) {
    const onGlobalError = useGlobalError();
    const toast = useToast();
    const [t] = useTranslation();
    const bookmarksQuery = useBookmarksQuery();
    const [updateFavoriteAccountMutation] = useUpdateFavoriteAccountMutation();
    const [updateFavoritePoolMutation] = useUpdateFavoritePoolMutation();
    const [updateFavoriteTokenMutation] = useUpdateFavoriteTokenMutation();

    const toggleFavorite = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        let isOk = false;
        let errorMsg = '';

        try {
            if (props.type === 'account') {
                const result = await updateFavoriteAccountMutation({
                    variables: {
                        input: {
                            eosAccount: props.eosAccount
                        }
                    },
                    optimisticResponse: {
                        __typename: 'Mutations',
                        updateFavoriteAccount: {
                            __typename: 'UpdateFavoriteAccountSuccess',
                            visitor: null as any,
                            account: {
                                name: props.eosAccount,
                                __typename: 'AccountNode',
                                isFavorite: !isFavorite
                            }
                        }
                    }
                });
                const updateFavoriteAccount = result?.data?.updateFavoriteAccount! ?? {};
                if (updateFavoriteAccount.__typename === 'UpdateFavoriteAccountSuccess') {
                    isOk = true;
                } else {
                    errorMsg = (updateFavoriteAccount as any).errorMessage;
                }
            } else if (props.type === 'pool') {
                const result = await updateFavoritePoolMutation({
                    variables: {
                        input: {
                            poolCode: props.poolCode
                        }
                    },
                    optimisticResponse: {
                        __typename: 'Mutations',
                        updateFavoritePool: {
                            __typename: 'UpdateFavoritePoolSuccess',
                            visitor: null as any,
                            pool: {
                                code: props.poolCode,
                                __typename: 'PoolNode',
                                isFavorite: !isFavorite
                            }
                        }
                    }
                });
                const updateFavoritePool = result.data?.updateFavoritePool! ?? {};

                if (updateFavoritePool.__typename === 'UpdateFavoritePoolSuccess') {
                    isOk = true;
                } else {
                    errorMsg = (updateFavoritePool as any).errorMessage;
                }
            } else {
                const result = await updateFavoriteTokenMutation({
                    variables: {
                        input: {
                            tokenId: +props.tokenId
                        }
                    },
                    optimisticResponse: {
                        __typename: 'Mutations',
                        updateFavoriteToken: {
                            __typename: 'UpdateFavoriteTokenSuccess',
                            visitor: null as any,
                            token: {
                                id: +props.tokenId,
                                __typename: 'TokenNode',
                                isFavorite: !isFavorite
                            }
                        }
                    }
                });
                const updateFavoriteToken = result.data?.updateFavoriteToken! ?? {};
                if (updateFavoriteToken.__typename === 'UpdateFavoriteTokenSuccess') {
                    isOk = true;
                } else {
                    errorMsg = (updateFavoriteToken as any).errorMessage;
                }
            }

            if (isOk) {
                onUpdateFavorite?.();
                if (!bookmarksQuery.data?.visitor) {
                    bookmarksQuery.refetch();
                }
            } else {
                onGlobalError(errorMsg, toast);
            }
        } catch (err) {
            onGlobalError(getErrorData(err).message, toast);
        }
    };

    const Icon = isFavorite ? (
        <BookmarkFilledIcon className={cx(isFavorite && 'active')} />
    ) : (
        <BookmarkIcon className={cx(isFavorite && 'active')} />
    );

    if (isSmall) {
        return (
            // eslint-disable-next-line react/button-has-type
            <button onClick={toggleFavorite} className={cx('BtnSmall', className)}>
                {Icon}
            </button>
        );
    }
    return (
        <Button
            onClick={toggleFavorite}
            className={className}
            color="tertairy-white"
            startIcon={isFavorite ? <BookmarkFilledIcon className={styles.active} /> : <BookmarkIcon />}
        >
            {t('global.btnBookmark', { context: isFavorite ? 'remove' : 'add' })}
        </Button>
    );
}

export default BookmarkBtn;
