import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import styles from './Home.module.scss';
import MLNKCollected from '../../components/HomeMLNKCollected/HomeMLNKCollected';
import MLNKPrice from '../../components/HomeMLNKPrice/HomeMLNKPrice';
import SectionTopPools from '../../components/HomeTopPools/HomeTopPools';
import SectionTopTokens from '../../components/HomeTopTokens/HomeTopTokens';
import TotalLiquidity from '../../components/HomeTotalLiquidity/HomeTotalLiquidity';
import TotalVolume from '../../components/HomeTotalVolume/HomeTotalVolume';
import Banners from '../../components/HomeBanners/HomeBanners';
import CashStatistics from '../../components/HomeCashStatistics/HomeCashStatistics';

const cx = classNames.bind(styles);

function PageHome() {
    const [t] = useTranslation();
    return (
        <>
            <Banners />
            <main className={cx('Component')}>
                <h1 className={cx('Title')}>{t('pageHome.title')}</h1>
                <CashStatistics />
                <div className={cx('CardList')}>
                    <SectionTopPools />
                    <MLNKPrice />
                    <TotalLiquidity />
                    <MLNKCollected />
                    <SectionTopTokens />
                    <TotalVolume />
                </div>
            </main>
        </>
    );
}

export default memo(PageHome);
