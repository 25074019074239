import { ChartResolutionEnum, useMlnkPriceQuery } from 'apollo/main/generated';
import classNames from 'classnames/bind';
import Card, { CardError, CardLoader, CardValue } from 'components/Card/Card';
import Chart, { COLOR_SCHEME, LoadMoreRange } from 'components/Chart/Chart';
import { BarsIcon, LineChartIcon } from 'ui/Icons/Icons';
import { USDCASH_MIN_MOVE, USDCASH_PRECISION } from 'constant';
import update from 'immutability-helper';
import { UTCTimestamp } from 'lightweight-charts';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './HomeMLNKPrice.module.scss';

const cx = classNames.bind(styles);

const DEFAULT_BARS_TO_SHOW = 100;
let now = new Date();
const TIMEZONE_OFFSET = new Date().getTimezoneOffset() * 60;

const getUTCTimestamp = (datetime: string) =>
    parseInt(`${+new Date(`${datetime}Z`) / 1_000 - TIMEZONE_OFFSET}`, 10) as UTCTimestamp;

const getVariables = ({
    filterToTimestamp = '',
    barsBeforeToLoad = DEFAULT_BARS_TO_SHOW,
    fetchMore = false,
    resolution = ChartResolutionEnum.Hour
} = {}) => {
    let to = new Date(+now);
    if (filterToTimestamp) {
        to = new Date(filterToTimestamp);
    }
    const from = new Date(+to);

    let MINUTES = 1;
    if (resolution === ChartResolutionEnum.FiveMinutes) {
        MINUTES = 5;
    } else if (resolution === ChartResolutionEnum.Hour) {
        MINUTES = 60;
    } else if (resolution === ChartResolutionEnum.Day) {
        MINUTES = 1_440;
    }

    from.setMinutes(from.getMinutes() - MINUTES * barsBeforeToLoad);

    return {
        resolution,
        fetchMore,
        filterToTimestamp: to.toISOString().replace('Z', ''),
        filterFromTimestamp: from.toISOString().replace('Z', '')
    };
};

const MLNKPrice = memo(({ className = '', allowTooltip = true }: { allowTooltip?: boolean; className?: string }) => {
    const [t] = useTranslation();
    const loadingRef = useRef(false);
    const [type, setType] = useState<'canblestick' | 'area'>('canblestick');

    const [variables, setVariables] = useState(getVariables());

    const query = useMlnkPriceQuery({
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only'
    });

    useEffect(() => {
        now = new Date();
    }, [variables]);

    const changeResolution = (resolution: ChartResolutionEnum) => {
        setVariables(
            getVariables({
                resolution
            })
        );
    };

    const loadMore = async (range: LoadMoreRange) => {
        const lastDate = query.data?.mlnkPriceChart?.points[0]?.timestamp;
        const dataFrom = query.data?.mlnkPriceChart?.pageInfo?.from;

        if (!lastDate || loadingRef.current || lastDate === dataFrom) return;

        loadingRef.current = true;

        await query
            .fetchMore({
                variables: getVariables({
                    resolution: variables.resolution,
                    fetchMore: true,
                    filterToTimestamp: `${lastDate}Z`,
                    barsBeforeToLoad: Math.min(Math.abs(range.barsBefore), DEFAULT_BARS_TO_SHOW)
                })
            })
            .then((data) => {
                query.updateQuery((previous) =>
                    update(previous, {
                        mlnkPriceChart: {
                            points: (points = []) => [
                                ...(data.data?.mlnkPriceChart?.points! ?? []).filter(
                                    (point) => !points.some((oldPoint) => point?.timestamp === oldPoint?.timestamp)
                                ),
                                ...points
                            ],
                            pageInfo: (pi) => data.data?.mlnkPriceChart?.pageInfo! || pi
                        }
                    })
                );
            })
            // eslint-disable-next-line no-console
            .catch(console.error);
        loadingRef.current = false;
    };

    return (
        <Card
            className={className}
            headerAdditionalContent={
                <div className={cx('Actions')}>
                    <div className={cx('Switcher', 'type')}>
                        <button
                            className={cx(type === 'canblestick' && 'active')}
                            onClick={() => setType('canblestick')}
                            type="button"
                        >
                            <BarsIcon />
                        </button>
                        <button
                            className={cx(type === 'area' && 'active')}
                            onClick={() => setType('area')}
                            type="button"
                        >
                            <LineChartIcon />
                        </button>
                    </div>
                    <div className={cx('Switcher', 'timeframes')}>
                        <button
                            className={cx(variables.resolution === ChartResolutionEnum.FiveMinutes && 'active')}
                            onClick={() => changeResolution(ChartResolutionEnum.FiveMinutes)}
                            type="button"
                        >
                            {t('pageHome.mlnkPrice.timeframe.FIVE_MINUTES')}
                        </button>
                        <button
                            className={cx(variables.resolution === ChartResolutionEnum.Hour && 'active')}
                            onClick={() => changeResolution(ChartResolutionEnum.Hour)}
                            type="button"
                        >
                            {t('pageHome.mlnkPrice.timeframe.HOUR')}
                        </button>
                        <button
                            className={cx(variables.resolution === ChartResolutionEnum.Day && 'active')}
                            onClick={() => changeResolution(ChartResolutionEnum.Day)}
                            type="button"
                        >
                            {t('pageHome.mlnkPrice.timeframe.DAY')}
                        </button>
                    </div>
                </div>
            }
            title={t('pageHome.mlnkPrice.title')}
        >
            {/* eslint-disable-next-line no-nested-ternary */}
            {query.error ? (
                <CardError resetError={query.refetch} disabled={query.loading} />
            ) : !query.data && query.loading ? (
                <CardLoader />
            ) : (
                <>
                    <CardValue
                        volatility={[
                            {
                                label: t('pageHome.mlnkPrice.volatility.day'),
                                value: query.data?.mlnkPriceChart?.percentageChange24h
                            },
                            {
                                label: t('pageHome.mlnkPrice.volatility.week'),
                                value: query.data?.mlnkPriceChart?.percentageChangeWeek
                            },
                            {
                                label: t('pageHome.mlnkPrice.volatility.month'),
                                value: query.data?.mlnkPriceChart?.percentageChangeMonth
                            }
                        ]}
                    >
                        ${query.data?.mlnkPriceChart?.value24h}
                    </CardValue>

                    <Chart
                        autoHeight
                        interactiveOnTouchable
                        options={{
                            timeScale: {
                                borderVisible: false,
                                timeVisible: true
                            }
                        }}
                        loadMore={loadMore}
                        showTooltip={allowTooltip && type === 'canblestick'}
                        data={{
                            type,
                            data:
                                query.data?.mlnkPriceChart?.points.map((point) =>
                                    type === 'canblestick'
                                        ? {
                                              time: getUTCTimestamp(point!.timestamp),
                                              close: Number(point!.close),
                                              open: Number(point!.open),
                                              low: Number(point!.low),
                                              high: Number(point!.high)
                                          }
                                        : {
                                              time: getUTCTimestamp(point!.timestamp),
                                              value: Number(point?.close)
                                          }
                                ) ?? [],
                            options: {
                                ...COLOR_SCHEME.pink.area,

                                priceFormat: {
                                    precision: USDCASH_PRECISION,
                                    minMove: USDCASH_MIN_MOVE
                                }
                            }
                        }}
                    />
                </>
            )}
        </Card>
    );
});

export default MLNKPrice;
