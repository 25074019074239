import classNames from 'classnames/bind';
import { useOverflowController, useOverlayClickHandler } from 'helpers';
import React, { createContext, memo, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import FocusLock from 'react-focus-lock';
import styles from './Modal.module.scss';

const cx = classNames.bind(styles);
/**
 * ==============================
 * Typedef section
 * ==============================
 */
export type Props = PropsWithChildren<{
    isOpen: boolean;
    close?: () => void;
    closable?: boolean;
    showCloseBtn?: boolean;
    className?: string;
    onClose?: () => void;
    wrapperClassName?: string;
    callClosePropOnly?: boolean;
    'data-testid'?: string;
}>;

/** alias for modal base props */
export type ModalProps = Props;

/**
 * ==============================
 * Constants
 * ==============================
 */
const ANIM_DURATION_BG = 250;
export const ANIM_DURATION_MODAL = 600;

/**
 * ==============================
 * Context
 * ==============================
 * helpful Modal context, that allow correctly close modal with initiate close animation firstly
 */
export const ModalContext = createContext({
    close: () => {}
});
// export const useModalContext = () => useContext(ModalContext);

/**
 * Fully controllable Modal component.
 * It diveded in pieaces to more easelly build modal components with
 * different markups.
 * Component has some internal logic of disaplaying:
 * 1) after change `isOpen` to `true` modal adds to the DOM by react's portal, animation starts
 * 2) on the end of animation local flag `modalActive` changes to `true`. Component ready to use
 * 3) on click close button or call `close` func from context - close animation fires by changing
 * `modalActive` flag to `true`
 * 4) on the end of animation `props.close` calls, `props.onClose` calls if it passed,
 * modal removes from the DOM
 *
 * @important
 * For better animation rendering on close use `close` function from **context**
 * ~~or pass `internalCloseRef` and call it if you need close modal outside/from upper level in the VDOM~~
 *
 * Additional features:
 * - on click **overlay** start closing modal
 * - on press `ESC` start closing modal
 * - uses focus-lock to disable leaving focus from modal
 */
const Modal = memo(
    ({
        isOpen,
        close,
        onClose,
        closable = true,
        callClosePropOnly = false,
        children,
        wrapperClassName,
        showCloseBtn,
        className = '',
        ...props
    }: Props) => {
        const [modalActive, setModalActive] = useState(false);
        const isFirstRenderRef = useRef(true);
        const closeTimeoutId = useRef<any>();
        const overflowController = useOverflowController();

        const closeRef = useRef(close);
        useEffect(() => {
            closeRef.current = close;
        }, [close]);

        const onExternalCloseRef = useRef<() => void>();
        const modalPortal = useRef(document.createElement('div')).current;
        const modalWrapperRef = useRef<HTMLElement>(null);
        const isClosing = (modalActive || isOpen) && !(modalActive && isOpen);
        const showModal = modalActive && isOpen;

        /** create portal container, mount it and set "ready" flag */
        const prepareEnvironment = () => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            modalPortal && document.body.appendChild(modalPortal);
            return setTimeout(() => {
                setModalActive(true);
                overflowController.blockScroll();
                modalWrapperRef.current?.scrollTo(0, 0);

                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                window.onkeydown = onPressEsc;
            });
        };

        const unmountEnvironment = (
            onBeforeAnimation = () => setModalActive(false),
            onEndAnimation = closeRef.current,
            forceClose = false
            // eslint-disable-next-line consistent-return
        ) => {
            if (callClosePropOnly) return onEndAnimation?.();

            // eslint-disable-next-line consistent-return
            if (!closable && !forceClose) return;

            // enable body's overflow
            overflowController.unblockScroll();
            // remove ekyboard event listener
            window.onkeydown = null;
            onBeforeAnimation();
            closeTimeoutId.current = setTimeout(() => {
                onExternalCloseRef.current = undefined;
                onClose?.();
                onEndAnimation?.();
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                modalPortal && modalPortal.remove();
            }, ANIM_DURATION_MODAL);
        };

        const onPressEsc = (e: KeyboardEvent) => {
            if (e.key === 'Escape') unmountEnvironment();
        };

        const overlayClickHandler = useOverlayClickHandler(unmountEnvironment);

        useEffect(
            () => () => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                modalPortal && modalPortal.remove();
                overflowController.unblockScroll();
                clearInterval(closeTimeoutId.current);
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            []
        );

        /**
         * on change `isOpen`
         * if `isOpen` is true - create portal container, render it and set "ready" flag
         * otherwise remove all event listeners and close modal
         */
        // eslint-disable-next-line consistent-return
        useEffect(() => {
            if (isOpen) {
                const tid = prepareEnvironment();
                onExternalCloseRef.current = () => {
                    unmountEnvironment(
                        closeRef.current,
                        () => {
                            setModalActive(false);
                        },
                        true
                    );
                    onExternalCloseRef.current = undefined;
                };

                return () => clearTimeout(tid);
            }
            if (isFirstRenderRef.current) {
                isFirstRenderRef.current = false;
                // eslint-disable-next-line consistent-return
                return;
            }
            onExternalCloseRef.current?.();

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isOpen]);

        if (!isOpen && !modalActive) return null;

        return createPortal(
            <FocusLock>
                {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
                <ModalContext.Provider value={{ close: () => unmountEnvironment() }}>
                    <aside
                        {...overlayClickHandler.overlayProps}
                        ref={modalWrapperRef}
                        data-testid="modal-wrapper"
                        style={{
                            transitionDuration: `${ANIM_DURATION_BG / 1000}s`,
                            transitionDelay: isClosing ? `${(ANIM_DURATION_BG / 1000) * 0.8}s` : undefined
                        }}
                        className={cx('Component', modalActive && isOpen && 'show', wrapperClassName)}
                    >
                        <div
                            {...overlayClickHandler.componentProps}
                            style={{
                                transitionDuration: `${ANIM_DURATION_MODAL / 1000}s`,
                                transitionDelay: showModal ? `${(ANIM_DURATION_BG / 1000) * 0.2}s` : undefined
                            }}
                            className={cx('Modal', className)}
                            data-testid={props['data-testid'] || 'modal'}
                        >
                            {children}
                        </div>
                    </aside>
                </ModalContext.Provider>
            </FocusLock>,
            modalPortal
        );
    }
);

export default Modal;
