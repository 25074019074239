import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import Button from 'ui/Button_DEPRECATED/Button';
import { scrollInto } from 'helpers';
import Banners from 'components/HomeBanners/HomeBanners';
import Tutorial, { TUTORIAL_SECTION_ID } from 'components/DepositExchangeTutorial/DepositExchangeTutorial';
import DepositExchangeIntro1x from 'assets/images/deposit-exchange-intro@1x.png';
import DepositExchangeIntro2x from 'assets/images/deposit-exchange-intro@2x.png';
import KrakenLogo from 'assets/images/exchanges/kraken.svg';
import HitBTCLogo from 'assets/images/exchanges/hitbtc.svg';
import ShapeshiftLogo from 'assets/images/exchanges/shapeshift.svg';
import BithumbLogo from 'assets/images/exchanges/bithumb.svg';
import BitfinexLogo from 'assets/images/exchanges/bitfinex.svg';
import styles from './DepositExchange.module.scss';

const cx = classNames.bind(styles);

function PageDepositExchange() {
    const [t] = useTranslation();
    return (
        <>
            <main className={cx('Component')}>
                <section className={cx('Intro')}>
                    <div className={cx('IntroDetails')}>
                        <h1 className={cx('Title')}>{t('pageDepositExchange.title')}</h1>
                        <p className={cx('Description')}>{t('pageDepositExchange.description')}</p>
                        <Button
                            className={styles.IntroButton}
                            onClick={(e) => scrollInto(e, TUTORIAL_SECTION_ID, 100)}
                            tag="button"
                            color="primary"
                        >
                            {t('pageDepositExchange.btnStart')}
                        </Button>
                    </div>
                    <div className={cx('IntroImages')}>
                        <img
                            className={cx('ImgCard')}
                            alt="Deposit Exchange Intro"
                            srcSet={DepositExchangeIntro2x}
                            src={DepositExchangeIntro1x}
                        />
                    </div>
                </section>

                <Tutorial />

                <section className={cx('ListSection')}>
                    <div>
                        <h3>{t('pageDepositExchange.exchangeList')}</h3>

                        <ul>
                            {[
                                {
                                    name: 'Kraken',
                                    logo: KrakenLogo,
                                    href: 'https://www.kraken.com/'
                                },
                                {
                                    name: 'HitBTC',
                                    logo: HitBTCLogo,
                                    href: 'https://hitbtc.com/'
                                },
                                {
                                    name: 'Shapeshift',
                                    logo: ShapeshiftLogo,
                                    href: 'https://shapeshift.com/'
                                },
                                {
                                    name: 'Bithumb',
                                    logo: BithumbLogo,
                                    href: 'https://www.bithumb.com/'
                                },
                                {
                                    name: 'Bitfinex',
                                    logo: BitfinexLogo,
                                    href: 'https://www.bitfinex.com/'
                                }
                            ].map(({ name, logo, href }) => (
                                <li key={href}>
                                    <a href={href} target="_blank" rel="noreferrer">
                                        <img src={logo} alt="" />
                                        <span>{name}</span>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </section>
            </main>
            <Banners types={['bank', 'paycash', 'invest']} className={cx('Banners')} />
        </>
    );
}

export default PageDepositExchange;
