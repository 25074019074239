import React from 'react';
import { isTouchableDevice } from 'utils/featuresDetection/featuresDetection';

/**
 * Hook defining touch device.
 *
 * @returns {boolean}
 */
export default function useIsTouchableDevice(): boolean {
    return React.useMemo(() => isTouchableDevice(), []);
}
