import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeeplinkNode, useReplenishCardMutation } from 'apollo/main/generated';
import addThousandsSeparator from 'utils/addThousandsSeparator/addThousandsSeparator';
import removeNonNumeric from 'utils/removeNonNumeric/removeNonNumeric';
import { numToPrecisionStr } from 'utils/roundNumber/roundNumber';
import { useGlobalError, useIsTouchableDevice } from 'helpers';
import { calculateTopUp } from 'helpers/calculateVirtualCardFees/calculateVirtualCardFees';
import { Confirm, Expired, Method, Payment, Pending, TopUp } from '../VirtualCardManageViews/VirtualCardManageViews';
import { View, WalletName } from '../VirtualCardManageViews/VirtualCardManageViews.d';
import { VirtualCardTopUpProcessProps } from './VirtualCardTopUpProcess.d';
import styles from '../VirtualCardManageViews/VirtualCardManageViews.module.scss';

const formatValue = (value?: string) => addThousandsSeparator(numToPrecisionStr(Number(value), 2));

export default function VirtualCardTopUpProcess({
    cardId,
    onClose,
    setView,
    topUpFees,
    view
}: VirtualCardTopUpProcessProps) {
    const [t] = useTranslation();
    const onGlobalError = useGlobalError();
    const isTouchableDevice = useIsTouchableDevice();

    const [amountGive, setAmountGive] = React.useState('');
    const [isAmountError, setIsAmountError] = React.useState(false);
    const [amountReceive, setAmountReceive] = React.useState('');
    const [memo, setMemo] = React.useState('');
    const [currency, setCurrency] = React.useState('');
    const [receiver, setReceiver] = React.useState('');
    const [time, setTime] = React.useState('');
    const [deepLinks, setDeepLinks] = React.useState<(DeeplinkNode | null)[]>([]);

    const { fixedFee, rate, issuerRate, issuerFixedFee, minimalAmount, maximalAmount } = topUpFees ?? {};

    const [replenishCardMutation, { loading: replenishCardLoading }] = useReplenishCardMutation();

    const handleAmountGiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const regex = /^(\d+(\.\d{0,5})?)?$/;
        const numericValue = removeNonNumeric(e.target.value, false);

        if (!regex.test(numericValue)) return;

        setIsAmountError(false);
        setAmountGive(numericValue);

        if (numericValue) {
            const calculated = calculateTopUp(
                'receive',
                Number(numericValue),
                Number(fixedFee),
                Number(rate),
                Number(issuerRate),
                Number(issuerFixedFee)
            );

            if (calculated < Number(minimalAmount) || calculated > Number(maximalAmount)) {
                setIsAmountError(true);
            }

            if (calculated > 0) {
                setAmountReceive(numToPrecisionStr(calculated, 2, false, 'ceil'));
            } else {
                setAmountReceive('');
            }
        } else {
            setAmountReceive('');
        }
    };

    const handleAmountReceiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const regex = /^(\d+(\.\d{0,5})?)?$/;
        const numericValue = removeNonNumeric(e.target.value, false);

        if (!regex.test(numericValue)) return;

        setIsAmountError(false);
        setAmountReceive(numericValue);

        if (
            numericValue &&
            (Number(numericValue) < Number(minimalAmount) || Number(numericValue) > Number(maximalAmount))
        ) {
            setIsAmountError(true);
        }

        if (numericValue) {
            setAmountGive(
                numToPrecisionStr(
                    calculateTopUp(
                        'give',
                        Number(numericValue),
                        Number(fixedFee),
                        Number(rate),
                        Number(issuerRate),
                        Number(issuerFixedFee)
                    ),
                    2,
                    false,
                    'ceil'
                )
            );
        } else {
            setAmountGive('');
        }
    };

    const handleSubmit = () => {
        if (Number(amountReceive) < Number(minimalAmount) || Number(amountReceive) > Number(maximalAmount)) {
            setIsAmountError(true);
        } else {
            setView(View.Confirm);
        }
    };

    const handleConfirmSubmit = () => {
        replenishCardMutation({
            variables: {
                input: {
                    cardId: cardId ?? '',
                    amount: amountReceive,
                    paymentCurrency: 'usdcash'
                }
            }
        }).then(({ data }) => {
            const topUp = data?.replenishCard;

            if (topUp?.__typename === 'CardActionNode') {
                const { paymentParams } = topUp ?? {};

                const {
                    currency: dataCurrency,
                    receiver: dataReceiver,
                    memo: dataMemo,
                    deeplinks,
                    expirationDate
                } = paymentParams ?? {};

                setMemo(dataMemo ?? '');
                setCurrency(dataCurrency ?? '');
                setReceiver(dataReceiver ?? '');
                setDeepLinks(deeplinks ?? []);
                setTime(expirationDate ?? '');

                setView(isTouchableDevice ? View.Method : View.Payment);
            } else {
                const { __typename, errorMessage = '' } = topUp ?? {};

                onGlobalError(t([`pageVirtualCard.issue.error.${__typename}`, errorMessage]));
            }
        });
    };

    const handleAppClick = (wallet: WalletName) => {
        const deeplink = deepLinks.find((dp) => dp?.name === WalletName[wallet].toLowerCase());

        if (deeplink?.link) {
            window.location.href = deeplink?.link;
        }
    };

    if (view === View.Method)
        return (
            <Method
                memo={memo}
                setView={setView}
                title={t('pageVirtualCard.topUp.title')}
                description={t('pageVirtualCard.payment.app.description')}
                bodyContent={
                    <div>
                        <div className={styles.AppLabel}>{t('pageVirtualCard.payment.summary')}</div>
                        <div className={styles.AppValue}>{formatValue(amountGive)} USDCASH</div>
                    </div>
                }
                onClick={handleAppClick}
            />
        );

    if (view === View.Payment)
        return (
            <Payment
                amount={amountGive}
                currency={currency}
                memo={memo}
                receiver={receiver}
                setView={setView}
                time={time}
                title={t('pageVirtualCard.topUp.title')}
                onBackClick={isTouchableDevice ? () => setView(View.Method) : undefined}
            />
        );

    if (view === View.Pending)
        return (
            <Pending
                give={`${amountGive} USDCASH`}
                receive={`$${amountReceive}`}
                rate={`1 USDCASH = ${issuerRate} USD`}
                onClose={onClose}
            />
        );

    if (view === View.Expired) return <Expired onClose={onClose} />;

    if (view === View.Confirm)
        return (
            <Confirm
                title={t('pageVirtualCard.topUp.title')}
                description={t('pageVirtualCard.topUp.description')}
                rate={`1 USDCASH = ${formatValue(issuerRate)} USD`}
                give={`${formatValue(amountGive)} USDCASH`}
                receive={`$${formatValue(amountReceive)}`}
                onSubmit={handleConfirmSubmit}
                loading={replenishCardLoading}
            />
        );

    return (
        <TopUp
            amountMin={minimalAmount}
            amountMax={maximalAmount}
            amountGive={amountGive}
            amountReceive={amountReceive}
            isAmountError={isAmountError}
            issuerRate={topUpFees?.issuerRate}
            onAmountGiveChange={handleAmountGiveChange}
            onAmountReceiveChange={handleAmountReceiveChange}
            onSubmit={handleSubmit}
        />
    );
}
