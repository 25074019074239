import React from 'react';
import classNames from 'classnames/bind';
import { ArrowTopRightIcon } from 'ui/Icons/Icons';
import { Trans } from 'react-i18next';
import styles from './NewTabLink.module.scss';

const cx = classNames.bind(styles);

function NewTabLink({ href, name }: { href: string; name: string }) {
    return (
        <a href={href} target="_blank" className={cx('Component')} rel="noreferrer">
            <span className={cx('Text')}>
                <Trans i18nKey="global.linkInNewTab" values={{ name }}>
                    <span />
                </Trans>
            </span>

            <ArrowTopRightIcon className={styles.Icon} />
        </a>
    );
}

export default NewTabLink;
