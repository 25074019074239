import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';
import { useCopy } from 'helpers';
import { useTranslation } from 'react-i18next';
import { CopyIcon } from 'ui/Icons/Icons';
import styles from './Param.module.scss';

const cx = classNames.bind(styles);

function Param({
    label,
    value,
    className,
    copyText,
    onCopyText,
    withCopy
}: {
    className?: string;
    label?: ReactNode;
    value?: ReactNode;
    copyText?: number | string;
    onCopyText?: string;
    withCopy?: boolean;
}) {
    const [t] = useTranslation();
    const copy = useCopy();

    const onClickCopy = () => {
        copy((copyText ?? value)!.toString(), t(onCopyText ?? 'global.copied'), t('errors.globalError'));
    };
    return (
        <p className={cx('Param', className)}>
            <span className={cx('ParamMain')}>
                <span className={cx('ParamLabel')}>{label}</span>
                <b className={cx('ParamValue')}>{value}</b>
            </span>

            {withCopy && (
                <button type="button" onClick={onClickCopy} className={cx('ParamBtnCopy')}>
                    <CopyIcon className={styles.Icon} />
                </button>
            )}
        </p>
    );
}

export default Param;
