import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionNodeFragment, ActionSortEnum, ActionsQuery, TxnType } from 'apollo/main/generated';
import classNames from 'classnames/bind';
import Button from 'ui/Button_DEPRECATED/Button';
import DividedValues from 'components/DividedValues/DividedValues';
import { RefreshIcon, ChevronRightIcon } from 'ui/Icons/Icons';
import PageState from 'components/PageState/PageState';
import Pagination from 'ui/Pagination/Pagination';
import Table from 'ui/Table/Table';
import TransactionTag from 'components/TransactionTag/TransactionTag';
import { BREAKPOINT_IPAD, BREAKPOINT_LAPTOP, ROUTES_MAP } from 'constant';
import { createEosTransactionLink, useFormatDate, useWidthCondition } from 'helpers';
import addThousandsSeparator from 'utils/addThousandsSeparator/addThousandsSeparator';
import BloksIoLogo from 'assets/images/logo-bloksio.svg';
import styles from './TransactionsTable.module.scss';

const cx = classNames.bind(styles);

export const SORT_TYPES = {
    Timestamp: {
        ASC: ActionSortEnum.Timestamp,
        DESC: ActionSortEnum.TimestampDesc
    },
    EosAccount: {
        ASC: ActionSortEnum.EosAccount,
        DESC: ActionSortEnum.EosAccountDesc
    }
};

type SortType = (typeof SORT_TYPES)[keyof typeof SORT_TYPES];

function TransactionCard({ action, isListItem }: { action: ActionNodeFragment; isListItem?: boolean }) {
    const [t] = useTranslation();
    const [isLaptopOrLess] = useWidthCondition((w) => w < BREAKPOINT_LAPTOP);
    const formatDate = useFormatDate();

    const date = <time>{formatDate(action.timestamp, 'FF')}</time>;
    const isCashAction = action.txnType === TxnType.CashIssue || action.txnType === TxnType.CashRetire;

    const Sender = (
        <Button
            tag="link"
            to={ROUTES_MAP.account(action.account1)}
            className={cx('Link_blue')}
            color="link"
            target="_blank"
        >
            {action.account1}
        </Button>
    );
    const Receiver = action.account2 && (
        <Button
            tag="link"
            to={ROUTES_MAP.account(action.account2)}
            className={cx('Link_blue')}
            color="link"
            target="_blank"
        >
            {action.account2}
        </Button>
    );

    const Accounts = (
        <DividedValues value1={Sender} value2={Receiver} divider={<ChevronRightIcon className={styles.ArrowIcon} />} />
    );

    const Amounts = isCashAction ? (
        <div className={cx('CashAmounts')}>
            <DividedValues
                value1={`${addThousandsSeparator(action.cashTokenAmount)} ${action.cashToken?.code}`}
                value2={null}
            />
            <div className={cx('CashAmountsDetails')}>
                <span className={cx('CashAmountsDetailsLabel')}>
                    {t('transactionsTable.params.cashAmounts.title', {
                        context: action.txnType
                    })}
                </span>
                <DividedValues
                    value1={`${addThousandsSeparator(action.token1Amount)} ${action.token1.code}`}
                    value2={`${addThousandsSeparator(action.token2Amount)} ${action.token2?.code ?? ''}`}
                />
            </div>
        </div>
    ) : (
        <DividedValues
            value1={`${addThousandsSeparator(action.token1Amount)} ${action.token1.code}`}
            value2={action.token2 ? `${addThousandsSeparator(action.token2Amount)} ${action.token2.code}` : null}
            divider={action.txnType === TxnType.Swap ? <ChevronRightIcon className={styles.ArrowIcon} /> : null}
        />
    );

    const BloksIoLink = (
        <Button
            tag="a"
            color="link"
            target="_blank"
            href={createEosTransactionLink(action.txid)}
            rel="noopener noreferrer"
        >
            {t('global.bloksIoLink')}
        </Button>
    );

    if (isListItem)
        return (
            <li className={cx('ListItem')}>
                <div className={cx('ListItemContent')}>
                    <div className={cx('ListItemHeader')}>
                        <TransactionTag type={action.txnType!} />
                        {date}
                    </div>
                    {Amounts}
                    <p className={cx('ListItemParam')}>
                        <span className={cx('ListItemParamLabel')}>
                            {t(`transactionsTable.params.${Receiver ? 'sender' : 'account'}.title`)}
                        </span>
                        {Sender}
                    </p>
                    {Receiver && (
                        <p className={cx('ListItemParam')}>
                            <span className={cx('ListItemParamLabel')}>
                                {t(`transactionsTable.params.receiver.title`)}
                            </span>
                            {Receiver}
                        </p>
                    )}
                </div>
                <div className={cx('ListItemActions')}>{BloksIoLink}</div>
            </li>
        );

    return (
        <tr>
            <td>
                <TransactionTag type={action.txnType!} />
            </td>
            <td>{Amounts}</td>
            <td>{Accounts}</td>
            <td>{date}</td>
            <td align="right">
                {isLaptopOrLess ? (
                    <a
                        target="_blank"
                        href={createEosTransactionLink(action.txid)}
                        rel="noopener noreferrer"
                        className={cx('BloksIoLinkWithIcon')}
                    >
                        <img src={BloksIoLogo} alt="BloksIo" />
                    </a>
                ) : (
                    BloksIoLink
                )}
            </td>
        </tr>
    );
}

function TransactionsTable({
    sort,
    onChangeSort,
    page,
    pageCount,
    onChangePage,
    loading,
    actions,
    className,
    onClickRefetch,
    showErrorScreen,
    isFirstLoading
}: {
    actions?: NonNullable<NonNullable<ActionsQuery>['actions']>['actions'];
    sort?: ActionSortEnum | null;
    onChangeSort?(v: ActionSortEnum): void;
    page: number;
    pageCount: number;
    loading?: boolean;
    onChangePage(v: number): void;
    className?: string;
    onClickRefetch?: () => void;
    showErrorScreen?: boolean;
    isFirstLoading?: boolean;
}) {
    const [t] = useTranslation();
    const [isIpadOrLess] = useWidthCondition((w) => w < BREAKPOINT_IPAD);
    const getSortType = ({ ASC, DESC }: SortType) =>
        // eslint-disable-next-line no-nested-ternary
        onChangeSort ? (sort === ASC ? 'ASC' : sort === DESC ? 'DESC' : null) : undefined;
    const createOnClickSort = ({ ASC, DESC }: SortType) =>
        // eslint-disable-next-line no-nested-ternary
        onChangeSort ? () => onChangeSort(sort === ASC ? DESC : sort === DESC ? ASC : ASC) : undefined;

    if (showErrorScreen)
        return (
            <PageState.Error
                title={t('transactionsTable.error.title')}
                description={t('transactionsTable.error.description')}
            >
                <Button disabled={loading} onClick={onClickRefetch} color="tertairy-green" startIcon={<RefreshIcon />}>
                    {t('transactionsTable.error.btn')}
                </Button>
            </PageState.Error>
        );

    if (isFirstLoading) return <PageState.Loading />;

    return (
        <div className={cx('Component', loading && 'loading', className || '', !actions?.length && 'full-size')}>
            {loading && <PageState.Loading className={cx('LoaderWrapper')} />}
            {actions?.length ? (
                <>
                    {!isIpadOrLess && (
                        <Table
                            inactiveRows
                            colls={[
                                {
                                    label: t('transactionsTable.params.type.title')
                                },
                                {
                                    label: t('transactionsTable.params.volume.title')
                                },
                                {
                                    label: t('transactionsTable.params.account.title'),
                                    sort: getSortType(SORT_TYPES.EosAccount),
                                    onClick: createOnClickSort(SORT_TYPES.EosAccount)
                                },
                                {
                                    label: t('transactionsTable.params.date.title'),
                                    sort: getSortType(SORT_TYPES.Timestamp),
                                    onClick: createOnClickSort(SORT_TYPES.Timestamp)
                                },
                                { label: null }
                            ]}
                        >
                            {actions.map((action) => action && <TransactionCard action={action} key={action.id} />)}
                        </Table>
                    )}

                    {isIpadOrLess && (
                        <ul className={cx('List')}>
                            {actions.map(
                                (action) => action && <TransactionCard isListItem action={action} key={action.id} />
                            )}
                        </ul>
                    )}

                    <Pagination
                        className={cx('Pagination')}
                        page={page}
                        pageCount={pageCount}
                        onChangePage={onChangePage}
                    />
                </>
            ) : (
                <PageState.Error
                    title={t('transactionsTable.empty.title')}
                    description={t('transactionsTable.empty.description')}
                />
            )}
        </div>
    );
}

export default TransactionsTable;
