import React from 'react';
import { DialogHeaderProps } from 'ui/Dialog/Dialog.d';

export enum View {
    App,
    Qr
}

export interface AuthAppProps {
    HeaderProps?: React.PropsWithChildren<DialogHeaderProps>;
    onChangeView?: (nextView: View) => void;
}
