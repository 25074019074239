import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export const CenteredMetric = (label: string) =>
    // eslint-disable-next-line func-names
    function ({ centerX, centerY }: any) {
        return (
            <text
                x={centerX}
                y={centerY}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    fill: '#FFFFFF',
                    fontFamily: ' Manrope, sans-serif',
                    fontSize: 24,
                    lineHeight: 29,
                    fontWeight: 600
                }}
            >
                {label}
            </text>
        );
    };
