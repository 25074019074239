import classNames from 'classnames/bind';
import getTokenLogo from 'helpers/getTokenLogo/getTokenLogo';
import highlightSubstring from 'helpers/highlightSubstring';
import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './TokenCard.module.scss';

const cx = classNames.bind(styles);

type Props = {
    logo?: string | null;
    publicName?: ReactNode;
    smartContract?: string;
    code?: string;

    className?: string;
    logoClassName?: string;
    publicNameClassName?: string;
    codeClassName?: string;

    logoSize?: number;
    highlight?: string;

    hidePublicName?: boolean;
    iconOnly?: boolean;
    labelOnly?: boolean;
};

function TokenCard({
    logo,
    labelOnly,
    iconOnly,
    hidePublicName,
    publicName,
    code,
    className = '',
    logoClassName = '',
    highlight = '',
    publicNameClassName = '',
    codeClassName = '',
    smartContract,
    logoSize = 32
}: Props) {
    const {
        t,
        i18n: { language }
    } = useTranslation();

    const tokenImg = useMemo(
        () => getTokenLogo(logo, typeof code === 'string' ? code : null, smartContract),
        [logo, smartContract, code]
    );

    // eslint-disable-next-line consistent-return
    const reworkedPublicName = useMemo(() => {
        if (hidePublicName) return '';
        if (
            (smartContract === 'swap.pcash' && code?.toUpperCase()?.startsWith('LQ')) ||
            (smartContract === 'swap.pcash' && code === 'MLNK') ||
            (smartContract === 'token.pcash' && code?.toUpperCase().endsWith('CASH')) ||
            (smartContract === 'token.list' && code?.toUpperCase()?.startsWith('LI'))
        ) {
            return t([`global.token.${code?.toUpperCase()?.startsWith('LQ') ? 'LQ*' : code}`, code], { code });
        }

        if (publicName) return publicName || code;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicName, code, smartContract, hidePublicName, language]);

    return (
        <div className={cx('Component', className)}>
            {!labelOnly && (
                <span style={{ width: logoSize, height: logoSize }} className={cx('Logo', logoClassName)}>
                    <img src={tokenImg} alt="" />
                </span>
            )}
            {!iconOnly && (publicName || code) && (
                <p className={cx('Label', publicNameClassName)}>
                    {typeof code === 'string' && highlight
                        ? highlightSubstring(
                              code ?? '',
                              (() => {
                                  const index = code?.indexOf(highlight.toUpperCase()) ?? -1;
                                  return [index, index + highlight.length] as const;
                              })()
                          )
                        : code}
                    {!hidePublicName && <span className={cx('Name', codeClassName)}>{reworkedPublicName}</span>}
                </p>
            )}
        </div>
    );
}

export default TokenCard;
