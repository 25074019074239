import { useCheckEosAccountQuery } from 'apollo/main/generated';
import BookmarkBtn from 'components/BookmarkBtn/BookmarkBtn';
import Button from 'ui/Button_DEPRECATED/Button';
import { RefreshIcon } from 'ui/Icons/Icons';
import PageState from 'components/PageState/PageState';
import { ROUTES_MAP } from 'constant';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Route, RouteComponentProps } from 'react-router';
import AccountDetails from '../components/AccountDetails/AccountDetails';
import PoolDetails from '../components/AccountPoolDetails/AccountPoolDetails';

function PageAccount({
    match: {
        params: { account }
    }
}: RouteComponentProps<{ account: string }>) {
    const [t] = useTranslation();
    const eosAccountQuery = useCheckEosAccountQuery({
        variables: { name: account },
        notifyOnNetworkStatusChange: true
    });

    const hasError = !!eosAccountQuery.error;

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (hasError) {
            return () => {
                eosAccountQuery.refetch();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasError, account]);

    if (eosAccountQuery.error)
        return (
            <PageState.Error title={t('pageAccount.error.title')} description={t('pageAccount.error.description')}>
                <Button
                    color="tertairy-green"
                    disabled={eosAccountQuery.loading}
                    onClick={() => eosAccountQuery.refetch()}
                    startIcon={<RefreshIcon />}
                >
                    {t('pageAccount.error.btn')}
                </Button>
            </PageState.Error>
        );

    if (eosAccountQuery.loading) return <PageState.Loading />;

    const accountData = eosAccountQuery.data?.eosAccount;

    if (!accountData) return <PageState.Error title={t('pageAccount.notFound.title')} />;

    if (!accountData.hasStatistic)
        return (
            <PageState.Error
                title={t('pageAccount.empty.title', { account })}
                description={t('pageAccount.empty.description')}
            >
                <BookmarkBtn isFavorite={accountData.isFavorite} type="account" eosAccount={account} />
            </PageState.Error>
        );

    return (
        <>
            <Route exact component={AccountDetails} path={ROUTES_MAP.account()} />
            <Route component={PoolDetails} path={ROUTES_MAP.accountPool()} />
        </>
    );
}

export default PageAccount;
