import React, { useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { DoubleCheckIcon } from 'ui/Icons/Icons';
import { usePoolNamesQuery } from 'apollo/main/generated';
import { CardError, CardLoader } from 'components/Card/Card';
import PoolCard from 'components/PoolCard/PoolCard';
import { Link } from 'react-router-dom';
import { ROUTES_MAP } from 'constant';
import Button from 'ui/Button_DEPRECATED/Button';
import styles from './AboutProjectPoolsSection.module.scss';

const cx = classNames.bind(styles);

function PoolsSection() {
    const [t] = useTranslation();
    const { data, loading, error, ...poolNamesQuery } = usePoolNamesQuery();
    const [showAll, setShowAll] = useState(false);

    const pools = useMemo(
        () => data?.pools?.pools?.filter((pool) => pool?.mlnkIncomeDetails.hasMlnkIncome) ?? [],
        [data]
    );

    return (
        <section className={cx('Component')}>
            <div className={cx('Pools')}>
                <h2 className={cx('PoolsTitle')}>{t('pageInfo.pools.title')}</h2>
                <p className={cx('PoolsDescription')}>{t('pageInfo.pools.description')}</p>

                {/* eslint-disable-next-line no-nested-ternary */}
                {loading ? (
                    <CardLoader />
                ) : error ? (
                    <CardError resetError={poolNamesQuery.refetch} />
                ) : (
                    <>
                        <ul className={cx('PoolsList')}>
                            {pools.map((pool, i) => {
                                if (!pool) return null;
                                if (!showAll && i > 3) return null;

                                return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <li key={i}>
                                        <Link to={ROUTES_MAP.pool(pool.code)}>
                                            <PoolCard
                                                tokens={[
                                                    {
                                                        name: pool.token1.code,
                                                        smartContract: pool.token1.smartContract,
                                                        icon: pool.token1.logo ?? ''
                                                    },
                                                    {
                                                        name: pool.token2.code,
                                                        smartContract: pool.token2.smartContract,
                                                        icon: pool.token2.logo ?? ''
                                                    }
                                                ]}
                                            />
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>

                        {pools.length > 4 && (
                            <Button
                                className={cx('BtnToggle')}
                                color={showAll ? 'secondary' : 'primary'}
                                type="button"
                                onClick={() => setShowAll(!showAll)}
                            >
                                {t(`pageInfo.pools.${showAll ? 'btnCollapse' : 'btnShowAll'}`)}
                            </Button>
                        )}
                    </>
                )}

                <p className={cx('PoolsHint')}>
                    <DoubleCheckIcon className={styles.PoolsHintIcon} />
                    {t('pageInfo.pools.hint')}
                </p>
            </div>
        </section>
    );
}

export default PoolsSection;
