import {
    ActionSortEnum,
    ActionsQueryVariables,
    PoolsQueryVariables,
    PoolsSortEnum,
    TxnType,
    useActionsQuery,
    usePoolsQuery,
    useTokenQuery
} from 'apollo/main/generated';
import classNames from 'classnames/bind';
import BookmarkBtn from 'components/BookmarkBtn/BookmarkBtn';
import Button from 'ui/Button_DEPRECATED/Button';
import Card, { CardValue } from 'components/Card/Card';
import Changed from 'components/Changed/Changed';
import { RefreshIcon, ChevronLeftIcon } from 'ui/Icons/Icons';
import PageState from 'components/PageState/PageState';
import PoolsTable from 'components/PoolsTable/PoolsTable';
import SectionBloksIoInfo, { Param } from 'components/SectionBloksIoInfo/SectionBloksIoInfo';
import TokenCard from 'components/TokenCard/TokenCard';
import TransactionsTable from 'components/TransactionsTable/TransactionsTable';
import TransactionTypeFilter from 'components/TransactionTypeFilter/TransactionTypeFilter';
import { BREAKPOINT_LANDSCAPE, BREAKPOINT_LAPTOP, ROUTES_MAP, USDT_MIN_MOVE, USDT_PRECISION } from 'constant';
import { useInView } from 'react-intersection-observer';
import { formatBigNumbers, useWidthCondition } from 'helpers';
import addThousandsSeparator from 'utils/addThousandsSeparator/addThousandsSeparator';
import update from 'immutability-helper';
import Chart, { COLOR_SCHEME } from 'components/Chart/Chart';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import FormatNumber from 'components/FormatNumber/FormatNumber';
import SortDropdown from 'components/SortDropdown/SortDropdown';
import styles from './Token.module.scss';
// import InView from 'react-intersection-observer';

const cx = classNames.bind(styles);

function PageToken({
    match: {
        params: { token: tokenId }
    }
}: RouteComponentProps<{ token: string }>) {
    const {
        loading,
        error,
        data: tokenData,
        ...tokenQuery
    } = useTokenQuery({
        variables: { id: +tokenId },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only'
    });
    const [isLandscapeOrLess] = useWidthCondition((w) => w < BREAKPOINT_LANDSCAPE);
    const { t } = useTranslation();
    const isEmpty = !loading && !tokenData?.token;

    const hasError = !!error;

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (hasError) {
            return () => {
                tokenQuery.refetch();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasError, tokenId]);

    if (error)
        return (
            <PageState.Error title={t('pageToken.error.title')} description={t('pageToken.error.description')}>
                <Button
                    disabled={loading}
                    onClick={() => tokenQuery.refetch()}
                    color="tertairy-green"
                    startIcon={<RefreshIcon />}
                >
                    {t('pageToken.error.btn')}
                </Button>
            </PageState.Error>
        );

    if (loading) return <PageState.Loading />;

    if (isEmpty)
        return (
            <PageState.Error title={t('pageToken.empty.title')} description={t('pageToken.empty.description')}>
                <Button color="tertairy-green" tag="link" to={ROUTES_MAP.tokens} startIcon={<ChevronLeftIcon />}>
                    {t('pageToken.empty.btn')}
                </Button>
            </PageState.Error>
        );

    const token = tokenData?.token!;
    const tokenPriceChart = tokenData?.tokenPriceChart!;
    const tokenVolumeChart = tokenData?.tokenVolumeChart!;
    const tokenLiquidityChart = tokenData?.tokenLiquidityChart!;
    const tokenActionsNumChart = tokenData?.tokenActionsNumChart!;

    return (
        <main className={cx('Component')}>
            <div className={cx('HeaderRow')}>
                <Link className={cx('BtnBack')} to={ROUTES_MAP.tokens}>
                    <ChevronLeftIcon />
                </Link>

                <div className={cx('HeaderRowMain')}>
                    <TokenCard
                        publicNameClassName={cx('TokenCardLabel')}
                        codeClassName={cx('TokenCardName')}
                        className={cx('TokenCard')}
                        logoClassName={cx('TokenCardIcon')}
                        logoSize={72}
                        {...token}
                    />

                    <div className={cx('PriceLabel')}>
                        ${addThousandsSeparator(tokenPriceChart.value24h)}
                        <Changed growth={tokenPriceChart.percentageChange24h} period="noText" />
                    </div>
                </div>
                <BookmarkBtn
                    isSmall={isLandscapeOrLess}
                    isFavorite={Boolean(token?.isFavorite)}
                    className={cx('BtnBookmark')}
                    type="token"
                    tokenId={token.id!}
                />
            </div>

            <div className={cx('Charts')}>
                <Card
                    title={t('pageToken.liquidity.title')}
                    helpText={t('pageToken.liquidity.helpText', { code: token.code })}
                >
                    {!!tokenLiquidityChart.points.length && (
                        <CardValue
                            changed={{
                                growth: tokenLiquidityChart.percentageChange24h,
                                period: '24h'
                            }}
                        >
                            <FormatNumber prefix="$" value={tokenLiquidityChart.value24h} />
                        </CardValue>
                    )}
                    <Chart
                        data={{
                            type: 'area',
                            data: tokenLiquidityChart.points.map((p) => ({
                                value: Number(p?.value),
                                time: p!.time
                            })),
                            options: COLOR_SCHEME.blue.area
                        }}
                    />
                </Card>
                <Card
                    headerClassName={cx('CardHeader')}
                    titleClassName={cx('CardTitle')}
                    title={
                        <div className={cx('CardValues')}>
                            <CardValue
                                changed={{
                                    growth: tokenVolumeChart.percentageChange24h ?? 0,
                                    period: '24h'
                                }}
                            >
                                <span className={cx('CardValueLabel')}>{t('pageToken.volume.title')}</span>
                                <FormatNumber value={tokenVolumeChart.value24h ?? 0} prefix="$" />
                            </CardValue>

                            <CardValue>
                                <span className={cx('CardValueLabel')}>
                                    {t('pageToken.volume.title', { context: 'week' })}
                                </span>
                                <FormatNumber value={tokenVolumeChart.lastWeekValue ?? 0} prefix="$" />
                            </CardValue>
                        </div>
                    }
                    helpText={t('pageToken.volume.helpText', { code: token.code })}
                >
                    <Chart
                        data={{
                            type: 'histogram',
                            data: tokenVolumeChart.points.map((p) => ({
                                value: Number(p?.value),
                                time: p!.time
                            })),
                            options: COLOR_SCHEME.green.histogram
                        }}
                    />
                </Card>
                <Card title={t('pageToken.transactions.title')} subTitle={t('pageToken.transactions.subTitle')}>
                    {!!tokenActionsNumChart.points.length && (
                        <CardValue
                            changed={{
                                growth: tokenActionsNumChart.percentageChange24h,
                                period: '24h'
                            }}
                        >
                            {addThousandsSeparator(tokenActionsNumChart.value24h)}
                        </CardValue>
                    )}

                    <Chart
                        options={{
                            localization: {
                                priceFormatter: (p: number) => formatBigNumbers(p.toString(), 0)
                            }
                        }}
                        data={{
                            type: 'histogram',
                            data: tokenActionsNumChart.points.map((p) => ({
                                value: Number(p?.value),
                                time: p!.time
                            })),
                            options: {
                                ...COLOR_SCHEME.yellow.histogram,
                                priceFormat: {
                                    type: 'price',
                                    precision: 0,
                                    minMove: 1
                                }
                            }
                        }}
                    />
                </Card>
                <Card
                    title={t('pageToken.price.title')}
                    helpText={t('pageToken.price.helpText', { code: token.code })}
                    subTitle={`1 USDT = ${token.reverseRate} ${token.code}`}
                >
                    {!!tokenPriceChart.points.length && (
                        <CardValue
                            changed={{
                                growth: tokenPriceChart.percentageChange24h,
                                period: '24h'
                            }}
                        >
                            ${addThousandsSeparator(tokenPriceChart.value24h)}
                        </CardValue>
                    )}
                    <Chart
                        data={{
                            type: 'area',
                            data: tokenPriceChart.points.map((p) => ({
                                value: Number(p?.value),
                                time: p!.time
                            })),
                            options: {
                                ...COLOR_SCHEME.purple.area,
                                priceFormat: {
                                    type: 'price',
                                    precision: USDT_PRECISION,
                                    minMove: USDT_MIN_MOVE
                                }
                            }
                        }}
                    />
                </Card>
            </div>

            {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
            <SectionPairs tokenId={token.id} />
            {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
            <SectionTransactions tokenId={token.id} />

            <SectionBloksIoInfo
                href={`https://bloks.io/tokens/${[token.code, token.chain || 'eos', token.smartContract]
                    .filter(Boolean)
                    .join('-')}`}
                title={t('pageToken.info.title')}
            >
                <Param label={t('pageToken.info.name')} value={token.code} />
                <Param label={t('pageToken.info.contract')} value={token.smartContract} />
            </SectionBloksIoInfo>
        </main>
    );
}

function SectionPairs({ tokenId }: { tokenId: number }) {
    const [t] = useTranslation();
    const STEP = 10;
    const [isLaptopOrLess] = useWidthCondition((w) => w < BREAKPOINT_LAPTOP);

    const [variables, setVariables] = useState<PoolsQueryVariables>({
        skip: 0,
        limit: STEP,
        sort: PoolsSortEnum.LiquidityDesc,
        filters: {
            tokenId
        }
    });

    const { loading, data, previousData, error, ...poolsQuery } = usePoolsQuery({
        variables,
        notifyOnNetworkStatusChange: true
    });

    const result = data?.pools ?? previousData?.pools;
    const { pageInfo, pools } = result ?? {};
    const totalCount = pageInfo?.totalFiltered ?? 0;
    const showErrorScreen = !!error;
    const isFirstLoading = !result && loading && !showErrorScreen;

    const onChangeSort = (sort: PoolsSortEnum) => {
        setVariables(
            update(variables, {
                skip: () => 0,
                sort: () => sort
            })
        );
    };

    return (
        <section className={cx('Pools')}>
            <div className={cx('PoolsHeader')}>
                <h2 className={cx('PoolsTitle')}>{t('pageToken.pairs.title')}</h2>
                {isLaptopOrLess && (
                    <SortDropdown
                        options={[
                            {
                                label: t('poolsTable.param.token.title_ASC'),
                                value: PoolsSortEnum.Tokens
                            },
                            {
                                label: t('poolsTable.param.token.title_DESC'),
                                value: PoolsSortEnum.TokensDesc
                            },
                            {
                                label: t('poolsTable.param.liquidity.title_ASC'),
                                value: PoolsSortEnum.Liquidity
                            },
                            {
                                label: t('poolsTable.param.liquidity.title_DESC'),
                                value: PoolsSortEnum.LiquidityDesc
                            },
                            {
                                label: t('poolsTable.param.volume.title_ASC'),
                                value: PoolsSortEnum.Volume
                            },
                            {
                                label: t('poolsTable.param.volume.title_DESC'),
                                value: PoolsSortEnum.VolumeDesc
                            },
                            {
                                label: t('poolsTable.param.income.title_ASC'),
                                value: PoolsSortEnum.RelativeProfit_24h
                            },
                            {
                                label: t('poolsTable.param.income.title_DESC'),
                                value: PoolsSortEnum.RelativeProfit_24hDesc
                            },
                            {
                                label: t('poolsTable.param.apy.title_ASC'),
                                value: PoolsSortEnum.RelativeProfitAnnual
                            },
                            {
                                label: t('poolsTable.param.apy.title_DESC'),
                                value: PoolsSortEnum.RelativeProfitAnnualDesc
                            }
                        ]}
                        label={t('global.sort')}
                        className={cx('SortDropdown')}
                        active={variables.sort!}
                        onChange={(sort) => {
                            setVariables({
                                ...variables,
                                skip: 0,
                                sort
                            });
                        }}
                    />
                )}
            </div>
            <PoolsTable
                loading={loading}
                className={cx('PoolsList')}
                pools={pools}
                isShortEmptyText
                isFirstLoading={isFirstLoading}
                onClickRefetch={() => poolsQuery.refetch()}
                showErrorScreen={showErrorScreen}
                sort={variables.sort}
                page={variables.skip! / STEP}
                pageCount={Math.ceil(totalCount / STEP)}
                onChangeSort={onChangeSort}
                onChangePage={(page) =>
                    setVariables({
                        ...variables,
                        skip: page * STEP
                    })
                }
            />
        </section>
    );
}

function SectionTransactions({ tokenId }: { tokenId: number }) {
    const STEP = 10;
    const [t] = useTranslation();
    const { ref, inView } = useInView({ triggerOnce: true, threshold: 0 });

    const initialTxnTypeFilter = [TxnType.AddLiquidity, TxnType.RemoveLiquidity, TxnType.Swap];

    const [variables, setVariables] = useState<ActionsQueryVariables>({
        skip: 0,
        limit: STEP,
        sort: [ActionSortEnum.TimestampDesc],

        filters: {
            tokenId,
            txnType: initialTxnTypeFilter
        }
    });

    const isInitialTxnTypeFilterSelected = initialTxnTypeFilter.join('') === variables.filters?.txnType?.join('');
    const txnType = variables.filters?.txnType?.[0] ?? null;

    const { loading, data, previousData, error, ...actionsQuery } = useActionsQuery({
        notifyOnNetworkStatusChange: true,
        skip: !inView,
        variables
    });
    const result = data?.actions ?? previousData?.actions;
    const { pageInfo, actions } = result ?? {};
    const totalCount = pageInfo?.totalFiltered ?? 0;
    const showErrorScreen = !!error;
    const contentReady = (!!result || !loading) && !showErrorScreen;
    const isFirstLoading = !result && loading && !showErrorScreen;

    const onChangeTxnType = (newType: TxnType | null) => {
        setVariables(
            update(variables, {
                skip: () => 0,
                filters: {
                    txnType: () => (newType ? [newType] : initialTxnTypeFilter)
                }
            })
        );
    };

    return (
        <section ref={ref} className={cx('Transactions')}>
            <header className={cx('TransactionsHeader')}>
                <h2 className={cx('TransactionsTitle')}>{t('transactionsTable.title')}</h2>

                {contentReady && (
                    <TransactionTypeFilter
                        className={cx('TransactionsFilters')}
                        onChange={onChangeTxnType}
                        includes={initialTxnTypeFilter}
                        active={isInitialTxnTypeFilterSelected || !txnType ? null : txnType}
                    />
                )}
            </header>

            <TransactionsTable
                className={cx('TransactionsTable')}
                loading={loading}
                actions={actions}
                isFirstLoading={isFirstLoading}
                onClickRefetch={() => actionsQuery.refetch()}
                showErrorScreen={showErrorScreen}
                page={variables.skip! / STEP}
                pageCount={Math.ceil(totalCount / STEP)}
                onChangePage={(page) =>
                    setVariables({
                        ...variables,
                        skip: page * STEP
                    })
                }
            />
        </section>
    );
}

export default PageToken;
