import React from 'react';
// import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { KALYNA_LANDING, MALINA_APP_LANDING, PAYCASH_LANDING } from 'constant';
import Applications1xImage from 'assets/images/about-cash-tokens/applications@1x.png';
import Applications2xImage from 'assets/images/about-cash-tokens/applications@2x.png';
import KalynaLogo from 'assets/images/logo-kalina-app.svg';
import PaycashLogo from 'assets/images/logo-paycash-white.svg';
import MalinaLogo from 'assets/images/logo-malina-app.svg';
import styles from './AboutCashTokensApplications.module.scss';

export default function AboutCashTokensApplications() {
    const [t] = useTranslation();

    return (
        <section id="applications" className={styles.Component}>
            <div className={styles.Container}>
                <div className={styles.Wrapper}>
                    <div className={styles.ImageWrapper}>
                        <img
                            className={styles.Image}
                            srcSet={`${Applications2xImage} 2x`}
                            src={Applications1xImage}
                            alt="Applications"
                        />
                    </div>
                    <div className={styles.Details}>
                        <h2 className={styles.Title}>{t('pageAboutCashTokens.applications.title')}</h2>

                        <ul className={styles.AppList}>
                            <li>
                                <a href={KALYNA_LANDING}>
                                    <img src={KalynaLogo} alt="Kalina" />
                                </a>
                            </li>
                            <li>
                                <a href={PAYCASH_LANDING}>
                                    <img src={PaycashLogo} alt="PaycashApp" />
                                </a>
                            </li>
                            <li>
                                <a href={MALINA_APP_LANDING}>
                                    <img src={MalinaLogo} alt="Malina" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}
