export function calculateTopUp(
    direction: 'give' | 'receive',
    amount: number,
    fixedFee: number,
    rate: number,
    issuerRate: number,
    issuerFixedFee: number
) {
    if (direction === 'give') {
        return ((amount + issuerFixedFee) / issuerRate + fixedFee) / rate;
    }

    return (amount * rate - fixedFee) * issuerRate - issuerFixedFee;
}

export function calculateIssue(
    direction: 'give' | 'receive',
    amount: number,
    fixedFee: number,
    rate: number,
    issuerRate: number,
    issuerFixedFee: number
) {
    if (direction === 'give') {
        return ((amount + issuerFixedFee) / issuerRate + fixedFee) / rate;
    }

    return (amount * rate - fixedFee) * issuerRate - issuerFixedFee;
}
