import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LOCAL_STORAGE_LNG_KEY } from 'constant/index';
import typedKeys from 'helpers/typedKeys';
import ru from './ru';
import en from './en';

const resources = {
    ru: {
        translation: ru
    },
    en: {
        translation: en
    }
};

/** Language ids */
export const LANGUAGES = typedKeys(resources);

/** Union of CLIENT languages */
export type Languages = (typeof LANGUAGES)[number];

export const DEFAULT_CLIENT_LNG: Languages = 'ru';

/** Try to get the most appropriative language  */
export const getDefaultLng = () => {
    let language = [window.localStorage.getItem(LOCAL_STORAGE_LNG_KEY), navigator.language, navigator.userLanguage]
        .filter(Boolean)[0]
        ?.slice(0, 2)
        .toLowerCase();

    if (!LANGUAGES.includes(language as any)) {
        language = DEFAULT_CLIENT_LNG;
    }

    return language;
};

const i18nInstanse = () =>
    i18n.use(initReactI18next).init({
        resources,
        lng: getDefaultLng(),
        interpolation: {
            escapeValue: false
        }
    });

export default i18nInstanse;
