import React, { memo } from 'react';
import QRCode, { BaseQRCodeProps } from 'qrcode.react';
import classNames from 'classnames/bind';
import styles from './QR.module.scss';

const cx = classNames.bind(styles);

export type Props = {
    data?: string | number | null;
    size?: number;
    className?: string;
    color?: string;
} & Partial<BaseQRCodeProps>;

const QR = memo(({ data, size = 200, className, color = '#FFFFFF', ...props }: Props) => (
    <div className={cx('Component', className)}>
        <QRCode
            value={`${data}`}
            size={size}
            bgColor="transparent"
            fgColor={color}
            level="L"
            includeMargin={false}
            renderAs="svg"
            {...props}
        />
    </div>
));

export default QR;
