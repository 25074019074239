import React from 'react';
import cn from 'classnames';
import { ReactComponent as About } from 'assets/icons/about.svg';
import { ReactComponent as AngleDown } from 'assets/icons/angle-down.svg';
import { ReactComponent as ArrowTopRight } from 'assets/icons/arrow-top-right.svg';
import { ReactComponent as ArrowLeftRight } from 'assets/icons/arrow-left-right.svg';
import { ReactComponent as Bag } from 'assets/icons/bag.svg';
import { ReactComponent as Bar } from 'assets/icons/bar.svg';
import { ReactComponent as Bars } from 'assets/icons/bars.svg';
import { ReactComponent as Bookmark } from 'assets/icons/bookmark.svg';
import { ReactComponent as BookmarkFilled } from 'assets/icons/bookmark-filled.svg';
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';
import { ReactComponent as CameraFilled } from 'assets/icons/camera-filled.svg';
import { ReactComponent as Card } from 'assets/icons/card.svg';
import { ReactComponent as Cards } from 'assets/icons/cards.svg';
import { ReactComponent as Chart } from 'assets/icons/chart.svg';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as ChevronDown } from 'assets/icons/arrow-bottom.svg';
import { ReactComponent as ChevronLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as ChevronUp } from 'assets/icons/arrow-top.svg';
import { ReactComponent as ChineseCoin } from 'assets/icons/chinese-coin.svg';
import { ReactComponent as Clock } from 'assets/icons/clock.svg';
import { ReactComponent as Copy } from 'assets/icons/copy.svg';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { ReactComponent as DoubleCheck } from 'assets/icons/double-check.svg';
import { ReactComponent as Exit } from 'assets/icons/exit.svg';
import { ReactComponent as Facebook } from 'assets/icons/logo-facebook.svg';
import { ReactComponent as FileFilled } from 'assets/icons/file-filled.svg';
import { ReactComponent as Gear } from 'assets/icons/settings.svg';
import { ReactComponent as InfoCircled } from 'assets/icons/info-circled.svg';
import { ReactComponent as Instagram } from 'assets/icons/logo-instagram.svg';
import { ReactComponent as LineChart } from 'assets/icons/line-chart.svg';
import { ReactComponent as MailFilled } from 'assets/icons/mail-filled.svg';
import { ReactComponent as Minus } from 'assets/icons/minus.svg';
import { ReactComponent as MoneyBagFilled } from 'assets/icons/money-bag-filled.svg';
import { ReactComponent as MoonFilled } from 'assets/icons/moon-filled.svg';
import { ReactComponent as MoreHorizontal } from 'assets/icons/more-horizontal.svg';
import { ReactComponent as MoreVertical } from 'assets/icons/more-vertical.svg';
import { ReactComponent as Pairs } from 'assets/icons/pairs.svg';
import { ReactComponent as PieChart } from 'assets/icons/pie-chart.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Qr } from 'assets/icons/qr.svg';
import { ReactComponent as Question } from 'assets/icons/question.svg';
import { ReactComponent as Refresh } from 'assets/icons/refresh.svg';
import { ReactComponent as Reload } from 'assets/icons/reload.svg';
import { ReactComponent as SadFaceFilled } from 'assets/icons/sad-filled.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as Sort } from 'assets/icons/sort.svg';
import { ReactComponent as SunFilled } from 'assets/icons/sun-filled.svg';
import { ReactComponent as Telegram } from 'assets/icons/logo-telegram.svg';
import { ReactComponent as TelegramChat } from 'assets/icons/logo-telegram-chat.svg';
import { ReactComponent as Token } from 'assets/icons/tokens.svg';
import { ReactComponent as Transactions } from 'assets/icons/transactions.svg';
import { ReactComponent as TwitterFilled } from 'assets/icons/logo-twitter-filled.svg';
import { ReactComponent as User } from 'assets/icons/user.svg';
import { ReactComponent as WarningFilled } from 'assets/icons/warning-filled.svg';
import { ReactComponent as WarningShield } from 'assets/icons/warning-shield.svg';
import { ReactComponent as Youtube } from 'assets/icons/logo-youtube.svg';
import styles from './Icons.module.scss';

type SvgIconProps = React.SVGProps<SVGSVGElement> & { className?: string };

const defaultProps = {
    'aria-hidden': true,
    focusable: false
};

export function AboutIcon({ className, ...props }: SvgIconProps) {
    return <About {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function AngleDownIcon({ className, ...props }: SvgIconProps) {
    return <AngleDown {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ArrowTopRightIcon({ className, ...props }: SvgIconProps) {
    return <ArrowTopRight {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ArrowLeftRightIcon({ className, ...props }: SvgIconProps) {
    return <ArrowLeftRight {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function BagIcon({ className, ...props }: SvgIconProps) {
    return <Bag {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function BarIcon({ className, ...props }: SvgIconProps) {
    return <Bar {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function BarsIcon({ className, ...props }: SvgIconProps) {
    return <Bars {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function BookmarkIcon({ className, ...props }: SvgIconProps) {
    return <Bookmark {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function BookmarkFilledIcon({ className, ...props }: SvgIconProps) {
    return <BookmarkFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function CardIcon({ className, ...props }: SvgIconProps) {
    return <Card {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function CardsIcon({ className, ...props }: SvgIconProps) {
    return <Cards {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function CalendarIcon({ className, ...props }: SvgIconProps) {
    return <Calendar {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function CameraFilledIcon({ className, ...props }: SvgIconProps) {
    return <CameraFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ChartIcon({ className, ...props }: SvgIconProps) {
    return <Chart {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function CheckIcon({ className, ...props }: SvgIconProps) {
    return <Check {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ChevronDownIcon({ className, ...props }: SvgIconProps) {
    return <ChevronDown {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ChevronLeftIcon({ className, ...props }: SvgIconProps) {
    return <ChevronLeft {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ChevronUpIcon({ className, ...props }: SvgIconProps) {
    return <ChevronUp {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ChineseCoinIcon({ className, ...props }: SvgIconProps) {
    return <ChineseCoin {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ChevronRightIcon({ className, ...props }: SvgIconProps) {
    return <ChevronRight {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ClockIcon({ className, ...props }: SvgIconProps) {
    return <Clock {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function CopyIcon({ className, ...props }: SvgIconProps) {
    return <Copy {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function CrossIcon({ className, ...props }: SvgIconProps) {
    return <Cross {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ExitIcon({ className, ...props }: SvgIconProps) {
    return <Exit {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function DoubleCheckIcon({ className, ...props }: SvgIconProps) {
    return <DoubleCheck {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function FacebookIcon({ className, ...props }: SvgIconProps) {
    return <Facebook {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function FileFilledIcon({ className, ...props }: SvgIconProps) {
    return <FileFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function GearIcon({ className, ...props }: SvgIconProps) {
    return <Gear {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function InfoCircledIcon({ className, ...props }: SvgIconProps) {
    return <InfoCircled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function InstagramIcon({ className, ...props }: SvgIconProps) {
    return <Instagram {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function LineChartIcon({ className, ...props }: SvgIconProps) {
    return <LineChart {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function MailFilledIcon({ className, ...props }: SvgIconProps) {
    return <MailFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function MinusIcon({ className, ...props }: SvgIconProps) {
    return <Minus {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function MoneyBagFilledIcon({ className, ...props }: SvgIconProps) {
    return <MoneyBagFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function MoonFilledIcon({ className, ...props }: SvgIconProps) {
    return <MoonFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function MoreHorizontalIcon({ className, ...props }: SvgIconProps) {
    return <MoreHorizontal {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function MoreVerticalIcon({ className, ...props }: SvgIconProps) {
    return <MoreVertical {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function QrIcon({ className, ...props }: SvgIconProps) {
    return <Qr {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function PairsIcon({ className, ...props }: SvgIconProps) {
    return <Pairs {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function PieChartIcon({ className, ...props }: SvgIconProps) {
    return <PieChart {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function PlusIcon({ className, ...props }: SvgIconProps) {
    return <Plus {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function QuestionIcon({ className, ...props }: SvgIconProps) {
    return <Question {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function RefreshIcon({ className, ...props }: SvgIconProps) {
    return <Refresh {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ReloadIcon({ className, ...props }: SvgIconProps) {
    return <Reload {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function SadFaceFilledIcon({ className, ...props }: SvgIconProps) {
    return <SadFaceFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function SearchIcon({ className, ...props }: SvgIconProps) {
    return <Search {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function SortIcon({ className, ...props }: SvgIconProps) {
    return <Sort {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function SunFilledIcon({ className, ...props }: SvgIconProps) {
    return <SunFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function TelegramIcon({ className, ...props }: SvgIconProps) {
    return <Telegram {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function TelegramChatIcon({ className, ...props }: SvgIconProps) {
    return <TelegramChat {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function TokenIcon({ className, ...props }: SvgIconProps) {
    return <Token {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function TransactionsIcon({ className, ...props }: SvgIconProps) {
    return <Transactions {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function TwitterFilledIcon({ className, ...props }: SvgIconProps) {
    return <TwitterFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function WarningFilledIcon({ className, ...props }: SvgIconProps) {
    return <WarningFilled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function WarningShieldIcon({ className, ...props }: SvgIconProps) {
    return <WarningShield {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function UserIcon({ className, ...props }: SvgIconProps) {
    return <User {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function YoutubeIcon({ className, ...props }: SvgIconProps) {
    return <Youtube {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}
