import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import RublesIcon from 'assets/images/tokens/rubles.svg';
import USDCASHIcon from 'assets/images/tokens/usdcash.svg';
import styles from './AboutProjectFee.module.scss';

const cx = classNames.bind(styles);

function Intro() {
    const [t] = useTranslation();

    return (
        <section className={cx('Intro')}>
            <div className={cx('Container')}>
                <h1 className={cx('Title')}>{t('pageInfo.feeIncome.title')}</h1>
                <p className={cx('Description')}>{t('pageInfo.feeIncome.description')}</p>

                <div className={cx('Road')}>
                    <div className={cx('Token')}>
                        <span className={cx('TokenIcon')}>
                            <img src={RublesIcon} alt="Rubles" />
                        </span>
                        <b>77 015 RUBLES</b>
                    </div>

                    <div className={cx('RoadFee')}>
                        <span>
                            {t('pageInfo.feeIncome.fee')}
                            <b>192 RUBLES</b>
                        </span>
                    </div>

                    <div className={cx('Token')}>
                        <span className={cx('TokenIcon')}>
                            <img src={USDCASHIcon} alt="USDCASH" />
                        </span>
                        <b>1 000 USDCASH</b>
                    </div>
                </div>

                <div className={cx('RoadDetailGroup')}>
                    <div className={cx('RoadDetail', 'investor')}>
                        {t('pageInfo.feeIncome.fee')}
                        <b>154 RUBLES</b>
                    </div>
                    <div className={cx('RoadDetail', 'platform')}>
                        {t('pageInfo.feeIncome.fee')}
                        <b>38 RUBLES</b>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Intro;
