import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAccountDepositsQuery } from 'apollo/main/generated';
import { ReactComponent as LogoBloksIo } from 'assets/images/logo-bloksio.svg';
import { useFormatDate } from 'helpers';
import getTokenLogo from 'helpers/getTokenLogo/getTokenLogo';
import Button from 'ui/Button_DEPRECATED/Button';
import { CardsIcon, ChevronLeftIcon } from 'ui/Icons/Icons';
import MLNKLogo from 'assets/images/tokens/mlnk.svg';
import Sidebar, { SidebarContent } from '../../Sidebar/Sidebar';
import FormatNumber from '../../FormatNumber/FormatNumber';
import Background from '../../Background/Background';
import Loader from '../../Loader/Loader';
import styles from './CashDeposits.module.scss';

function TabContent({ isEmpty, children }: React.PropsWithChildren<{ isEmpty: boolean }>) {
    const [t] = useTranslation();

    if (isEmpty) {
        return (
            <div className={styles.TabContent}>
                <div className={styles.EmptyScreen}>
                    <CardsIcon className={styles.CardsIcon} />
                    <h6>{t('pageAccount.cashDeposits.empty.title')}</h6>
                    <p>{t('pageAccount.cashDeposits.empty.text')}</p>
                </div>
            </div>
        );
    }

    return <ul className={styles.CardList}>{children}</ul>;
}

const getBcUrl = (id?: number, contract?: string, token?: string) => {
    const currentScope = token?.toLowerCase() === 'usdcash' ? contract : token;

    return `https://bloks.io/account/${contract}?loadContract=true&tab=Tables&table=deposits&account=${contract}&scope=${currentScope}&limit=1&lower_bound=${id}&upper_bound=${id}`;
};

export default function CashDeposits({ isOpen, close, account }: { isOpen: boolean; close(): void; account: string }) {
    const [t] = useTranslation();
    const formatDate = useFormatDate();

    const { data, loading } = useAccountDepositsQuery({
        variables: { name: account },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only'
    });

    return (
        <Sidebar wrapperClassName={styles.Wrapper} className={styles.Sidebar} isOpen={isOpen} close={close}>
            <Background className={cn(styles.Background, isOpen && styles.active)} />
            <SidebarContent className={styles.Component}>
                <h2 className={styles.Title}>
                    <button className={styles.BtnClose} type="button" onClick={close}>
                        <ChevronLeftIcon className={styles.Icon} />
                    </button>
                    {t('pageAccount.cashDeposits.title')}
                </h2>

                {loading && (
                    <div className={styles.Loader}>
                        <Loader />
                    </div>
                )}

                {!loading && (
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    <TabContent isEmpty={!data?.eosAccount?.deposits.length}>
                        {data?.eosAccount?.deposits.map((deposit) => {
                            if (!deposit) return null;

                            return (
                                <li key={deposit.smartTableRowId} className={styles.Card}>
                                    <p className={styles.CardIssued}>
                                        {t('pageAccount.cashDeposits.card.issued')}
                                        <FormatNumber
                                            className={styles.CardIssuedValue}
                                            value={deposit.issuedCash}
                                            suffix={` ${deposit.tokenOut.code}`}
                                            precision={5}
                                            compress
                                            floatZerosOffset={0}
                                        />
                                    </p>

                                    <ul className={styles.CardBacked}>
                                        <li className={styles.CardBackedItem}>
                                            <img className={styles.CardBackedItemIcon} src={MLNKLogo} alt="MLNK" />
                                            <div className={styles.CardBackedItemMain}>
                                                <span className={styles.CardBackedItemLabel}>
                                                    {t('pageAccount.cashDeposits.card.backed', {
                                                        token: 'MLNK'
                                                    })}
                                                </span>
                                                <FormatNumber
                                                    className={styles.CardBackedItemValue}
                                                    value={deposit.depositedMlnk}
                                                    precision={8}
                                                    compress
                                                    floatZerosOffset={0}
                                                />
                                            </div>
                                        </li>
                                        <li className={styles.CardBackedItem}>
                                            <img
                                                className={styles.CardBackedItemIcon}
                                                src={getTokenLogo(
                                                    deposit.tokenIn.logo,
                                                    deposit.tokenIn.code,
                                                    deposit.tokenIn.smartContract
                                                )}
                                                alt="Token"
                                            />
                                            <div className={styles.CardBackedItemMain}>
                                                <span className={styles.CardBackedItemLabel}>
                                                    {t('pageAccount.cashDeposits.card.backed', {
                                                        token: deposit.tokenIn.code
                                                    })}
                                                </span>
                                                <FormatNumber
                                                    className={styles.CardBackedItemValue}
                                                    value={deposit.depositedToken}
                                                    precision={4}
                                                    compress
                                                    floatZerosOffset={0}
                                                />
                                            </div>
                                        </li>
                                    </ul>

                                    <div className={styles.CardRow}>
                                        <Button
                                            className={styles.CardLink}
                                            color="link"
                                            tag="a"
                                            target="_blank"
                                            href={getBcUrl(
                                                deposit.smartTableRowId,
                                                deposit.tokenOut.smartContract,
                                                deposit.tokenOut.code
                                            )}
                                        >
                                            <LogoBloksIo />
                                            {t('pageAccount.cashDeposits.card.linkBloksIo')}
                                        </Button>

                                        <time className={styles.CardTime}>
                                            {formatDate(deposit.creationTimestamp, 'FF')}
                                        </time>
                                    </div>
                                </li>
                            );
                        })}
                    </TabContent>
                )}
            </SidebarContent>
        </Sidebar>
    );
}
