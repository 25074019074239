import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { TxnType } from 'apollo/main/generated';
import styles from './TransactionTypeFilter.module.scss';

type Props = {
    active: TxnType | null;
    className?: string;
    onChange?: (t: TxnType | null) => void;
    includes?: TxnType[];
};

// const TRANSACTIONS_FROM_STORE = ['DEPOSIT', 'PAYBACK', 'WITHDRAW', 'ORDER_PAYMENT'];
// const TRANSACTION_TYPES = Object.values(TxnType).filter((t) => !TRANSACTIONS_FROM_STORE.includes(t));

const CUSTOM_ORDER = [
    TxnType.AddLiquidity,
    TxnType.RemoveLiquidity,
    TxnType.Swap,
    TxnType.Transfer,
    TxnType.MlnkCollect,
    TxnType.CashIssue,
    TxnType.CashRetire
];

export default function TransactionTypeFilter({ active, onChange, className = '', includes }: Props) {
    const [t] = useTranslation();

    const types = React.useMemo(
        () => (includes?.length ? CUSTOM_ORDER.filter((type) => includes.includes(type)) : CUSTOM_ORDER),
        [includes]
    );

    return (
        <div className={cn(styles.Component, className)}>
            <button type="button" className={cn(active === null && styles.active)} onClick={() => onChange?.(null)}>
                {t(`global.transactionType.all`)}
            </button>
            {types.map((type) => (
                <button
                    type="button"
                    className={cn(active === type && styles.active)}
                    onClick={() => onChange?.(type)}
                    key={type}
                >
                    {t(`global.transactionType.${type}`)}
                </button>
            ))}
        </div>
    );
}
