import classNames from 'classnames/bind';
import { BookmarkIcon, ChartIcon, PairsIcon, SearchIcon, TokenIcon, TransactionsIcon } from 'ui/Icons/Icons';
import { BREAKPOINT_LANDSCAPE, ROUTES_MAP } from 'constant';
import { useAppContext } from 'components/Helpers/AppContext';
import { useWidthCondition } from 'helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './BottomBar.module.scss';

const cx = classNames.bind(styles);

function BottomBar() {
    const [t] = useTranslation();
    const {
        bookmarks: { open: openBookmarks },
        searchAccount: { open: openSearchAccount }
    } = useAppContext();
    const [isLandscapeOrLess] = useWidthCondition((w) => w < BREAKPOINT_LANDSCAPE);
    const isAccountPage = useLocation().pathname.startsWith('/account');
    if (isAccountPage) return null;

    return (
        <aside className={cx('Component')}>
            <nav className={cx('Nav')}>
                <NavLink exact to={ROUTES_MAP.home} activeClassName={cx('active')}>
                    <ChartIcon />
                    {t('header.nav.home')}
                </NavLink>
                <NavLink to={ROUTES_MAP.tokens} activeClassName={cx('active')}>
                    <TokenIcon />
                    {t('header.nav.tokens')}
                </NavLink>
                <NavLink to={ROUTES_MAP.pools} activeClassName={cx('active')}>
                    <PairsIcon />
                    {t('header.nav.pools')}
                </NavLink>
                <NavLink to={ROUTES_MAP.transactions} activeClassName={cx('active')}>
                    <TransactionsIcon />
                    {t('header.nav.transactions')}
                </NavLink>
                {/* eslint-disable-next-line react/button-has-type */}
                <button onClick={openSearchAccount}>
                    <SearchIcon />
                    {t('header.nav.search')}
                </button>
                {!isLandscapeOrLess && (
                    <button type="button" onClick={openBookmarks}>
                        <BookmarkIcon />
                        {t('header.nav.bookmarks')}
                    </button>
                )}
            </nav>
        </aside>
    );
}

export default BottomBar;
