import React from 'react';
import cn from 'classnames';
import useIsTouchableDevice from 'hooks/useIsTouchableDevice/useIsTouchableDevice';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import Dialog, { useDialog } from 'ui/Dialog/Dialog';
import VirtualCardTopUpProcess from '../VirtualCardTopUpProcess/VirtualCardTopUpProcess';
import { View } from '../VirtualCardManageViews/VirtualCardManageViews.d';
import styles from './VirtualCardTopUpModal.module.scss';

export default function VirtualCardTopUpModal({ open, setOpen, context, ...props }: ReturnType<typeof useDialog>) {
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);
    const isTouchableDevice = useIsTouchableDevice();

    const [view, setView] = React.useState(View.TopUp);

    const handleClose = () => setOpen(false);
    const handleModalUnmount = () => setView(View.TopUp);

    return (
        <Dialog
            classes={{ container: cn(styles.Container, isTouchableDevice && styles.ContainerIsTouch) }}
            open={open}
            onOpenChange={handleClose}
            fullScreen={isMobileOrLess || isTouchableDevice}
            onUnmount={handleModalUnmount}
            transition={isMobileOrLess || isTouchableDevice ? 'slide-down' : undefined}
            {...props}
        >
            <VirtualCardTopUpProcess
                topUpFees={context.dataRef.current?.topUpFees ?? {}}
                cardId={context.dataRef.current?.cardId ?? ''}
                setView={setView}
                view={view}
                onClose={handleClose}
            />
        </Dialog>
    );
}
