/* eslint-disable import/no-anonymous-default-export */
export default {
    global: {
        bloksIoLink: 'Смотреть на bloks.io',
        copied: 'Скопировано',
        noData: 'Данные отсутствуют',

        btnBookmark_add: 'В закладки',
        btnBookmark_remove: 'В закладках',

        diff: 'Изменение за 24 часа:',

        sort: 'Сортировка',
        search: 'Поиск',
        readMore: 'Подробнее',

        linkInNewTab: 'Перейти на сайт {{name}}<0>откроется в новой вкладке</0>',

        transactionType: {
            all: 'Все',
            ADD_LIQUIDITY: 'Добавлено',
            REMOVE_LIQUIDITY: 'Изъято',
            CASH_ISSUE: 'Выпуск',
            CASH_RETIRE: 'Сжигание',
            SWAP: 'Обмен',
            TRANSFER: 'Перевод',
            MLNK_COLLECT: 'Сбор MLNK'
        },

        token: {
            LIRULED: 'LiST Россия, Санкт-Петербург',
            LIRUMOW: 'LiST Россия, Москва',
            LIRUCEK: 'LiST Россия, Челябинск',
            LIUAIEV: 'LiST Украина, Киев',
            RUBCASH: 'Рубль',
            USDCASH: 'Доллар',
            EURCASH: 'Евро',
            UAHCASH: 'Гривна',
            MLNK: 'Малинка',
            'LQ*': 'Токен ликвидности {{code}}'
        },

        btnDownload: {
            btnAndroid: '<0>Доступно в</0>Google Play',
            btnIOS: '<0>Доступно в</0>App Store',
            btnDesktop: '<0>Windows, MacOS</0>Скоро'
        },

        cardError: {
            text: 'Во время запроса произошла ошибка.',
            btn: 'Обновить'
        },

        candlestickParams: {
            low: 'Мин.',
            high: 'Макс.',
            open: 'Открытие',
            close: 'Закрытие'
        }
    },

    errors: {
        globalError: 'Произошла ошибка.'
    },

    header: {
        language: 'Язык',
        nav: {
            info: 'О проекте',
            info_description: 'Узнайте больше о проекте и начинайте\nинвестировать прямо сейчас',
            openLetters: 'Открытые письма',
            whitePaper: 'White paper',
            whitePaper_description: 'White paper и подробности\nпо экосистеме PayCash',
            paycash: 'PayCash',
            paycash_description: 'Для инвестиций в пулы используйте\nкриптокошелёк PayCash',
            depositExchange: 'Пополнение баланса',
            depositExchange_description: 'Инструкция пополнения баланса\nкошелька PayCash и биржи',
            home: 'Обзор',
            tokens: 'Токены',
            pools: 'Пулы',
            transactions: 'Транзакции',
            search: 'Поиск',
            bookmarks: 'Закладки',

            mlnkInfo: 'Малинка',
            usdcashInfo: 'Стейблкоины'
        },
        searchAccount: 'Найти аккаунт'
    },

    footer: {
        help: {
            title: 'Помощь',
            addLiquidity: 'Как добавить ликвидность?',
            depositToWallet: 'Как пополнить кошелек?',
            issueUSDCASH: 'Процесс выпуска'
        },
        more: {
            title: 'Еще',
            paycashAppLanding: 'PayCash Wallet',
            paycashForStore: 'PayCash for Store'
        },

        contactText:
            'Вы можете связаться с нами, если у вас есть предложения, жалобы или по любой другой причине <0>{{email}}</0>'
    },

    pageSearchAccount: {
        title: 'Результаты поиска',
        subTitle_0: 'Найден {{count}} результат по запросу {{search}}',
        subTitle_1: 'Найдено {{count}} результата по запросу {{search}}',
        subTitle_2: 'Найдено {{count}} результатов по запросу {{search}}',

        placeholder: 'Поиск среди аккаунтов',

        empty: {
            title: 'Ничего не найдено',
            description:
                'По вашему запросу ничего не найдено.\nУбедитесь что все написано правильно\nили попробуйте поиск по другим\nключевым словам '
        },

        init: {
            title: 'Введите запрос'
        }
    },

    pageAccount: {
        title: 'Статистика аккаунта',

        investments: {
            title: 'Инвестиции в пулы',
            helpText: 'Сумма вложений всех токенов\n(Эквивалент в USDT)'
        },

        cashStatistics: {
            issued: 'Выпущено',
            backing: 'В обеспечении',
            yield: 'Доход за 24 часа',
            btnHistory: 'История',
            btnDeposits: 'Активные выпуски'
        },

        cashDeposits: {
            title: 'Выпуски',

            card: {
                issued: 'Выпущено',
                backed: 'В обеспечении {{ token }}',
                linkBloksIo: 'Смотреть на bloks.io'
            },

            empty: {
                title: 'Пусто',
                text: 'Нет активных выпусков'
            }
        },

        income: {
            title: 'Начислено MLNK',
            day: 'За 24 часа',
            total: 'За всё время',
            legend: 'Доходность в MLNK',
            helpText: 'Собранная MLNK\nЭквивалент в USDT - расчет\nпо текущей цене',
            subTitle: 'за 24 часа'
        },
        tokens: 'Токены',
        pools: 'Пулы',

        error: {
            title: 'Ошибка',
            description: 'Произошла ошибка и мы не смогли прогрузить\nдетали аккаунта. Попробуйте обновить страницу',
            btn: 'Обновить'
        },

        notFound: {
            title: 'Такого аккаунта\nне существует'
        },
        empty: {
            title: 'Статистика отсутствует\nдля {{account}}',
            description: 'Аккаунт еще не инвестировал\nв пулы или не производил обмены'
        },

        banner: {
            receive: 'Доступно',
            mln: 'малинок',
            account: 'для {{account}}',
            account_bottom: 'малинок доступно для {{account}}',
            btnCollect: 'Собрать',
            empty: 'Вся малинка для\nданного аккаунта\nсобрана'
        },

        poolDetails: {
            title: 'Информация о пуле',

            empty: {
                title: 'Статистика для {{account}}\nпо данному пулу отсутствует',
                description: 'Аккаунт еще не производил действий\nс данным пулом ликвидности',
                btn: 'Вернуться к статистике {{ account }}'
            },

            addedToPool: 'Добавлено в пул',
            liquidityTokens: {
                title: 'Токены ликвидности',
                helpText: 'Доля аккаунта в пуле ликвидности',
                '%Pool': 'пула',
                total: 'Всего токенов ликвидности в пуле',
                account: 'Токенов ликвидности у {{account}}'
            },
            totalIncome: {
                title: 'Доходы за 24 часа',
                title_total: 'За всё время',

                params: {
                    turnover: 'Комиссия {{token}}',
                    mlnk: 'Начислено MLNK'
                },

                helpText:
                    'Комиссии + собранная MLNK\nпо данному аккаунту в данном пуле\n(Эквивалент в USDT - расчет\nпо цене закрытия)'
            },
            feeIncome: {
                title: 'Конвертационная комиссия',
                helpText:
                    'Конвертационная комиссия -\nдоход от оборота токенов в пуле\nв эквиваленте в USDT\n(Пропорциональный доле аккаунта в пуле)'
            }
        }
    },

    searchAccount: {
        placeholder: 'Поиск среди аккаунтов',

        result: {
            title: 'Результаты поиска',
            description_0: 'Найден {{count}} результат',
            description_1: 'Найдено {{count}} результата',
            description_2: 'Найдено {{count}} результатов',
            btnViewAll: 'Смотреть все результаты'
        },

        empty: {
            title: 'Ничего не найдено',
            description:
                'По вашему запросу ничего не найдено.\nУбедитесь что все написано правильно\nили попробуйте поиск по другим\nключевым словам '
        }
    },

    pageBookmarks: {
        title: 'Закладки',
        tabs: {
            accounts: {
                title: 'Аккаунты'
            },
            tokens: {
                title: 'Токены'
            },
            pairs: {
                title: 'Пулы'
            }
        },
        empty: {
            title: 'Тут пусто',
            text: 'Вы еще ничего не\nдобавили в закладки'
        }
    },

    modalCollectMLNK: {
        info: {
            title: 'Собрать малинку',
            amount: '{{amount}} MLNK\nдоступно для {{account}}',
            description:
                'Чтобы собрать малинку необходимо отправить\n1 USDCASH с того же аккаунта. Вы можете отправить 1 USDCASH через приложение\n<0>PayCash</0> отсканировав QR код'
        },

        transaction: {
            qr: 'Просканируйте QR код',
            params: 'или оплатите вручную',
            account: 'Кошелек',
            memo: 'memo'
        },

        btnPay: 'Оплатить через приложение',
        btnPay_desktop: 'Оплатить',
        btnPay_details: 'Оплатить вручную'
    },

    pageHome: {
        title: 'Статистика',

        error: {
            title: 'Ошибка',
            description: 'Произошла ошибка и мы не смогли прогрузить\nграфики. Попробуйте обновить страницу',
            btn: 'Обновить'
        },

        cashStatistics: {
            title: 'Выпуск криптоналичных',
            backing: 'Обеспечение',

            yield: 'Ср. доходность за год',

            btnHistory: 'Смотреть историю',
            btnDetails: 'Подробнее'
        },

        liquidity: {
            title: 'Суммарные инвестиции',
            helpText: 'Сумма всех токенов во всех пулах\n(Эквивалент в USDT)'
        },

        volume: {
            title: 'Сумма обменов за 24 часа',
            title_week: 'За 7 дней',
            helpText: 'Сумма обменянных токенов\nза последние 24 часа\n(Эквивалент в USDT)'
        },

        mlnkCollected: {
            title: 'Статистика MLNK',
            helpText: 'MLNK токен - награда которую можно\nполучить за инвестиции в пулы ',

            left: 'Осталось к распределению MLNK',
            burned: 'Сожжено MLNK',
            collected: 'Распределено всего MLNK',
            total: 'Всего MLNK',
            speed: 'Распределено MLNK в {{blockNum}} блоке',
            speed_day: 'Распределено MLNK за последние 24 часа',
            holders_0: '{{number}} держатель токена',
            holders_1: '{{number}} держателя токена',
            holders_2: '{{number}} держателей токена'
        },

        mlnkPrice: {
            title: 'Цена MLNK',
            change: 'Изменение за',
            timeframe: {
                DAY: '1 день',
                HOUR: '1 час',
                FIVE_MINUTES: '5 мин.'
            },
            volatility: {
                day: '24 часа',
                week: '7 дней',
                month: '30 дней'
            }
        },

        topTokens: {
            title: 'Топ токенов по предоставленной ликвидности',
            helpText: 'Токены с наибольшим объёмом инвестиций',
            btnViewAll: 'Смотреть все токены',

            params: {
                price: 'Цена',
                liquidity: 'Объем инвестиций',
                volume: 'Сумма обменов',
                transactions: 'Кол-во транзакций'
            }
        },

        topPools: {
            title: 'Топ пулов за 24 часа',
            subTitle: 'Эквивалент за год',
            helpText: 'Пулы с наибольшей экв. годовой\nдоходностью за последние 24 часа',
            btnViewAll: 'Смотреть все пулы',
            params: {
                profitability: 'Доходность',
                price: 'Цена {{ currency }}',
                liquidity: 'Объем инвестиций',
                volume: 'Сумма обменов'
            }
        }
    },

    pageTokens: {
        title: 'Токены',

        error: {
            title: 'Ошибка',
            description: 'Произошла ошибка и мы не смогли прогрузить\nтокены. Попробуйте обновить страницу',
            btn: 'Обновить'
        },

        empty: {
            title: 'Ничего не найдено',
            description:
                'По вашему запросу ничего не найдено. Убедитесь\nчто все написано правильно или попробуйте поиск\nпо другим ключевым словам'
        },

        table: {
            header: {
                token: {
                    title: 'Токен',
                    title_ASC: 'По имени токена A-Z',
                    title_DESC: 'По имени токена Z-A'
                },
                liquidity: {
                    title: 'Объём инвестиций',
                    title_ASC: 'Объём инвестиций по возрастанию',
                    title_DESC: 'Объём инвестиций по убыванию'
                },
                volume: {
                    title: 'Сумма обменов (24 часа)',
                    title_ASC: 'Сумма обменов 24ч по возрастанию',
                    title_DESC: 'Сумма обменов 24ч по убыванию'
                },
                price: {
                    title: 'Цена',
                    title_ASC: 'Цена по возрастанию',
                    title_DESC: 'Цена по убыванию'
                },
                priceChange: {
                    title: 'Изменение цены (24 часа)',
                    title_ASC: 'Изменение цены 24ч по возрастанию',
                    title_DESC: 'Изменение цены 24ч по убыванию'
                }
            }
        }
    },

    pageToken: {
        error: {
            title: 'Ошибка',
            description: 'Произошла ошибка и мы не смогли прогрузить\nтокен. Попробуйте обновить страницу',
            btn: 'Обновить'
        },

        empty: {
            title: 'Токен не найден',
            description:
                'Указанный токен не найден. Убедитесь\nчто все написано правильно или попробуйте\nвоспользоваться поиском',
            btn: 'К списку'
        },

        liquidity: {
            title: 'Объём инвестиций',
            helpText: 'Сумма инвестиций токена\n{{code}} во всех пулах\n(Эквивалент в USDT)'
        },

        volume: {
            title: 'Сумма обменов за 24 часа',
            title_week: 'За 7 дней',
            helpText: 'Сумма обменов с участием токена\n{{code}} за последние 24 часа\n(Эквивалент в USDT)'
        },

        transactions: {
            title: 'Количество транзакций',
            subTitle: 'за 24 часа'
        },

        price: {
            title: 'Цена',
            helpText:
                'Цена токена рассчитывается на основе\nминимального пути обмена {{code}} на USDCASH.\nЕсли таких путей несколько используется тот,\nпри котором цена {{code}} ниже.\nЗначение USDCASH переводится по курсу в USDT'
        },

        pairs: {
            title: 'Пулы',
            colls: {
                type: 'Тип транзакции',
                liquidity: 'Объём инвестиций',
                volume: 'Сумма обменов (24 часа)',
                fee: 'Комиссия',
                income: 'Доходность'
            }
        },

        info: {
            title: 'Информация о токене',

            name: 'Название токена',
            contract: 'Смартконтракт EOS'
        }
    },

    pagePool: {
        title: 'Пул',
        liquidityToken: {
            title: 'Токен ликвидности',
            helpText: 'Токены которые выдаются всем вкладчикам\nпропорционально их вкладу в пул',
            released: 'Выпущено токенов ликвидности'
        },

        error: {
            title: 'Ошибка',
            description: 'Произошла ошибка и мы не смогли прогрузить\nдетали пула. Попробуйте обновить страницу',
            btn: 'Обновить'
        },

        empty: {
            title: 'Пул не найден',
            description:
                'Указанный пул не найден. Убедитесь\nчто все написано правильно или попробуйте\nвоспользоваться поиском',
            btn: 'К списку'
        },

        token: {
            price: 'Цена',
            pool: 'В пуле'
        },

        liquidity: {
            title: 'Объём инвестиций',
            subTitle: '',
            helpText: 'Сумма инвестиций токенов\n{{code1}} и {{code2}} в пуле\n(Эквивалент в USDT)'
        },

        volume: {
            title: 'Сумма обменов за 24 часа',
            title_week: 'За 7 дней',
            helpText: 'Сумма обменянных {{code1}}\nи {{code2}} токенов за последние\n24 часа (Эквивалент в USDT)'
        },

        income: {
            title: 'Доходность за 24 часа',
            title_year: 'Экв. доходность за год',
            subTitle: '',
            helpText: 'Комиссии + начисленная MLNK\nв эквиваленте USDT',

            tokenTurnover: 'Комиссия {{token}}',
            mlnkIncome: 'Начислено MLNK'
        },

        info: {
            title: 'Информация о пуле',

            name: 'Название пула',
            address: 'Адрес смартконтракта',
            contract: 'Смартконтракт {{token}}'
        }
    },

    transactionsTable: {
        title: 'Транзакции',

        error: {
            title: 'Ошибка',
            description: 'Произошла ошибка и мы не смогли прогрузить\nтранзакции. Попробуйте обновить страницу',
            btn: 'Обновить'
        },

        empty: {
            title: 'Ничего не найдено',
            description: 'По вашему запросу ничего не найдено.'
        },

        params: {
            type: {
                title: 'Тип',
                title_ASC: 'Тип по возрастанию',
                title_DESC: 'Тип по убыванию'
            },
            account: {
                title: 'Аккаунт',
                title_ASC: 'Имя аккаунта 1-5 и A-Z',
                title_DESC: 'Имя аккаунта Z-A и 5-1'
            },
            sender: {
                title: 'Отправитель'
                // title_ASC: 'Отправитель по возрастанию',
                // title_DESC: 'Отправитель по убыванию',
            },
            receiver: {
                title: 'Получатель'
                // title_ASC: 'Получатель по возрастанию',
                // title_DESC: 'Получатель по убыванию',
            },
            volume: {
                title: 'Объём',
                title_ASC: 'Объём по возрастанию',
                title_DESC: 'Объём по убыванию'
            },
            date: {
                title: 'Дата и время',
                title_ASC: 'Дата по возрастанию',
                title_DESC: 'Дата по убыванию'
            },

            cashAmounts: {
                title_CASH_ISSUE: 'В обеспечении',
                title_CASH_RETIRE: 'Получено'
            }
        }
    },

    poolsTable: {
        title: 'Пулы',
        error: {
            title: 'Ошибка',
            description: 'Произошла ошибка и мы не смогли прогрузить\nпулы. Попробуйте обновить страницу',
            btn: 'Обновить'
        },

        empty: {
            title: 'Ничего не найдено',
            description:
                'По вашему запросу ничего не найдено. Убедитесь\nчто все написано правильно или попробуйте поиск\nпо другим ключевым словам',
            description_short: 'По вашему запросу ничего не найдено.'
        },

        param: {
            token: {
                title: 'Пулы',
                title_ASC: 'ID пула по возрастанию',
                title_DESC: 'ID пула по убыванию'
            },
            liquidity: {
                title: 'Объём инвестиций',
                title_ASC: 'Объём инвестиций по возрастанию',
                title_DESC: 'Объём инвестиций по убыванию'
            },
            volume: {
                title: 'Сумма обменов (24 часа)',
                title_ASC: 'Сумма обменов 24ч по возрастанию',
                title_DESC: 'Сумма обменов 24ч по убыванию'
            },
            income: {
                title: 'Доходность (24 часа)',
                title_ASC: 'Доходность 24ч по возрастанию',
                title_DESC: 'Доходность 24ч по убыванию'
            },
            apy: {
                title: 'Экв. доходность за год',
                title_ASC: 'Экв. доходность за год по возрастанию',
                title_DESC: 'Экв. доходность за год по убыванию'
            }
        }
    },

    pageError: {
        error: 'Ошибка',

        404: {
            title: 'Страница\nне найдена',
            description: 'Вероятно, страница не существует\nили вы ошиблись, когда вводили\nадрес в строке браузера'
        },
        500: {
            title: 'Внутренняя\nошибка сервера',
            description:
                'На сервере произошла непредвиденная\nошибка. Пожалуйста, подождите:\nона вскоре будет исправлена.'
        },

        btnHome: 'Вернуться на главную'
    },

    pageInfo: {
        intro: {
            title: '<0>Инвестируй</0><1>\nв криптовалюту</1><2>легко</2>',
            description: 'Начинай инвестировать прямо сейчас\nи получай бонусы в виде криптотокена малинки',
            btnStart: 'Начать инвестирование',
            btnMore: 'Подробнее'
        },

        about: {
            title: 'Идея заработка\nочень проста!',
            text: [
                'Пользователи, их еще называют поставщики ликвидности, вкладывают свою криптовалюту в любую валютную пару (пул), в равных пропорциях! Тем самым обеспечивая максимальный запас этих криптовалют (ликвидность) в обменнике для всех, кто хочет осуществить обменные операции в данной валютной паре. ',
                'В зависимости от количества вложенной и выбранной валютной пары, каждый пользователь получает токены ликвидности (LQA, LQB, LQC и т.д). Они свидетельствуют о его доле в конкретном пуле и дают право на получение прибыли в виде комиссий с каждой обменной операции пропорционально его доле вложений в данный пул. '
            ]
        },

        statistics: {
            title: 'Статистика',

            liquidity: {
                title: 'Суммарные инвестиции в пулы',
                title_short: 'Инвестиции'
            },
            volume: {
                title: 'Сумма обменов за 24 часа',

                title_short: 'Сумма обменов'
            },
            mlnk: {
                title: 'Статистика MLNK'
            },
            mlnkPrice: {
                title: 'Цена MLNK'
            }
        },

        feeIncome: {
            title: 'Как рассчитываются доходы?',
            description: 'За каждую обменную операцию в пулах\nвзымается комиссия 0.25% от суммы обмена',

            fee: 'Комиссия',
            investor: '<0>0.2%</0>\nПропорционально распределяется\nмежду пользователями-поставщиками\nликвидности',

            platform: '<0>0.05%</0>\nИдет на сжигание\nтокена-вознаграждения\nКрипто Малинки (MLNK)'
        },

        mlnkIncome: {
            title: 'Помимо комиссионого дохода',
            description:
                'поставщикам ликвидности начисляется супертокен -\nКрипто Малинка (MLNK) в виде вознаграждения!',
            btnCalc: 'Калькулятор доходности пула'
        },

        aboutMLNK: {
            title: '<0>Что такое Крипто</0><1>\nМалинка (MLNK)?</1>',
            description: [
                'Помимо основного заработка в виде доли комиссий за обмен, всем пользователям, которые вложили в пулы свои крипто наличные PayCash или токены LiST начисляется супертокен - Крипто Малинка (MLNK) в виде вознаграждения!',
                'Каждый блок в блокчейне (0,5 секунды), с 21 августа 2021 года до 29 августа 2081 года, рождается некое количество малинок - от 100 в первый и далее, с плавным снижением до 0.1 Малинки в последний!',
                'Токен Malinka (MLNK) начисляется в качестве вознаграждения только на пулы MLNK с одним из токенов из списка White List. Крипто Малинка распределяется между пулами по их весу в общих инвестициях! А далее распределяется между инвесторами в пулах в соответствии с их долями!'
            ],

            fee: '<0>0.05%</0>\n(а это 20% от всех обменных комиссий пулов) конвертируются в доллар и поступают на выкуп Крипто Малинки для последующего её сжигания!',

            result: '<0>Результат</0>\nКрипто Малинке будет обеспечена постоянная ликвидность, зависящая от интенсивности роста обмена валют и увеличения количества пользователей, роста ее цены на рынке и уменьшение ее количества в обороте! ',

            banner: {
                text: '<1>Доступно</1> <0>2 055 375</0><1>малинок</1>',
                account: 'для user.pcash',
                btn: 'Собрать'
            }
        },

        pools: {
            title: 'Пулы получающие малинку',
            description: 'Все пулы в которых есть токен MLNK',
            hint: '- вот так отмечены пары токенов в списке пулов, получающие малинку',
            btnShowAll: 'Показать все',
            btnCollapse: 'Свернуть'
        },

        start: {
            title: 'Начните зарабатывать\nв несколько простых шагов!',

            step: '{{ count }} шаг',

            btnVideo: 'Видеоинструкция',
            btnNext: 'Следующий шаг',
            btnBack: 'Предыдущий шаг',

            step1: {
                title: 'Скачайте приложение',
                text: 'Чтобы начать инвестировать необходимо\nскачать приложение PayCash',
                btnSite: 'Скачать с сайта PayCash'
            },

            step2: {
                title: 'Создайте EOS аккаунт',
                text: 'После того как вы скачали приложение, вам необходимо создать кошелек.\n\nТакже вы можете использовать уже существующий EOS кошелек, вставив приватный ключ '
            },

            step3: {
                title: 'Выберите пул',
                text: 'На сайте PayCash Swap Перейдите в раздел "Пулы" и выберите подходящий пул для ваших инвестиций.\n\nВыбрать пул можно исходя из доходности за 24 часа или эквивалентной доходности за год.\n\nПерейдя на страницу пула вы можете посмотреть подробную информацию по доходности пула и другим данным'
            },

            step4: {
                title: 'Пополните баланс',
                text: 'После того как вы выбрали пул токенов, необходимо приобрести токены указанные на данной странице пропорционально по 50% вашей суммы инвестиций на каждый токен',
                list: [
                    'Вы можете купить криптовалюту через раздел "Купить"',
                    'Купить EOS через биржу Binance или обменник и конвертировать их через раздел "Обмен" в любую криптовалюту'
                ]
            },

            step5: {
                title: 'Перейдите в раздел инвестиций',
                text: 'Купив необходимые токены, в приложении PayCash перейдите в раздел "Еще", далее перейдите в раздел "Депозит в пул ликвидности"'
            },

            step6: {
                title: 'Добавьте ликвидности в пулы',
                text: 'Выберите пару токенов которые вы купили. После того как вы выбрали токены, внизу появится 2 поля суммы пополнений по каждому токену. '
            },

            step7: {
                title: 'Отслеживайте доход',
                text: 'При помощи сайта PayCash Swap отслеживайте свой доход и криптомалинку.',
                list: [
                    'Зайдите на сайт PayCash Swap',
                    'Нажмите на кнопку "Поиск"',
                    'Введите название вашего аккаунта в поиск, после загрузки результатов нажмите на него'
                ]
            },
            step8: {
                title: 'Собирайте малинку',
                text: 'На странице с деталями вашего аккаунта вы можете собрать малинку полученую за вклад в пулы ликвидности',
                list: [
                    'Нажмите на баннер с малинками',
                    'Оплатите 1 USDCASH через приложение, или отправьте просканировав QR код'
                ]
            }
        }
    },

    pageOpenLetters: {
        title: 'Открытые письма',
        letter1: {
            title: 'Обращение к сообществу',
            date: '26 Фев. 2024',
            text: [
                'Уважаемое криптосообщество,',
                '22 февраля 2024 года команда Recover+ успешно заморозила 2 миллиона EOS скомпрометированных  средств на EOS EVM и уже находится в процессе окончательного восстановления.',
                'Команда PayCash выражает нашу глубочайшую благодарность команде Recover+ за их неизменную поддержку и опыт в обеспечении восстановления справедливости.',
                'Это не только подчеркивает эффективность инициативы Recover+, но и показывает насколько надежна и продвинута экосистема EOS.',
                'В знак нашей признательности и приверженности созданию более безопасной блокчейн-среды мы обязуемся пожертвовать 5% от всех восстановленных средств на дальнейшую поддержку и развитие проекта Recover+.\nЭтот жест отражает наш вклад поддержку текущих усилий по усилению мер безопасности и защите EOS сообщества от возможных угроз, а также всецелую популяризацию блокчейна EOS.',
                'С искренней благодарностью,\nКоманда разработчиков PayCash'
            ]
        }
    },

    banners: {
        // bankCard: { title: 'Как пополнить кошелек при\nпомощи банковской карты' },
        bankCard: { title: 'Пополнить с карты\nВывести на карту', btn: 'Перейти' },
        exchange: {
            title: 'Пополнение\nкошелька и биржи'
        },
        paycash: {
            title: 'Для инвестиций в пулы\nиспользуйте кошелёк PayCash',
            btnDetails: 'Подробная инструкция',
            btnDownload: 'Скачать приложение'
        },

        cryptocash: {
            title: 'Токены, обеспеченные м\u00B2\nметрами недвижимости'
        },

        invest: {
            title: 'Начните зарабатывать\nв несколько простых шагов!',
            tag: 'О проекте'
        }
    },

    pageDepositByBankCard: {
        title: 'Как пополнить\nкошелек при помощи\nбанковской карты',
        description: 'Пополнений любой банковской картой мира',

        hint: 'Для пополнения баланса, необходимо использовать компьютерную версию сайта',

        step: '{{step}} шаг',

        btnNext: 'Следующий шаг',
        btnBack: 'Предыдущий шаг',

        step1: {
            title: 'Перейдите на сайт',
            text: 'Для пополнения баланса PayCash кошелька необходимо перейти на сайте tapplive.com'
        },

        step2: {
            title: 'Авторизируйтесь',
            text: 'Найжмите на кнопку "Вход" в правом верхнем углу, далее выберите "Войти при помощи PayCash"\n\nВы можете настроить свой аккаунт, добавив фото, выбрав юзернейм, блок темы или вы можете пропустить настройку аккаунта'
        },

        step3: {
            title: 'Перейдите к балансу',
            text: 'Далее нажмите на иконку баланса в верхнем меню'
        },

        step4: {
            title: 'Выберите способ пополнения',
            text: 'Нажмите на кнопку "Пополнить", далее выберите "Банковская карта"'
        },

        step5: {
            title: 'Введите сумму пополнения',
            text: 'Введите сумму на которую хотите пополнить кошелек, примите "Политику соглашения" и "Политику конфиденциальности" и нажмите кнопку "Далее"'
        },

        step6: {
            title: 'Оплатите пополнения баланса',
            text: 'На открывшейся странице введите данные своей карты'
        },

        step7: {
            title: 'Переведите баланс на свой кошелек',

            text: 'После пополнения баланса, нажмите на кнопку "Вывести", далее выберите PayCash. В появившемся окне введите свой аккаунт и сумму перевода\n\nСвой аккаунт можно найти в приложение PayCash, в левом верхнем углу'
        }
    },

    pageDepositExchange: {
        title: 'Пополнение\nкошелька и биржи',
        description: 'Пополнение PayCash кошелька токенами MLNK, EOS,\nUSDT через биржи Binance и WhiteBit',

        btnStart: 'Перейти к инструкции',

        exchangeList: 'Так же вы можете купить криптовалюту на других биржах',

        step: '{{step}} шаг',
        btnNext: 'Далее',
        btnBack: 'Назад',

        tutorial: {
            deposit: 'Пополнение кошелька',
            withdraw: 'Пополнение биржи',

            token: 'Токен',
            exchange: 'Биржа',

            tooltip_disabledGateway: 'Токена нет\nна этой бирже'
        },

        deposit: {
            EOS: {
                whitebit: {
                    step_1: {
                        title: 'Перейдите на сайт ',
                        text: 'Чтобы пополнить баланс на WhiteBit, перейдите на сайт нажав кнопку ниже'
                    },
                    step_2: {
                        title: 'Авторизуйтесь на сайте',
                        text: 'Необходимо авторизоваться на сайте WhiteBit. Войдите в свой аккаунт если у вас есть или зарегистрируйте новый'
                    },
                    step_3: {
                        title: 'Перейдите к балансам',
                        text: 'Для того чтобы пополнить ваш кошелек на бирже перейдите в раздел "Спотовый" в выпадающем меню "Балансы" в правой части верхнего меню'
                    },
                    step_4: {
                        title: 'Выберите EOS',
                        text: 'Перейдя к балансам, воспользуйтесь поиском среди токенов, и найдите в списке токен EOS и нажмите кнопку "Пополнить"'
                    },
                    step_5: {
                        title: 'Переведите EOS',
                        text: 'После того как вы выбрали токен, вы получите адрес кошелька и MEMO которые нужно указать при переводе EOS из кошелька PayCash'
                    }
                },

                binance: {
                    step_1: {
                        title: 'Перейдите на сайт',
                        text: 'Чтобы отправить EOS на бинанс, перейдите на сайт нажав кнопку ниже'
                    },
                    step_2: {
                        title: 'Авторизуйтесь на сайте',
                        text: 'Необходимо авторизоваться на сайте Binance. Войдите в свой аккаунт если у вас есть или зарегистрируйте новый'
                    },
                    step_3: {
                        title: 'Купите EOS при помощи банковской карты',
                        text: 'В выпадающем пункте "Купить криптовалюту", выберите "Кредитная/ дебетовая карта" '
                    },
                    step_4: {
                        title: 'Укажите сумму покупки',
                        text: 'На открывшейся странице, в поле "Получить", выберите криптовалюту EOS, в поле "Списать" укажите сумму на которую хотите купить EOS.\n\nИли укажите количество EOS которое хотите купить и сумма сформируется автоматически'
                    },
                    step_5: {
                        title: 'Оплатите',
                        text: 'После того как вы указали нужное количество EOS, откроется страница оплаты, нажмите на кнопку "Добавить новую карту" и введите данные карты'
                    },
                    step_6: {
                        title: 'Переведите\nEOS в PayCash',
                        text: 'После того как вы купили EOS, необходимо перевести их в PayCash, для этого перейдите в раздел "Кошелек", далее нажмите на кнопку "Вывод"'
                    },
                    step_7: {
                        title: 'Укажите данные кошелька',
                        text: 'На открывшейся странице выберите валюту для вывода, далее укажите свой аккаунт PayCash(его можно найти в левом верхнем углу приложения). Выберите <0>сеть EOS</0> и укажите сумму вывода, MEMO указывать не обязательно'
                    }
                }
            },

            MLNK: {
                whitebit: {
                    step_1: {
                        title: 'Перейдите на сайт ',
                        text: 'Чтобы пополнить баланс на WhiteBit, перейдите на сайт нажав кнопку ниже'
                    },
                    step_2: {
                        title: 'Авторизуйтесь на сайте',
                        text: 'Необходимо авторизоваться на сайте WhiteBit. Войдите в свой аккаунт если у вас есть или зарегистрируйте новый'
                    },
                    step_3: {
                        title: 'Перейдите к балансам',
                        text: 'Для того чтобы пополнить ваш кошелек на бирже перейдите в раздел "Спотовый" в выпадющем меню "Балансы" в правой части верхнего меню'
                    },
                    step_4: {
                        title: 'Выберите MLNK',
                        text: 'Перейдя к балансам, воспользуйтесь поиском среди токенов, и найдите в списке токен MLNK и нажмите кнопку "Пополнить"'
                    },
                    step_5: {
                        title: 'Переведите MLNK',
                        text: 'После того как вы выбрали токен, вы получите адрес кошелька и MEMO которые нужно указать при переводе MLNK из кошелька PayCash'
                    }
                }
            },
            TETHER: {
                whitebit: {
                    step_1: {
                        title: 'Перейдите на сайт ',
                        text: 'Чтобы пополнить баланс на WhiteBit, перейдите на сайт нажав кнопку ниже'
                    },
                    step_2: {
                        title: 'Авторизуйтесь на сайте',
                        text: 'Необходимо авторизоваться на сайте WhiteBit. Войдите в свой аккаунт если у вас есть или зарегистрируйте новый'
                    },
                    step_3: {
                        title: 'Перейдите к балансам',
                        text: 'Для того чтобы пополнить ваш кошелек на бирже перейдите в раздел "Спотовый" в выпадющем меню "Балансы" в правой части верхнего меню'
                    },
                    step_4: {
                        title: 'Выберите USDT',
                        text: 'Перейдя к балансам, воспользуйтесь поиском среди токенов, и найдите в списке токен USDT и нажмите кнопку "Пополнить"'
                    },
                    step_5: {
                        title: 'Переведите USDT',
                        text: 'После того как вы выбрали токен, вы получите адрес кошелька и MEMO которые нужно указать при переводе USDT из кошелька PayCash'
                    }
                }
            }
        },
        withdraw: {
            EOS: {
                whitebit: {
                    step_1: {
                        title: 'Перейдите на сайт ',
                        text: 'Чтобы пополнить баланс на WhiteBit, перейдите на сайт нажав кнопку ниже'
                    },
                    step_2: {
                        title: 'Авторизуйтесь на сайте',
                        text: 'Необходимо авторизоваться на сайте WhiteBit. Войдите в свой аккаунт если у вас есть или зарегистрируйте новый'
                    },
                    step_3: {
                        title: 'Перейдите к балансам',
                        text: 'Для того чтобы пополнить ваш кошелек на бирже перейдите в раздел "Спотовый" в выпадающем меню "Балансы" в правой части верхнего меню'
                    },
                    step_4: {
                        title: 'Выберите EOS',
                        text: 'Перейдя к балансам, воспользуйтесь поиском среди токенов, и найдите в списке токен EOS и нажмите кнопку "Пополнить"'
                    },
                    step_5: {
                        title: 'Переведите EOS',
                        text: 'После того как вы выбрали токен, вы получите адрес кошелька и MEMO которые нужно указать при переводе EOS из кошелька PayCash'
                    }
                },
                binance: {
                    step_1: {
                        title: 'Перейдите на сайт',
                        text: 'Чтобы отправить EOS на бинанс, перейдите на сайт нажав кнопку ниже'
                    },
                    step_2: {
                        title: 'Авторизируйтесь на сайте',
                        text: 'Необходимо авторизироваться на сайте Binance. Войдите в свой аккаунт если у вас есть или зарегистрируйте новый'
                    },
                    step_3: {
                        title: 'Перейдите в кошелек',
                        text: 'Для того чтобы пополнить ваш кошелек на бирже перейдите в раздел "Фиат и спот" в выпадющем меню "Кошелек" в правой части верхнего меню'
                    },
                    step_4: {
                        title: 'Перейдите к вводу криптовалюты',
                        text: 'Далее перейдите в раздел "Ввод", который находится под заголовком "Основнйо аккаунт"'
                    },
                    step_5: {
                        title: 'Выберите EOS',
                        text: 'В выпадающем списке "Монета" выберите токен EOS, в разделе "Сеть" необходимо выбрать "EOS"',
                        alert: 'Для отправки EOS из приложения PayCash обязательно нужно  выбрать сеть EOS'
                    },
                    step_6: {
                        title: 'Переведите EOS',
                        text: 'После того как вы выбрали токен и сеть вы получите адрес кошелька и MEMO которые нужно указать при переводе EOS из кошелька PayCash'
                    }
                }
            },

            MLNK: {
                whitebit: {
                    step_1: {
                        title: 'Перейдите на сайт ',
                        text: 'Чтобы пополнить баланс на WhiteBit, перейдите на сайт нажав кнопку ниже'
                    },
                    step_2: {
                        title: 'Авторизируйтесь на сайте',
                        text: 'Необходимо авторизироваться на сайте WhiteBit. Войдите в свой аккаунт если у вас есть или зарегистрируйте новый'
                    },
                    step_3: {
                        title: 'Перейдите к балансам',
                        text: 'Для того чтобы пополнить ваш кошелек на бирже перейдите в раздел "Спотовый" в выпадющем меню "Балансы" в правой части верхнего меню'
                    },
                    step_4: {
                        title: 'Выберите MLNK',
                        text: 'Перейдя к балансам, воспользуйтесь поиском среди токенов, и найдите в списке токен MLNK и нажмите кнопку "Пополнить"'
                    },
                    step_5: {
                        title: 'Переведите MLNK',
                        text: 'После того как вы выбрали токен, вы получите адрес кошелька и MEMO которые нужно указать при переводе MLNK из кошелька PayCash'
                    }
                }
            },

            TETHER: {
                whitebit: {
                    step_1: {
                        title: 'Перейдите на сайт ',
                        text: 'Чтобы пополнить баланс на WhiteBit, перейдите на сайт нажав кнопку ниже'
                    },
                    step_2: {
                        title: 'Авторизируйтесь на сайте',
                        text: 'Необходимо авторизироваться на сайте WhiteBit. Войдите в свой аккаунт если у вас есть или зарегистрируйте новый'
                    },
                    step_3: {
                        title: 'Перейдите к балансам',
                        text: 'Для того чтобы пополнить ваш кошелек на бирже перейдите в раздел "Спотовый" в выпадющем меню "Балансы" в правой части верхнего меню'
                    },
                    step_4: {
                        title: 'Выберите USDT',
                        text: 'Перейдя к балансам, воспользуйтесь поиском среди токенов, и найдите в списке токен USDT и нажмите кнопку "Пополнить"'
                    },
                    step_5: {
                        title: 'Переведите USDT',
                        text: 'После того как вы выбрали токен, вы получите адрес кошелька и MEMO которые нужно указать при переводе USDT из кошелька PayCash'
                    }
                }
            }
        }
    },

    pageAboutCashTokens: {
        intro: {
            title: '<0>Cтейблкоины</0><1>\nобеспеченные</1><2>на 200%</2>',
            btnMore: 'Подробнее'
        },

        commission: {
            text: '<0>Никаких скрытых\nкомиссий</0> и звездочек\nна все переводы *',
            subtext: '*вот правда, никаких'
        },

        statistics: {
            title_link: 'Нет комиссий',
            params: {
                issued: 'выпущено',
                backed: 'В обеспечении ',
                yield: 'Доходность'
            }
        },

        guarantees: {
            title: 'Гарантированный обмен криптоналичных',
            title_link: 'Гарантийный обмен',

            description:
                'Любой пользователь гарантированно может обменять свои криптоналичные на один из токенов (в зависимости от выпущенных токенов)',
            subtext:
                'Пользователь выпустивший криптоналичные, может вернуть свой залог путем сжигания выпущенных токенов.',
            list: ['USDCASH на USDBX', 'RUBCASH на RUBLES', 'RMBCASH на RMBC']
        },

        income: {
            title: 'Увеличьте свои\u00A0доходы',
            title_link: 'Доходность',

            description:
                'Получайте повышенную доходность от пула с токенами обеспечения, выпуская криптоналичные. Вы также можете добавить в пул выпущенные криптоналичные и получать еще больше доходов от своих инвестиций.'
        },

        inheritance: {
            title: 'Наследство',
            title_link: 'Наследство',

            description:
                'Настраивайте наследников, их доли и срок срабатывания наследства в несколько кликов без юридических документов и лишних затрат.',
            btnDownload: 'Скачать приложение',

            example: {
                duration: {
                    label: 'Установленный период неактивности',
                    value: '365 дней'
                },
                date: {
                    label: 'Дата исполнения наследства',
                    value: '28 Фев. 2022'
                }
            }
        },

        applications: {
            title: 'Используйте одно из рекомендованных приложений, чтобы воспользоваться всеми преимуществами криптоналичных',

            title_link: 'Рекомендуемые приложения'
        },

        tutorials: {
            title_link: 'FAQ',

            btnDownload: 'Скачать приложение',

            step: 'Шаг {{ stepNumber }}',

            content: {
                issue: {
                    title: 'Выпуск',

                    steps: [
                        {
                            title: 'Перейдите в раздел',
                            content:
                                'Воспользуйтесь приложением из списка рекомендуемых, для выпуска криптоналичных. Перейдите в раздел "Выпуск криптоналичных". На открывшемся экране нажмите на кнопку "Новый выпуск"'
                        },
                        {
                            title: 'Выберите токен',
                            content: 'Выберите токен, который хотите выпустить'
                        },
                        {
                            title: 'Заполните поля',
                            content:
                                'Укажите сумму одного из токенов, которую вы хотите инвестировать. Сумма второго токена будет заполнена автоматически с учетом текущего курса пула'
                        },
                        {
                            title: 'Подтверждение',
                            content:
                                'Внимательно проверьте количество токенов, которое будет списано с вашего кошелька, а также сумму к получению. Подтвердите выпуск, если всё правильно или отредактируйте данные'
                        },
                        {
                            title: 'Готово!',
                            content:
                                'В случае успешного выпуска вы увидите подтверждение транзакции. Окно содержит информацию о сумме залога, выпущенном количестве токенов, а также ID транзакции. Скопируйте ID или перейдите на bloks.io для просмотра сделки в сети EOS'
                        }
                    ]
                },

                burn: {
                    title: 'Сжигание',

                    steps: [
                        {
                            title: 'Перейдите в раздел',
                            content:
                                'Воспользуйтесь приложением из списка рекомендуемых, для сжигания криптоналичных. Перейдите в раздел "Выпуск криптоналичных". Выберите один из выпусков в списке и нажмите на кнопку "Вернуть залог"'
                        },
                        {
                            title: 'Заполните поле',
                            content:
                                'Далее необходимо заполнить поле "Сумма возврата". Впишите туда количество токенов, которое хотите вернуть'
                        },
                        {
                            title: 'Подтверждение операции',
                            content:
                                'Внимательно проверьте количество токенов, которое будет списано с вашего кошелька, а также сумму к получению. Подтвердите возврат, если всё правильно или отредактируйте данные'
                        },
                        {
                            title: 'Готово! ',
                            content:
                                'В случае успешного возврата вы увидите подтверждение транзакции. Окно содержит информацию о сумме возвращенных токенов и полученных, а также ID транзакции. Скопируйте ID или перейдите на bloks.io для просмотра сделки в сети EOS'
                        }
                    ]
                },

                buy: {
                    title: 'Гарантийный обмен',

                    steps: [
                        {
                            title: 'Перейдите в раздел',
                            content:
                                'Перейдите в раздел "Гарантийный обмен". На открывшемся экране выберите токен, который хотите обменять'
                        },
                        {
                            title: 'Сумма обмена',
                            content:
                                'Далее необходимо заполнить поле "Сумма к возврату". Введите количество токенов, которое вы хотите обменять. Сумма к возврату должна быть кратна сумме, указанной выше'
                        },
                        {
                            title: 'Подтверждение',
                            content:
                                'Внимательно проверьте сумму обмена. Если всё правильно, подтвердите обмен или отредактируйте данные. '
                        },
                        {
                            title: 'Готово!',
                            content:
                                'В случае успешного обмена вы увидите подтверждение транзакции. Окно содержит информацию о токенах и сумме обмена, а также ID транзакции. Скопируйте ID или перейдите на bloks.io для просмотра сделки в сети EOS'
                        }
                    ]
                }
            }
        }
    },

    pageExchange: {
        title: 'Вывод и пополнение\nкошелька',
        badge: {
            label: 'Бета версия',
            content: 'В случае возникновения ошибок, обращайтесь в поддержку'
        },
        sell: 'Вывести',
        buy: 'Пополнить',
        currency: 'Валюта',
        minAmount: 'Минимум',
        maxAmount: 'Максимум',
        commission: 'Комиссия',
        divisible: 'Сумма должна быть кратна {{value}}',
        cardNumber: 'Номер карты',
        walletEos: 'EOS кошелек',
        walletTrx: 'TRC-20 кошелек',
        email: 'Почта',
        emailDescription: 'Укажите свою почту и мы отправим ссылку\nна страницу для отслеживания статуса',
        optional: 'Не обязательно',
        submit: 'Продолжить',
        support: 'Поддержка',
        error: {
            ExchangePairNotPresentError: 'Отсутствует пара для обмена',
            ExchangePairNotFoundError: 'Пара для обмена не найдена',
            CurrencyNotFoundError: 'Валюта не найдена',
            FiatMethodNotFoundError: 'Фиатный метод не найден',
            InvalidTransferParamsError: {
                crypto: 'Указанный вами аккаунт не найден',
                card: 'Номер карты не верен'
            },
            UnderMaintenanceError: 'Сервис на техническом обслуживании'
        },
        amount: {
            enterAmount: 'Введите сумму',
            give: 'Я отдам',
            receive: 'Я получу'
        },
        techWorks: {
            title: 'Проводятся технические работы',
            estimatedTime: 'Предполагаемое время завершения'
        }
    },

    pageStatus: {
        success: 'Ссылка на отслеживание статуса заявки была отправлена на указанную почту',
        deposit: 'Пополнение',
        withdraw: 'Вывод',
        error: {
            status: 'При обработке транзакции произошла ошибка,\nобратитесь в тех. поддержку',
            expired: 'Вы не оплатили выставленный счет. Время\nсуществования ссылки на оплату истекло',
            ExchangeNotFoundError: 'Операция обмена не найдена'
        },
        info: {
            refunded: 'По данной заявке был произведен\nвозврат средств'
        },
        receiverAccount: 'Аккаунт получателя',
        receiverCard: 'Карта получателя',
        willBeReceived: 'Будет получено',
        received: 'Получено',
        paid: 'Оплачено',
        refunded: 'Возврат средств',
        transactionId: 'ID транзакции',
        email: 'Почта',
        emailText: 'Укажите свою почту и мы отправим ссылку на страницу для отслеживания статуса',
        submit: 'Продолжить',
        refresh: 'Новый обмен',
        support: 'Поддержка'
    },

    pageVirtualCard: {
        auth: {
            title: 'Виртуальная карта',
            description:
                'Пополняйте карту в USDCASH и оплачивайте покупки\nонлайн и оффлайн при помощи Apple Pay и Google Pay',
            button: {
                login: 'Войти',
                logout: 'Выйти'
            },
            qr: {
                title: 'Вход по QR',
                description: 'Для входа или регистрации,\n<0>выберите приложение и отсканируйте QR-код</0>',
                appButton: 'Войти через приложение',
                error: {
                    failLoading: 'Не удалось\nзагрузить QR-код'
                },
                reload: 'Обновить'
            },
            app: {
                title: 'Авторизация',
                description: 'Для быстрой и удобной авторизации\nрекомендуем использовать одно из приложений',
                qrButton: 'Войти по QR коду'
            },
            error: {
                deepLinkError: 'Ошибка получения deeplink',
                getTokenError: 'Ошибка получения токена'
            }
        },
        qr: {
            divider: 'или'
        },
        app: {
            description: 'Выберите приложение',
            download: {
                title: 'Нет приложения?',
                link: 'Скачать'
            }
        },
        card: {
            account: 'Аккаунт',
            balance: 'Баланс',
            description: 'Для просмотра реквизитов карты - нажмите на нее',
            name: 'Имя на карте',
            number: 'Номер карты',
            expiration: 'Срок действия',
            error: {
                title: 'Ошибка',
                description: 'При загрузке произошла ошибка'
            }
        },
        history: {
            title: 'История транзакций',
            spend: 'Оплата',
            topUp: 'Пополнение карты'
        },
        amount: {
            title: 'Сумма пополнения',
            give: 'Вы отдаете',
            receive: 'Вы получите',
            rate: 'Курс обмена',
            min: 'Мин.',
            max: 'Макс.',
            summaryGive: 'Итого к оплате',
            summaryReceive: 'Будет получено'
        },
        issue: {
            title: 'Выпуск карты',
            description: 'Для быстрой и удобной оплаты рекомендуем использовать одно из приложений',
            name: {
                label: 'Имя на карте',
                placeholder: 'Введите полное имя',
                helperText: 'Заполните имя в 2 слова на английском языке.\nНапример - John Doe'
            },
            terms: {
                topUp: 'Пополнение карты',
                topUpMin: 'Минимальная сумма пополнения'
            },
            confirm: {
                description: 'Вы собираетесь выпустить виртуальную карту'
            },
            error: {
                CardEmissionDisallowedError: 'Выдача карты запрещена',
                InvalidCardholderError: 'Неверный владелец карты',
                UnderMaintenanceError: 'На тех. обслуживании'
            }
        },
        topUp: {
            title: 'Пополнить карту',
            description: 'Вы собираетесь пополнить виртуальную карту',
            error: {
                CardEmissionDisallowedError: 'Выдача карты запрещена',
                InvalidCardholderError: 'Неверный владелец карты',
                UnderMaintenanceError: 'На тех. обслуживании'
            }
        },
        confirm: {
            give: 'С вас будет списано',
            receive: 'Вы получите на карту'
        },
        payment: {
            summary: 'Итого к оплате',
            expiration: 'Срок действия счета',
            wallet: 'Кошелек',
            memo: 'MEMO',
            value: 'Сумма к оплате',
            attention: 'Правильно введите кошелек, сумму и MEMO.\nВ случае ошибки средства будут утрачены.',
            app: {
                description: 'Оплатить через приложение',
                description_issue: 'Для быстрой и удобной оплаты рекомендуем использовать одно из приложений',
                qrButton: 'По QR коду и реквизитам'
            }
        },
        pending: {
            title: 'Обработка',
            description:
                'Вы оплатили заявку. Вы получите перевод на карту после окончания обработки заявки.' +
                'Обычно это занимает 5-10 минут.',
            deposit: 'Пополнение',
            give: 'C вас было списано',
            receive: 'Будет получено',
            rate: 'Курс обмена',
            transactionId: 'ID транзакции'
        },
        expired: {
            title: 'Заявка не оплачена',
            description:
                'Вы не оплатили заявку на пополнение виртуальной карты. Закройте это окно, и попробуйте еще раз',
            description_issue:
                'Вы не оплатили заявку на выпуск виртуальной карты. Закройте это окно, и попробуйте еще раз'
        },
        status: {
            title: 'Виртуальная карта',
            pending: {
                title: 'Ваша заявка обрабатывается',
                description: 'Ваша заявка в обработке, в ближайшее\nвремя ваша карта будет готова'
            },
            unpaid: {
                title: 'Ожидание оплаты',
                description: 'Вы не оплатили вашу заявку\nна выпуск виртуальной карты'
            },
            fail: {
                title: 'Ошибка',
                description: 'Во время выпуска виртуальной карты\nпроизошла ошибка. Обратитесь в тех. поддержку'
            }
        },
        terms: {
            title: 'Виртуальная\nкарта MasterCard',
            list: [
                {
                    id: 'type',
                    label: 'Тип',
                    value: 'Виртуальная карта международной\nплатежной системы MasterCard'
                },
                {
                    id: 'country',
                    label: 'Страна',
                    value: 'Гонконг'
                },
                {
                    id: 'region',
                    label: 'Где можно оплачивать данной картой',
                    value: 'Во всех странах, кроме Китая'
                },
                {
                    id: 'currency',
                    label: 'Валюта карты',
                    value: '$ • USD'
                },
                {
                    id: 'topUpCurrency',
                    label: 'Валюта пополнения',
                    value: 'USDCASH'
                },
                {
                    id: 'issuance',
                    label: 'Выпуск карты',
                    value: ''
                },
                {
                    id: 'maintenance',
                    label: 'Обслуживание карты',
                    value: 'Бесплатно'
                },
                {
                    id: 'topUpFee',
                    label: 'Пополнение карты',
                    value: ''
                },
                {
                    id: 'crossBorderFee',
                    label: 'Cross-border transaction fee',
                    value: '0'
                },
                {
                    id: 'topUpMin',
                    label: 'Минимальная сумма пополнения',
                    value: ''
                },
                {
                    id: 'withdrawal',
                    label: 'Снятие денежных средств в банкоматах',
                    value: 'Недоступно'
                },
                {
                    id: 'balanceMax',
                    label: 'Максимальный баланс',
                    value: 'Без лимитов'
                },
                {
                    id: 'limits',
                    label: 'Лимиты по количеству и сумме транзакций',
                    value: 'Отсутствуют'
                }
            ]
        },
        button: {
            continue: 'Продолжить',
            done: 'Готово',
            toPay: 'Перейти к оплате',
            terms: 'Тарифы и лимиты',
            topUp: 'Пополнить',
            refresh: 'Обновить',
            support: 'Написать в тех. поддержку'
        }
    }
};
