import React from 'react';

/** Object representing breakpoint values in pixels. */
const BREAKPOINTS = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 1024,
    xl: 1260,
    xxl: 1420
};

type MediaQueries = {
    up: { [key in keyof typeof BREAKPOINTS]: string };
    down: { [key in keyof typeof BREAKPOINTS]: string };
};

/**
 * Creates breakpoints for media queries.
 *
 * @param {typeof BREAKPOINTS} breakpoints - The breakpoint values in pixels.
 *
 * @returns {MediaQueries} The media queries for the provided breakpoints.
 */
function createBreakpoints(breakpoints: typeof BREAKPOINTS): MediaQueries {
    const breakpointKeys = Object.keys(breakpoints) as Array<keyof typeof BREAKPOINTS>;

    const up = breakpointKeys.reduce(
        (acc, key) => {
            acc[key] = `(min-width: ${breakpoints[key]}px)`;

            return acc;
        },
        {} as MediaQueries['up']
    );

    const down = breakpointKeys.reduce(
        (acc, key) => {
            acc[key] = `(max-width: ${breakpoints[key] - 0.02}px)`;

            return acc;
        },
        {} as MediaQueries['down']
    );

    return { up, down };
}

export const breakpoints: MediaQueries = createBreakpoints(BREAKPOINTS);

/**
 * Media query hook. Helps check if the current viewing screen matches the media query.
 *
 * @param {(breakpoints: MediaQueries) => string} queryFunction - A function that takes the breakpoints and returns a query string.
 *
 * @returns {boolean} True if the media query matches, false otherwise.
 *
 * @example
 * const isTabletOrLess = useMediaQuery((breakpoints) => breakpoints.down.lg);
 * const isMobileOrLarge = useMediaQuery((breakpoints) => breakpoints.up.sm);
 *
 * @description
 * - xs: '0'
 * - sm: '576px' (mobile)
 * - md: '768px' (landscape)
 * - lg: '1024px' (tablets)
 * - xl: '1260px' (desktops)
 * - xxl: '1420px' (large desktops)
 */
export default function useMediaQuery(queryFunction: (breakpoints: MediaQueries) => string): boolean {
    const query = queryFunction(breakpoints);
    /**
     * Get matches for a given media query.
     *
     * @param {string} q - The media query string.
     *
     * @returns {boolean} True if the media query matches, false otherwise.
     */
    const getMatches = (q: string): boolean => {
        /** Prevents SSR issues by checking if 'window' is defined. */
        if (typeof window !== 'undefined') {
            return window.matchMedia(q).matches;
        }

        return false;
    };

    const [matches, setMatches] = React.useState<boolean>(getMatches(query));

    const handleChange = React.useCallback(() => setMatches(getMatches(query)), [query]);

    React.useEffect(() => {
        const matchMedia = window.matchMedia(query);
        /** Triggered at the first client-side load and if query changes */
        handleChange();
        /** Listen to 'change' event */
        matchMedia.addEventListener('change', handleChange);
        /** Clean up the event listener on unmount. */
        return () => matchMedia.removeEventListener('change', handleChange);
    }, [handleChange, query]);

    return matches;
}
