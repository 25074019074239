import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import useIsTouchableDevice from 'hooks/useIsTouchableDevice/useIsTouchableDevice';
import Button from 'ui/Button/Button';
import { ClockIcon, WarningFilledIcon } from 'ui/Icons/Icons';
import { useDialog } from 'ui/Dialog/Dialog';
import VirtualCardAccount from '../VirtualCardAccount/VirtualCardAccount';
import VirtualCardBackground from '../VirtualCardBackground/VirtualCardBackground';
import VirtualCardTermsModal from '../VirtualCardTermsModal/VirtualCardTermsModal';
import VirtualCardIssueModal from '../VirtualCardIssueModal/VirtualCardIssueModal';
import { View } from '../VirtualCardManageViews/VirtualCardManageViews.d';
import { Status, StatusProps, VirtualCardStatusProps } from './VirtualCardStatus.d';
import styles from './VirtualCardStatus.module.scss';

const SUPPORT_LINK = 'https://t.me/paycashchat';

function VirtualCardStatusPending(/* { onClick }: StatusProps */) {
    const [t] = useTranslation();
    // const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);

    return (
        <>
            <div className={styles.Status}>
                <ClockIcon className={styles.StatusIcon} />
                {t('pageVirtualCard.status.pending.title')}
            </div>
            <h1 className={styles.Title}>{t('pageVirtualCard.status.title')}</h1>
            <div className={styles.Text}>{t('pageVirtualCard.status.pending.description')}</div>
            {/* <Button fullWidth={isMobileOrLess} onClick={onClick}> */}
            {/*    {t('pageVirtualCard.button.terms')} */}
            {/* </Button> */}
        </>
    );
}

function VirtualCardStatusUnpaid({ onClick }: StatusProps) {
    const [t] = useTranslation();
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);

    return (
        <>
            <div className={cn(styles.Status, styles.StatusWarning)}>
                <ClockIcon className={styles.StatusIcon} />
                {t('pageVirtualCard.status.unpaid.title')}
            </div>
            <h1 className={styles.Title}>{t('pageVirtualCard.status.title')}</h1>
            <div className={styles.Text}>{t('pageVirtualCard.status.unpaid.description')}</div>
            <Button fullWidth={isMobileOrLess} onClick={onClick}>
                {t('pageVirtualCard.button.toPay')}
            </Button>
        </>
    );
}

function VirtualCardStatusError({ onClick }: StatusProps) {
    const [t] = useTranslation();
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);

    return (
        <>
            <div className={cn(styles.Status, styles.StatusError)}>
                <WarningFilledIcon className={styles.StatusIcon} />
                {t('pageVirtualCard.status.fail.title')}
            </div>
            <h1 className={styles.Title}>{t('pageVirtualCard.status.fail.title')}</h1>
            <div className={styles.Text}>{t('pageVirtualCard.status.fail.description')}</div>
            <Button fullWidth={isMobileOrLess} onClick={onClick}>
                {t('pageVirtualCard.button.support')}
            </Button>
        </>
    );
}

export default function VirtualCardStatus({
    action,
    children,
    emissionStatus,
    eosAccount,
    onClose
}: React.PropsWithChildren<VirtualCardStatusProps>) {
    const isTouchableDevice = useIsTouchableDevice();

    const termsModal = useDialog();
    const issueModal = useDialog();

    const { id, status, paymentParams } = action ?? {};
    const { memo, expirationDate, receiver, deeplinks, amount, currency } = paymentParams ?? {};

    // const handleTermsClick = () => termsModal.setOpen(true);

    const handlePayClick = () => {
        issueModal.context.dataRef.current = {
            cardId: id,
            time: expirationDate,
            memo,
            receiver,
            deepLinks: deeplinks,
            amountGive: amount,
            currency
        };

        issueModal.setOpen(true);
    };

    const handleSupportClick = () => {
        window.location.href = SUPPORT_LINK;
    };

    return (
        <>
            <div className={styles.Root}>
                <div className={styles.Account}>{eosAccount && <VirtualCardAccount account={eosAccount} />}</div>
                <VirtualCardBackground classes={{ background: styles.Background }}>
                    <div className={styles.Content}>
                        {children || (
                            <>
                                {(status === Status[Status.PROCESSING] ||
                                    status === Status[Status.MONEY_RECEIVED] ||
                                    status === Status[Status.PAID]) && (
                                    <VirtualCardStatusPending
                                    // onClick={handleTermsClick}
                                    />
                                )}

                                {(status === Status[Status.CREATED] || emissionStatus === 'created') && (
                                    <VirtualCardStatusUnpaid onClick={handlePayClick} />
                                )}

                                {(status === Status[Status.FAILED] || emissionStatus === 'failed') && (
                                    <VirtualCardStatusError onClick={handleSupportClick} />
                                )}
                            </>
                        )}
                    </div>
                </VirtualCardBackground>
            </div>

            <VirtualCardTermsModal {...termsModal} />

            <VirtualCardIssueModal
                view={isTouchableDevice ? View.Method : View.Payment}
                onClose={onClose}
                {...issueModal}
            />
        </>
    );
}
