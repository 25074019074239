import React from 'react';
import cn from 'classnames';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import KalynaIcon from 'assets/images/app-kalyna.svg';
import MalinkaIcon from 'assets/images/app-malinka.svg';
import PaycashIcon from 'assets/images/app-paycash.svg';
import { ReactComponent as BlockIcon } from 'assets/images/block.svg';
import addThousandsSeparator from 'utils/addThousandsSeparator/addThousandsSeparator';
import { numToPrecisionStr } from 'utils/roundNumber/roundNumber';
import { useCopy, useFormatDate } from 'helpers';
import useCountdownTimer from 'hooks/useCountdownTimer/useCountdownTimer';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import { ClockIcon, CopyIcon, QrIcon } from 'ui/Icons/Icons';
import { DialogBody, DialogFooter, DialogHeader } from 'ui/Dialog/Dialog';
import Button from 'ui/Button/Button';
import Skeleton from 'ui/Skeleton/Skeleton';
import Preloader from 'ui/Preloader/Preloader';
import QR from '../QR/QR';
import VirtualCardAmount from '../VirtualCardAmount/VirtualCardAmount';
import {
    ConfirmProps,
    ExpiredProps,
    MethodProps,
    TopUpProps,
    PaymentProps,
    View,
    WalletName
} from './VirtualCardManageViews.d';
import styles from './VirtualCardManageViews.module.scss';

const isTest =
    process.env.NODE_ENV === 'development' ||
    window.location.hostname.includes('dev') ||
    window.location.hostname.includes('test');

const getQrData = (amount: string = '', memo: string = '', receiver: string = '') =>
    JSON.stringify({
        symbol: 'USDCASH',
        address: receiver,
        precision: 5,
        contract: isTest ? 'cashescashes' : 'token.pcash',
        protocol: 'ScanProtocol',
        action: 'transfer',
        memo,
        amount
    });

const formatValue = (value?: string | null) => addThousandsSeparator(numToPrecisionStr(Number(value), 2));

function formatShortString(input?: string | null) {
    if (!input) return '';

    if (input?.length < 13) return input;

    const prefix = input.slice(0, 5);
    const suffix = input.slice(-7);

    return `${prefix} ··· ${suffix}`;
}

const getEosScan = (txId?: string | null) => `https://bloks.io/transaction/${txId}`;

export function Payment({ title, time, amount, currency, memo, receiver, setView, onBackClick }: PaymentProps) {
    const [t] = useTranslation();
    const copy = useCopy();
    const timer = useCountdownTimer(
        0,
        DateTime.fromFormat(`${time}`, 'yyyy-MM-dd HH:mm:ss', { zone: 'utc' }).toMillis()
    );

    const minutes = parseInt(`${timer.timeLeft / 60}`, 10);
    const seconds = parseInt(`${timer.timeLeft % 60}`, 10);

    const onClickCopy = (value: string) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        copy(value.toString(), t('global.copied'), t('errors.globalError'));
    };

    React.useEffect(() => {
        if (timer.timeLeft === 0) setView?.(View.Expired);
    }, [setView, timer.timeLeft]);

    return (
        <>
            <DialogHeader title={title} onBackButtonClick={onBackClick} />
            <DialogBody>
                <div className={styles.Payment}>
                    <div className={styles.PaymentTimerContainer}>
                        <div className={styles.PaymentTimerLabel}>
                            <ClockIcon className={styles.PaymentTimerIcon} />
                            {t('pageVirtualCard.payment.expiration')}
                        </div>
                        <div className={styles.PaymentTimer}>
                            {minutes}:{seconds.toString().padStart(2, '0')}
                        </div>
                    </div>
                    <div className={styles.PaymentQrContainer}>
                        <QR data={getQrData(amount, memo, receiver)} />
                    </div>
                    <div className={styles.PaymentItem}>
                        {receiver && (
                            <div>
                                <div className={styles.PaymentLabel}>{t('pageVirtualCard.payment.wallet')}</div>
                                <div className={styles.PaymentValue}>{receiver}</div>
                            </div>
                        )}
                        <Button
                            type="button"
                            variant="text"
                            size="small"
                            color="gray"
                            isRound
                            onClick={onClickCopy(receiver ?? '')}
                            iconStart={<CopyIcon className={styles.PaymentCopyIcon} />}
                        />
                    </div>
                    <div className={styles.PaymentItem}>
                        {(amount || currency) && (
                            <div>
                                <div className={styles.PaymentLabel}>{t('pageVirtualCard.payment.value')}</div>
                                <div className={styles.PaymentValue}>
                                    {formatValue(amount)} {currency}
                                </div>
                            </div>
                        )}
                        <Button
                            type="button"
                            variant="text"
                            size="small"
                            color="gray"
                            isRound
                            onClick={onClickCopy(`${amount} ${currency}`)}
                            iconStart={<CopyIcon className={styles.PaymentCopyIcon} />}
                        />
                    </div>
                    <div className={styles.PaymentItem}>
                        {memo && (
                            <div>
                                <div className={styles.PaymentLabel}>{t('pageVirtualCard.payment.memo')}</div>
                                <div className={styles.PaymentValue}>{memo}</div>
                            </div>
                        )}
                        <Button
                            type="button"
                            variant="text"
                            size="small"
                            color="gray"
                            isRound
                            onClick={onClickCopy(memo ?? '')}
                            iconStart={<CopyIcon className={styles.PaymentCopyIcon} />}
                        />
                    </div>
                    <div className={styles.PaymentWarning}>{t('pageVirtualCard.payment.attention')}</div>
                </div>
            </DialogBody>
        </>
    );
}

export function Pending({
    give,
    receive,
    rate,
    loading,
    txId,
    onClose
}: {
    give?: string;
    receive?: string;
    rate?: string;
    loading?: boolean;
    txId?: string;
    onClose?: () => void;
}) {
    const [t] = useTranslation();
    const formatDate = useFormatDate();

    return (
        <>
            <DialogHeader />
            <DialogBody>
                <div className={styles.Status}>
                    <div className={styles.StatusBage}>
                        {loading ? (
                            <Skeleton variant="circular" width={80} height={80} style={{ margin: 'auto' }} />
                        ) : (
                            <div
                                className={cn(
                                    styles.StatusIconContainer
                                    // styles[`StatusIconContainer-${status}`]
                                )}
                            >
                                <ClockIcon className={styles.StatusIcon} />
                            </div>
                        )}
                        <h2 className={styles.StatusTitle}>{t('pageVirtualCard.pending.title')}</h2>
                        <div className={styles.StatusText}>
                            {loading ? (
                                <Skeleton variant="text" width={200} style={{ margin: 'auto' }} />
                            ) : (
                                <>
                                    {t('pageVirtualCard.pending.deposit')} ·{' '}
                                    {formatDate('2024-01-19T11:10:37.101050', 'FF')}
                                </>
                            )}
                        </div>
                        {/* {status === ExchangeStatusEnum.Failed && ( */}
                        {/*    <div className={styles.StatusText}>{t('pageStatus.error.status')}</div> */}
                        {/* )} */}
                        {/* {status === ExchangeStatusEnum.Expired && ( */}
                        {/*    <div className={styles.StatusText}>{t('pageStatus.error.expired')}</div> */}
                        {/* )} */}
                        {/* {status === ExchangeStatusEnum.Refunded && ( */}
                        {/*    <div className={styles.StatusText}>{t('pageStatus.info.refunded')}</div> */}
                        {/* )} */}
                    </div>

                    <div className={styles.StatusDescription}>{t('pageVirtualCard.pending.description')}</div>

                    <div className={styles.Info}>
                        <div className={styles.Divider} />
                        {loading ? (
                            <>
                                <div className={styles.InfoItem}>
                                    <div className={styles.InfoLabel}>
                                        <Skeleton variant="text" width={80} />
                                    </div>
                                    <div className={styles.InfoValue}>
                                        <Skeleton variant="text" width={120} />
                                    </div>
                                </div>
                                <div className={styles.InfoItem}>
                                    <div className={styles.InfoLabel}>
                                        <Skeleton variant="text" width={80} />
                                    </div>
                                    <div className={styles.InfoValue}>
                                        <Skeleton variant="text" width={120} />
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={styles.InfoItem}>
                                    <div className={styles.InfoLabel}>{t('pageVirtualCard.pending.give')}</div>
                                    <div className={styles.InfoValue}>{give}</div>
                                </div>
                                <div className={styles.InfoItem}>
                                    <div className={styles.InfoLabel}>{t('pageVirtualCard.pending.rate')}</div>
                                    <div className={styles.InfoValue}>{rate}</div>
                                </div>
                                <div className={styles.InfoItem}>
                                    <div className={styles.InfoLabel}>{t('pageVirtualCard.pending.receive')}</div>
                                    <div className={cn(styles.InfoValue, styles.InfoValueAccent)}>
                                        {receive}
                                        {/* {status === ExchangeStatusEnum.Refunded && refund */}
                                        {/*    ? `${refund.amount} ${getCurrencyName(input?.name)}` */}
                                        {/*    : `${output?.amount} ${getCurrencyName(output?.name)}`} */}
                                    </div>
                                </div>

                                {txId && (
                                    <>
                                        <div className={styles.Divider} />
                                        <div className={styles.InfoItem}>
                                            <div className={styles.InfoLabel}>
                                                <span style={{ marginRight: '0.5rem' }}>
                                                    {t('pageVirtualCard.pending.transactionId')}
                                                </span>
                                                <BlockIcon />
                                            </div>
                                            <a
                                                className={styles.InfoValue}
                                                href={getEosScan(txId)}
                                                title={txId ?? ''}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {formatShortString(txId)}
                                            </a>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </DialogBody>
            <DialogFooter>
                <Button
                    // className={styles.EmailSubmitButton}
                    // color="primary"
                    // type="submit"
                    // disabled={!email.value || loading || sendEmailLoading}
                    // startIcon={<ChevronRightIcon/>}
                    onClick={onClose}
                    fullWidth
                >
                    {t('pageVirtualCard.button.done')}
                </Button>
            </DialogFooter>
        </>
    );
}

export function Expired({ description, onClose }: ExpiredProps) {
    const [t] = useTranslation();

    const buttonRef = React.useRef<HTMLButtonElement | null>(null);

    React.useEffect(() => {
        if (buttonRef.current) buttonRef.current.focus();
    }, []);

    return (
        <>
            <DialogHeader />
            <DialogBody>
                <div className={styles.Status}>
                    <div className={styles.StatusBage}>
                        <div className={cn(styles.StatusIconContainer, styles[`StatusIconContainer-EXPIRED`])}>
                            <ClockIcon className={styles.StatusIcon} />
                        </div>
                        <h2 className={styles.StatusTitle}>{t('pageVirtualCard.expired.title')}</h2>
                    </div>
                    <div className={styles.StatusDescription}>
                        <small>{description || t('pageVirtualCard.expired.description')}</small>
                    </div>
                </div>
            </DialogBody>
            <DialogFooter>
                <Button ref={buttonRef} onClick={onClose} fullWidth>
                    {t('pageVirtualCard.button.done')}
                </Button>
            </DialogFooter>
        </>
    );
}

export function Confirm({
    // setView,
    title,
    description,
    rate,
    valueName,
    give,
    valueIssue,
    receive,
    onSubmit,
    loading
}: ConfirmProps) {
    const [t] = useTranslation();

    const buttonRef = React.useRef<HTMLButtonElement | null>(null);

    React.useEffect(() => {
        if (buttonRef.current) buttonRef.current.focus();
    }, []);

    return (
        <>
            <DialogHeader title={title} />
            <DialogBody>
                {description && <div className={styles.ConfirmDescription}>{description}</div>}

                {valueName && (
                    <div className={styles.ConfirmItem}>
                        <div className={styles.ConfirmLabel}>{t('pageVirtualCard.issue.name.label')}</div>
                        <div className={cn(styles.ConfirmValue, styles.Uppercase)}>{valueName}</div>
                    </div>
                )}

                {give && (
                    <div className={styles.ConfirmItem}>
                        <div className={styles.ConfirmLabel}>{t('pageVirtualCard.confirm.give')}</div>
                        <div className={styles.ConfirmValue}>{give}</div>
                    </div>
                )}

                {rate && (
                    <div className={styles.ConfirmItem}>
                        <div className={styles.ConfirmLabel}>{t('pageVirtualCard.amount.rate')}</div>
                        <div className={styles.ConfirmValue}>{rate}</div>
                    </div>
                )}

                {valueIssue && (
                    <div className={styles.ConfirmItem}>
                        <div className={styles.ConfirmLabel}>{t('pageVirtualCard.issue.title')}</div>
                        <div className={styles.ConfirmValue}>{valueIssue}</div>
                    </div>
                )}

                {receive && (
                    <div className={styles.ConfirmItem}>
                        <div className={styles.ConfirmLabel}>{t('pageVirtualCard.confirm.receive')}</div>
                        <div className={styles.ConfirmValue}>{receive}</div>
                    </div>
                )}
            </DialogBody>
            <DialogFooter>
                <Button ref={buttonRef} fullWidth onClick={onSubmit} loading={loading}>
                    {t('pageVirtualCard.button.toPay')}
                </Button>
            </DialogFooter>
        </>
    );
}

export function TopUp({
    isAmountError,
    amountGive,
    onAmountGiveChange,
    amountReceive,
    onAmountReceiveChange,
    issuerRate,
    amountMin,
    amountMax,
    onSubmit
}: TopUpProps) {
    const [t] = useTranslation();
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);

    return (
        <>
            <DialogHeader title={t('pageVirtualCard.topUp.title')} />
            <DialogBody>
                <div className={styles.Amount}>
                    {isMobileOrLess && <div className={styles.AmountLabel}>{t('pageVirtualCard.amount.title')}</div>}
                    <VirtualCardAmount
                        isError={isAmountError}
                        amountGiveValue={amountGive}
                        onAmountGiveChange={onAmountGiveChange}
                        amountReceiveValue={amountReceive}
                        onAmountReceiveChange={onAmountReceiveChange}
                    />
                </div>
                <div className={styles.Info}>
                    <div className={styles.InfoItem}>
                        <div className={styles.InfoLabel}>{t('pageVirtualCard.amount.rate')}</div>
                        <div className={styles.InfoValue}>{`1 USDCASH = ${formatValue(issuerRate)} USD`}</div>
                    </div>
                    <div className={cn(styles.InfoItem, isAmountError && styles.InfoItemError)}>
                        <div className={styles.InfoLabel}>
                            {t('pageVirtualCard.amount.min')} - {t('pageVirtualCard.amount.max')}
                        </div>
                        <div className={styles.InfoValue}>
                            {`${formatValue(amountMin)} - ${formatValue(amountMax)} USD`}
                        </div>
                    </div>
                    <div className={styles.Divider} />
                    <div className={styles.InfoItem}>
                        <div className={styles.InfoLabel}>{t('pageVirtualCard.amount.summaryGive')}</div>
                        <div className={cn(styles.InfoValue, styles.InfoValueAccent)}>
                            {Number(amountGive) > 0 ? formatValue(amountGive) : '-'} USDCASH
                        </div>
                    </div>
                    <div className={styles.InfoItem}>
                        <div className={styles.InfoLabel}>{t('pageVirtualCard.amount.summaryReceive')}</div>
                        <div className={cn(styles.InfoValue, styles.InfoValueAccent)}>
                            {Number(amountReceive) > 0 ? formatValue(amountReceive) : '-'} USD
                        </div>
                    </div>
                </div>
            </DialogBody>
            <DialogFooter>
                <Button fullWidth onClick={onSubmit} disabled={!amountGive || !amountReceive || isAmountError}>
                    {t('pageVirtualCard.button.continue')}
                </Button>
            </DialogFooter>
        </>
    );
}

export function Method({ loading, title, description, bodyContent, onClick, setView }: MethodProps) {
    const [t] = useTranslation();

    const buttonRef = React.useRef<HTMLButtonElement | null>(null);

    const handleQrButtonClick = () => {
        setView?.(View.Payment);
    };

    React.useEffect(() => {
        if (buttonRef.current) buttonRef.current.focus();
    }, []);

    return (
        <>
            <DialogHeader title={title} />
            {bodyContent && <DialogBody>{bodyContent}</DialogBody>}
            <DialogFooter>
                {/* {error && <div className={styles.Error}>{`Ошибка. ${error}`}</div>} */}
                {loading ? (
                    <Preloader classes={{ root: styles.Preloader }} />
                ) : (
                    <>
                        {description && <div className={styles.AppDescription}>{description}</div>}
                        <Button
                            type="button"
                            classes={{ root: styles.AppButton }}
                            color="gray"
                            size="large"
                            iconStart={<img src={PaycashIcon} alt="PayCash" />}
                            onClick={() => onClick?.(WalletName.Paycash)}
                            fullWidth
                        >
                            Paycash
                        </Button>
                        <Button
                            type="button"
                            classes={{ root: styles.AppButton }}
                            color="gray"
                            size="large"
                            iconStart={<img src={KalynaIcon} alt="Kalyna" />}
                            onClick={() => onClick?.(WalletName.Kalyna)}
                            fullWidth
                        >
                            Kalyna
                        </Button>
                        <Button
                            type="button"
                            classes={{ root: styles.AppButton }}
                            color="gray"
                            size="large"
                            iconStart={<img src={MalinkaIcon} alt="Malinka" />}
                            onClick={() => onClick?.(WalletName.Malinka)}
                            fullWidth
                        >
                            Malinka
                        </Button>
                    </>
                )}
                <div className={styles.AppDivider}>
                    <div className={styles.AppDividerText}>{t('pageVirtualCard.qr.divider')}</div>
                </div>
                <Button
                    classes={{ root: styles.GroupButtonItem }}
                    color="gray"
                    size="large"
                    iconStart={<QrIcon />}
                    onClick={handleQrButtonClick}
                    fullWidth
                >
                    {t('pageVirtualCard.payment.app.qrButton')}
                </Button>
            </DialogFooter>
        </>
    );
}
