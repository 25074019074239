import React from 'react';
import { SOCIALS } from 'constant';
import {
    FacebookIcon,
    InstagramIcon,
    TelegramChatIcon,
    TelegramIcon,
    TwitterFilledIcon,
    YoutubeIcon
} from 'ui/Icons/Icons';

export default function Socials({ className }: { className?: string }) {
    return (
        <ul className={className}>
            <li>
                <a href={SOCIALS.telegramChat} target="_blank" rel="noreferrer">
                    <TelegramChatIcon />
                </a>
            </li>
            <li>
                <a href={SOCIALS.telegram} target="_blank" rel="noreferrer">
                    <TelegramIcon />
                </a>
            </li>
            <li>
                <a href={SOCIALS.youtube} target="_blank" rel="noreferrer">
                    <YoutubeIcon />
                </a>
            </li>
            <li>
                <a href={SOCIALS.twitter} target="_blank" rel="noreferrer">
                    <TwitterFilledIcon />
                </a>
            </li>
            <li>
                <a href={SOCIALS.instagram} target="_blank" rel="noreferrer">
                    <InstagramIcon />
                </a>
            </li>
            <li>
                <a href={SOCIALS.fb} target="_blank" rel="noreferrer">
                    <FacebookIcon />
                </a>
            </li>
        </ul>
    );
}
