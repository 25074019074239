import React, { useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import styles from './HomeScrollBar.module.scss';

const cx = classNames.bind(styles);

type Props = {
    className?: string;
    targetRef: React.RefObject<HTMLElement>;
};

function ScrollBar({ targetRef, className = '' }: Props) {
    const progressRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        if (!targetRef.current || !progressRef.current) return;

        const updateProgressBar = () => {
            const scrollWidth = targetRef.current?.scrollWidth ?? 0;
            const scrollLeft = targetRef.current?.scrollLeft ?? 0;
            const width = targetRef.current?.offsetWidth ?? 0;
            const C = width / scrollWidth;

            progressRef.current!.parentElement!.style.opacity = C >= 1 ? '0' : '1';

            progressRef.current!.style.width = `${(progressRef.current?.parentElement?.offsetWidth ?? 0) * C}px`;
            progressRef.current!.style.marginLeft = `${scrollLeft * C}px`;
        };

        // eslint-disable-next-line no-param-reassign
        targetRef.current.onscroll = updateProgressBar;
        updateProgressBar();

        // eslint-disable-next-line consistent-return
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps,no-param-reassign
            if (targetRef.current) targetRef.current.onscroll = null;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={cx('Wrapper', className)}>
            <div className={cx('Bar')}>
                <span ref={progressRef} />
            </div>
        </div>
    );
}

export default ScrollBar;
