import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAccountDepositStatisticQuery } from 'apollo/main/generated';
import MalinkaIcon from 'assets/images/tokens/mlnk.svg';
import addThousandsSeparator from 'utils/addThousandsSeparator/addThousandsSeparator';
import { useModal } from 'helpers';
import getTokenLogo from 'helpers/getTokenLogo/getTokenLogo';
import Button from 'ui/Button_DEPRECATED/Button';
import { PairsIcon, CrossIcon } from 'ui/Icons/Icons';
import CashDeposits from '../CashDeposits/CashDeposits';
import Card, { CardError, CardLoader } from '../../Card/Card';
import FormatNumber from '../../FormatNumber/FormatNumber';
import styles from './CashStatistics.module.scss';

export default function CashStatistics({ account }: { account: string }) {
    const cashDeposits = useModal();
    const [t] = useTranslation();

    const { data, loading, error, refetch } = useAccountDepositStatisticQuery({
        variables: { name: account },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only'
    });

    const depositStatistic = data?.eosAccount?.depositStatistic;

    return (
        <Card title="" className={styles.Root} mainClassName={styles.Component}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {error ? (
                <CardError resetError={refetch} disabled={loading} />
            ) : loading ? (
                <CardLoader />
            ) : (
                <>
                    <div className={styles.Row}>
                        {depositStatistic?.map((item) => {
                            const { mlnkIncome24h, depositedToken, depositedMlnk, issuedCash, tokenIn, tokenOut } =
                                item ?? {};

                            return (
                                <div className={styles.Column} key={tokenOut?.id}>
                                    <div className={styles.Card}>
                                        <div className={styles.Issued}>
                                            <img
                                                className={styles.IssuedIcon}
                                                src={getTokenLogo(
                                                    tokenOut?.logo,
                                                    tokenOut?.code,
                                                    tokenOut?.smartContract
                                                )}
                                                alt="Token"
                                            />
                                            <div className={styles.Info}>
                                                <div className={styles.Text}>
                                                    {t('pageAccount.cashStatistics.issued')} {tokenOut?.code}
                                                </div>
                                                <FormatNumber
                                                    className={styles.Subtext}
                                                    value={issuedCash}
                                                    precision={5}
                                                    floatZerosOffset={0}
                                                    compress
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.Backing}>
                                            <div className={cn(styles.Text, styles.BackingText)}>
                                                {t('pageAccount.cashStatistics.backing')}
                                            </div>
                                            <div className={styles.BackingInfo}>
                                                <div className={styles.BackingInfoItem}>
                                                    <img className={styles.BackingIcon} src={MalinkaIcon} alt="MLNK" />
                                                    <FormatNumber
                                                        className={cn(styles.Subtext, styles.SubtextSmall)}
                                                        value={depositedMlnk}
                                                        precision={2}
                                                        floatZerosOffset={0}
                                                        compress
                                                    />
                                                </div>
                                                {/* eslint-disable-next-line react/jsx-pascal-case */}
                                                <CrossIcon className={styles.BackingInfoItemDivider} />
                                                <div className={styles.BackingInfoItem}>
                                                    <img
                                                        className={styles.BackingIcon}
                                                        src={getTokenLogo(
                                                            tokenIn?.logo,
                                                            tokenIn?.code,
                                                            tokenIn?.smartContract
                                                        )}
                                                        alt="Token"
                                                    />
                                                    <FormatNumber
                                                        className={cn(styles.Subtext, styles.SubtextSmall)}
                                                        value={depositedToken}
                                                        precision={4}
                                                        floatZerosOffset={0}
                                                        compress
                                                    />
                                                </div>
                                            </div>
                                            <div className={styles.BackingDivider} />
                                            <div className={styles.BackingLinkWrapper}>
                                                <Button
                                                    color="link"
                                                    className={styles.BackingLink}
                                                    onClick={cashDeposits.open}
                                                >
                                                    {t('pageAccount.cashStatistics.btnDeposits')}
                                                </Button>
                                            </div>
                                        </div>
                                        <div className={styles.Yield}>
                                            <div className={styles.YieldIconWrapper}>
                                                <PairsIcon className={styles.YieldIcon} />
                                            </div>
                                            <div className={styles.Info}>
                                                <div className={styles.Text}>
                                                    {t('pageAccount.cashStatistics.yield')}
                                                </div>
                                                <div className={cn(styles.Subtext, styles.YieldSubtext)}>
                                                    {addThousandsSeparator(mlnkIncome24h)} MLNK
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className={styles.BtnDepositsWrapper}>
                        <CashDeposits {...cashDeposits} account={account} />
                    </div>
                </>
            )}
        </Card>
    );
}
