type Mode = 'round' | 'floor' | 'ceil' | 'trunc';

/**
 * Calculates the rounded value based on the number and digits.
 *
 * @param {number} num - The number to round.
 * @param {number} digits - The number of decimal places to round to.
 *
 * @returns {number} The rounded value.
 */
function calculateRoundedValue(num: number, digits: number): number {
    return num > 0 ? (num + Number.EPSILON) * 10 ** Math.abs(digits) : (num - Number.EPSILON) * 10 ** Math.abs(digits);
}

/**
 * Applies floor rounding to a value.
 *
 * @param {number} value - The value to round.
 * @param {number} denominator - The denominator for rounding.
 *
 * @returns {number} The rounded value.
 */
function applyFloorRounding(value: number, denominator: number): number {
    return Math.floor(value) / denominator;
}

/**
 * Applies ceil rounding to a value.
 *
 * @param {number} value - The value to round.
 * @param {number} denominator - The denominator for rounding.
 *
 * @returns {number} The rounded value.
 */
function applyCeilRounding(value: number, denominator: number): number {
    return Math.ceil(value) / denominator;
}

/**
 * Applies truncate rounding to a value.
 *
 * @param {number} value - The value to round.
 * @param {number} denominator - The denominator for rounding.
 *
 * @returns {number} The rounded value.
 */
function applyTruncateRounding(value: number, denominator: number): number {
    return Math.trunc(value) / denominator;
}

/**
 * Applies round rounding to a value.
 *
 * @param {number} value - The value to round.
 * @param {number} denominator - The denominator for rounding.
 *
 * @returns {number} The rounded value.
 */
function applyRoundRounding(value: number, denominator: number): number {
    return Math.round(value) / denominator;
}

/**
 * Rounds a number to a specified number of decimal places using various rounding modes.
 *
 * @param {number} num - The number to round.
 * @param {number} digits - The number of decimal places to round to. By default, `0`.
 * @param {Mode} mode - The rounding mode: 'round', 'floor', 'ceil', or 'trunc'. By default, 'round'.
 *
 * @returns {number} The rounded number.
 */
export default function roundNumber(num: number, digits: number = 0, mode: Mode = 'round'): number {
    const value = calculateRoundedValue(num, digits);
    const denominator = 10 ** Math.abs(digits);

    switch (mode) {
        case 'floor':
            return applyFloorRounding(value, denominator);
        case 'ceil':
            return applyCeilRounding(value, denominator);
        case 'trunc':
            return applyTruncateRounding(value, denominator);
        default:
            return applyRoundRounding(value, denominator);
    }
}

/**
 * Converts a number to a string with a specified number of decimal places using various rounding modes.
 *
 * @param {number} num - The number to convert.
 * @param {number} digits - The number of decimal places to round to. By default, `0`.
 * @param {boolean} zeroPadding - Whether to zero-pad the resulting string. Defaults to `false`.
 * @param {Mode} mode - The rounding mode: 'round', 'floor', 'ceil', or 'trunc'. By default, 'round'.
 *
 * @returns {string} The string representation of the rounded number.
 */
export const numToPrecisionStr = (
    num?: number,
    digits: number = 0,
    zeroPadding: boolean = false,
    mode?: Mode
): string => {
    if (num === undefined) return '';

    const roundedValue = roundNumber(num, digits, mode);

    return zeroPadding ? roundedValue.toFixed(digits) : String(roundedValue);
};
