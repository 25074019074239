import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    DateTime: any;
    Decimal: any;
};

export type AccountDepositNode = {
    __typename: 'AccountDepositNode';
    creationTimestamp: Scalars['DateTime'];
    depositedMlnk: Scalars['String'];
    depositedToken: Scalars['String'];
    issuedCash: Scalars['String'];
    smartTableRowId: Scalars['Int'];
    tokenIn: TokenNode;
    tokenOut: TokenNode;
};

export type AccountDepositStatisticNode = {
    __typename: 'AccountDepositStatisticNode';
    depositedMlnk: Scalars['String'];
    depositedToken: Scalars['String'];
    issuedCash: Scalars['String'];
    mlnkIncome24h: Scalars['String'];
    tokenIn: TokenNode;
    tokenOut: TokenNode;
};

export type AccountMlnkProfitChartNode = {
    __typename: 'AccountMlnkProfitChartNode';
    points: Array<Maybe<ChartPointNode>>;
    resolution: ChartResolutionEnum;
    totalMlnk: Scalars['String'];
    totalUsd: Scalars['String'];
    value24hMlnk: Scalars['String'];
    value24hUsd: Scalars['String'];
};

export type AccountNode = {
    __typename: 'AccountNode';
    actions?: Maybe<ActionsList>;
    depositStatistic?: Maybe<Array<Maybe<AccountDepositStatisticNode>>>;
    deposits: Array<Maybe<AccountDepositNode>>;
    hasStatistic: Scalars['Boolean'];
    holdedPool?: Maybe<HoldedPoolNode>;
    holdedPools?: Maybe<Array<Maybe<HoldedPoolNode>>>;
    holdedTokens?: Maybe<Array<Maybe<HoldedTokenNode>>>;
    isFavorite: Scalars['Boolean'];
    mlnkBalance: Scalars['String'];
    mlnkCollectParams?: Maybe<MlnkCollectParams>;
    mlnkNotOptimizedBalance: Scalars['String'];
    mlnkOptimizedBalance: Scalars['String'];
    name: Scalars['String'];
    totalUsdInPortfolio?: Maybe<Scalars['String']>;
    zeroriskStatistic?: Maybe<Array<Maybe<AccountZeroriskStatisticNode>>>;
    zerorisks: Array<Maybe<AccountZeroriskNode>>;
};

export type AccountNodeActionsArgs = {
    filters?: InputMaybe<ActionFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
    sort?: InputMaybe<Array<InputMaybe<ActionSortEnum>>>;
};

export type AccountNodeHoldedPoolArgs = {
    poolCode: Scalars['String'];
};

export type AccountProfitChartNode = {
    __typename: 'AccountProfitChartNode';
    percentageChange24h: Scalars['String'];
    points: Array<Maybe<ChartPointNode>>;
    resolution: ChartResolutionEnum;
    totalProfit: Scalars['String'];
    value24h: Scalars['String'];
};

export type AccountZeroriskNode = {
    __typename: 'AccountZeroriskNode';
    amount: Scalars['String'];
    creationTimestamp: Scalars['DateTime'];
    smartTableRowId: Scalars['Int'];
    token: TokenNode;
};

export type AccountZeroriskStatisticNode = {
    __typename: 'AccountZeroriskStatisticNode';
    amount: Scalars['String'];
    mlnkIncome24h: Scalars['String'];
    token: TokenNode;
};

export type AccountsList = {
    __typename: 'AccountsList';
    accounts: Array<Maybe<AccountNode>>;
    total: Scalars['Int'];
};

export type ActionFilterInput = {
    cashTokenId?: InputMaybe<Scalars['Int']>;
    eosAccount?: InputMaybe<Scalars['String']>;
    poolCode?: InputMaybe<Scalars['String']>;
    tokenId?: InputMaybe<Scalars['Int']>;
    txnType?: InputMaybe<Array<InputMaybe<TxnType>>>;
};

export type ActionNode = {
    __typename: 'ActionNode';
    account1: Scalars['String'];
    account2?: Maybe<Scalars['String']>;
    cashToken?: Maybe<TokenNode>;
    cashTokenAmount?: Maybe<Scalars['String']>;
    executionPercent?: Maybe<Scalars['Float']>;
    id: Scalars['String'];
    orderId?: Maybe<Scalars['Int']>;
    timestamp: Scalars['DateTime'];
    token1: TokenNode;
    token1Amount: Scalars['String'];
    token1Remains?: Maybe<Scalars['String']>;
    token2?: Maybe<TokenNode>;
    token2Amount?: Maybe<Scalars['String']>;
    token2Remains?: Maybe<Scalars['String']>;
    txid: Scalars['String'];
    txnType?: Maybe<TxnType>;
};

export enum ActionSortEnum {
    EosAccount = 'EOS_ACCOUNT',
    EosAccountDesc = 'EOS_ACCOUNT_DESC',
    Timestamp = 'TIMESTAMP',
    TimestampDesc = 'TIMESTAMP_DESC'
}

export type ActionsList = {
    __typename: 'ActionsList';
    actions: Array<Maybe<ActionNode>>;
    pageInfo: PageInfo;
};

export type CalculateCardEmissionInput = {
    amount: Scalars['String'];
    cardCurrency?: Scalars['String'];
    paymentCurrency?: Scalars['String'];
};

export type CalculateCardEmissionOutput = CardActionCalculationNode;

export type CalculateCardReplenishmentOutput = CardActionCalculationNode | CardIsInactiveError | CardNotFoundError;

export type CalculationOutput =
    | CurrencyNotFoundError
    | ExchangeAmountTooHighError
    | ExchangeAmountTooLowError
    | ExchangeCalcNode
    | ExchangePairNotFoundError
    | ExchangePairNotPresentError
    | FiatMethodNotFoundError
    | InvalidTransferParamsError
    | UnderMaintenanceError;

export type CardAccountNode = {
    __typename: 'CardAccountNode';
    action?: Maybe<CardActionNode>;
    activeCard?: Maybe<CardNode>;
    emissionStatus?: Maybe<Scalars['String']>;
    eosAccount: Scalars['String'];
};

export type CardActionCalculationNode = {
    __typename: 'CardActionCalculationNode';
    amountToPay: Scalars['String'];
    amountToReceive: Scalars['String'];
    paymentCurrency: Scalars['String'];
    receiveCurrency: Scalars['String'];
};

export type CardActionNode = {
    __typename: 'CardActionNode';
    amountToReceive: Scalars['String'];
    createdAt: Scalars['DateTime'];
    currencyToReceive: Scalars['String'];
    eosAccount: Scalars['String'];
    id: Scalars['String'];
    paymentParams?: Maybe<CardPaymentParamsNode>;
    status: Scalars['String'];
    txid?: Maybe<Scalars['String']>;
    type: Scalars['String'];
};

export type CardBalanceNode = {
    __typename: 'CardBalanceNode';
    available: Scalars['String'];
    frozen: Scalars['String'];
    pending: Scalars['String'];
};

export type CardEmissionDisallowedError = {
    __typename: 'CardEmissionDisallowedError';
    errorMessage: Scalars['String'];
};

export type CardFeeEntryNode = {
    __typename: 'CardFeeEntryNode';
    fixedFee: Scalars['String'];
    issuerFixedFee: Scalars['String'];
    issuerRate: Scalars['String'];
    maximalAmount?: Maybe<Scalars['String']>;
    minimalAmount: Scalars['String'];
    rate: Scalars['String'];
};

export type CardFeesNode = {
    __typename: 'CardFeesNode';
    emission: CardFeeEntryNode;
    replenishment: CardFeeEntryNode;
};

export type CardHistoryNode = {
    __typename: 'CardHistoryNode';
    pageTotal: Scalars['Int'];
    total: Scalars['Int'];
    transactions?: Maybe<Array<Maybe<CardTransactionNode>>>;
};

export type CardIsInactiveError = {
    __typename: 'CardIsInactiveError';
    errorMessage: Scalars['String'];
};

export type CardNode = {
    __typename: 'CardNode';
    balance?: Maybe<CardBalanceNode>;
    cardholderName: Scalars['String'];
    currency: Scalars['String'];
    emittedAt: Scalars['DateTime'];
    eosAccount: Scalars['String'];
    id: Scalars['String'];
    privateInfo?: Maybe<CardPrivateInfoNode>;
    status: Scalars['String'];
};

export type CardNotFoundError = {
    __typename: 'CardNotFoundError';
    errorMessage: Scalars['String'];
};

export type CardPaymentParamsNode = {
    __typename: 'CardPaymentParamsNode';
    amount: Scalars['String'];
    currency: Scalars['String'];
    deeplinks?: Maybe<Array<Maybe<DeeplinkNode>>>;
    expirationDate: Scalars['String'];
    memo: Scalars['String'];
    receiver: Scalars['String'];
};

export type CardPrivateInfoNode = {
    __typename: 'CardPrivateInfoNode';
    cardNumber: Scalars['String'];
    cvv: Scalars['String'];
    expirationDate: Scalars['String'];
    pinCode: Scalars['String'];
};

export type CardTransactionNode = {
    __typename: 'CardTransactionNode';
    amount: Scalars['String'];
    completedAt: Scalars['DateTime'];
    createdAt: Scalars['DateTime'];
    currency: Scalars['String'];
    detail: Scalars['String'];
    fee: Scalars['String'];
    id: Scalars['String'];
    status: Scalars['String'];
    type: Scalars['String'];
};

export type ChartColumnNode = {
    __typename: 'ChartColumnNode';
    pageInfo: PageTimestampInfo;
    percentageChange24h: Scalars['String'];
    percentageChangeMonth: Scalars['String'];
    percentageChangeWeek: Scalars['String'];
    points: Array<Maybe<ChartPointColumnNode>>;
    value24h: Scalars['String'];
};

export type ChartNode = {
    __typename: 'ChartNode';
    percentageChange24h: Scalars['String'];
    points: Array<Maybe<ChartPointNode>>;
    resolution: ChartResolutionEnum;
    value24h: Scalars['String'];
};

export type ChartPointColumnNode = {
    __typename: 'ChartPointColumnNode';
    close: Scalars['Decimal'];
    high: Scalars['Decimal'];
    low: Scalars['Decimal'];
    open: Scalars['Decimal'];
    timestamp: Scalars['DateTime'];
};

export type ChartPointNode = {
    __typename: 'ChartPointNode';
    timestamp: Scalars['DateTime'];
    value: Scalars['Decimal'];
};

export enum ChartResolutionEnum {
    Day = 'DAY',
    FiveMinutes = 'FIVE_MINUTES',
    Hour = 'HOUR'
}

export type CurrencyNode = {
    __typename: 'CurrencyNode';
    blockchain: Scalars['String'];
    feeFix?: Maybe<Scalars['String']>;
    feePercentage?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    minTransferAmount?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    precision?: Maybe<Scalars['String']>;
    withdrawFeeFix?: Maybe<Scalars['String']>;
    withdrawFeePercentage?: Maybe<Scalars['String']>;
};

export type CurrencyNotFoundError = {
    __typename: 'CurrencyNotFoundError';
    errorMessage: Scalars['String'];
};

export type DeeplinkNode = {
    __typename: 'DeeplinkNode';
    link?: Maybe<Scalars['String']>;
    name: Scalars['String'];
};

export type EmitCardInput = {
    amount: Scalars['String'];
    cardCurrency?: Scalars['String'];
    cardholderName: Scalars['String'];
    paymentCurrency?: Scalars['String'];
    phoneCode?: InputMaybe<Scalars['String']>;
    phoneNumber?: InputMaybe<Scalars['String']>;
};

export type EmitCardOutput =
    | CardActionNode
    | CardEmissionDisallowedError
    | InvalidCardholderError
    | UnderMaintenanceError;

export type EosAccountNotFoundError = {
    __typename: 'EosAccountNotFoundError';
    errorMessage: Scalars['String'];
};

export type ExchangeAmountNode = {
    __typename: 'ExchangeAmountNode';
    amount: Scalars['String'];
    methodId: Scalars['String'];
    name: Scalars['String'];
    txid?: Maybe<Scalars['String']>;
};

export type ExchangeAmountTooHighError = {
    __typename: 'ExchangeAmountTooHighError';
    errorMessage: Scalars['String'];
};

export type ExchangeAmountTooLowError = {
    __typename: 'ExchangeAmountTooLowError';
    errorMessage: Scalars['String'];
};

export type ExchangeCalcInput = {
    exchangePair: Scalars['String'];
    inputAmount?: InputMaybe<Scalars['String']>;
    outputAmount?: InputMaybe<Scalars['String']>;
};

export type ExchangeCalcNode = {
    __typename: 'ExchangeCalcNode';
    amountToPay?: Maybe<Scalars['String']>;
    amountToReceive?: Maybe<Scalars['String']>;
    exchangeRate?: Maybe<Scalars['String']>;
    inputFee?: Maybe<Scalars['String']>;
    orderAmount?: Maybe<Scalars['String']>;
    outputFee?: Maybe<Scalars['String']>;
};

export enum ExchangeDirectionEnum {
    Deposit = 'DEPOSIT',
    Withdraw = 'WITHDRAW'
}

export type ExchangeFilterInput = {
    createdAfter?: InputMaybe<Scalars['String']>;
    createdBefore?: InputMaybe<Scalars['String']>;
    direction?: InputMaybe<ExchangeDirectionEnum>;
    eosAccount?: InputMaybe<Scalars['String']>;
    exchangePairId?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['String']>;
    inputCurrencyName?: InputMaybe<Scalars['String']>;
    inputMethodId?: InputMaybe<Scalars['String']>;
    outputCurrencyName?: InputMaybe<Scalars['String']>;
    outputMethodId?: InputMaybe<Scalars['String']>;
    pc4storeOrderId?: InputMaybe<Scalars['String']>;
    pc4storeStatus?: InputMaybe<Scalars['String']>;
    pc4storeTransferId?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<ExchangeStatusEnum>;
    type?: InputMaybe<ExchangeTypeEnum>;
};

export type ExchangeMethodNode = CurrencyNode | FiatMethodNode;

export type ExchangeNode = {
    __typename: 'ExchangeNode';
    created?: Maybe<Scalars['DateTime']>;
    exchangePairId?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    input: ExchangeAmountNode;
    orderId?: Maybe<Scalars['String']>;
    output: ExchangeAmountNode;
    paymentLink?: Maybe<Scalars['String']>;
    paymentParams?: Maybe<PaymentParamsNode>;
    receiver?: Maybe<Scalars['String']>;
    refund?: Maybe<RefundNode>;
    sender?: Maybe<Scalars['String']>;
    status: ExchangeStatusEnum;
    type?: Maybe<ExchangeTypeEnum>;
};

export type ExchangeNotFoundError = {
    __typename: 'ExchangeNotFoundError';
    errorMessage: Scalars['String'];
};

export type ExchangePairFilterInput = {
    direction?: InputMaybe<ExchangeDirectionEnum>;
    id?: InputMaybe<Scalars['String']>;
    inputCurrencyName?: InputMaybe<Scalars['String']>;
    inputMethodId?: InputMaybe<Scalars['String']>;
    outputCurrencyName?: InputMaybe<Scalars['String']>;
    outputMethodId?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<ExchangeTypeEnum>;
};

export type ExchangePairNode = {
    __typename: 'ExchangePairNode';
    exchangeRate?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    inputCurrencyName: Scalars['String'];
    inputFeeFix?: Maybe<Scalars['String']>;
    inputFeePercentage?: Maybe<Scalars['String']>;
    inputMethod?: Maybe<ExchangeMethodNode>;
    inputMethodId?: Maybe<Scalars['String']>;
    inputPrecision?: Maybe<Scalars['Int']>;
    isAvailable?: Maybe<Scalars['Boolean']>;
    isEnabled?: Maybe<Scalars['Boolean']>;
    maximalAmount?: Maybe<Scalars['String']>;
    minimalAmount?: Maybe<Scalars['String']>;
    outputCurrencyName: Scalars['String'];
    outputFeeFix?: Maybe<Scalars['String']>;
    outputFeePercentage?: Maybe<Scalars['String']>;
    outputMethod?: Maybe<ExchangeMethodNode>;
    outputMethodId?: Maybe<Scalars['String']>;
    outputPrecision?: Maybe<Scalars['Int']>;
    type: ExchangeTypeEnum;
};

export type ExchangePairNotFoundError = {
    __typename: 'ExchangePairNotFoundError';
    errorMessage: Scalars['String'];
};

export type ExchangePairNotPresentError = {
    __typename: 'ExchangePairNotPresentError';
    errorMessage: Scalars['String'];
};

export enum ExchangeStatusEnum {
    Cancelled = 'CANCELLED',
    Created = 'CREATED',
    Expired = 'EXPIRED',
    Failed = 'FAILED',
    Frozen = 'FROZEN',
    MoneyReceived = 'MONEY_RECEIVED',
    Paid = 'PAID',
    Refunded = 'REFUNDED',
    Sending = 'SENDING',
    Sent = 'SENT',
    Success = 'SUCCESS'
}

export type ExchangeSystemStatus = {
    __typename: 'ExchangeSystemStatus';
    maintenance: MaintenanceStatus;
};

export enum ExchangeTypeEnum {
    CryptoCrypto = 'CRYPTO_CRYPTO',
    CryptoFiat = 'CRYPTO_FIAT',
    FiatCrypto = 'FIAT_CRYPTO'
}

export type FiatMethodNode = {
    __typename: 'FiatMethodNode';
    currencyName?: Maybe<Scalars['String']>;
    direction: Scalars['String'];
    feeFix?: Maybe<Scalars['String']>;
    feePercentage?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    location: Scalars['String'];
    maximalAmount?: Maybe<Scalars['String']>;
    minimalAmount?: Maybe<Scalars['String']>;
    minimalCoin?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
};

export type FiatMethodNotFoundError = {
    __typename: 'FiatMethodNotFoundError';
    errorMessage: Scalars['String'];
};

export type HoldedPoolNode = {
    __typename: 'HoldedPoolNode';
    code: Scalars['String'];
    myLiquidityTokens: Scalars['String'];
    poolId: Scalars['Int'];
    shareInPool: Scalars['String'];
    shareInPortfolio: Scalars['String'];
    token1: TokenNode;
    token1Amount: Scalars['String'];
    token1UsdAmount: Scalars['String'];
    token2: TokenNode;
    token2Amount: Scalars['String'];
    token2UsdAmount: Scalars['String'];
    totalLiquidityTokens: Scalars['String'];
    usdEquivalent: Scalars['String'];
};

export type HoldedTokenNode = {
    __typename: 'HoldedTokenNode';
    amount?: Maybe<Scalars['String']>;
    code: Scalars['String'];
    id: Scalars['Int'];
    logo?: Maybe<Scalars['String']>;
    precision: Scalars['Int'];
    publicName?: Maybe<Scalars['String']>;
    shareInPortfolio: Scalars['String'];
    smartContract: Scalars['String'];
    usdEquivalent: Scalars['String'];
};

export type InvalidCardholderError = {
    __typename: 'InvalidCardholderError';
    errorMessage: Scalars['String'];
};

export type InvalidTransferParamsError = {
    __typename: 'InvalidTransferParamsError';
    errorMessage: Scalars['String'];
};

export enum LanguageEnum {
    En = 'EN',
    Ru = 'RU'
}

export type LimitOrderFilterInput = {
    eosAccount?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Array<InputMaybe<LimitOrderStatus>>>;
    tokenId?: InputMaybe<Scalars['Int']>;
    tokenInId?: InputMaybe<Scalars['Int']>;
    tokenOutId?: InputMaybe<Scalars['Int']>;
};

export type LimitOrderList = {
    __typename: 'LimitOrderList';
    orders: Array<Maybe<LimitOrderNode>>;
    pageInfo: PageInfo;
};

export type LimitOrderNode = {
    __typename: 'LimitOrderNode';
    creationTimestamp: Scalars['DateTime'];
    eosAccount: Scalars['String'];
    initialInAmount: Scalars['String'];
    initialOutAmount: Scalars['String'];
    lastUpdateTimestamp: Scalars['DateTime'];
    price: Scalars['Float'];
    smartTableRowId: Scalars['Int'];
    status: LimitOrderStatus;
    tokenIn: TokenNode;
    tokenInAmount: Scalars['String'];
    tokenOut: TokenNode;
    tokenOutAmount: Scalars['String'];
};

export enum LimitOrderSortEnum {
    CreationTimestamp = 'CREATION_TIMESTAMP',
    CreationTimestampDesc = 'CREATION_TIMESTAMP_DESC',
    EosAccount = 'EOS_ACCOUNT',
    EosAccountDesc = 'EOS_ACCOUNT_DESC',
    Status = 'STATUS',
    StatusDesc = 'STATUS_DESC',
    UpdateTimestamp = 'UPDATE_TIMESTAMP',
    UpdateTimestampDesc = 'UPDATE_TIMESTAMP_DESC'
}

export enum LimitOrderStatus {
    Active = 'ACTIVE',
    Cancelled = 'CANCELLED',
    Complete = 'COMPLETE',
    Inactive = 'INACTIVE'
}

export type MaintenanceStatus = {
    __typename: 'MaintenanceStatus';
    description?: Maybe<Scalars['String']>;
    endTime?: Maybe<Scalars['String']>;
    isActive: Scalars['Boolean'];
};

export type MakeExchangeInput = {
    amount?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    exchangePair: Scalars['String'];
    inputAmount?: InputMaybe<Scalars['String']>;
    language?: InputMaybe<LanguageEnum>;
    memo?: InputMaybe<Scalars['String']>;
    outputAmount?: InputMaybe<Scalars['String']>;
    receiver: Scalars['String'];
    redirectUrl?: InputMaybe<Scalars['String']>;
};

export type MakeExchangeOutput =
    | CurrencyNotFoundError
    | ExchangeAmountTooHighError
    | ExchangeAmountTooLowError
    | ExchangePairNotFoundError
    | ExchangePairNotPresentError
    | FiatMethodNotFoundError
    | InvalidTransferParamsError
    | MakeExchangeSuccess
    | UnderMaintenanceError;

export type MakeExchangeSuccess = {
    __typename: 'MakeExchangeSuccess';
    exchangeId?: Maybe<Scalars['String']>;
    orderId?: Maybe<Scalars['String']>;
};

export type MlnkCollectParams = {
    __typename: 'MlnkCollectParams';
    deeplink: Scalars['String'];
    memo: Scalars['String'];
    /** JSON in valid for payment systems form */
    metaInfo: Scalars['String'];
    receiver: Scalars['String'];
};

export type MlnkIncomeDetails = {
    __typename: 'MlnkIncomeDetails';
    hasHighMlnkIncome: Scalars['Boolean'];
    hasMlnkIncome: Scalars['Boolean'];
};

export type MlnkStatisticNode = {
    __typename: 'MlnkStatisticNode';
    burned: Scalars['String'];
    collected: Scalars['String'];
    lastBlockNum: Scalars['Int'];
    left: Scalars['String'];
    mlnkHoldersNum: Scalars['Int'];
    mlnkInLast24h: Scalars['String'];
    mlnkInLastBlock: Scalars['String'];
    total: Scalars['String'];
};

export type Mutations = {
    __typename: 'Mutations';
    calculateCardEmission?: Maybe<CalculateCardEmissionOutput>;
    calculateCardReplenishment?: Maybe<CalculateCardReplenishmentOutput>;
    emitCard?: Maybe<EmitCardOutput>;
    makeExchange?: Maybe<MakeExchangeOutput>;
    replenishCard?: Maybe<ReplenishCardOutput>;
    sendExchangeEmail?: Maybe<SendExchangeEmailOutput>;
    updateFavoriteAccount?: Maybe<UpdateFavoriteAccountOutput>;
    updateFavoritePool?: Maybe<UpdateFavoritePoolOutput>;
    updateFavoriteToken?: Maybe<UpdateFavoriteTokenOutput>;
};

export type MutationsCalculateCardEmissionArgs = {
    input: CalculateCardEmissionInput;
};

export type MutationsCalculateCardReplenishmentArgs = {
    input: ReplenishCardInput;
};

export type MutationsEmitCardArgs = {
    input: EmitCardInput;
};

export type MutationsMakeExchangeArgs = {
    input: MakeExchangeInput;
};

export type MutationsReplenishCardArgs = {
    input: ReplenishCardInput;
};

export type MutationsSendExchangeEmailArgs = {
    input: SendExchangeEmailInput;
};

export type MutationsUpdateFavoriteAccountArgs = {
    input: UpdateFavoriteAccountInput;
};

export type MutationsUpdateFavoritePoolArgs = {
    input: UpdateFavoritePoolInput;
};

export type MutationsUpdateFavoriteTokenArgs = {
    input: UpdateFavoriteTokenInput;
};

export type NoAuthUserNode = {
    __typename: 'NoAuthUserNode';
    accounts: Array<Maybe<AccountNode>>;
    code: Scalars['String'];
    id: Scalars['Int'];
    pools: Array<Maybe<PoolNode>>;
    tokens: Array<Maybe<TokenNode>>;
};

export type PageInfo = {
    __typename: 'PageInfo';
    total?: Maybe<Scalars['Int']>;
    totalFiltered?: Maybe<Scalars['Int']>;
};

export type PageTimestampInfo = {
    __typename: 'PageTimestampInfo';
    from: Scalars['DateTime'];
    to: Scalars['DateTime'];
};

export type PaymentParamsNode = {
    __typename: 'PaymentParamsNode';
    amount: Scalars['String'];
    blockchain?: Maybe<Scalars['String']>;
    expirationDate?: Maybe<Scalars['String']>;
    memo?: Maybe<Scalars['String']>;
    receiver: Scalars['String'];
};

export type PoolNode = {
    __typename: 'PoolNode';
    code: Scalars['String'];
    fee24h: Scalars['String'];
    fee24hGrowth: Scalars['String'];
    isFavorite: Scalars['Boolean'];
    liquidity: Scalars['String'];
    liquidityGrowth: Scalars['String'];
    liquidityTokensAmount: Scalars['String'];
    mlnkIncomeDetails: MlnkIncomeDetails;
    poolId: Scalars['Int'];
    profit24h: Scalars['String'];
    profit24hGrowth: Scalars['String'];
    profitAnnual: Scalars['String'];
    profitDetails?: Maybe<PoolProfitDetailsNode>;
    relative24hProfit: Scalars['String'];
    relativeAnnualProfit: Scalars['String'];
    token1: TokenNode;
    token1Amount: Scalars['String'];
    token1ToToken2Rate: Scalars['String'];
    token2: TokenNode;
    token2Amount: Scalars['String'];
    token2ToToken1Rate: Scalars['String'];
    volume24h: Scalars['String'];
    volume24hGrowth: Scalars['String'];
};

export type PoolNotFoundError = {
    __typename: 'PoolNotFoundError';
    errorMessage: Scalars['String'];
};

export type PoolProfitDetailsNode = {
    __typename: 'PoolProfitDetailsNode';
    mlnk: Scalars['String'];
    mlnkPercent: Scalars['String'];
    mlnkUsd: Scalars['String'];
    token1: Scalars['String'];
    token1Percent: Scalars['String'];
    token1Usd: Scalars['String'];
    token2: Scalars['String'];
    token2Percent: Scalars['String'];
    token2Usd: Scalars['String'];
    total24hProfit: Scalars['String'];
};

export type PoolsFilterInput = {
    liquidity?: InputMaybe<RangeMoneyFilterInput>;
    tokenId?: InputMaybe<Scalars['Int']>;
    tokenNames?: InputMaybe<Scalars['String']>;
};

export type PoolsList = {
    __typename: 'PoolsList';
    pageInfo: PageInfo;
    pools: Array<Maybe<PoolNode>>;
};

export enum PoolsSortEnum {
    Liquidity = 'LIQUIDITY',
    LiquidityDesc = 'LIQUIDITY_DESC',
    Profit = 'PROFIT',
    ProfitDesc = 'PROFIT_DESC',
    RelativeProfit_24h = 'RELATIVE_PROFIT_24h',
    RelativeProfit_24hDesc = 'RELATIVE_PROFIT_24h_DESC',
    RelativeProfitAnnual = 'RELATIVE_PROFIT_ANNUAL',
    RelativeProfitAnnualDesc = 'RELATIVE_PROFIT_ANNUAL_DESC',
    Tokens = 'TOKENS',
    TokensDesc = 'TOKENS_DESC',
    Volume = 'VOLUME',
    VolumeDesc = 'VOLUME_DESC'
}

export type ProfitDetailsNode = {
    __typename: 'ProfitDetailsNode';
    mlnkUsd: Scalars['String'];
    token1Usd: Scalars['String'];
    token2Usd: Scalars['String'];
    total24hProfit: Scalars['String'];
};

export type Query = {
    __typename: 'Query';
    accountInvestmentChart?: Maybe<ChartNode>;
    accountMlnkProfitChart?: Maybe<AccountMlnkProfitChartNode>;
    accountPoolProfitChart?: Maybe<AccountProfitChartNode>;
    accountPoolProfitDetails?: Maybe<ProfitDetailsNode>;
    actions?: Maybe<ActionsList>;
    cardFees?: Maybe<CardFeesNode>;
    eosAccount?: Maybe<AccountNode>;
    exchange?: Maybe<ExchangeNode>;
    exchangeCalc?: Maybe<CalculationOutput>;
    exchangePairs?: Maybe<Array<Maybe<ExchangePairNode>>>;
    exchangeSystemStatus?: Maybe<ExchangeSystemStatus>;
    exchanges?: Maybe<Array<Maybe<ExchangeNode>>>;
    limitOrders?: Maybe<LimitOrderList>;
    mlnkPriceChart?: Maybe<ChartColumnNode>;
    mlnkStatistic?: Maybe<MlnkStatisticNode>;
    /** @deprecated Use MyCardAccount instead */
    myActiveCard?: Maybe<CardNode>;
    myCardAccount?: Maybe<CardAccountNode>;
    myCardHistory?: Maybe<CardHistoryNode>;
    pool?: Maybe<PoolNode>;
    poolLiquidityChart?: Maybe<ChartNode>;
    poolProfitChart?: Maybe<ChartNode>;
    poolVolumeChart?: Maybe<VolumeChartNode>;
    pools?: Maybe<PoolsList>;
    searchEosAccounts?: Maybe<AccountsList>;
    token?: Maybe<TokenNode>;
    tokenActionsNumChart?: Maybe<ChartNode>;
    tokenLiquidityChart?: Maybe<ChartNode>;
    tokenPriceChart?: Maybe<ChartNode>;
    tokenVolumeChart?: Maybe<VolumeChartNode>;
    tokens?: Maybe<TokensList>;
    totalDepositStatistic?: Maybe<Array<Maybe<TotalDepositStatisticNode>>>;
    totalLiquidityChart?: Maybe<ChartNode>;
    totalVolumeChart?: Maybe<VolumeChartNode>;
    totalZeroriskStatistic?: Maybe<Array<Maybe<TotalZeroriskStatisticNode>>>;
    visitor?: Maybe<NoAuthUserNode>;
};

export type QueryAccountInvestmentChartArgs = {
    account: Scalars['String'];
    resolution?: InputMaybe<ChartResolutionEnum>;
};

export type QueryAccountMlnkProfitChartArgs = {
    account: Scalars['String'];
    poolCode?: InputMaybe<Scalars['String']>;
    resolution?: InputMaybe<ChartResolutionEnum>;
};

export type QueryAccountPoolProfitChartArgs = {
    account: Scalars['String'];
    poolCode: Scalars['String'];
    resolution?: InputMaybe<ChartResolutionEnum>;
};

export type QueryAccountPoolProfitDetailsArgs = {
    account: Scalars['String'];
    poolCode: Scalars['String'];
};

export type QueryActionsArgs = {
    filters?: InputMaybe<ActionFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
    sort?: InputMaybe<Array<InputMaybe<ActionSortEnum>>>;
};

export type QueryEosAccountArgs = {
    name: Scalars['String'];
};

export type QueryExchangeArgs = {
    id: Scalars['String'];
};

export type QueryExchangeCalcArgs = {
    input: ExchangeCalcInput;
};

export type QueryExchangePairsArgs = {
    filters?: InputMaybe<ExchangePairFilterInput>;
};

export type QueryExchangesArgs = {
    filters?: ExchangeFilterInput;
    limit?: Scalars['Int'];
    skip?: Scalars['Int'];
};

export type QueryLimitOrdersArgs = {
    filters?: InputMaybe<LimitOrderFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
    sort?: InputMaybe<Array<InputMaybe<LimitOrderSortEnum>>>;
};

export type QueryMlnkPriceChartArgs = {
    filterFromTimestamp?: InputMaybe<Scalars['DateTime']>;
    filterToTimestamp?: InputMaybe<Scalars['DateTime']>;
    resolution?: InputMaybe<ChartResolutionEnum>;
};

export type QueryMyCardHistoryArgs = {
    cardId: Scalars['String'];
};

export type QueryPoolArgs = {
    code: Scalars['String'];
};

export type QueryPoolLiquidityChartArgs = {
    poolCode: Scalars['String'];
    resolution?: InputMaybe<ChartResolutionEnum>;
};

export type QueryPoolProfitChartArgs = {
    poolCode: Scalars['String'];
    resolution?: InputMaybe<ChartResolutionEnum>;
};

export type QueryPoolVolumeChartArgs = {
    poolCode: Scalars['String'];
    resolution?: InputMaybe<ChartResolutionEnum>;
};

export type QueryPoolsArgs = {
    filters?: InputMaybe<PoolsFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
    sort?: InputMaybe<PoolsSortEnum>;
};

export type QuerySearchEosAccountsArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    part: Scalars['String'];
    skip?: InputMaybe<Scalars['Int']>;
};

export type QueryTokenArgs = {
    id: Scalars['Int'];
};

export type QueryTokenActionsNumChartArgs = {
    resolution?: InputMaybe<ChartResolutionEnum>;
    tokenId: Scalars['Int'];
};

export type QueryTokenLiquidityChartArgs = {
    resolution?: InputMaybe<ChartResolutionEnum>;
    tokenId: Scalars['Int'];
};

export type QueryTokenPriceChartArgs = {
    resolution?: InputMaybe<ChartResolutionEnum>;
    tokenId: Scalars['Int'];
};

export type QueryTokenVolumeChartArgs = {
    resolution?: InputMaybe<ChartResolutionEnum>;
    tokenId: Scalars['Int'];
};

export type QueryTokensArgs = {
    filters?: InputMaybe<TokenFilterInput>;
    limit?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
    sort?: InputMaybe<Array<InputMaybe<TokenSortEnum>>>;
};

export type QueryTotalLiquidityChartArgs = {
    resolution?: InputMaybe<ChartResolutionEnum>;
};

export type QueryTotalVolumeChartArgs = {
    resolution?: InputMaybe<ChartResolutionEnum>;
};

export type RangeMoneyFilterInput = {
    from?: InputMaybe<Scalars['String']>;
    to?: InputMaybe<Scalars['String']>;
};

export type RefundNode = {
    __typename: 'RefundNode';
    amount: Scalars['String'];
    receiver: Scalars['String'];
    transferId: Scalars['String'];
    txid: Scalars['String'];
};

export type ReplenishCardInput = {
    amount: Scalars['String'];
    cardId: Scalars['String'];
    paymentCurrency?: Scalars['String'];
};

export type ReplenishCardOutput = CardActionNode | CardIsInactiveError | CardNotFoundError | UnderMaintenanceError;

export type SendExchangeEmailInput = {
    email: Scalars['String'];
    exchangeId: Scalars['String'];
    language?: InputMaybe<LanguageEnum>;
};

export type SendExchangeEmailOutput = ExchangeNotFoundError | SendExchangeEmailSuccess;

export type SendExchangeEmailSuccess = {
    __typename: 'SendExchangeEmailSuccess';
    exchangeId?: Maybe<Scalars['String']>;
};

export type Subscription = {
    __typename: 'Subscription';
    exchangeStatus?: Maybe<ExchangeNode>;
    mlnkBalance?: Maybe<AccountNode>;
};

export type SubscriptionExchangeStatusArgs = {
    exchangeId: Scalars['String'];
};

export type SubscriptionMlnkBalanceArgs = {
    eosAccount: Scalars['String'];
};

export type TokenFilterInput = {
    code?: InputMaybe<Scalars['String']>;
    liquidity?: InputMaybe<RangeMoneyFilterInput>;
};

export type TokenNode = {
    __typename: 'TokenNode';
    actionsNum24h: Scalars['Int'];
    actionsNum24hChange: Scalars['String'];
    chain?: Maybe<Scalars['String']>;
    code: Scalars['String'];
    id: Scalars['Int'];
    isFavorite: Scalars['Boolean'];
    liquidity: Scalars['String'];
    liquidityGrowth: Scalars['String'];
    logo?: Maybe<Scalars['String']>;
    precision: Scalars['Int'];
    publicName?: Maybe<Scalars['String']>;
    rateGrowth: Scalars['String'];
    rateToUsd: Scalars['String'];
    reverseRate: Scalars['String'];
    smartContract: Scalars['String'];
    volume24h: Scalars['String'];
    volume24hGrowth: Scalars['String'];
};

export type TokenNotFoundError = {
    __typename: 'TokenNotFoundError';
    errorMessage: Scalars['String'];
};

export enum TokenSortEnum {
    Code = 'CODE',
    CodeDesc = 'CODE_DESC',
    Liquidity = 'LIQUIDITY',
    LiquidityDesc = 'LIQUIDITY_DESC',
    Price = 'PRICE',
    PriceChange = 'PRICE_CHANGE',
    PriceChangeDesc = 'PRICE_CHANGE_DESC',
    PriceDesc = 'PRICE_DESC',
    Volume = 'VOLUME',
    VolumeDesc = 'VOLUME_DESC'
}

export type TokensList = {
    __typename: 'TokensList';
    pageInfo: PageInfo;
    tokens: Array<Maybe<TokenNode>>;
};

export type TotalDepositStatisticNode = {
    __typename: 'TotalDepositStatisticNode';
    annualRelativeProfit: Scalars['String'];
    depositedMlnk: Scalars['String'];
    depositedToken: Scalars['String'];
    depositorsNumber: Scalars['Int'];
    issuedCash: Scalars['String'];
    tokenIn: TokenNode;
    tokenOut: TokenNode;
};

export type TotalZeroriskStatisticNode = {
    __typename: 'TotalZeroriskStatisticNode';
    amount: Scalars['String'];
    annualRelativeProfit: Scalars['String'];
    depositorsNumber: Scalars['Int'];
    token: TokenNode;
};

export enum TxnType {
    AddLiquidity = 'ADD_LIQUIDITY',
    CashIssue = 'CASH_ISSUE',
    CashRetire = 'CASH_RETIRE',
    LimitOrderCreate = 'LIMIT_ORDER_CREATE',
    LimitOrderDelete = 'LIMIT_ORDER_DELETE',
    LimitOrderSwap = 'LIMIT_ORDER_SWAP',
    MlnkCollect = 'MLNK_COLLECT',
    RemoveLiquidity = 'REMOVE_LIQUIDITY',
    Swap = 'SWAP',
    Transfer = 'TRANSFER',
    ZeroriskDeposit = 'ZERORISK_DEPOSIT',
    ZeroriskWithdraw = 'ZERORISK_WITHDRAW'
}

export type UnderMaintenanceError = {
    __typename: 'UnderMaintenanceError';
    errorMessage: Scalars['String'];
};

export type UpdateFavoriteAccountInput = {
    eosAccount: Scalars['String'];
};

export type UpdateFavoriteAccountOutput = EosAccountNotFoundError | UpdateFavoriteAccountSuccess | UserNotFoundError;

export type UpdateFavoriteAccountSuccess = {
    __typename: 'UpdateFavoriteAccountSuccess';
    account?: Maybe<AccountNode>;
    visitor: NoAuthUserNode;
};

export type UpdateFavoritePoolInput = {
    poolCode: Scalars['String'];
};

export type UpdateFavoritePoolOutput = PoolNotFoundError | UpdateFavoritePoolSuccess | UserNotFoundError;

export type UpdateFavoritePoolSuccess = {
    __typename: 'UpdateFavoritePoolSuccess';
    pool?: Maybe<PoolNode>;
    visitor: NoAuthUserNode;
};

export type UpdateFavoriteTokenInput = {
    tokenId: Scalars['Int'];
};

export type UpdateFavoriteTokenOutput = TokenNotFoundError | UpdateFavoriteTokenSuccess | UserNotFoundError;

export type UpdateFavoriteTokenSuccess = {
    __typename: 'UpdateFavoriteTokenSuccess';
    token?: Maybe<TokenNode>;
    visitor: NoAuthUserNode;
};

export type UserNotFoundError = {
    __typename: 'UserNotFoundError';
    errorMessage: Scalars['String'];
};

export type VolumeChartNode = {
    __typename: 'VolumeChartNode';
    lastWeekValue: Scalars['String'];
    percentageChange24h: Scalars['String'];
    points: Array<Maybe<ChartPointNode>>;
    resolution: ChartResolutionEnum;
    value24h: Scalars['String'];
};

export type AccountDepositStatisticNodeFragment = {
    __typename: 'AccountDepositStatisticNode';
    depositedMlnk: string;
    depositedToken: string;
    issuedCash: string;
    mlnkIncome24h: string;
    tokenIn: {
        __typename: 'TokenNode';
        actionsNum24h: number;
        actionsNum24hChange: string;
        chain?: string | null;
        code: string;
        id: number;
        isFavorite: boolean;
        liquidity: string;
        liquidityGrowth: string;
        logo?: string | null;
        precision: number;
        publicName?: string | null;
        rateGrowth: string;
        rateToUsd: string;
        reverseRate: string;
        smartContract: string;
        volume24h: string;
        volume24hGrowth: string;
    };
    tokenOut: {
        __typename: 'TokenNode';
        actionsNum24h: number;
        actionsNum24hChange: string;
        chain?: string | null;
        code: string;
        id: number;
        isFavorite: boolean;
        liquidity: string;
        liquidityGrowth: string;
        logo?: string | null;
        precision: number;
        publicName?: string | null;
        rateGrowth: string;
        rateToUsd: string;
        reverseRate: string;
        smartContract: string;
        volume24h: string;
        volume24hGrowth: string;
    };
};

export type ActionNodeFragment = {
    __typename: 'ActionNode';
    id: string;
    timestamp: any;
    txnType?: TxnType | null;
    txid: string;
    token1Amount: string;
    token2Amount?: string | null;
    account1: string;
    account2?: string | null;
    cashTokenAmount?: string | null;
    token1: { __typename: 'TokenNode'; id: number; code: string };
    token2?: { __typename: 'TokenNode'; id: number; code: string } | null;
    cashToken?: { __typename: 'TokenNode'; id: number; code: string } | null;
};

export type ExchangeNodeFragment = {
    __typename: 'ExchangeNode';
    id?: string | null;
    orderId?: string | null;
    paymentLink?: string | null;
    created?: any | null;
    status: ExchangeStatusEnum;
    receiver?: string | null;
    exchangePairId?: string | null;
    type?: ExchangeTypeEnum | null;
    refund?: { __typename: 'RefundNode'; amount: string; receiver: string; transferId: string; txid: string } | null;
    input: { __typename: 'ExchangeAmountNode'; amount: string; methodId: string; name: string; txid?: string | null };
    output: { __typename: 'ExchangeAmountNode'; amount: string; methodId: string; name: string; txid?: string | null };
};

export type TokenNodeFragment = {
    __typename: 'TokenNode';
    actionsNum24h: number;
    actionsNum24hChange: string;
    chain?: string | null;
    code: string;
    id: number;
    isFavorite: boolean;
    liquidity: string;
    liquidityGrowth: string;
    logo?: string | null;
    precision: number;
    publicName?: string | null;
    rateGrowth: string;
    rateToUsd: string;
    reverseRate: string;
    smartContract: string;
    volume24h: string;
    volume24hGrowth: string;
};

export type TotalDepositStatisticNodeFragment = {
    __typename: 'TotalDepositStatisticNode';
    annualRelativeProfit: string;
    depositedMlnk: string;
    depositedToken: string;
    depositorsNumber: number;
    issuedCash: string;
    tokenIn: {
        __typename: 'TokenNode';
        actionsNum24h: number;
        actionsNum24hChange: string;
        chain?: string | null;
        code: string;
        id: number;
        isFavorite: boolean;
        liquidity: string;
        liquidityGrowth: string;
        logo?: string | null;
        precision: number;
        publicName?: string | null;
        rateGrowth: string;
        rateToUsd: string;
        reverseRate: string;
        smartContract: string;
        volume24h: string;
        volume24hGrowth: string;
    };
    tokenOut: {
        __typename: 'TokenNode';
        actionsNum24h: number;
        actionsNum24hChange: string;
        chain?: string | null;
        code: string;
        id: number;
        isFavorite: boolean;
        liquidity: string;
        liquidityGrowth: string;
        logo?: string | null;
        precision: number;
        publicName?: string | null;
        rateGrowth: string;
        rateToUsd: string;
        reverseRate: string;
        smartContract: string;
        volume24h: string;
        volume24hGrowth: string;
    };
};

export type CalculateCardEmissionMutationVariables = Exact<{
    input: CalculateCardEmissionInput;
}>;

export type CalculateCardEmissionMutation = {
    __typename: 'Mutations';
    calculateCardEmission?: {
        __typename: 'CardActionCalculationNode';
        amountToPay: string;
        amountToReceive: string;
        receiveCurrency: string;
        paymentCurrency: string;
    } | null;
};

export type CalculateCardReplenishmentMutationVariables = Exact<{
    input: ReplenishCardInput;
}>;

export type CalculateCardReplenishmentMutation = {
    __typename: 'Mutations';
    calculateCardReplenishment?:
        | {
              __typename: 'CardActionCalculationNode';
              amountToPay: string;
              amountToReceive: string;
              receiveCurrency: string;
              paymentCurrency: string;
          }
        | { __typename: 'CardIsInactiveError'; errorMessage: string }
        | { __typename: 'CardNotFoundError'; errorMessage: string }
        | null;
};

export type EmitCardMutationVariables = Exact<{
    input: EmitCardInput;
}>;

export type EmitCardMutation = {
    __typename: 'Mutations';
    emitCard?:
        | {
              __typename: 'CardActionNode';
              id: string;
              type: string;
              status: string;
              createdAt: any;
              eosAccount: string;
              amountToReceive: string;
              currencyToReceive: string;
              paymentParams?: {
                  __typename: 'CardPaymentParamsNode';
                  currency: string;
                  receiver: string;
                  memo: string;
                  amount: string;
                  expirationDate: string;
                  deeplinks?: Array<{ __typename: 'DeeplinkNode'; name: string; link?: string | null } | null> | null;
              } | null;
          }
        | { __typename: 'CardEmissionDisallowedError'; errorMessage: string }
        | { __typename: 'InvalidCardholderError'; errorMessage: string }
        | { __typename: 'UnderMaintenanceError'; errorMessage: string }
        | null;
};

export type MakeExchangeMutationVariables = Exact<{
    input: MakeExchangeInput;
}>;

export type MakeExchangeMutation = {
    __typename: 'Mutations';
    makeExchange?:
        | { __typename: 'CurrencyNotFoundError'; errorMessage: string }
        | { __typename: 'ExchangeAmountTooHighError'; errorMessage: string }
        | { __typename: 'ExchangeAmountTooLowError'; errorMessage: string }
        | { __typename: 'ExchangePairNotFoundError'; errorMessage: string }
        | { __typename: 'ExchangePairNotPresentError'; errorMessage: string }
        | { __typename: 'FiatMethodNotFoundError'; errorMessage: string }
        | { __typename: 'InvalidTransferParamsError'; errorMessage: string }
        | { __typename: 'MakeExchangeSuccess'; exchangeId?: string | null; orderId?: string | null }
        | { __typename: 'UnderMaintenanceError'; errorMessage: string }
        | null;
};

export type ReplenishCardMutationVariables = Exact<{
    input: ReplenishCardInput;
}>;

export type ReplenishCardMutation = {
    __typename: 'Mutations';
    replenishCard?:
        | {
              __typename: 'CardActionNode';
              id: string;
              type: string;
              status: string;
              createdAt: any;
              eosAccount: string;
              amountToReceive: string;
              currencyToReceive: string;
              paymentParams?: {
                  __typename: 'CardPaymentParamsNode';
                  currency: string;
                  receiver: string;
                  memo: string;
                  amount: string;
                  expirationDate: string;
                  deeplinks?: Array<{ __typename: 'DeeplinkNode'; name: string; link?: string | null } | null> | null;
              } | null;
          }
        | { __typename: 'CardIsInactiveError'; errorMessage: string }
        | { __typename: 'CardNotFoundError'; errorMessage: string }
        | { __typename: 'UnderMaintenanceError'; errorMessage: string }
        | null;
};

export type SendExchangeEmailMutationVariables = Exact<{
    input: SendExchangeEmailInput;
}>;

export type SendExchangeEmailMutation = {
    __typename: 'Mutations';
    sendExchangeEmail?:
        | { __typename: 'ExchangeNotFoundError'; errorMessage: string }
        | { __typename: 'SendExchangeEmailSuccess'; exchangeId?: string | null }
        | null;
};

export type UpdateFavoriteAccountMutationVariables = Exact<{
    input: UpdateFavoriteAccountInput;
}>;

export type UpdateFavoriteAccountMutation = {
    __typename: 'Mutations';
    updateFavoriteAccount?:
        | { __typename: 'EosAccountNotFoundError'; errorMessage: string }
        | {
              __typename: 'UpdateFavoriteAccountSuccess';
              visitor: {
                  __typename: 'NoAuthUserNode';
                  id: number;
                  accounts: Array<{ __typename: 'AccountNode'; name: string } | null>;
              };
              account?: { __typename: 'AccountNode'; name: string; isFavorite: boolean } | null;
          }
        | { __typename: 'UserNotFoundError'; errorMessage: string }
        | null;
};

export type UpdateFavoritePoolMutationVariables = Exact<{
    input: UpdateFavoritePoolInput;
}>;

export type UpdateFavoritePoolMutation = {
    __typename: 'Mutations';
    updateFavoritePool?:
        | { __typename: 'PoolNotFoundError'; errorMessage: string }
        | {
              __typename: 'UpdateFavoritePoolSuccess';
              visitor: {
                  __typename: 'NoAuthUserNode';
                  id: number;
                  pools: Array<{
                      __typename: 'PoolNode';
                      code: string;
                      token1: {
                          __typename: 'TokenNode';
                          id: number;
                          code: string;
                          logo?: string | null;
                          smartContract: string;
                      };
                      token2: {
                          __typename: 'TokenNode';
                          id: number;
                          code: string;
                          smartContract: string;
                          logo?: string | null;
                      };
                  } | null>;
              };
              pool?: { __typename: 'PoolNode'; code: string; isFavorite: boolean } | null;
          }
        | { __typename: 'UserNotFoundError'; errorMessage: string }
        | null;
};

export type UpdateFavoriteTokenMutationVariables = Exact<{
    input: UpdateFavoriteTokenInput;
}>;

export type UpdateFavoriteTokenMutation = {
    __typename: 'Mutations';
    updateFavoriteToken?:
        | { __typename: 'TokenNotFoundError'; errorMessage: string }
        | {
              __typename: 'UpdateFavoriteTokenSuccess';
              visitor: {
                  __typename: 'NoAuthUserNode';
                  id: number;
                  tokens: Array<{
                      __typename: 'TokenNode';
                      id: number;
                      code: string;
                      logo?: string | null;
                      smartContract: string;
                  } | null>;
              };
              token?: { __typename: 'TokenNode'; id: number; isFavorite: boolean } | null;
          }
        | { __typename: 'UserNotFoundError'; errorMessage: string }
        | null;
};

export type AccountDepositsQueryVariables = Exact<{
    name: Scalars['String'];
}>;

export type AccountDepositsQuery = {
    __typename: 'Query';
    eosAccount?: {
        __typename: 'AccountNode';
        name: string;
        deposits: Array<{
            __typename: 'AccountDepositNode';
            smartTableRowId: number;
            creationTimestamp: any;
            issuedCash: string;
            depositedMlnk: string;
            depositedToken: string;
            tokenIn: {
                __typename: 'TokenNode';
                actionsNum24h: number;
                actionsNum24hChange: string;
                chain?: string | null;
                code: string;
                id: number;
                isFavorite: boolean;
                liquidity: string;
                liquidityGrowth: string;
                logo?: string | null;
                precision: number;
                publicName?: string | null;
                rateGrowth: string;
                rateToUsd: string;
                reverseRate: string;
                smartContract: string;
                volume24h: string;
                volume24hGrowth: string;
            };
            tokenOut: {
                __typename: 'TokenNode';
                actionsNum24h: number;
                actionsNum24hChange: string;
                chain?: string | null;
                code: string;
                id: number;
                isFavorite: boolean;
                liquidity: string;
                liquidityGrowth: string;
                logo?: string | null;
                precision: number;
                publicName?: string | null;
                rateGrowth: string;
                rateToUsd: string;
                reverseRate: string;
                smartContract: string;
                volume24h: string;
                volume24hGrowth: string;
            };
        } | null>;
    } | null;
};

export type AccountDepositStatisticQueryVariables = Exact<{
    name: Scalars['String'];
}>;

export type AccountDepositStatisticQuery = {
    __typename: 'Query';
    eosAccount?: {
        __typename: 'AccountNode';
        name: string;
        depositStatistic?: Array<{
            __typename: 'AccountDepositStatisticNode';
            depositedMlnk: string;
            depositedToken: string;
            issuedCash: string;
            mlnkIncome24h: string;
            tokenIn: {
                __typename: 'TokenNode';
                actionsNum24h: number;
                actionsNum24hChange: string;
                chain?: string | null;
                code: string;
                id: number;
                isFavorite: boolean;
                liquidity: string;
                liquidityGrowth: string;
                logo?: string | null;
                precision: number;
                publicName?: string | null;
                rateGrowth: string;
                rateToUsd: string;
                reverseRate: string;
                smartContract: string;
                volume24h: string;
                volume24hGrowth: string;
            };
            tokenOut: {
                __typename: 'TokenNode';
                actionsNum24h: number;
                actionsNum24hChange: string;
                chain?: string | null;
                code: string;
                id: number;
                isFavorite: boolean;
                liquidity: string;
                liquidityGrowth: string;
                logo?: string | null;
                precision: number;
                publicName?: string | null;
                rateGrowth: string;
                rateToUsd: string;
                reverseRate: string;
                smartContract: string;
                volume24h: string;
                volume24hGrowth: string;
            };
        } | null> | null;
    } | null;
};

export type AccountMlnkBalanceQueryVariables = Exact<{
    name: Scalars['String'];
}>;

export type AccountMlnkBalanceQuery = {
    __typename: 'Query';
    eosAccount?: { __typename: 'AccountNode'; name: string; mlnkBalance: string } | null;
};

export type AccountMlnkProfitChartQueryVariables = Exact<{
    name: Scalars['String'];
    poolCode?: InputMaybe<Scalars['String']>;
}>;

export type AccountMlnkProfitChartQuery = {
    __typename: 'Query';
    accountMlnkProfitChart?: {
        __typename: 'AccountMlnkProfitChartNode';
        value24hUsd: string;
        value24hMlnk: string;
        totalUsd: string;
        totalMlnk: string;
        points: Array<{ __typename: 'ChartPointNode'; value: any; timestamp: any } | null>;
    } | null;
};

export type AccountPoolProfitChartQueryVariables = Exact<{
    name: Scalars['String'];
    poolCode: Scalars['String'];
}>;

export type AccountPoolProfitChartQuery = {
    __typename: 'Query';
    accountPoolProfitChart?: {
        __typename: 'AccountProfitChartNode';
        percentageChange24h: string;
        totalProfit: string;
        points: Array<{ __typename: 'ChartPointNode'; value: any; timestamp: any } | null>;
    } | null;
};

export type ActionsQueryVariables = Exact<{
    limit?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
    filters?: InputMaybe<ActionFilterInput>;
    sort?: InputMaybe<Array<InputMaybe<ActionSortEnum>> | InputMaybe<ActionSortEnum>>;
}>;

export type ActionsQuery = {
    __typename: 'Query';
    actions?: {
        __typename: 'ActionsList';
        pageInfo: { __typename: 'PageInfo'; total?: number | null; totalFiltered?: number | null };
        actions: Array<{
            __typename: 'ActionNode';
            id: string;
            timestamp: any;
            txnType?: TxnType | null;
            txid: string;
            token1Amount: string;
            token2Amount?: string | null;
            account1: string;
            account2?: string | null;
            cashTokenAmount?: string | null;
            token1: { __typename: 'TokenNode'; id: number; code: string };
            token2?: { __typename: 'TokenNode'; id: number; code: string } | null;
            cashToken?: { __typename: 'TokenNode'; id: number; code: string } | null;
        } | null>;
    } | null;
};

export type BookmarksQueryVariables = Exact<{ [key: string]: never }>;

export type BookmarksQuery = {
    __typename: 'Query';
    visitor?: {
        __typename: 'NoAuthUserNode';
        id: number;
        accounts: Array<{ __typename: 'AccountNode'; name: string } | null>;
        pools: Array<{
            __typename: 'PoolNode';
            code: string;
            token1: { __typename: 'TokenNode'; id: number; code: string; logo?: string | null; smartContract: string };
            token2: { __typename: 'TokenNode'; id: number; code: string; logo?: string | null; smartContract: string };
        } | null>;
        tokens: Array<{
            __typename: 'TokenNode';
            id: number;
            code: string;
            logo?: string | null;
            smartContract: string;
        } | null>;
    } | null;
};

export type CardFeesQueryVariables = Exact<{ [key: string]: never }>;

export type CardFeesQuery = {
    __typename: 'Query';
    cardFees?: {
        __typename: 'CardFeesNode';
        emission: {
            __typename: 'CardFeeEntryNode';
            fixedFee: string;
            rate: string;
            issuerRate: string;
            issuerFixedFee: string;
            minimalAmount: string;
            maximalAmount?: string | null;
        };
        replenishment: {
            __typename: 'CardFeeEntryNode';
            fixedFee: string;
            rate: string;
            issuerRate: string;
            issuerFixedFee: string;
            minimalAmount: string;
            maximalAmount?: string | null;
        };
    } | null;
};

export type CheckEosAccountQueryVariables = Exact<{
    name: Scalars['String'];
}>;

export type CheckEosAccountQuery = {
    __typename: 'Query';
    eosAccount?: { __typename: 'AccountNode'; isFavorite: boolean; name: string; hasStatistic: boolean } | null;
};

export type EosAccountQueryVariables = Exact<{
    name: Scalars['String'];
}>;

export type EosAccountQuery = {
    __typename: 'Query';
    eosAccount?: {
        __typename: 'AccountNode';
        isFavorite: boolean;
        name: string;
        heldTokens?: Array<{
            __typename: 'HoldedTokenNode';
            code: string;
            amount?: string | null;
            logo?: string | null;
            smartContract: string;
            usdEquivalent: string;
            shareInPortfolio: string;
        } | null> | null;
        heldPools?: Array<{
            __typename: 'HoldedPoolNode';
            code: string;
            usdEquivalent: string;
            shareInPortfolio: string;
            token1Amount: string;
            token2Amount: string;
            token1: { __typename: 'TokenNode'; id: number; code: string; logo?: string | null; smartContract: string };
            token2: { __typename: 'TokenNode'; id: number; code: string; logo?: string | null; smartContract: string };
        } | null> | null;
        mlnkCollectParams?: {
            __typename: 'MlnkCollectParams';
            memo: string;
            receiver: string;
            metaInfo: string;
            deeplink: string;
        } | null;
    } | null;
};

export type EosAccountInvestmentChartQueryVariables = Exact<{
    name: Scalars['String'];
}>;

export type EosAccountInvestmentChartQuery = {
    __typename: 'Query';
    accountInvestmentChart?: {
        __typename: 'ChartNode';
        value24h: string;
        percentageChange24h: string;
        points: Array<{ __typename: 'ChartPointNode'; value: any; timestamp: any } | null>;
    } | null;
};

export type EosAccountPoolQueryVariables = Exact<{
    name: Scalars['String'];
    poolCode: Scalars['String'];
}>;

export type EosAccountPoolQuery = {
    __typename: 'Query';
    eosAccount?: {
        __typename: 'AccountNode';
        isFavorite: boolean;
        name: string;
        mlnkCollectParams?: {
            __typename: 'MlnkCollectParams';
            memo: string;
            receiver: string;
            metaInfo: string;
            deeplink: string;
        } | null;
        heldPool?: {
            __typename: 'HoldedPoolNode';
            code: string;
            usdEquivalent: string;
            shareInPool: string;
            myLiquidityTokens: string;
            totalLiquidityTokens: string;
            token1Amount: string;
            token1UsdAmount: string;
            token2Amount: string;
            token2UsdAmount: string;
            token1: {
                __typename: 'TokenNode';
                id: number;
                code: string;
                logo?: string | null;
                publicName?: string | null;
                smartContract: string;
            };
            token2: {
                __typename: 'TokenNode';
                id: number;
                code: string;
                logo?: string | null;
                publicName?: string | null;
                smartContract: string;
            };
        } | null;
    } | null;
    accountPoolProfitChart?: {
        __typename: 'AccountProfitChartNode';
        percentageChange24h: string;
        totalProfit: string;
        points: Array<{ __typename: 'ChartPointNode'; value: any; timestamp: any } | null>;
    } | null;
    accountPoolProfitDetails?: {
        __typename: 'ProfitDetailsNode';
        mlnkUsd: string;
        token1Usd: string;
        token2Usd: string;
        total24hProfit: string;
    } | null;
};

export type ExchangeQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type ExchangeQuery = {
    __typename: 'Query';
    exchange?: {
        __typename: 'ExchangeNode';
        id?: string | null;
        orderId?: string | null;
        paymentLink?: string | null;
        created?: any | null;
        status: ExchangeStatusEnum;
        receiver?: string | null;
        exchangePairId?: string | null;
        type?: ExchangeTypeEnum | null;
        refund?: {
            __typename: 'RefundNode';
            amount: string;
            receiver: string;
            transferId: string;
            txid: string;
        } | null;
        input: {
            __typename: 'ExchangeAmountNode';
            amount: string;
            methodId: string;
            name: string;
            txid?: string | null;
        };
        output: {
            __typename: 'ExchangeAmountNode';
            amount: string;
            methodId: string;
            name: string;
            txid?: string | null;
        };
    } | null;
};

export type ExchangeCalcQueryVariables = Exact<{
    input: ExchangeCalcInput;
}>;

export type ExchangeCalcQuery = {
    __typename: 'Query';
    exchangeCalc?:
        | { __typename: 'CurrencyNotFoundError'; errorMessage: string }
        | { __typename: 'ExchangeAmountTooHighError'; errorMessage: string }
        | { __typename: 'ExchangeAmountTooLowError'; errorMessage: string }
        | {
              __typename: 'ExchangeCalcNode';
              amountToReceive?: string | null;
              orderAmount?: string | null;
              amountToPay?: string | null;
              exchangeRate?: string | null;
              inputFee?: string | null;
              outputFee?: string | null;
          }
        | { __typename: 'ExchangePairNotFoundError'; errorMessage: string }
        | { __typename: 'ExchangePairNotPresentError'; errorMessage: string }
        | { __typename: 'FiatMethodNotFoundError'; errorMessage: string }
        | { __typename: 'InvalidTransferParamsError'; errorMessage: string }
        | { __typename: 'UnderMaintenanceError' }
        | null;
};

export type ExchangePairsQueryVariables = Exact<{
    filters?: InputMaybe<ExchangePairFilterInput>;
}>;

export type ExchangePairsQuery = {
    __typename: 'Query';
    exchangePairs?: Array<{
        __typename: 'ExchangePairNode';
        id?: string | null;
        type: ExchangeTypeEnum;
        inputMethodId?: string | null;
        inputCurrencyName: string;
        outputMethodId?: string | null;
        outputCurrencyName: string;
        inputFeeFix?: string | null;
        inputFeePercentage?: string | null;
        outputFeeFix?: string | null;
        outputFeePercentage?: string | null;
        exchangeRate?: string | null;
        minimalAmount?: string | null;
        maximalAmount?: string | null;
        inputPrecision?: number | null;
        outputPrecision?: number | null;
        isEnabled?: boolean | null;
        isAvailable?: boolean | null;
        inputMethod?:
            | {
                  __typename: 'CurrencyNode';
                  blockchain: string;
                  feeFix?: string | null;
                  feePercentage?: string | null;
                  id?: string | null;
                  minTransferAmount?: string | null;
                  name?: string | null;
                  precision?: string | null;
                  withdrawFeeFix?: string | null;
                  withdrawFeePercentage?: string | null;
              }
            | {
                  __typename: 'FiatMethodNode';
                  currencyName?: string | null;
                  direction: string;
                  feeFix?: string | null;
                  feePercentage?: string | null;
                  id?: string | null;
                  location: string;
                  maximalAmount?: string | null;
                  minimalAmount?: string | null;
                  name?: string | null;
              }
            | null;
        outputMethod?:
            | {
                  __typename: 'CurrencyNode';
                  blockchain: string;
                  feeFix?: string | null;
                  feePercentage?: string | null;
                  id?: string | null;
                  minTransferAmount?: string | null;
                  name?: string | null;
                  precision?: string | null;
                  withdrawFeeFix?: string | null;
                  withdrawFeePercentage?: string | null;
              }
            | {
                  __typename: 'FiatMethodNode';
                  currencyName?: string | null;
                  direction: string;
                  feeFix?: string | null;
                  feePercentage?: string | null;
                  id?: string | null;
                  location: string;
                  maximalAmount?: string | null;
                  minimalAmount?: string | null;
                  name?: string | null;
              }
            | null;
    } | null> | null;
};

export type ExchangeSystemStatusQueryVariables = Exact<{ [key: string]: never }>;

export type ExchangeSystemStatusQuery = {
    __typename: 'Query';
    exchangeSystemStatus?: {
        __typename: 'ExchangeSystemStatus';
        maintenance: {
            __typename: 'MaintenanceStatus';
            isActive: boolean;
            description?: string | null;
            endTime?: string | null;
        };
    } | null;
};

export type MlnkCollectedQueryVariables = Exact<{ [key: string]: never }>;

export type MlnkCollectedQuery = {
    __typename: 'Query';
    mlnkStatistic?: {
        __typename: 'MlnkStatisticNode';
        total: string;
        burned: string;
        collected: string;
        left: string;
        lastBlockNum: number;
        mlnkInLastBlock: string;
        mlnkHoldersNum: number;
        mlnkInLast24h: string;
    } | null;
};

export type MlnkPriceQueryVariables = Exact<{
    resolution?: InputMaybe<ChartResolutionEnum>;
    filterFromTimestamp?: InputMaybe<Scalars['DateTime']>;
    filterToTimestamp?: InputMaybe<Scalars['DateTime']>;
    fetchMore?: InputMaybe<Scalars['Boolean']>;
}>;

export type MlnkPriceQuery = {
    __typename: 'Query';
    mlnkPriceChart?: {
        __typename: 'ChartColumnNode';
        value24h?: string;
        percentageChange24h?: string;
        percentageChangeWeek?: string;
        percentageChangeMonth?: string;
        pageInfo?: { __typename: 'PageTimestampInfo'; from: any; to: any };
        points: Array<{
            __typename: 'ChartPointColumnNode';
            timestamp: any;
            low: any;
            high: any;
            open: any;
            close: any;
        } | null>;
    } | null;
};

export type MyCardAccountQueryVariables = Exact<{ [key: string]: never }>;

export type MyCardAccountQuery = {
    __typename: 'Query';
    myCardAccount?: {
        __typename: 'CardAccountNode';
        eosAccount: string;
        emissionStatus?: string | null;
        activeCard?: {
            __typename: 'CardNode';
            id: string;
            emittedAt: any;
            cardholderName: string;
            eosAccount: string;
            currency: string;
            status: string;
            privateInfo?: {
                __typename: 'CardPrivateInfoNode';
                cardNumber: string;
                expirationDate: string;
                cvv: string;
                pinCode: string;
            } | null;
            balance?: { __typename: 'CardBalanceNode'; available: string; pending: string; frozen: string } | null;
        } | null;
        action?: {
            __typename: 'CardActionNode';
            amountToReceive: string;
            createdAt: any;
            currencyToReceive: string;
            eosAccount: string;
            id: string;
            status: string;
            type: string;
            txid?: string | null;
            paymentParams?: {
                __typename: 'CardPaymentParamsNode';
                amount: string;
                currency: string;
                expirationDate: string;
                memo: string;
                receiver: string;
                deeplinks?: Array<{ __typename: 'DeeplinkNode'; name: string; link?: string | null } | null> | null;
            } | null;
        } | null;
    } | null;
};

export type MyCardHistoryQueryVariables = Exact<{
    cardId: Scalars['String'];
}>;

export type MyCardHistoryQuery = {
    __typename: 'Query';
    myCardHistory?: {
        __typename: 'CardHistoryNode';
        pageTotal: number;
        total: number;
        transactions?: Array<{
            __typename: 'CardTransactionNode';
            id: string;
            currency: string;
            amount: string;
            fee: string;
            type: string;
            detail: string;
            status: string;
            createdAt: any;
            completedAt: any;
        } | null> | null;
    } | null;
};

export type PoolTokenDataFragment = {
    __typename: 'TokenNode';
    id: number;
    code: string;
    logo?: string | null;
    publicName?: string | null;
    rateToUsd: string;
    reverseRate: string;
    smartContract: string;
};

export type PoolQueryVariables = Exact<{
    code: Scalars['String'];
}>;

export type PoolQuery = {
    __typename: 'Query';
    pool?: {
        __typename: 'PoolNode';
        code: string;
        isFavorite: boolean;
        poolId: number;
        fee24h: string;
        fee24hGrowth: string;
        token1Amount: string;
        token2ToToken1Rate: string;
        token2Amount: string;
        token1ToToken2Rate: string;
        liquidityTokensAmount: string;
        profit24h: string;
        profit24hGrowth: string;
        relative24hProfit: string;
        profitAnnual: string;
        relativeAnnualProfit: string;
        token1: {
            __typename: 'TokenNode';
            id: number;
            code: string;
            logo?: string | null;
            publicName?: string | null;
            rateToUsd: string;
            reverseRate: string;
            smartContract: string;
        };
        token2: {
            __typename: 'TokenNode';
            id: number;
            code: string;
            logo?: string | null;
            publicName?: string | null;
            rateToUsd: string;
            reverseRate: string;
            smartContract: string;
        };
        mlnkIncomeDetails: { __typename: 'MlnkIncomeDetails'; hasMlnkIncome: boolean; hasHighMlnkIncome: boolean };
        profitDetails?: {
            __typename: 'PoolProfitDetailsNode';
            mlnk: string;
            mlnkUsd: string;
            mlnkPercent: string;
            token1: string;
            token1Usd: string;
            token1Percent: string;
            token2: string;
            token2Usd: string;
            token2Percent: string;
        } | null;
    } | null;
    poolLiquidityChart?: {
        __typename: 'ChartNode';
        percentageChange24h: string;
        value24h: string;
        points: Array<{ __typename: 'ChartPointNode'; value: any; timestamp: any } | null>;
    } | null;
    poolVolumeChart?: {
        __typename: 'VolumeChartNode';
        lastWeekValue: string;
        percentageChange24h: string;
        value24h: string;
        points: Array<{ __typename: 'ChartPointNode'; value: any; time: any } | null>;
    } | null;
};

export type PoolsQueryVariables = Exact<{
    sort?: InputMaybe<PoolsSortEnum>;
    limit?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
    filters?: InputMaybe<PoolsFilterInput>;
}>;

export type PoolsQuery = {
    __typename: 'Query';
    pools?: {
        __typename: 'PoolsList';
        pools: Array<{
            __typename: 'PoolNode';
            code: string;
            isFavorite: boolean;
            liquidity: string;
            liquidityGrowth: string;
            volume24h: string;
            volume24hGrowth: string;
            profit24hGrowth: string;
            relative24hProfit: string;
            relativeAnnualProfit: string;
            mlnkIncomeDetails: { __typename: 'MlnkIncomeDetails'; hasMlnkIncome: boolean; hasHighMlnkIncome: boolean };
            token1: { __typename: 'TokenNode'; id: number; code: string; logo?: string | null; smartContract: string };
            token2: { __typename: 'TokenNode'; id: number; code: string; logo?: string | null; smartContract: string };
        } | null>;
        pageInfo: { __typename: 'PageInfo'; total?: number | null; totalFiltered?: number | null };
    } | null;
};

export type PoolNamesQueryVariables = Exact<{ [key: string]: never }>;

export type PoolNamesQuery = {
    __typename: 'Query';
    pools?: {
        __typename: 'PoolsList';
        pools: Array<{
            __typename: 'PoolNode';
            code: string;
            mlnkIncomeDetails: { __typename: 'MlnkIncomeDetails'; hasMlnkIncome: boolean; hasHighMlnkIncome: boolean };
            token1: { __typename: 'TokenNode'; id: number; code: string; logo?: string | null; smartContract: string };
            token2: { __typename: 'TokenNode'; id: number; code: string; logo?: string | null; smartContract: string };
        } | null>;
    } | null;
};

export type PoolProfitChartQueryVariables = Exact<{
    poolCode: Scalars['String'];
}>;

export type PoolProfitChartQuery = {
    __typename: 'Query';
    poolProfitChart?: {
        __typename: 'ChartNode';
        points: Array<{ __typename: 'ChartPointNode'; value: any; timestamp: any } | null>;
    } | null;
};

export type SearchEosAccountsQueryVariables = Exact<{
    part: Scalars['String'];
    limit?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
}>;

export type SearchEosAccountsQuery = {
    __typename: 'Query';
    searchEosAccounts?: {
        __typename: 'AccountsList';
        total: number;
        accounts: Array<{ __typename: 'AccountNode'; name: string; isFavorite: boolean } | null>;
    } | null;
};

export type TokenQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type TokenQuery = {
    __typename: 'Query';
    token?: {
        __typename: 'TokenNode';
        id: number;
        code: string;
        smartContract: string;
        publicName?: string | null;
        logo?: string | null;
        isFavorite: boolean;
        reverseRate: string;
        chain?: string | null;
    } | null;
    tokenPriceChart?: {
        __typename: 'ChartNode';
        value24h: string;
        percentageChange24h: string;
        points: Array<{ __typename: 'ChartPointNode'; value: any; time: any } | null>;
    } | null;
    tokenLiquidityChart?: {
        __typename: 'ChartNode';
        value24h: string;
        percentageChange24h: string;
        points: Array<{ __typename: 'ChartPointNode'; value: any; time: any } | null>;
    } | null;
    tokenVolumeChart?: {
        __typename: 'VolumeChartNode';
        value24h: string;
        percentageChange24h: string;
        lastWeekValue: string;
        points: Array<{ __typename: 'ChartPointNode'; value: any; time: any } | null>;
    } | null;
    tokenActionsNumChart?: {
        __typename: 'ChartNode';
        value24h: string;
        percentageChange24h: string;
        points: Array<{ __typename: 'ChartPointNode'; value: any; time: any } | null>;
    } | null;
};

export type TokensQueryVariables = Exact<{
    filters?: InputMaybe<TokenFilterInput>;
    sort?: InputMaybe<Array<InputMaybe<TokenSortEnum>> | InputMaybe<TokenSortEnum>>;
    limit?: InputMaybe<Scalars['Int']>;
    skip?: InputMaybe<Scalars['Int']>;
}>;

export type TokensQuery = {
    __typename: 'Query';
    tokens?: {
        __typename: 'TokensList';
        pageInfo: { __typename: 'PageInfo'; total?: number | null; totalFiltered?: number | null };
        tokens: Array<{
            __typename: 'TokenNode';
            id: number;
            code: string;
            publicName?: string | null;
            isFavorite: boolean;
            liquidity: string;
            liquidityGrowth: string;
            rateToUsd: string;
            rateGrowth: string;
            volume24h: string;
            volume24hGrowth: string;
            smartContract: string;
            logo?: string | null;
        } | null>;
    } | null;
};

export type TopPoolsQueryVariables = Exact<{ [key: string]: never }>;

export type TopPoolsQuery = {
    __typename: 'Query';
    topProfitPools?: {
        __typename: 'PoolsList';
        pools: Array<{
            __typename: 'PoolNode';
            code: string;
            liquidity: string;
            volume24h: string;
            relativeAnnualProfit: string;
            token1: {
                __typename: 'TokenNode';
                id: number;
                code: string;
                logo?: string | null;
                rateToUsd: string;
                smartContract: string;
            };
            token2: {
                __typename: 'TokenNode';
                id: number;
                code: string;
                logo?: string | null;
                rateToUsd: string;
                smartContract: string;
            };
        } | null>;
    } | null;
};

export type TopTokensQueryVariables = Exact<{ [key: string]: never }>;

export type TopTokensQuery = {
    __typename: 'Query';
    topProfitTokens?: {
        __typename: 'TokensList';
        tokens: Array<{
            __typename: 'TokenNode';
            id: number;
            code: string;
            logo?: string | null;
            volume24h: string;
            volume24hGrowth: string;
            liquidity: string;
            actionsNum24h: number;
            rateToUsd: string;
            smartContract: string;
        } | null>;
    } | null;
};

export type TotalDepositStatisticQueryVariables = Exact<{ [key: string]: never }>;

export type TotalDepositStatisticQuery = {
    __typename: 'Query';
    totalDepositStatistic?: Array<{
        __typename: 'TotalDepositStatisticNode';
        annualRelativeProfit: string;
        depositedMlnk: string;
        depositedToken: string;
        depositorsNumber: number;
        issuedCash: string;
        tokenIn: {
            __typename: 'TokenNode';
            actionsNum24h: number;
            actionsNum24hChange: string;
            chain?: string | null;
            code: string;
            id: number;
            isFavorite: boolean;
            liquidity: string;
            liquidityGrowth: string;
            logo?: string | null;
            precision: number;
            publicName?: string | null;
            rateGrowth: string;
            rateToUsd: string;
            reverseRate: string;
            smartContract: string;
            volume24h: string;
            volume24hGrowth: string;
        };
        tokenOut: {
            __typename: 'TokenNode';
            actionsNum24h: number;
            actionsNum24hChange: string;
            chain?: string | null;
            code: string;
            id: number;
            isFavorite: boolean;
            liquidity: string;
            liquidityGrowth: string;
            logo?: string | null;
            precision: number;
            publicName?: string | null;
            rateGrowth: string;
            rateToUsd: string;
            reverseRate: string;
            smartContract: string;
            volume24h: string;
            volume24hGrowth: string;
        };
    } | null> | null;
};

export type TotalLiquidityQueryVariables = Exact<{ [key: string]: never }>;

export type TotalLiquidityQuery = {
    __typename: 'Query';
    totalLiquidityChart?: {
        __typename: 'ChartNode';
        value24h: string;
        percentageChange24h: string;
        points: Array<{ __typename: 'ChartPointNode'; timestamp: any; value: any } | null>;
    } | null;
};

export type TotalVolumeQueryVariables = Exact<{ [key: string]: never }>;

export type TotalVolumeQuery = {
    __typename: 'Query';
    totalVolumeChart?: {
        __typename: 'VolumeChartNode';
        value24h: string;
        lastWeekValue: string;
        percentageChange24h: string;
        points: Array<{ __typename: 'ChartPointNode'; timestamp: any; value: any } | null>;
    } | null;
};

export type ExchangeStatusWsSubscriptionVariables = Exact<{
    exchangeId: Scalars['String'];
}>;

export type ExchangeStatusWsSubscription = {
    __typename: 'Subscription';
    exchangeStatus?: {
        __typename: 'ExchangeNode';
        id?: string | null;
        orderId?: string | null;
        paymentLink?: string | null;
        created?: any | null;
        status: ExchangeStatusEnum;
        receiver?: string | null;
        exchangePairId?: string | null;
        type?: ExchangeTypeEnum | null;
        refund?: {
            __typename: 'RefundNode';
            amount: string;
            receiver: string;
            transferId: string;
            txid: string;
        } | null;
        input: {
            __typename: 'ExchangeAmountNode';
            amount: string;
            methodId: string;
            name: string;
            txid?: string | null;
        };
        output: {
            __typename: 'ExchangeAmountNode';
            amount: string;
            methodId: string;
            name: string;
            txid?: string | null;
        };
    } | null;
};

export type MlnkBalanceSubscriptionVariables = Exact<{
    eosAccount: Scalars['String'];
}>;

export type MlnkBalanceSubscription = {
    __typename: 'Subscription';
    mlnkBalance?: { __typename: 'AccountNode'; mlnkBalance: string; name: string } | null;
};

export const TokenNodeFragmentDoc = gql`
    fragment TokenNode on TokenNode {
        actionsNum24h
        actionsNum24hChange
        chain
        code
        id
        isFavorite
        liquidity
        liquidityGrowth
        logo
        precision
        publicName
        rateGrowth
        rateToUsd
        reverseRate
        smartContract
        volume24h
        volume24hGrowth
    }
`;
export const AccountDepositStatisticNodeFragmentDoc = gql`
    fragment AccountDepositStatisticNode on AccountDepositStatisticNode {
        depositedMlnk
        depositedToken
        issuedCash
        mlnkIncome24h
        tokenIn {
            ...TokenNode
        }
        tokenOut {
            ...TokenNode
        }
    }
    ${TokenNodeFragmentDoc}
`;
export const ActionNodeFragmentDoc = gql`
    fragment ActionNode on ActionNode {
        id
        timestamp
        txnType
        txid
        token1 {
            id
            code
        }
        token1Amount
        token2 {
            id
            code
        }
        token2Amount
        account1
        account2
        cashToken {
            id
            code
        }
        cashTokenAmount
    }
`;
export const ExchangeNodeFragmentDoc = gql`
    fragment ExchangeNode on ExchangeNode {
        id
        orderId
        paymentLink
        created
        status
        receiver
        exchangePairId
        type
        refund {
            amount
            receiver
            transferId
            txid
        }
        input {
            amount
            methodId
            name
            txid
        }
        output {
            amount
            methodId
            name
            txid
        }
    }
`;
export const TotalDepositStatisticNodeFragmentDoc = gql`
    fragment TotalDepositStatisticNode on TotalDepositStatisticNode {
        annualRelativeProfit
        depositedMlnk
        depositedToken
        depositorsNumber
        issuedCash
        tokenIn {
            ...TokenNode
        }
        tokenOut {
            ...TokenNode
        }
    }
    ${TokenNodeFragmentDoc}
`;
export const PoolTokenDataFragmentDoc = gql`
    fragment PoolTokenData on TokenNode {
        id
        code
        logo
        publicName
        rateToUsd
        reverseRate
        smartContract
    }
`;
export const CalculateCardEmissionDocument = gql`
    mutation CalculateCardEmission($input: CalculateCardEmissionInput!) {
        calculateCardEmission(input: $input) {
            ... on CardActionCalculationNode {
                amountToPay
                amountToReceive
                receiveCurrency
                paymentCurrency
            }
        }
    }
`;
export type CalculateCardEmissionMutationFn = Apollo.MutationFunction<
    CalculateCardEmissionMutation,
    CalculateCardEmissionMutationVariables
>;

/**
 * __useCalculateCardEmissionMutation__
 *
 * To run a mutation, you first call `useCalculateCardEmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalculateCardEmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calculateCardEmissionMutation, { data, loading, error }] = useCalculateCardEmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalculateCardEmissionMutation(
    baseOptions?: Apollo.MutationHookOptions<CalculateCardEmissionMutation, CalculateCardEmissionMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CalculateCardEmissionMutation, CalculateCardEmissionMutationVariables>(
        CalculateCardEmissionDocument,
        options
    );
}
export type CalculateCardEmissionMutationHookResult = ReturnType<typeof useCalculateCardEmissionMutation>;
export type CalculateCardEmissionMutationResult = Apollo.MutationResult<CalculateCardEmissionMutation>;
export type CalculateCardEmissionMutationOptions = Apollo.BaseMutationOptions<
    CalculateCardEmissionMutation,
    CalculateCardEmissionMutationVariables
>;
export const CalculateCardReplenishmentDocument = gql`
    mutation CalculateCardReplenishment($input: ReplenishCardInput!) {
        calculateCardReplenishment(input: $input) {
            ... on CardActionCalculationNode {
                amountToPay
                amountToReceive
                receiveCurrency
                paymentCurrency
            }
            ... on CardNotFoundError {
                errorMessage
            }
            ... on CardIsInactiveError {
                errorMessage
            }
        }
    }
`;
export type CalculateCardReplenishmentMutationFn = Apollo.MutationFunction<
    CalculateCardReplenishmentMutation,
    CalculateCardReplenishmentMutationVariables
>;

/**
 * __useCalculateCardReplenishmentMutation__
 *
 * To run a mutation, you first call `useCalculateCardReplenishmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalculateCardReplenishmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calculateCardReplenishmentMutation, { data, loading, error }] = useCalculateCardReplenishmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalculateCardReplenishmentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CalculateCardReplenishmentMutation,
        CalculateCardReplenishmentMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CalculateCardReplenishmentMutation, CalculateCardReplenishmentMutationVariables>(
        CalculateCardReplenishmentDocument,
        options
    );
}
export type CalculateCardReplenishmentMutationHookResult = ReturnType<typeof useCalculateCardReplenishmentMutation>;
export type CalculateCardReplenishmentMutationResult = Apollo.MutationResult<CalculateCardReplenishmentMutation>;
export type CalculateCardReplenishmentMutationOptions = Apollo.BaseMutationOptions<
    CalculateCardReplenishmentMutation,
    CalculateCardReplenishmentMutationVariables
>;
export const EmitCardDocument = gql`
    mutation EmitCard($input: EmitCardInput!) {
        emitCard(input: $input) {
            ... on CardActionNode {
                id
                type
                status
                createdAt
                eosAccount
                amountToReceive
                currencyToReceive
                paymentParams {
                    currency
                    receiver
                    memo
                    amount
                    expirationDate
                    deeplinks {
                        name
                        link
                    }
                }
            }
            ... on CardEmissionDisallowedError {
                errorMessage
            }
            ... on InvalidCardholderError {
                errorMessage
            }
            ... on UnderMaintenanceError {
                errorMessage
            }
        }
    }
`;
export type EmitCardMutationFn = Apollo.MutationFunction<EmitCardMutation, EmitCardMutationVariables>;

/**
 * __useEmitCardMutation__
 *
 * To run a mutation, you first call `useEmitCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmitCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emitCardMutation, { data, loading, error }] = useEmitCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmitCardMutation(
    baseOptions?: Apollo.MutationHookOptions<EmitCardMutation, EmitCardMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<EmitCardMutation, EmitCardMutationVariables>(EmitCardDocument, options);
}
export type EmitCardMutationHookResult = ReturnType<typeof useEmitCardMutation>;
export type EmitCardMutationResult = Apollo.MutationResult<EmitCardMutation>;
export type EmitCardMutationOptions = Apollo.BaseMutationOptions<EmitCardMutation, EmitCardMutationVariables>;
export const MakeExchangeDocument = gql`
    mutation MakeExchange($input: MakeExchangeInput!) {
        makeExchange(input: $input) {
            ... on MakeExchangeSuccess {
                exchangeId
                orderId
            }
            ... on CurrencyNotFoundError {
                errorMessage
            }
            ... on ExchangePairNotPresentError {
                errorMessage
            }
            ... on ExchangeAmountTooHighError {
                errorMessage
            }
            ... on ExchangeAmountTooLowError {
                errorMessage
            }
            ... on FiatMethodNotFoundError {
                errorMessage
            }
            ... on InvalidTransferParamsError {
                errorMessage
            }
            ... on ExchangePairNotFoundError {
                errorMessage
            }
            ... on UnderMaintenanceError {
                errorMessage
            }
        }
    }
`;
export type MakeExchangeMutationFn = Apollo.MutationFunction<MakeExchangeMutation, MakeExchangeMutationVariables>;

/**
 * __useMakeExchangeMutation__
 *
 * To run a mutation, you first call `useMakeExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeExchangeMutation, { data, loading, error }] = useMakeExchangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMakeExchangeMutation(
    baseOptions?: Apollo.MutationHookOptions<MakeExchangeMutation, MakeExchangeMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<MakeExchangeMutation, MakeExchangeMutationVariables>(MakeExchangeDocument, options);
}
export type MakeExchangeMutationHookResult = ReturnType<typeof useMakeExchangeMutation>;
export type MakeExchangeMutationResult = Apollo.MutationResult<MakeExchangeMutation>;
export type MakeExchangeMutationOptions = Apollo.BaseMutationOptions<
    MakeExchangeMutation,
    MakeExchangeMutationVariables
>;
export const ReplenishCardDocument = gql`
    mutation ReplenishCard($input: ReplenishCardInput!) {
        replenishCard(input: $input) {
            ... on CardActionNode {
                id
                type
                status
                createdAt
                eosAccount
                amountToReceive
                currencyToReceive
                paymentParams {
                    currency
                    receiver
                    memo
                    amount
                    expirationDate
                    deeplinks {
                        name
                        link
                    }
                }
            }
            ... on CardNotFoundError {
                errorMessage
            }
            ... on CardIsInactiveError {
                errorMessage
            }
            ... on UnderMaintenanceError {
                errorMessage
            }
        }
    }
`;
export type ReplenishCardMutationFn = Apollo.MutationFunction<ReplenishCardMutation, ReplenishCardMutationVariables>;

/**
 * __useReplenishCardMutation__
 *
 * To run a mutation, you first call `useReplenishCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplenishCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replenishCardMutation, { data, loading, error }] = useReplenishCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReplenishCardMutation(
    baseOptions?: Apollo.MutationHookOptions<ReplenishCardMutation, ReplenishCardMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ReplenishCardMutation, ReplenishCardMutationVariables>(ReplenishCardDocument, options);
}
export type ReplenishCardMutationHookResult = ReturnType<typeof useReplenishCardMutation>;
export type ReplenishCardMutationResult = Apollo.MutationResult<ReplenishCardMutation>;
export type ReplenishCardMutationOptions = Apollo.BaseMutationOptions<
    ReplenishCardMutation,
    ReplenishCardMutationVariables
>;
export const SendExchangeEmailDocument = gql`
    mutation SendExchangeEmail($input: SendExchangeEmailInput!) {
        sendExchangeEmail(input: $input) {
            ... on SendExchangeEmailSuccess {
                exchangeId
            }
            ... on ExchangeNotFoundError {
                errorMessage
            }
        }
    }
`;
export type SendExchangeEmailMutationFn = Apollo.MutationFunction<
    SendExchangeEmailMutation,
    SendExchangeEmailMutationVariables
>;

/**
 * __useSendExchangeEmailMutation__
 *
 * To run a mutation, you first call `useSendExchangeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendExchangeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendExchangeEmailMutation, { data, loading, error }] = useSendExchangeEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendExchangeEmailMutation(
    baseOptions?: Apollo.MutationHookOptions<SendExchangeEmailMutation, SendExchangeEmailMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SendExchangeEmailMutation, SendExchangeEmailMutationVariables>(
        SendExchangeEmailDocument,
        options
    );
}
export type SendExchangeEmailMutationHookResult = ReturnType<typeof useSendExchangeEmailMutation>;
export type SendExchangeEmailMutationResult = Apollo.MutationResult<SendExchangeEmailMutation>;
export type SendExchangeEmailMutationOptions = Apollo.BaseMutationOptions<
    SendExchangeEmailMutation,
    SendExchangeEmailMutationVariables
>;
export const UpdateFavoriteAccountDocument = gql`
    mutation UpdateFavoriteAccount($input: UpdateFavoriteAccountInput!) {
        updateFavoriteAccount(input: $input) {
            ... on UpdateFavoriteAccountSuccess {
                visitor {
                    id
                    accounts {
                        name
                    }
                }
                account {
                    name
                    isFavorite
                }
            }
            ... on UserNotFoundError {
                errorMessage
            }
            ... on EosAccountNotFoundError {
                errorMessage
            }
        }
    }
`;
export type UpdateFavoriteAccountMutationFn = Apollo.MutationFunction<
    UpdateFavoriteAccountMutation,
    UpdateFavoriteAccountMutationVariables
>;

/**
 * __useUpdateFavoriteAccountMutation__
 *
 * To run a mutation, you first call `useUpdateFavoriteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFavoriteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFavoriteAccountMutation, { data, loading, error }] = useUpdateFavoriteAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFavoriteAccountMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateFavoriteAccountMutation, UpdateFavoriteAccountMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateFavoriteAccountMutation, UpdateFavoriteAccountMutationVariables>(
        UpdateFavoriteAccountDocument,
        options
    );
}
export type UpdateFavoriteAccountMutationHookResult = ReturnType<typeof useUpdateFavoriteAccountMutation>;
export type UpdateFavoriteAccountMutationResult = Apollo.MutationResult<UpdateFavoriteAccountMutation>;
export type UpdateFavoriteAccountMutationOptions = Apollo.BaseMutationOptions<
    UpdateFavoriteAccountMutation,
    UpdateFavoriteAccountMutationVariables
>;
export const UpdateFavoritePoolDocument = gql`
    mutation UpdateFavoritePool($input: UpdateFavoritePoolInput!) {
        updateFavoritePool(input: $input) {
            ... on UpdateFavoritePoolSuccess {
                visitor {
                    id
                    pools {
                        code
                        token1 {
                            id
                            code
                            logo
                            smartContract
                        }
                        token2 {
                            id
                            code
                            smartContract
                            logo
                        }
                    }
                }
                pool {
                    code
                    isFavorite
                }
            }
            ... on UserNotFoundError {
                errorMessage
            }
            ... on PoolNotFoundError {
                errorMessage
            }
        }
    }
`;
export type UpdateFavoritePoolMutationFn = Apollo.MutationFunction<
    UpdateFavoritePoolMutation,
    UpdateFavoritePoolMutationVariables
>;

/**
 * __useUpdateFavoritePoolMutation__
 *
 * To run a mutation, you first call `useUpdateFavoritePoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFavoritePoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFavoritePoolMutation, { data, loading, error }] = useUpdateFavoritePoolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFavoritePoolMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateFavoritePoolMutation, UpdateFavoritePoolMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateFavoritePoolMutation, UpdateFavoritePoolMutationVariables>(
        UpdateFavoritePoolDocument,
        options
    );
}
export type UpdateFavoritePoolMutationHookResult = ReturnType<typeof useUpdateFavoritePoolMutation>;
export type UpdateFavoritePoolMutationResult = Apollo.MutationResult<UpdateFavoritePoolMutation>;
export type UpdateFavoritePoolMutationOptions = Apollo.BaseMutationOptions<
    UpdateFavoritePoolMutation,
    UpdateFavoritePoolMutationVariables
>;
export const UpdateFavoriteTokenDocument = gql`
    mutation UpdateFavoriteToken($input: UpdateFavoriteTokenInput!) {
        updateFavoriteToken(input: $input) {
            ... on UpdateFavoriteTokenSuccess {
                visitor {
                    id
                    tokens {
                        id
                        code
                        logo
                        smartContract
                    }
                }
                token {
                    id
                    isFavorite
                }
            }
            ... on UserNotFoundError {
                errorMessage
            }
            ... on TokenNotFoundError {
                errorMessage
            }
        }
    }
`;
export type UpdateFavoriteTokenMutationFn = Apollo.MutationFunction<
    UpdateFavoriteTokenMutation,
    UpdateFavoriteTokenMutationVariables
>;

/**
 * __useUpdateFavoriteTokenMutation__
 *
 * To run a mutation, you first call `useUpdateFavoriteTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFavoriteTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFavoriteTokenMutation, { data, loading, error }] = useUpdateFavoriteTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFavoriteTokenMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateFavoriteTokenMutation, UpdateFavoriteTokenMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateFavoriteTokenMutation, UpdateFavoriteTokenMutationVariables>(
        UpdateFavoriteTokenDocument,
        options
    );
}
export type UpdateFavoriteTokenMutationHookResult = ReturnType<typeof useUpdateFavoriteTokenMutation>;
export type UpdateFavoriteTokenMutationResult = Apollo.MutationResult<UpdateFavoriteTokenMutation>;
export type UpdateFavoriteTokenMutationOptions = Apollo.BaseMutationOptions<
    UpdateFavoriteTokenMutation,
    UpdateFavoriteTokenMutationVariables
>;
export const AccountDepositsDocument = gql`
    query AccountDeposits($name: String!) {
        eosAccount(name: $name) {
            name
            deposits {
                smartTableRowId
                creationTimestamp
                issuedCash
                depositedMlnk
                depositedToken
                tokenIn {
                    ...TokenNode
                }
                tokenOut {
                    ...TokenNode
                }
            }
        }
    }
    ${TokenNodeFragmentDoc}
`;

/**
 * __useAccountDepositsQuery__
 *
 * To run a query within a React component, call `useAccountDepositsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountDepositsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDepositsQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAccountDepositsQuery(
    baseOptions: Apollo.QueryHookOptions<AccountDepositsQuery, AccountDepositsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AccountDepositsQuery, AccountDepositsQueryVariables>(AccountDepositsDocument, options);
}
export function useAccountDepositsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AccountDepositsQuery, AccountDepositsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AccountDepositsQuery, AccountDepositsQueryVariables>(AccountDepositsDocument, options);
}
export type AccountDepositsQueryHookResult = ReturnType<typeof useAccountDepositsQuery>;
export type AccountDepositsLazyQueryHookResult = ReturnType<typeof useAccountDepositsLazyQuery>;
export type AccountDepositsQueryResult = Apollo.QueryResult<AccountDepositsQuery, AccountDepositsQueryVariables>;
export const AccountDepositStatisticDocument = gql`
    query AccountDepositStatistic($name: String!) {
        eosAccount(name: $name) {
            name
            depositStatistic {
                ...AccountDepositStatisticNode
            }
        }
    }
    ${AccountDepositStatisticNodeFragmentDoc}
`;

/**
 * __useAccountDepositStatisticQuery__
 *
 * To run a query within a React component, call `useAccountDepositStatisticQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountDepositStatisticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDepositStatisticQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAccountDepositStatisticQuery(
    baseOptions: Apollo.QueryHookOptions<AccountDepositStatisticQuery, AccountDepositStatisticQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AccountDepositStatisticQuery, AccountDepositStatisticQueryVariables>(
        AccountDepositStatisticDocument,
        options
    );
}
export function useAccountDepositStatisticLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AccountDepositStatisticQuery, AccountDepositStatisticQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AccountDepositStatisticQuery, AccountDepositStatisticQueryVariables>(
        AccountDepositStatisticDocument,
        options
    );
}
export type AccountDepositStatisticQueryHookResult = ReturnType<typeof useAccountDepositStatisticQuery>;
export type AccountDepositStatisticLazyQueryHookResult = ReturnType<typeof useAccountDepositStatisticLazyQuery>;
export type AccountDepositStatisticQueryResult = Apollo.QueryResult<
    AccountDepositStatisticQuery,
    AccountDepositStatisticQueryVariables
>;
export const AccountMlnkBalanceDocument = gql`
    query AccountMlnkBalance($name: String!) {
        eosAccount(name: $name) {
            name
            mlnkBalance
        }
    }
`;

/**
 * __useAccountMlnkBalanceQuery__
 *
 * To run a query within a React component, call `useAccountMlnkBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountMlnkBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountMlnkBalanceQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAccountMlnkBalanceQuery(
    baseOptions: Apollo.QueryHookOptions<AccountMlnkBalanceQuery, AccountMlnkBalanceQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AccountMlnkBalanceQuery, AccountMlnkBalanceQueryVariables>(
        AccountMlnkBalanceDocument,
        options
    );
}
export function useAccountMlnkBalanceLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AccountMlnkBalanceQuery, AccountMlnkBalanceQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AccountMlnkBalanceQuery, AccountMlnkBalanceQueryVariables>(
        AccountMlnkBalanceDocument,
        options
    );
}
export type AccountMlnkBalanceQueryHookResult = ReturnType<typeof useAccountMlnkBalanceQuery>;
export type AccountMlnkBalanceLazyQueryHookResult = ReturnType<typeof useAccountMlnkBalanceLazyQuery>;
export type AccountMlnkBalanceQueryResult = Apollo.QueryResult<
    AccountMlnkBalanceQuery,
    AccountMlnkBalanceQueryVariables
>;
export const AccountMlnkProfitChartDocument = gql`
    query AccountMlnkProfitChart($name: String!, $poolCode: String) {
        accountMlnkProfitChart(account: $name, poolCode: $poolCode) {
            value24hUsd
            value24hMlnk
            totalUsd
            totalMlnk
            points {
                value
                timestamp
            }
        }
    }
`;

/**
 * __useAccountMlnkProfitChartQuery__
 *
 * To run a query within a React component, call `useAccountMlnkProfitChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountMlnkProfitChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountMlnkProfitChartQuery({
 *   variables: {
 *      name: // value for 'name'
 *      poolCode: // value for 'poolCode'
 *   },
 * });
 */
export function useAccountMlnkProfitChartQuery(
    baseOptions: Apollo.QueryHookOptions<AccountMlnkProfitChartQuery, AccountMlnkProfitChartQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AccountMlnkProfitChartQuery, AccountMlnkProfitChartQueryVariables>(
        AccountMlnkProfitChartDocument,
        options
    );
}
export function useAccountMlnkProfitChartLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AccountMlnkProfitChartQuery, AccountMlnkProfitChartQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AccountMlnkProfitChartQuery, AccountMlnkProfitChartQueryVariables>(
        AccountMlnkProfitChartDocument,
        options
    );
}
export type AccountMlnkProfitChartQueryHookResult = ReturnType<typeof useAccountMlnkProfitChartQuery>;
export type AccountMlnkProfitChartLazyQueryHookResult = ReturnType<typeof useAccountMlnkProfitChartLazyQuery>;
export type AccountMlnkProfitChartQueryResult = Apollo.QueryResult<
    AccountMlnkProfitChartQuery,
    AccountMlnkProfitChartQueryVariables
>;
export const AccountPoolProfitChartDocument = gql`
    query AccountPoolProfitChart($name: String!, $poolCode: String!) {
        accountPoolProfitChart(account: $name, poolCode: $poolCode) {
            percentageChange24h
            totalProfit
            points {
                value
                timestamp
            }
        }
    }
`;

/**
 * __useAccountPoolProfitChartQuery__
 *
 * To run a query within a React component, call `useAccountPoolProfitChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountPoolProfitChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountPoolProfitChartQuery({
 *   variables: {
 *      name: // value for 'name'
 *      poolCode: // value for 'poolCode'
 *   },
 * });
 */
export function useAccountPoolProfitChartQuery(
    baseOptions: Apollo.QueryHookOptions<AccountPoolProfitChartQuery, AccountPoolProfitChartQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AccountPoolProfitChartQuery, AccountPoolProfitChartQueryVariables>(
        AccountPoolProfitChartDocument,
        options
    );
}
export function useAccountPoolProfitChartLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AccountPoolProfitChartQuery, AccountPoolProfitChartQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AccountPoolProfitChartQuery, AccountPoolProfitChartQueryVariables>(
        AccountPoolProfitChartDocument,
        options
    );
}
export type AccountPoolProfitChartQueryHookResult = ReturnType<typeof useAccountPoolProfitChartQuery>;
export type AccountPoolProfitChartLazyQueryHookResult = ReturnType<typeof useAccountPoolProfitChartLazyQuery>;
export type AccountPoolProfitChartQueryResult = Apollo.QueryResult<
    AccountPoolProfitChartQuery,
    AccountPoolProfitChartQueryVariables
>;
export const ActionsDocument = gql`
    query Actions($limit: Int, $skip: Int, $filters: ActionFilterInput, $sort: [ActionSortEnum]) {
        actions(limit: $limit, skip: $skip, filters: $filters, sort: $sort) {
            pageInfo {
                total
                totalFiltered
            }
            actions {
                ...ActionNode
            }
        }
    }
    ${ActionNodeFragmentDoc}
`;

/**
 * __useActionsQuery__
 *
 * To run a query within a React component, call `useActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useActionsQuery(baseOptions?: Apollo.QueryHookOptions<ActionsQuery, ActionsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ActionsQuery, ActionsQueryVariables>(ActionsDocument, options);
}
export function useActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActionsQuery, ActionsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ActionsQuery, ActionsQueryVariables>(ActionsDocument, options);
}
export type ActionsQueryHookResult = ReturnType<typeof useActionsQuery>;
export type ActionsLazyQueryHookResult = ReturnType<typeof useActionsLazyQuery>;
export type ActionsQueryResult = Apollo.QueryResult<ActionsQuery, ActionsQueryVariables>;
export const BookmarksDocument = gql`
    query Bookmarks {
        visitor {
            id
            accounts {
                name
            }
            pools {
                code
                token1 {
                    id
                    code
                    logo
                    smartContract
                }
                token2 {
                    id
                    code
                    logo
                    smartContract
                }
            }
            tokens {
                id
                code
                logo
                smartContract
            }
        }
    }
`;

/**
 * __useBookmarksQuery__
 *
 * To run a query within a React component, call `useBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookmarksQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookmarksQuery(baseOptions?: Apollo.QueryHookOptions<BookmarksQuery, BookmarksQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<BookmarksQuery, BookmarksQueryVariables>(BookmarksDocument, options);
}
export function useBookmarksLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<BookmarksQuery, BookmarksQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<BookmarksQuery, BookmarksQueryVariables>(BookmarksDocument, options);
}
export type BookmarksQueryHookResult = ReturnType<typeof useBookmarksQuery>;
export type BookmarksLazyQueryHookResult = ReturnType<typeof useBookmarksLazyQuery>;
export type BookmarksQueryResult = Apollo.QueryResult<BookmarksQuery, BookmarksQueryVariables>;
export const CardFeesDocument = gql`
    query CardFees {
        cardFees {
            emission {
                fixedFee
                rate
                issuerRate
                issuerFixedFee
                minimalAmount
                maximalAmount
            }
            replenishment {
                fixedFee
                rate
                issuerRate
                issuerFixedFee
                minimalAmount
                maximalAmount
            }
        }
    }
`;

/**
 * __useCardFeesQuery__
 *
 * To run a query within a React component, call `useCardFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardFeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCardFeesQuery(baseOptions?: Apollo.QueryHookOptions<CardFeesQuery, CardFeesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CardFeesQuery, CardFeesQueryVariables>(CardFeesDocument, options);
}
export function useCardFeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardFeesQuery, CardFeesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CardFeesQuery, CardFeesQueryVariables>(CardFeesDocument, options);
}
export type CardFeesQueryHookResult = ReturnType<typeof useCardFeesQuery>;
export type CardFeesLazyQueryHookResult = ReturnType<typeof useCardFeesLazyQuery>;
export type CardFeesQueryResult = Apollo.QueryResult<CardFeesQuery, CardFeesQueryVariables>;
export const CheckEosAccountDocument = gql`
    query CheckEosAccount($name: String!) {
        eosAccount(name: $name) {
            isFavorite
            name
            hasStatistic
        }
    }
`;

/**
 * __useCheckEosAccountQuery__
 *
 * To run a query within a React component, call `useCheckEosAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckEosAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckEosAccountQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCheckEosAccountQuery(
    baseOptions: Apollo.QueryHookOptions<CheckEosAccountQuery, CheckEosAccountQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CheckEosAccountQuery, CheckEosAccountQueryVariables>(CheckEosAccountDocument, options);
}
export function useCheckEosAccountLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CheckEosAccountQuery, CheckEosAccountQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CheckEosAccountQuery, CheckEosAccountQueryVariables>(CheckEosAccountDocument, options);
}
export type CheckEosAccountQueryHookResult = ReturnType<typeof useCheckEosAccountQuery>;
export type CheckEosAccountLazyQueryHookResult = ReturnType<typeof useCheckEosAccountLazyQuery>;
export type CheckEosAccountQueryResult = Apollo.QueryResult<CheckEosAccountQuery, CheckEosAccountQueryVariables>;
export const EosAccountDocument = gql`
    query EosAccount($name: String!) {
        eosAccount(name: $name) {
            isFavorite
            name
            heldTokens: holdedTokens {
                code
                amount
                logo
                smartContract
                usdEquivalent
                shareInPortfolio
            }
            heldPools: holdedPools {
                code
                usdEquivalent
                shareInPortfolio
                token1Amount
                token1 {
                    id
                    code
                    logo
                    smartContract
                }
                token2Amount
                token2 {
                    id
                    code
                    logo
                    smartContract
                }
            }
            mlnkCollectParams {
                memo
                receiver
                metaInfo
                deeplink
            }
        }
    }
`;

/**
 * __useEosAccountQuery__
 *
 * To run a query within a React component, call `useEosAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEosAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEosAccountQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEosAccountQuery(baseOptions: Apollo.QueryHookOptions<EosAccountQuery, EosAccountQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<EosAccountQuery, EosAccountQueryVariables>(EosAccountDocument, options);
}
export function useEosAccountLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<EosAccountQuery, EosAccountQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<EosAccountQuery, EosAccountQueryVariables>(EosAccountDocument, options);
}
export type EosAccountQueryHookResult = ReturnType<typeof useEosAccountQuery>;
export type EosAccountLazyQueryHookResult = ReturnType<typeof useEosAccountLazyQuery>;
export type EosAccountQueryResult = Apollo.QueryResult<EosAccountQuery, EosAccountQueryVariables>;
export const EosAccountInvestmentChartDocument = gql`
    query EosAccountInvestmentChart($name: String!) {
        accountInvestmentChart(account: $name) {
            value24h
            percentageChange24h
            points {
                value
                timestamp
            }
        }
    }
`;

/**
 * __useEosAccountInvestmentChartQuery__
 *
 * To run a query within a React component, call `useEosAccountInvestmentChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useEosAccountInvestmentChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEosAccountInvestmentChartQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEosAccountInvestmentChartQuery(
    baseOptions: Apollo.QueryHookOptions<EosAccountInvestmentChartQuery, EosAccountInvestmentChartQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<EosAccountInvestmentChartQuery, EosAccountInvestmentChartQueryVariables>(
        EosAccountInvestmentChartDocument,
        options
    );
}
export function useEosAccountInvestmentChartLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<EosAccountInvestmentChartQuery, EosAccountInvestmentChartQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<EosAccountInvestmentChartQuery, EosAccountInvestmentChartQueryVariables>(
        EosAccountInvestmentChartDocument,
        options
    );
}
export type EosAccountInvestmentChartQueryHookResult = ReturnType<typeof useEosAccountInvestmentChartQuery>;
export type EosAccountInvestmentChartLazyQueryHookResult = ReturnType<typeof useEosAccountInvestmentChartLazyQuery>;
export type EosAccountInvestmentChartQueryResult = Apollo.QueryResult<
    EosAccountInvestmentChartQuery,
    EosAccountInvestmentChartQueryVariables
>;
export const EosAccountPoolDocument = gql`
    query EosAccountPool($name: String!, $poolCode: String!) {
        eosAccount(name: $name) {
            isFavorite
            name
            mlnkCollectParams {
                memo
                receiver
                metaInfo
                deeplink
            }
            heldPool: holdedPool(poolCode: $poolCode) {
                code
                usdEquivalent
                shareInPool
                myLiquidityTokens
                totalLiquidityTokens
                token1Amount
                token1UsdAmount
                token1 {
                    id
                    code
                    logo
                    publicName
                    smartContract
                }
                token2Amount
                token2UsdAmount
                token2 {
                    id
                    code
                    logo
                    publicName
                    smartContract
                }
            }
        }
        accountPoolProfitChart(account: $name, poolCode: $poolCode) {
            percentageChange24h
            totalProfit
            points {
                value
                timestamp
            }
        }
        accountPoolProfitDetails(account: $name, poolCode: $poolCode) {
            mlnkUsd
            token1Usd
            token2Usd
            total24hProfit
        }
    }
`;

/**
 * __useEosAccountPoolQuery__
 *
 * To run a query within a React component, call `useEosAccountPoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useEosAccountPoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEosAccountPoolQuery({
 *   variables: {
 *      name: // value for 'name'
 *      poolCode: // value for 'poolCode'
 *   },
 * });
 */
export function useEosAccountPoolQuery(
    baseOptions: Apollo.QueryHookOptions<EosAccountPoolQuery, EosAccountPoolQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<EosAccountPoolQuery, EosAccountPoolQueryVariables>(EosAccountPoolDocument, options);
}
export function useEosAccountPoolLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<EosAccountPoolQuery, EosAccountPoolQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<EosAccountPoolQuery, EosAccountPoolQueryVariables>(EosAccountPoolDocument, options);
}
export type EosAccountPoolQueryHookResult = ReturnType<typeof useEosAccountPoolQuery>;
export type EosAccountPoolLazyQueryHookResult = ReturnType<typeof useEosAccountPoolLazyQuery>;
export type EosAccountPoolQueryResult = Apollo.QueryResult<EosAccountPoolQuery, EosAccountPoolQueryVariables>;
export const ExchangeDocument = gql`
    query Exchange($id: String!) {
        exchange(id: $id) {
            ...ExchangeNode
        }
    }
    ${ExchangeNodeFragmentDoc}
`;

/**
 * __useExchangeQuery__
 *
 * To run a query within a React component, call `useExchangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExchangeQuery(baseOptions: Apollo.QueryHookOptions<ExchangeQuery, ExchangeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ExchangeQuery, ExchangeQueryVariables>(ExchangeDocument, options);
}
export function useExchangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExchangeQuery, ExchangeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ExchangeQuery, ExchangeQueryVariables>(ExchangeDocument, options);
}
export type ExchangeQueryHookResult = ReturnType<typeof useExchangeQuery>;
export type ExchangeLazyQueryHookResult = ReturnType<typeof useExchangeLazyQuery>;
export type ExchangeQueryResult = Apollo.QueryResult<ExchangeQuery, ExchangeQueryVariables>;
export const ExchangeCalcDocument = gql`
    query ExchangeCalc($input: ExchangeCalcInput!) {
        exchangeCalc(input: $input) {
            ... on ExchangeCalcNode {
                amountToReceive
                orderAmount
                amountToPay
                exchangeRate
                inputFee
                outputFee
            }
            ... on CurrencyNotFoundError {
                errorMessage
            }
            ... on FiatMethodNotFoundError {
                errorMessage
            }
            ... on ExchangePairNotPresentError {
                errorMessage
            }
            ... on ExchangePairNotFoundError {
                errorMessage
            }
            ... on ExchangeAmountTooLowError {
                errorMessage
            }
            ... on ExchangeAmountTooHighError {
                errorMessage
            }
            ... on InvalidTransferParamsError {
                errorMessage
            }
        }
    }
`;

/**
 * __useExchangeCalcQuery__
 *
 * To run a query within a React component, call `useExchangeCalcQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangeCalcQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangeCalcQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExchangeCalcQuery(
    baseOptions: Apollo.QueryHookOptions<ExchangeCalcQuery, ExchangeCalcQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ExchangeCalcQuery, ExchangeCalcQueryVariables>(ExchangeCalcDocument, options);
}
export function useExchangeCalcLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ExchangeCalcQuery, ExchangeCalcQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ExchangeCalcQuery, ExchangeCalcQueryVariables>(ExchangeCalcDocument, options);
}
export type ExchangeCalcQueryHookResult = ReturnType<typeof useExchangeCalcQuery>;
export type ExchangeCalcLazyQueryHookResult = ReturnType<typeof useExchangeCalcLazyQuery>;
export type ExchangeCalcQueryResult = Apollo.QueryResult<ExchangeCalcQuery, ExchangeCalcQueryVariables>;
export const ExchangePairsDocument = gql`
    query ExchangePairs($filters: ExchangePairFilterInput) {
        exchangePairs(filters: $filters) {
            id
            type
            inputMethodId
            inputCurrencyName
            outputMethodId
            outputCurrencyName
            inputFeeFix
            inputFeePercentage
            outputFeeFix
            outputFeePercentage
            exchangeRate
            minimalAmount
            maximalAmount
            inputPrecision
            outputPrecision
            isEnabled
            isAvailable
            inputMethod {
                ... on CurrencyNode {
                    blockchain
                    feeFix
                    feePercentage
                    id
                    minTransferAmount
                    name
                    precision
                    withdrawFeeFix
                    withdrawFeePercentage
                }
                ... on FiatMethodNode {
                    currencyName
                    direction
                    feeFix
                    feePercentage
                    id
                    location
                    maximalAmount
                    minimalAmount
                    name
                }
            }
            outputMethod {
                ... on CurrencyNode {
                    blockchain
                    feeFix
                    feePercentage
                    id
                    minTransferAmount
                    name
                    precision
                    withdrawFeeFix
                    withdrawFeePercentage
                }
                ... on FiatMethodNode {
                    currencyName
                    direction
                    feeFix
                    feePercentage
                    id
                    location
                    maximalAmount
                    minimalAmount
                    name
                }
            }
        }
    }
`;

/**
 * __useExchangePairsQuery__
 *
 * To run a query within a React component, call `useExchangePairsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangePairsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangePairsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useExchangePairsQuery(
    baseOptions?: Apollo.QueryHookOptions<ExchangePairsQuery, ExchangePairsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ExchangePairsQuery, ExchangePairsQueryVariables>(ExchangePairsDocument, options);
}
export function useExchangePairsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ExchangePairsQuery, ExchangePairsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ExchangePairsQuery, ExchangePairsQueryVariables>(ExchangePairsDocument, options);
}
export type ExchangePairsQueryHookResult = ReturnType<typeof useExchangePairsQuery>;
export type ExchangePairsLazyQueryHookResult = ReturnType<typeof useExchangePairsLazyQuery>;
export type ExchangePairsQueryResult = Apollo.QueryResult<ExchangePairsQuery, ExchangePairsQueryVariables>;
export const ExchangeSystemStatusDocument = gql`
    query ExchangeSystemStatus {
        exchangeSystemStatus {
            maintenance {
                isActive
                description
                endTime
            }
        }
    }
`;

/**
 * __useExchangeSystemStatusQuery__
 *
 * To run a query within a React component, call `useExchangeSystemStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangeSystemStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangeSystemStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useExchangeSystemStatusQuery(
    baseOptions?: Apollo.QueryHookOptions<ExchangeSystemStatusQuery, ExchangeSystemStatusQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ExchangeSystemStatusQuery, ExchangeSystemStatusQueryVariables>(
        ExchangeSystemStatusDocument,
        options
    );
}
export function useExchangeSystemStatusLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ExchangeSystemStatusQuery, ExchangeSystemStatusQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ExchangeSystemStatusQuery, ExchangeSystemStatusQueryVariables>(
        ExchangeSystemStatusDocument,
        options
    );
}
export type ExchangeSystemStatusQueryHookResult = ReturnType<typeof useExchangeSystemStatusQuery>;
export type ExchangeSystemStatusLazyQueryHookResult = ReturnType<typeof useExchangeSystemStatusLazyQuery>;
export type ExchangeSystemStatusQueryResult = Apollo.QueryResult<
    ExchangeSystemStatusQuery,
    ExchangeSystemStatusQueryVariables
>;
export const MlnkCollectedDocument = gql`
    query MLNKCollected {
        mlnkStatistic {
            total
            burned
            collected
            left
            lastBlockNum
            mlnkInLastBlock
            mlnkHoldersNum
            mlnkInLast24h
        }
    }
`;

/**
 * __useMlnkCollectedQuery__
 *
 * To run a query within a React component, call `useMlnkCollectedQuery` and pass it any options that fit your needs.
 * When your component renders, `useMlnkCollectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMlnkCollectedQuery({
 *   variables: {
 *   },
 * });
 */
export function useMlnkCollectedQuery(
    baseOptions?: Apollo.QueryHookOptions<MlnkCollectedQuery, MlnkCollectedQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MlnkCollectedQuery, MlnkCollectedQueryVariables>(MlnkCollectedDocument, options);
}
export function useMlnkCollectedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<MlnkCollectedQuery, MlnkCollectedQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MlnkCollectedQuery, MlnkCollectedQueryVariables>(MlnkCollectedDocument, options);
}
export type MlnkCollectedQueryHookResult = ReturnType<typeof useMlnkCollectedQuery>;
export type MlnkCollectedLazyQueryHookResult = ReturnType<typeof useMlnkCollectedLazyQuery>;
export type MlnkCollectedQueryResult = Apollo.QueryResult<MlnkCollectedQuery, MlnkCollectedQueryVariables>;
export const MlnkPriceDocument = gql`
    query MlnkPrice(
        $resolution: ChartResolutionEnum
        $filterFromTimestamp: DateTime
        $filterToTimestamp: DateTime
        $fetchMore: Boolean = false
    ) {
        mlnkPriceChart(
            resolution: $resolution
            filterFromTimestamp: $filterFromTimestamp
            filterToTimestamp: $filterToTimestamp
        ) {
            pageInfo @skip(if: $fetchMore) {
                from
                to
            }
            value24h @skip(if: $fetchMore)
            percentageChange24h @skip(if: $fetchMore)
            percentageChangeWeek @skip(if: $fetchMore)
            percentageChangeMonth @skip(if: $fetchMore)
            points {
                timestamp
                low
                high
                open
                close
            }
        }
    }
`;

/**
 * __useMlnkPriceQuery__
 *
 * To run a query within a React component, call `useMlnkPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useMlnkPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMlnkPriceQuery({
 *   variables: {
 *      resolution: // value for 'resolution'
 *      filterFromTimestamp: // value for 'filterFromTimestamp'
 *      filterToTimestamp: // value for 'filterToTimestamp'
 *      fetchMore: // value for 'fetchMore'
 *   },
 * });
 */
export function useMlnkPriceQuery(baseOptions?: Apollo.QueryHookOptions<MlnkPriceQuery, MlnkPriceQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MlnkPriceQuery, MlnkPriceQueryVariables>(MlnkPriceDocument, options);
}
export function useMlnkPriceLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<MlnkPriceQuery, MlnkPriceQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MlnkPriceQuery, MlnkPriceQueryVariables>(MlnkPriceDocument, options);
}
export type MlnkPriceQueryHookResult = ReturnType<typeof useMlnkPriceQuery>;
export type MlnkPriceLazyQueryHookResult = ReturnType<typeof useMlnkPriceLazyQuery>;
export type MlnkPriceQueryResult = Apollo.QueryResult<MlnkPriceQuery, MlnkPriceQueryVariables>;
export const MyCardAccountDocument = gql`
    query MyCardAccount {
        myCardAccount {
            eosAccount
            emissionStatus
            activeCard {
                id
                emittedAt
                cardholderName
                eosAccount
                currency
                status
                privateInfo {
                    cardNumber
                    expirationDate
                    cvv
                    pinCode
                }
                balance {
                    available
                    pending
                    frozen
                }
            }
            action {
                amountToReceive
                createdAt
                currencyToReceive
                eosAccount
                id
                status
                type
                txid
                paymentParams {
                    amount
                    currency
                    deeplinks {
                        name
                        link
                    }
                    expirationDate
                    memo
                    receiver
                }
            }
        }
    }
`;

/**
 * __useMyCardAccountQuery__
 *
 * To run a query within a React component, call `useMyCardAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCardAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCardAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyCardAccountQuery(
    baseOptions?: Apollo.QueryHookOptions<MyCardAccountQuery, MyCardAccountQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MyCardAccountQuery, MyCardAccountQueryVariables>(MyCardAccountDocument, options);
}
export function useMyCardAccountLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<MyCardAccountQuery, MyCardAccountQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MyCardAccountQuery, MyCardAccountQueryVariables>(MyCardAccountDocument, options);
}
export type MyCardAccountQueryHookResult = ReturnType<typeof useMyCardAccountQuery>;
export type MyCardAccountLazyQueryHookResult = ReturnType<typeof useMyCardAccountLazyQuery>;
export type MyCardAccountQueryResult = Apollo.QueryResult<MyCardAccountQuery, MyCardAccountQueryVariables>;
export const MyCardHistoryDocument = gql`
    query MyCardHistory($cardId: String!) {
        myCardHistory(cardId: $cardId) {
            transactions {
                id
                currency
                amount
                fee
                type
                detail
                status
                createdAt
                completedAt
            }
            pageTotal
            total
        }
    }
`;

/**
 * __useMyCardHistoryQuery__
 *
 * To run a query within a React component, call `useMyCardHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCardHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCardHistoryQuery({
 *   variables: {
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useMyCardHistoryQuery(
    baseOptions: Apollo.QueryHookOptions<MyCardHistoryQuery, MyCardHistoryQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MyCardHistoryQuery, MyCardHistoryQueryVariables>(MyCardHistoryDocument, options);
}
export function useMyCardHistoryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<MyCardHistoryQuery, MyCardHistoryQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MyCardHistoryQuery, MyCardHistoryQueryVariables>(MyCardHistoryDocument, options);
}
export type MyCardHistoryQueryHookResult = ReturnType<typeof useMyCardHistoryQuery>;
export type MyCardHistoryLazyQueryHookResult = ReturnType<typeof useMyCardHistoryLazyQuery>;
export type MyCardHistoryQueryResult = Apollo.QueryResult<MyCardHistoryQuery, MyCardHistoryQueryVariables>;
export const PoolDocument = gql`
    query Pool($code: String!) {
        pool(code: $code) {
            code
            isFavorite
            poolId
            fee24h
            fee24hGrowth
            token1Amount
            token2ToToken1Rate
            token2Amount
            token1ToToken2Rate
            token1 {
                ...PoolTokenData
            }
            token2 {
                ...PoolTokenData
            }
            mlnkIncomeDetails {
                hasMlnkIncome
                hasHighMlnkIncome
            }
            liquidityTokensAmount
            profit24h
            profit24hGrowth
            relative24hProfit
            profitAnnual
            relativeAnnualProfit
            profitDetails {
                mlnk
                mlnkUsd
                mlnkPercent
                token1
                token1Usd
                token1Percent
                token2
                token2Usd
                token2Percent
            }
        }
        poolLiquidityChart(poolCode: $code) {
            percentageChange24h
            value24h
            points {
                value
                timestamp
            }
        }
        poolVolumeChart(poolCode: $code) {
            lastWeekValue
            percentageChange24h
            value24h
            points {
                value
                time: timestamp
            }
        }
    }
    ${PoolTokenDataFragmentDoc}
`;

/**
 * __usePoolQuery__
 *
 * To run a query within a React component, call `usePoolQuery` and pass it any options that fit your needs.
 * When your component renders, `usePoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePoolQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function usePoolQuery(baseOptions: Apollo.QueryHookOptions<PoolQuery, PoolQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PoolQuery, PoolQueryVariables>(PoolDocument, options);
}
export function usePoolLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PoolQuery, PoolQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PoolQuery, PoolQueryVariables>(PoolDocument, options);
}
export type PoolQueryHookResult = ReturnType<typeof usePoolQuery>;
export type PoolLazyQueryHookResult = ReturnType<typeof usePoolLazyQuery>;
export type PoolQueryResult = Apollo.QueryResult<PoolQuery, PoolQueryVariables>;
export const PoolsDocument = gql`
    query Pools($sort: PoolsSortEnum, $limit: Int, $skip: Int, $filters: PoolsFilterInput) {
        pools(limit: $limit, skip: $skip, sort: $sort, filters: $filters) {
            pools {
                code
                isFavorite
                liquidity
                liquidityGrowth
                volume24h
                volume24hGrowth
                profit24hGrowth
                relative24hProfit
                relativeAnnualProfit
                mlnkIncomeDetails {
                    hasMlnkIncome
                    hasHighMlnkIncome
                }
                token1 {
                    id
                    code
                    logo
                    smartContract
                }
                token2 {
                    id
                    code
                    logo
                    smartContract
                }
            }
            pageInfo {
                total
                totalFiltered
            }
        }
    }
`;

/**
 * __usePoolsQuery__
 *
 * To run a query within a React component, call `usePoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePoolsQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePoolsQuery(baseOptions?: Apollo.QueryHookOptions<PoolsQuery, PoolsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PoolsQuery, PoolsQueryVariables>(PoolsDocument, options);
}
export function usePoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PoolsQuery, PoolsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PoolsQuery, PoolsQueryVariables>(PoolsDocument, options);
}
export type PoolsQueryHookResult = ReturnType<typeof usePoolsQuery>;
export type PoolsLazyQueryHookResult = ReturnType<typeof usePoolsLazyQuery>;
export type PoolsQueryResult = Apollo.QueryResult<PoolsQuery, PoolsQueryVariables>;
export const PoolNamesDocument = gql`
    query PoolNames {
        pools {
            pools {
                code
                mlnkIncomeDetails {
                    hasMlnkIncome
                    hasHighMlnkIncome
                }
                token1 {
                    id
                    code
                    logo
                    smartContract
                }
                token2 {
                    id
                    code
                    logo
                    smartContract
                }
            }
        }
    }
`;

/**
 * __usePoolNamesQuery__
 *
 * To run a query within a React component, call `usePoolNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePoolNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePoolNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePoolNamesQuery(baseOptions?: Apollo.QueryHookOptions<PoolNamesQuery, PoolNamesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PoolNamesQuery, PoolNamesQueryVariables>(PoolNamesDocument, options);
}
export function usePoolNamesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PoolNamesQuery, PoolNamesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PoolNamesQuery, PoolNamesQueryVariables>(PoolNamesDocument, options);
}
export type PoolNamesQueryHookResult = ReturnType<typeof usePoolNamesQuery>;
export type PoolNamesLazyQueryHookResult = ReturnType<typeof usePoolNamesLazyQuery>;
export type PoolNamesQueryResult = Apollo.QueryResult<PoolNamesQuery, PoolNamesQueryVariables>;
export const PoolProfitChartDocument = gql`
    query PoolProfitChart($poolCode: String!) {
        poolProfitChart(poolCode: $poolCode) {
            points {
                value
                timestamp
            }
        }
    }
`;

/**
 * __usePoolProfitChartQuery__
 *
 * To run a query within a React component, call `usePoolProfitChartQuery` and pass it any options that fit your needs.
 * When your component renders, `usePoolProfitChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePoolProfitChartQuery({
 *   variables: {
 *      poolCode: // value for 'poolCode'
 *   },
 * });
 */
export function usePoolProfitChartQuery(
    baseOptions: Apollo.QueryHookOptions<PoolProfitChartQuery, PoolProfitChartQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PoolProfitChartQuery, PoolProfitChartQueryVariables>(PoolProfitChartDocument, options);
}
export function usePoolProfitChartLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PoolProfitChartQuery, PoolProfitChartQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PoolProfitChartQuery, PoolProfitChartQueryVariables>(PoolProfitChartDocument, options);
}
export type PoolProfitChartQueryHookResult = ReturnType<typeof usePoolProfitChartQuery>;
export type PoolProfitChartLazyQueryHookResult = ReturnType<typeof usePoolProfitChartLazyQuery>;
export type PoolProfitChartQueryResult = Apollo.QueryResult<PoolProfitChartQuery, PoolProfitChartQueryVariables>;
export const SearchEosAccountsDocument = gql`
    query SearchEosAccounts($part: String!, $limit: Int, $skip: Int) {
        searchEosAccounts(part: $part, limit: $limit, skip: $skip) {
            accounts {
                name
                isFavorite
            }
            total
        }
    }
`;

/**
 * __useSearchEosAccountsQuery__
 *
 * To run a query within a React component, call `useSearchEosAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchEosAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchEosAccountsQuery({
 *   variables: {
 *      part: // value for 'part'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useSearchEosAccountsQuery(
    baseOptions: Apollo.QueryHookOptions<SearchEosAccountsQuery, SearchEosAccountsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SearchEosAccountsQuery, SearchEosAccountsQueryVariables>(SearchEosAccountsDocument, options);
}
export function useSearchEosAccountsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SearchEosAccountsQuery, SearchEosAccountsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SearchEosAccountsQuery, SearchEosAccountsQueryVariables>(
        SearchEosAccountsDocument,
        options
    );
}
export type SearchEosAccountsQueryHookResult = ReturnType<typeof useSearchEosAccountsQuery>;
export type SearchEosAccountsLazyQueryHookResult = ReturnType<typeof useSearchEosAccountsLazyQuery>;
export type SearchEosAccountsQueryResult = Apollo.QueryResult<SearchEosAccountsQuery, SearchEosAccountsQueryVariables>;
export const TokenDocument = gql`
    query Token($id: Int!) {
        token(id: $id) {
            id
            code
            smartContract
            publicName
            logo
            isFavorite
            reverseRate
            chain
        }
        tokenPriceChart(tokenId: $id) {
            value24h
            percentageChange24h
            points {
                value
                time: timestamp
            }
        }
        tokenLiquidityChart(tokenId: $id) {
            value24h
            percentageChange24h
            points {
                value
                time: timestamp
            }
        }
        tokenVolumeChart(tokenId: $id) {
            value24h
            percentageChange24h
            lastWeekValue
            points {
                value
                time: timestamp
            }
        }
        tokenActionsNumChart(tokenId: $id) {
            value24h
            percentageChange24h
            points {
                value
                time: timestamp
            }
        }
    }
`;

/**
 * __useTokenQuery__
 *
 * To run a query within a React component, call `useTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTokenQuery(baseOptions: Apollo.QueryHookOptions<TokenQuery, TokenQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<TokenQuery, TokenQueryVariables>(TokenDocument, options);
}
export function useTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokenQuery, TokenQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<TokenQuery, TokenQueryVariables>(TokenDocument, options);
}
export type TokenQueryHookResult = ReturnType<typeof useTokenQuery>;
export type TokenLazyQueryHookResult = ReturnType<typeof useTokenLazyQuery>;
export type TokenQueryResult = Apollo.QueryResult<TokenQuery, TokenQueryVariables>;
export const TokensDocument = gql`
    query Tokens($filters: TokenFilterInput, $sort: [TokenSortEnum], $limit: Int, $skip: Int) {
        tokens(filters: $filters, sort: $sort, limit: $limit, skip: $skip) {
            pageInfo {
                total
                totalFiltered
            }
            tokens {
                id
                code
                publicName
                isFavorite
                liquidity
                liquidityGrowth
                rateToUsd
                rateGrowth
                volume24h
                volume24hGrowth
                smartContract
                logo
            }
        }
    }
`;

/**
 * __useTokensQuery__
 *
 * To run a query within a React component, call `useTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokensQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useTokensQuery(baseOptions?: Apollo.QueryHookOptions<TokensQuery, TokensQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<TokensQuery, TokensQueryVariables>(TokensDocument, options);
}
export function useTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokensQuery, TokensQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<TokensQuery, TokensQueryVariables>(TokensDocument, options);
}
export type TokensQueryHookResult = ReturnType<typeof useTokensQuery>;
export type TokensLazyQueryHookResult = ReturnType<typeof useTokensLazyQuery>;
export type TokensQueryResult = Apollo.QueryResult<TokensQuery, TokensQueryVariables>;
export const TopPoolsDocument = gql`
    query TopPools {
        topProfitPools: pools(limit: 5, sort: RELATIVE_PROFIT_ANNUAL_DESC, filters: { liquidity: { from: "1000" } }) {
            pools {
                code
                liquidity
                volume24h
                relativeAnnualProfit
                token1 {
                    id
                    code
                    logo
                    rateToUsd
                    smartContract
                }
                token2 {
                    id
                    code
                    logo
                    rateToUsd
                    smartContract
                }
            }
        }
    }
`;

/**
 * __useTopPoolsQuery__
 *
 * To run a query within a React component, call `useTopPoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopPoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopPoolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTopPoolsQuery(baseOptions?: Apollo.QueryHookOptions<TopPoolsQuery, TopPoolsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<TopPoolsQuery, TopPoolsQueryVariables>(TopPoolsDocument, options);
}
export function useTopPoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopPoolsQuery, TopPoolsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<TopPoolsQuery, TopPoolsQueryVariables>(TopPoolsDocument, options);
}
export type TopPoolsQueryHookResult = ReturnType<typeof useTopPoolsQuery>;
export type TopPoolsLazyQueryHookResult = ReturnType<typeof useTopPoolsLazyQuery>;
export type TopPoolsQueryResult = Apollo.QueryResult<TopPoolsQuery, TopPoolsQueryVariables>;
export const TopTokensDocument = gql`
    query TopTokens {
        topProfitTokens: tokens(limit: 5, sort: LIQUIDITY_DESC, filters: { liquidity: { from: "1000" } }) {
            tokens {
                id
                code
                logo
                volume24h
                volume24hGrowth
                liquidity
                actionsNum24h
                rateToUsd
                smartContract
            }
        }
    }
`;

/**
 * __useTopTokensQuery__
 *
 * To run a query within a React component, call `useTopTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopTokensQuery({
 *   variables: {
 *   },
 * });
 */
export function useTopTokensQuery(baseOptions?: Apollo.QueryHookOptions<TopTokensQuery, TopTokensQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<TopTokensQuery, TopTokensQueryVariables>(TopTokensDocument, options);
}
export function useTopTokensLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<TopTokensQuery, TopTokensQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<TopTokensQuery, TopTokensQueryVariables>(TopTokensDocument, options);
}
export type TopTokensQueryHookResult = ReturnType<typeof useTopTokensQuery>;
export type TopTokensLazyQueryHookResult = ReturnType<typeof useTopTokensLazyQuery>;
export type TopTokensQueryResult = Apollo.QueryResult<TopTokensQuery, TopTokensQueryVariables>;
export const TotalDepositStatisticDocument = gql`
    query TotalDepositStatistic {
        totalDepositStatistic {
            ...TotalDepositStatisticNode
        }
    }
    ${TotalDepositStatisticNodeFragmentDoc}
`;

/**
 * __useTotalDepositStatisticQuery__
 *
 * To run a query within a React component, call `useTotalDepositStatisticQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalDepositStatisticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalDepositStatisticQuery({
 *   variables: {
 *   },
 * });
 */
export function useTotalDepositStatisticQuery(
    baseOptions?: Apollo.QueryHookOptions<TotalDepositStatisticQuery, TotalDepositStatisticQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<TotalDepositStatisticQuery, TotalDepositStatisticQueryVariables>(
        TotalDepositStatisticDocument,
        options
    );
}
export function useTotalDepositStatisticLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<TotalDepositStatisticQuery, TotalDepositStatisticQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<TotalDepositStatisticQuery, TotalDepositStatisticQueryVariables>(
        TotalDepositStatisticDocument,
        options
    );
}
export type TotalDepositStatisticQueryHookResult = ReturnType<typeof useTotalDepositStatisticQuery>;
export type TotalDepositStatisticLazyQueryHookResult = ReturnType<typeof useTotalDepositStatisticLazyQuery>;
export type TotalDepositStatisticQueryResult = Apollo.QueryResult<
    TotalDepositStatisticQuery,
    TotalDepositStatisticQueryVariables
>;
export const TotalLiquidityDocument = gql`
    query TotalLiquidity {
        totalLiquidityChart {
            value24h
            percentageChange24h
            points {
                timestamp
                value
            }
        }
    }
`;

/**
 * __useTotalLiquidityQuery__
 *
 * To run a query within a React component, call `useTotalLiquidityQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalLiquidityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalLiquidityQuery({
 *   variables: {
 *   },
 * });
 */
export function useTotalLiquidityQuery(
    baseOptions?: Apollo.QueryHookOptions<TotalLiquidityQuery, TotalLiquidityQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<TotalLiquidityQuery, TotalLiquidityQueryVariables>(TotalLiquidityDocument, options);
}
export function useTotalLiquidityLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<TotalLiquidityQuery, TotalLiquidityQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<TotalLiquidityQuery, TotalLiquidityQueryVariables>(TotalLiquidityDocument, options);
}
export type TotalLiquidityQueryHookResult = ReturnType<typeof useTotalLiquidityQuery>;
export type TotalLiquidityLazyQueryHookResult = ReturnType<typeof useTotalLiquidityLazyQuery>;
export type TotalLiquidityQueryResult = Apollo.QueryResult<TotalLiquidityQuery, TotalLiquidityQueryVariables>;
export const TotalVolumeDocument = gql`
    query TotalVolume {
        totalVolumeChart {
            value24h
            lastWeekValue
            percentageChange24h
            points {
                timestamp
                value
            }
        }
    }
`;

/**
 * __useTotalVolumeQuery__
 *
 * To run a query within a React component, call `useTotalVolumeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalVolumeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalVolumeQuery({
 *   variables: {
 *   },
 * });
 */
export function useTotalVolumeQuery(
    baseOptions?: Apollo.QueryHookOptions<TotalVolumeQuery, TotalVolumeQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<TotalVolumeQuery, TotalVolumeQueryVariables>(TotalVolumeDocument, options);
}
export function useTotalVolumeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<TotalVolumeQuery, TotalVolumeQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<TotalVolumeQuery, TotalVolumeQueryVariables>(TotalVolumeDocument, options);
}
export type TotalVolumeQueryHookResult = ReturnType<typeof useTotalVolumeQuery>;
export type TotalVolumeLazyQueryHookResult = ReturnType<typeof useTotalVolumeLazyQuery>;
export type TotalVolumeQueryResult = Apollo.QueryResult<TotalVolumeQuery, TotalVolumeQueryVariables>;
export const ExchangeStatusWsDocument = gql`
    subscription ExchangeStatusWS($exchangeId: String!) {
        exchangeStatus(exchangeId: $exchangeId) {
            ...ExchangeNode
        }
    }
    ${ExchangeNodeFragmentDoc}
`;

/**
 * __useExchangeStatusWsSubscription__
 *
 * To run a query within a React component, call `useExchangeStatusWsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useExchangeStatusWsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangeStatusWsSubscription({
 *   variables: {
 *      exchangeId: // value for 'exchangeId'
 *   },
 * });
 */
export function useExchangeStatusWsSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<ExchangeStatusWsSubscription, ExchangeStatusWsSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<ExchangeStatusWsSubscription, ExchangeStatusWsSubscriptionVariables>(
        ExchangeStatusWsDocument,
        options
    );
}
export type ExchangeStatusWsSubscriptionHookResult = ReturnType<typeof useExchangeStatusWsSubscription>;
export type ExchangeStatusWsSubscriptionResult = Apollo.SubscriptionResult<ExchangeStatusWsSubscription>;
export const MlnkBalanceDocument = gql`
    subscription MlnkBalance($eosAccount: String!) {
        mlnkBalance(eosAccount: $eosAccount) {
            mlnkBalance
            name
        }
    }
`;

/**
 * __useMlnkBalanceSubscription__
 *
 * To run a query within a React component, call `useMlnkBalanceSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMlnkBalanceSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMlnkBalanceSubscription({
 *   variables: {
 *      eosAccount: // value for 'eosAccount'
 *   },
 * });
 */
export function useMlnkBalanceSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<MlnkBalanceSubscription, MlnkBalanceSubscriptionVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<MlnkBalanceSubscription, MlnkBalanceSubscriptionVariables>(
        MlnkBalanceDocument,
        options
    );
}
export type MlnkBalanceSubscriptionHookResult = ReturnType<typeof useMlnkBalanceSubscription>;
export type MlnkBalanceSubscriptionResult = Apollo.SubscriptionResult<MlnkBalanceSubscription>;

export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[];
    };
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        CalculateCardEmissionOutput: ['CardActionCalculationNode'],
        CalculateCardReplenishmentOutput: ['CardActionCalculationNode', 'CardIsInactiveError', 'CardNotFoundError'],
        CalculationOutput: [
            'CurrencyNotFoundError',
            'ExchangeAmountTooHighError',
            'ExchangeAmountTooLowError',
            'ExchangeCalcNode',
            'ExchangePairNotFoundError',
            'ExchangePairNotPresentError',
            'FiatMethodNotFoundError',
            'InvalidTransferParamsError',
            'UnderMaintenanceError'
        ],
        EmitCardOutput: [
            'CardActionNode',
            'CardEmissionDisallowedError',
            'InvalidCardholderError',
            'UnderMaintenanceError'
        ],
        ExchangeMethodNode: ['CurrencyNode', 'FiatMethodNode'],
        MakeExchangeOutput: [
            'CurrencyNotFoundError',
            'ExchangeAmountTooHighError',
            'ExchangeAmountTooLowError',
            'ExchangePairNotFoundError',
            'ExchangePairNotPresentError',
            'FiatMethodNotFoundError',
            'InvalidTransferParamsError',
            'MakeExchangeSuccess',
            'UnderMaintenanceError'
        ],
        ReplenishCardOutput: ['CardActionNode', 'CardIsInactiveError', 'CardNotFoundError', 'UnderMaintenanceError'],
        SendExchangeEmailOutput: ['ExchangeNotFoundError', 'SendExchangeEmailSuccess'],
        UpdateFavoriteAccountOutput: ['EosAccountNotFoundError', 'UpdateFavoriteAccountSuccess', 'UserNotFoundError'],
        UpdateFavoritePoolOutput: ['PoolNotFoundError', 'UpdateFavoritePoolSuccess', 'UserNotFoundError'],
        UpdateFavoriteTokenOutput: ['TokenNotFoundError', 'UpdateFavoriteTokenSuccess', 'UserNotFoundError']
    }
};
export default result;
