import { ErrorBoundary as SErrorBoundary } from '@sentry/react';
import PageError from 'pages/Error/Error';
import React from 'react';

function ErrorBoundary({ children }: { children: React.ReactNode }) {
    return (
        <SErrorBoundary
            // eslint-disable-next-line react/no-unstable-nested-components
            fallback={({ resetError }) => <PageError error={500} onClickBtn={resetError} />}
            showDialog={false}
        >
            {children}
        </SErrorBoundary>
    );
}

export default ErrorBoundary;
