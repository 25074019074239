import React from 'react';
import classNames from 'classnames/bind';
import { Trans, useTranslation } from 'react-i18next';
import MLNKLogo from 'assets/images/tokens/mlnk.svg';
import styles from './AboutProjectIncomeDescription.module.scss';

const cx = classNames.bind(styles);

function IncomeDescription() {
    const [t] = useTranslation();
    return (
        <section>
            <div className={cx('Container')}>
                <div className={cx('FeeDescription')}>
                    <h2 className={cx('Investor')}>
                        <Trans i18nKey="pageInfo.feeIncome.investor">
                            <b />
                        </Trans>
                    </h2>
                    <h2 className={cx('Platform')}>
                        <Trans i18nKey="pageInfo.feeIncome.platform">
                            <b />
                        </Trans>
                    </h2>
                </div>

                <div className={cx('MlnkDescription')}>
                    <div className={cx('MlnkDescriptionImages')}>
                        <img src={MLNKLogo} alt="MLNK" />
                        <img src={MLNKLogo} alt="MLNK" />
                        <img src={MLNKLogo} alt="MLNK" />
                    </div>

                    <h2>{t('pageInfo.mlnkIncome.title')}</h2>

                    <p>{t('pageInfo.mlnkIncome.description')}</p>
                </div>
            </div>
        </section>
    );
}

export default IncomeDescription;
