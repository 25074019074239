import classNames from 'classnames/bind';
import { BREAKPOINT_LANDSCAPE } from 'constant';
import { useWidthCondition } from 'helpers';
import MLNKCollected from 'components/HomeMLNKCollected/HomeMLNKCollected';
import MLNKPrice from 'components/HomeMLNKPrice/HomeMLNKPrice';
import TotalLiquidity from 'components/HomeTotalLiquidity/HomeTotalLiquidity';
import TotalVolume from 'components/HomeTotalVolume/HomeTotalVolume';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AboutProjectStatistics.module.scss';

const cx = classNames.bind(styles);

type Tab = 'liquidity' | 'volume' | 'mlnk' | 'mlnkPrice';

function Statistics() {
    const [t] = useTranslation();
    const [tab, setTab] = useState<Tab>('liquidity');
    const [isLandscapeOrLess] = useWidthCondition((w) => w < BREAKPOINT_LANDSCAPE);
    return (
        <section className={cx('Component')}>
            <div className={cx('Container')}>
                <h2 className={cx('Title')}>{t('pageInfo.statistics.title')}</h2>

                <div className={cx('Card')}>
                    <nav className={cx('CardTabs')}>
                        <button
                            type="button"
                            className={cx(tab === 'liquidity' && 'active')}
                            onClick={() => setTab('liquidity')}
                        >
                            {t('pageInfo.statistics.liquidity.title', { context: 'short' })}
                        </button>
                        <button
                            type="button"
                            className={cx(tab === 'volume' && 'active')}
                            onClick={() => setTab('volume')}
                        >
                            {t('pageInfo.statistics.volume.title', { context: 'short' })}
                        </button>
                        <button type="button" className={cx(tab === 'mlnk' && 'active')} onClick={() => setTab('mlnk')}>
                            {t('pageInfo.statistics.mlnk.title', { context: 'short' })}
                        </button>
                        <button
                            type="button"
                            className={cx(tab === 'mlnkPrice' && 'active')}
                            onClick={() => setTab('mlnkPrice')}
                        >
                            {t('pageInfo.statistics.mlnkPrice.title', { context: 'short' })}
                        </button>
                    </nav>

                    <div className={cx('CardMain')}>
                        <div
                            style={{
                                transform: isLandscapeOrLess
                                    ? ''
                                    : `translateX(-${
                                          100 *
                                          // eslint-disable-next-line no-nested-ternary
                                          (tab === 'liquidity' ? 0 : tab === 'volume' ? 1 : tab === 'mlnk' ? 2 : 3)
                                      }%)`
                            }}
                            className={cx('CardTabContentContainer')}
                        >
                            <TotalLiquidity className={cx(tab === 'liquidity' && 'active')} />
                            <TotalVolume className={cx(tab === 'volume' && 'active')} />
                            <MLNKCollected className={cx(tab === 'mlnk' && 'active')} />
                            <MLNKPrice allowTooltip={false} className={cx(tab === 'mlnkPrice' && 'active')} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Statistics;
