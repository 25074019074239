import classNames from 'classnames/bind';
import { DoubleCheckIcon, CrossIcon } from 'ui/Icons/Icons';
import getTokenLogo from 'helpers/getTokenLogo/getTokenLogo';
import highlightSubstring from 'helpers/highlightSubstring';
import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './PoolCard.module.scss';

const cx = classNames.bind(styles);

type Props = {
    tokens: { icon: string; name: ReactNode; smartContract?: string }[];
    className?: string;
    iconsClassName?: string;
    highlight?: string;
    labelsClassName?: string;
    code?: string;
    mlnkIncomeDetails?: {
        hasMlnkIncome?: boolean;
        hasHighMlnkIncome?: boolean;
    };
};

function PoolCard({
    tokens,
    className = '',
    labelsClassName = '',
    highlight,
    iconsClassName = '',
    mlnkIncomeDetails,
    code
}: Props) {
    const [t] = useTranslation();
    const tokenImages = useMemo(
        () =>
            tokens.map((token) =>
                getTokenLogo(token.icon, typeof token.name === 'string' ? token.name : null, token.smartContract)
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    let tokenNames: ReactNode[] = tokens.map((token) => token.name);

    if (highlight) {
        let startIndex = tokenNames.join('').indexOf(highlight.toUpperCase());
        let searchWindow = [startIndex, startIndex + highlight.length] as const;
        let crossing = 0;

        tokenNames = tokenNames.map((n) => {
            const name = n as string;
            const result = highlightSubstring(name, searchWindow);

            crossing = startIndex > name.length ? 0 : name.length - startIndex;
            startIndex = startIndex - name.length + crossing;
            searchWindow = [startIndex, startIndex + highlight.length - crossing];

            return result;
        });
    }

    return (
        <div className={cx('Component', className)}>
            <div className={cx('IconGroup', iconsClassName)}>
                {tokenImages.map((icon, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <span className={cx('Icon')} key={i}>
                        <img src={icon} alt="" />
                    </span>
                ))}
            </div>
            <div className={cx('Name')}>
                <div className={cx('TokenList', labelsClassName)}>
                    {tokens.map(({ name }, i) => (
                        <React.Fragment key={typeof name === 'string' ? name : i}>
                            <span className={cx('Token')}>{tokenNames[i]}</span>
                            {/* eslint-disable-next-line react/jsx-pascal-case */}
                            {tokens.length - 1 !== i && <CrossIcon className={cx('IconDivider')} />}
                        </React.Fragment>
                    ))}
                    {mlnkIncomeDetails?.hasHighMlnkIncome && (
                        <span className={cx('IconChecked')}>
                            <DoubleCheckIcon />
                        </span>
                    )}
                </div>

                {code && <span className={cx('Subname')}>{t('global.token.LQ*', { code })}</span>}
            </div>
        </div>
    );
}

export default PoolCard;
