import classNames from 'classnames/bind';
import { ChartIcon } from 'ui/Icons/Icons';
import Loader from 'components/Loader/Loader';
import { formatBigNumbers, useIsTouchableDevice } from 'helpers';
import {
    AreaSeriesPartialOptions,
    CandlestickSeriesPartialOptions,
    createChart,
    HistogramSeriesPartialOptions,
    IChartApi,
    SeriesDataItemTypeMap,
    SeriesType,
    ISeriesApi,
    BarsInfo,
    BarData,
    MouseEventHandler
} from 'lightweight-charts';
import { DateTime } from 'luxon';
import React, { memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Chart.module.scss';

const cx = classNames.bind(styles);

const createColorScheme = (color = '') => ({
    area: {
        topColor: `rgba(${color}, 0.56)`,
        bottomColor: `rgba(${color}, 0.04)`,
        lineColor: `rgba(${color}, 1)`
    },
    histogram: {
        color: `rgba(${color}, 1)`
    }
});

export const COLOR_SCHEME = {
    green: createColorScheme('17, 182, 138'),
    blue: createColorScheme('28, 150, 238'),
    pink: createColorScheme('255, 66, 144'),
    yellow: createColorScheme('250, 173, 22'),
    purple: createColorScheme('130, 71, 229')
};

const constructParam = (label: string, value: number) => `<span>${label}</span><b>${value}</b>`;

export type LoadMoreRange = BarsInfo;

type Props = {
    options?: Parameters<typeof createChart>[1];
    autoHeight?: boolean;
    symbolBefore?: string;
    symbolAfter?: string;
    className?: string;
    floatZerosOffset?: number;
    compress?: boolean;
    /**
     * @default false
     * @description can user scroll and scale chart on touchable device
     */
    interactiveOnTouchable?: boolean;
    /**
     * @important
     * only for `canblestick`
     */
    showTooltip?: boolean;
    loading?: boolean;
    loadMore?(range?: LoadMoreRange): void;
    data:
        | {
              type: 'area';
              options?: AreaSeriesPartialOptions;
              data: SeriesDataItemTypeMap[SeriesType][];
          }
        | {
              type: 'histogram';
              options?: HistogramSeriesPartialOptions;
              data: SeriesDataItemTypeMap[SeriesType][];
          }
        | {
              type: 'canblestick';
              options?: CandlestickSeriesPartialOptions;
              data: SeriesDataItemTypeMap[SeriesType][];
          };
};

function Chart({
    options,
    data,
    autoHeight,
    loading,
    symbolAfter = '',
    symbolBefore = '$',
    showTooltip,
    className = '',
    floatZerosOffset,
    compress,
    loadMore,
    interactiveOnTouchable = false
}: Props) {
    const [t, { language }] = useTranslation();
    const isTouchableDevice = useIsTouchableDevice();
    const lastToDate = useRef('');
    const chartRef = useRef<HTMLDivElement>(null);
    const chart = useRef<IChartApi | null>(null);
    const seriesRef = useRef<{
        type: 'area' | 'histogram' | 'canblestick';
        series: ISeriesApi<SeriesType> | null;
    } | null>(null);

    const getSize = () => ({
        width: autoHeight ? chartRef.current?.offsetWidth! : options?.width ?? chartRef.current!.offsetWidth!,
        height: Math.max(autoHeight ? chartRef.current?.offsetHeight! : 0, options?.height ?? 230)
    });

    const resize = () => {
        const { width, height } = getSize();
        chart.current?.resize(width, height);
        chart.current?.timeScale().fitContent();
    };

    useEffect(() => {
        const USDT_PRICE_FORMAT = {
            type: 'price' as const,
            precision: 2,
            minMove: 0.01
        };

        const priceFormat = data.options?.priceFormat || USDT_PRICE_FORMAT;
        // @todo TEMP FIX FOR SKIP LABEL CROPPING. WAITING FOR 3.7.0 LIB VERSION
        let maxLabelLength = 0;
        const priceFormatter = (price: number) => {
            const { precision } = priceFormat as typeof USDT_PRICE_FORMAT;
            // @todo maybe make `toFixed` firstly in order to skip small values
            // add $ sign before price
            // eslint-disable-next-line no-param-reassign
            if (Math.abs(price) <= priceFormat.minMove! && price !== 0) price = 0;

            return (
                symbolBefore +
                formatBigNumbers(price.toFixed(precision), precision, floatZerosOffset, compress) +
                symbolAfter
            ).padEnd(maxLabelLength, ' ');
        };

        maxLabelLength = Math.max(
            ...data.data.map((point) =>
                Math.max(
                    ...(data.type !== 'canblestick'
                        ? [priceFormatter((point as any).value).length]
                        : [
                              priceFormatter((point as any).close).length,
                              priceFormatter((point as any).open).length,
                              priceFormatter((point as any).low).length,
                              priceFormatter((point as any).high).length
                          ])
                )
            )
        );
        // @todo END OF TEMP FIX

        // Create new chart instance or use old
        chart.current =
            chart.current ??
            createChart(chartRef.current!, {
                handleScale: !isTouchableDevice || interactiveOnTouchable,
                handleScroll: !isTouchableDevice || interactiveOnTouchable,
                localization: {
                    priceFormatter
                },
                grid: {
                    vertLines: {
                        visible: false
                    },
                    horzLines: {
                        color: 'rgba(152, 161, 195, 0.05)'
                    }
                },
                layout: {
                    backgroundColor: 'transparent',
                    textColor: '#566980',
                    fontSize: 12
                },
                rightPriceScale: {
                    borderVisible: false,
                    autoScale: true,
                    scaleMargins: {
                        top: 0.3,
                        bottom: 0.1
                    }
                },
                crosshair: showTooltip
                    ? {
                          vertLine: {
                              labelVisible: false
                          }
                      }
                    : undefined,
                timeScale: {
                    borderVisible: false
                },
                ...options,
                ...getSize()
            });

        // Construct data series
        if (seriesRef.current && seriesRef.current.type !== data.type) {
            seriesRef.current.series?.setData([]);
            seriesRef.current = null;
        }

        seriesRef.current = {
            type: data.type,
            series: seriesRef.current?.series ?? null
        };

        if (!seriesRef.current.series) {
            if (data.type === 'area') {
                seriesRef.current.series = chart.current.addAreaSeries({
                    lineWidth: 2,
                    ...COLOR_SCHEME.blue.area,
                    ...data.options,
                    priceFormat
                });
            } else if (data.type === 'histogram') {
                seriesRef.current.series = chart.current.addHistogramSeries({
                    base: 0,
                    ...COLOR_SCHEME.green.histogram,
                    ...data.options,
                    priceFormat
                });
            } else if (data.type === 'canblestick') {
                seriesRef.current.series = chart.current.addCandlestickSeries({
                    ...data.options,
                    priceFormat,
                    upColor: '#11b68a',
                    borderUpColor: '#11b68a',
                    wickUpColor: '#11b68a',
                    downColor: '#ff4e4e',
                    borderDownColor: '#ff4e4e',
                    wickDownColor: '#ff4e4e'
                });
            }
        }

        seriesRef.current.series?.setData(data.data);

        const timeScale = chart.current.timeScale();
        const currentToDate = data.data?.[data.data.length - 1]?.time ?? null;
        if (lastToDate.current !== currentToDate) {
            timeScale.fitContent();
            lastToDate.current = currentToDate as string;
        }

        let tid: any = null;

        const subscribeHandler = loadMore
            ? () => {
                  clearTimeout(tid);
                  tid = setTimeout(() => {
                      const logicalRange = timeScale.getVisibleLogicalRange();
                      if (logicalRange !== null && seriesRef.current?.series) {
                          const barsInfo = seriesRef.current?.series.barsInLogicalRange(logicalRange);

                          if (barsInfo?.barsBefore && barsInfo.barsBefore < 0) {
                              loadMore?.(barsInfo);
                          }
                      }
                  }, 50);
              }
            : undefined;

        if (subscribeHandler) {
            timeScale.subscribeVisibleLogicalRangeChange(subscribeHandler);
        }

        resize();
        window.addEventListener('resize', resize);

        return () => {
            if (subscribeHandler) timeScale.unsubscribeVisibleLogicalRangeChange(subscribeHandler);
            clearTimeout(tid);
            window.removeEventListener('resize', resize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, data, autoHeight]);

    // Tooltip_DEPRECATED controller
    useEffect(() => {
        if (!showTooltip || !chartRef.current || !chart.current || data.type !== 'canblestick') return;

        const toolTipWidth = 120;
        const tooltip = document.createElement('div');
        tooltip.className = cx('PriceTooltip');
        chartRef.current?.appendChild(tooltip);

        const updateTooltip: MouseEventHandler = (param) => {
            if (
                param.point === undefined ||
                !param.time ||
                param.point.x < 0 ||
                param.point.x > chartRef.current?.clientWidth! ||
                param.point.y < 0 ||
                param.point.y > chartRef.current?.clientHeight!
            ) {
                tooltip.style.display = 'none';
            } else {
                const price = param.seriesPrices.get(seriesRef.current?.series!) as Omit<BarData, 'time'>;
                tooltip.style.display = 'block';
                const time = `<time>${DateTime.fromSeconds(param.time as number, {
                    zone: 'utc'
                })
                    .setLocale(language)
                    .toFormat('ff')}</time>`;
                tooltip.innerHTML = `${time}<div>${[
                    constructParam(t('global.candlestickParams.low'), price.low),
                    constructParam(t('global.candlestickParams.high'), price.high),
                    constructParam(t('global.candlestickParams.open'), price.open),
                    constructParam(t('global.candlestickParams.close'), price.close)
                ].join('')}</div>`;

                const coordinate = seriesRef.current?.series?.priceToCoordinate(price.open);
                let shiftedCoordinate = param.point.x - 50;
                if (!coordinate) return;
                shiftedCoordinate = Math.max(
                    0,
                    // eslint-disable-next-line no-unsafe-optional-chaining
                    Math.min(chartRef.current?.clientWidth! - toolTipWidth, shiftedCoordinate)
                );
                tooltip.style.left = `${shiftedCoordinate}px`;
                tooltip.style.top = 'calc(100% - 16px)';
            }
        };
        chart.current.subscribeCrosshairMove(updateTooltip);

        // eslint-disable-next-line consistent-return
        return () => {
            chart.current?.unsubscribeCrosshairMove(updateTooltip);
            tooltip.parentElement?.removeChild(tooltip);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showTooltip, chartRef.current, data.type, language]);

    // Cleanup
    useEffect(
        () => () => {
            chart.current?.remove();
            chart.current = null;
        },
        []
    );

    return (
        <div className={cx(className, 'Component')} ref={chartRef}>
            {!loading && !data.data.length && (
                <div className={cx('Empty')}>
                    <ChartIcon />

                    {t('global.noData')}
                </div>
            )}
            {loading && (
                <div className={cx('Empty', 'loader')}>
                    <Loader />
                </div>
            )}
        </div>
    );
}

export default memo(Chart);
