import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'ui/Button_DEPRECATED/Button';
import { AboutIcon, ChevronUpIcon, ChevronDownIcon } from 'ui/Icons/Icons';
import Tooltip, { useTooltip } from 'ui/Tooltip_DEPRECATED/Tooltip';
import { usePixelRatio } from 'helpers';
import typedKeys from 'helpers/typedKeys';
import getImage from 'helpers/getImage/getImage';
import NewTabLink from 'components/NewTabLink/NewTabLink';
import EOSIcon from 'assets/images/tokens/eos.svg';
import MLNKIcon from 'assets/images/tokens/mlnk.svg';
import TetherIcon from 'assets/images/tokens/tether.svg';
import WhitebitIcon from 'assets/images/exchanges/whitebit.svg';
import BinanceIcon from 'assets/images/exchanges/binance-sm.svg';
import styles from './DepositExchangeTutorial.module.scss';

const cx = classNames.bind(styles);

export const TUTORIAL_SECTION_ID = 'tutorialSection';

type Flow = 'deposit' | 'withdraw';
type Exchange = 'whitebit' | 'binance';
type Token = 'MLNK' | 'EOS' | 'TETHER';
type ExchangeConfig = null | {
    steps: number;
    slides: string[][];
};
type Config = {
    [key in Flow]: {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        [key in Token]: {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            [key in Exchange]: ExchangeConfig;
        };
    };
};

const CONFIG: Config = {
    deposit: {
        MLNK: {
            whitebit: {
                steps: 5,
                slides: [
                    ['deposit-EOS-whitebit-1'],
                    ['deposit-EOS-whitebit-2'],
                    ['deposit-EOS-whitebit-3'],
                    ['deposit-EOS-whitebit-4-1', 'deposit-MLNK-whitebit-4-2'],
                    ['deposit-MLNK-whitebit-5']
                ]
            },
            binance: null
        },
        EOS: {
            whitebit: {
                steps: 5,
                slides: [
                    ['deposit-EOS-whitebit-1'],
                    ['deposit-EOS-whitebit-2'],
                    ['deposit-EOS-whitebit-3'],
                    ['deposit-EOS-whitebit-4-1', 'deposit-EOS-whitebit-4-2'],
                    ['deposit-EOS-whitebit-5']
                ]
            },
            binance: {
                steps: 7,
                slides: [
                    ['deposit-EOS-binance-1'],
                    ['deposit-EOS-binance-2'],
                    ['deposit-EOS-binance-3'],
                    ['deposit-EOS-binance-4'],
                    ['deposit-EOS-binance-5-1', 'deposit-EOS-binance-5-2'],
                    ['deposit-EOS-binance-6'],
                    [
                        'deposit-EOS-binance-7-1',
                        'deposit-EOS-binance-7-2',
                        'deposit-EOS-binance-7-3',
                        'deposit-EOS-binance-7-4'
                    ]
                ]
            }
        },
        TETHER: {
            whitebit: {
                steps: 5,
                slides: [
                    ['deposit-EOS-whitebit-1'],
                    ['deposit-EOS-whitebit-2'],
                    ['deposit-EOS-whitebit-3'],
                    ['deposit-EOS-whitebit-4-1', 'deposit-TETHER-whitebit-4-2'],
                    ['deposit-TETHER-whitebit-5']
                ]
            },
            binance: null
        }
    },
    withdraw: {
        MLNK: {
            whitebit: {
                steps: 5,
                slides: [
                    ['deposit-EOS-whitebit-1'],
                    ['deposit-EOS-whitebit-2'],
                    ['deposit-EOS-whitebit-3'],
                    ['deposit-EOS-whitebit-4-1', 'deposit-MLNK-whitebit-4-2'],
                    ['withdraw-MLNK-whitebit-5']
                ]
            },
            binance: null
        },
        EOS: {
            whitebit: {
                steps: 5,
                slides: [
                    ['deposit-EOS-whitebit-1'],
                    ['deposit-EOS-whitebit-2'],
                    ['deposit-EOS-whitebit-3'],
                    ['deposit-EOS-whitebit-4-1', 'deposit-MLNK-whitebit-4-2'],
                    ['withdraw-EOS-whitebit-5']
                ]
            },
            binance: {
                steps: 6,
                slides: [
                    ['deposit-EOS-binance-1'],
                    ['deposit-EOS-binance-2'],
                    ['withdraw-EOS-binance-3'],
                    ['withdraw-EOS-binance-4'],
                    ['withdraw-EOS-binance-5-1', 'withdraw-EOS-binance-5-2', 'withdraw-EOS-binance-5-3'],
                    ['withdraw-EOS-binance-6-1', 'withdraw-EOS-binance-6-2']
                ]
            }
        },
        TETHER: {
            whitebit: {
                steps: 5,
                slides: [
                    ['deposit-EOS-whitebit-1'],
                    ['deposit-EOS-whitebit-2'],
                    ['deposit-EOS-whitebit-3'],
                    ['deposit-EOS-whitebit-4-1', 'deposit-MLNK-whitebit-4-2'],
                    ['withdraw-TETHER-whitebit-5']
                ]
            },
            binance: null
        }
    }
};

const TOKEN_ICONS: { [key in Token]: string } = {
    EOS: EOSIcon,
    MLNK: MLNKIcon,
    TETHER: TetherIcon
};

const EXCHANGE_ICONS: { [key in Exchange]: string } = {
    whitebit: WhitebitIcon,
    binance: BinanceIcon
};

const EXCHANGE_URLS: { [key in Exchange]: string } = {
    whitebit: 'https://whitebit.com',
    binance: 'https://binance.com'
};

function TutorialSlides({ activeStepNumber, images }: { activeStepNumber: number; images: string[][] }) {
    const pixelRatio = usePixelRatio();
    const {
        i18n: { language }
    } = useTranslation();
    const [activeSlide, setActiveSlide] = useState(0);
    const ref = useRef<HTMLDivElement>(null);
    const activeStepIndex = activeStepNumber - 1;

    useEffect(() => {
        const width = 1062;
        const height = 590;
        const ratio = width / height;

        const handle = () => {
            if (!ref.current) return;
            ref.current.style.height = `${(ref.current.offsetWidth ?? 0) / ratio}px`;
        };

        window.addEventListener('resize', handle);

        handle();

        return () => {
            window.removeEventListener('resize', handle);
        };
    }, []);

    useEffect(() => {
        setActiveSlide(0);
    }, [activeStepIndex]);

    useEffect(() => {
        const tid = setTimeout(() => {
            let nextSlide = activeSlide + 1;
            if (nextSlide > images[activeStepIndex].length - 1) nextSlide = 0;
            setActiveSlide(nextSlide);
        }, 3000);
        return () => {
            clearTimeout(tid);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStepIndex, activeSlide]);

    return (
        <div ref={ref} className={cx('Slides')}>
            {images.map((stepImages, stepNumber) =>
                stepImages.map((name, imageIndex) => (
                    <img
                        className={cx(activeSlide === imageIndex && stepNumber === activeStepIndex && 'active')}
                        key={name}
                        src={getImage(name, language, pixelRatio)}
                        alt="Tutorials step"
                    />
                ))
            )}
        </div>
    );
}

const FilterOption: React.FC<{
    disabled: boolean;
    isActive: boolean;
    onClick(): void;
    children: ReactNode;
    // eslint-disable-next-line react/function-component-definition
}> = ({ disabled, children, isActive, onClick }) => {
    const tooltip = useTooltip();
    const [t] = useTranslation();
    return (
        <div ref={tooltip.setPopperReference}>
            {/* eslint-disable-next-line react/button-has-type */}
            <button
                onMouseEnter={disabled ? tooltip.show : undefined}
                onMouseLeave={disabled ? tooltip.hide : undefined}
                onClick={disabled ? undefined : onClick}
                className={cx({ active: isActive, disabled })}
            >
                {children}
            </button>

            <Tooltip {...tooltip} popperOptions={(options) => ({ ...options, placement: 'right' })}>
                {t('pageDepositExchange.tutorial.tooltip', {
                    context: 'disabledGateway'
                })}
            </Tooltip>
        </div>
    );
};

type CardProps = {
    step: number;
    steps: number;
    title: ReactNode;
    text: ReactNode;
    additional?: ReactNode;
    back: () => void;
    next: () => void;
};

function Card({ step, steps, title, text, additional, back, next }: CardProps) {
    const [t] = useTranslation();

    return (
        <div className={cx('Card')}>
            <div className={cx('CardDetails')}>
                <p className={cx('CardStep')}>{t('pageDepositExchange.step', { step })}</p>
                <h2 className={cx('CardTitle')}>{title}</h2>
                <p className={cx('CardDescription')}>{text}</p>
                {additional}
            </div>

            <div className={cx('CardBtnGroup')}>
                {step !== 1 ? (
                    <Button color="tertairy-green" onClick={back}>
                        {t('pageDepositExchange.btnBack')}
                    </Button>
                ) : (
                    <div />
                )}
                {step !== steps ? (
                    <Button color="primary" onClick={next}>
                        {t('pageDepositExchange.btnNext')}
                    </Button>
                ) : (
                    <div />
                )}
            </div>
        </div>
    );
}

function Tutorial() {
    const [t] = useTranslation();
    const [step, setStep] = useState(1);
    const [flow, setFlow] = useState<Flow>('deposit');
    const [token, setToken] = useState<Token>('TETHER');
    const [exchange, setExchange] = useState<Exchange>('whitebit');

    // Get tokens config by flow, reconstruct config for exchanges
    const TOKENS = CONFIG[flow];
    const EXCHANGES = useMemo(
        () =>
            typedKeys(TOKENS).reduce(
                (exchanges, tkn) => {
                    typedKeys(TOKENS[tkn]).forEach((exc) => {
                        const config = TOKENS[tkn][exc];
                        // eslint-disable-next-line no-param-reassign
                        exchanges[exc] = {
                            ...(exchanges[exc] ?? {}),
                            [tkn]: config
                        };
                    });

                    return exchanges;
                },
                {} as { [key in Exchange]: { [k in Token]: ExchangeConfig } }
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [flow]
    );

    const currentGateway = CONFIG[flow][token][exchange]!;

    const next = () => {
        const nextStep = step + 1;
        setStep(nextStep > currentGateway.steps ? 1 : nextStep);
    };

    const back = () => {
        const nextStep = step - 1;
        setStep(nextStep < 1 ? currentGateway.steps : nextStep);
    };

    useEffect(() => {
        setStep(1);
    }, [flow, token, exchange]);

    const stepCardAlert = t([`pageDepositExchange.${flow}.${token}.${exchange}.step_${step}.alert`, '']);

    return (
        <section id={TUTORIAL_SECTION_ID} className={cx('Component')}>
            <div className={cx('Window')}>
                <div className={cx('WindowHeader')}>
                    <ul className={cx('WindowHeaderDots')}>
                        <li />
                        <li />
                        <li />
                    </ul>
                    <div className={styles.SwitcherWrapper}>
                        <div className={cx('Switcher')}>
                            <button
                                type="button"
                                onClick={() => setFlow('deposit')}
                                className={cx({ active: flow === 'deposit' })}
                            >
                                <ChevronUpIcon className={styles.Icon} />
                                {t('pageDepositExchange.tutorial.deposit')}
                            </button>
                            <button
                                type="button"
                                onClick={() => setFlow('withdraw')}
                                className={cx({ active: flow === 'withdraw' })}
                            >
                                <ChevronDownIcon className={styles.Icon} />
                                {t('pageDepositExchange.tutorial.withdraw')}
                            </button>
                        </div>
                    </div>
                </div>
                <div className={cx('WindowMain')}>
                    <div className={cx('WindowAside')}>
                        <div className={cx('Filter')}>
                            <p className={cx('FilterLabel')}>{t('pageDepositExchange.tutorial.token')}</p>
                            <div className={cx('FilterOptions')}>
                                {typedKeys(TOKENS).map((item) => (
                                    <FilterOption
                                        key={item}
                                        disabled={!EXCHANGES[exchange][item]}
                                        onClick={() => setToken(item)}
                                        isActive={item === token}
                                    >
                                        <img src={TOKEN_ICONS[item]} alt={item} />
                                    </FilterOption>
                                ))}
                            </div>
                        </div>
                        <div className={cx('Filter')}>
                            <p className={cx('FilterLabel')}>{t('pageDepositExchange.tutorial.exchange')}</p>
                            <div className={cx('FilterOptions')}>
                                {typedKeys(EXCHANGES).map((item) => (
                                    <FilterOption
                                        key={item}
                                        disabled={!TOKENS[token][item]}
                                        onClick={() => setExchange(item)}
                                        isActive={item === exchange}
                                    >
                                        <img src={EXCHANGE_ICONS[item]} alt={item} />
                                    </FilterOption>
                                ))}
                            </div>
                        </div>
                    </div>
                    <TutorialSlides images={currentGateway.slides} activeStepNumber={step} />
                </div>
            </div>

            <Card
                step={step}
                steps={currentGateway.steps}
                back={back}
                next={next}
                title={t(`pageDepositExchange.${flow}.${token}.${exchange}.step_${step}.title`)}
                text={
                    <Trans i18nKey={`pageDepositExchange.${flow}.${token}.${exchange}.step_${step}.text`}>
                        <b />
                    </Trans>
                }
                additional={
                    <>
                        {step === 1 && (
                            <NewTabLink
                                href={EXCHANGE_URLS[exchange]}
                                name={`${exchange.slice(0, 1).toUpperCase()}${exchange.slice(1).toLowerCase()}`}
                            />
                        )}
                        {stepCardAlert && (
                            <p className={cx('Alert')}>
                                <AboutIcon />

                                <span>{stepCardAlert}</span>
                            </p>
                        )}
                    </>
                }
            />
        </section>
    );
}

export default Tutorial;
