import React from 'react';
import cn from 'classnames';
import {
    useMergeRefs,
    FloatingTree,
    FloatingNode,
    useFloatingNodeId,
    useFloating,
    useDismiss,
    useRole,
    useClick,
    useInteractions,
    FloatingFocusManager,
    FloatingOverlay,
    FloatingPortal,
    useTransitionStatus,
    useId
} from '@floating-ui/react';
import { OSDetection } from 'utils/featuresDetection/featuresDetection';
import Button from '../Button/Button';
import { ChevronLeftIcon, CrossIcon } from '../Icons/Icons';
import { DialogProps, DialogOptions, DialogHeaderProps } from './Dialog.d';
import styles from './Dialog.module.scss';

export type ContextType =
    | (ReturnType<typeof useDialog> & {
          setLabelId: React.Dispatch<React.SetStateAction<string | undefined>>;
          setDescriptionId: React.Dispatch<React.SetStateAction<string | undefined>>;
      })
    | null;

export function useDialog({
    initialOpen = false,
    open: controlledOpen,
    onOpenChange: setControlledOpen
}: DialogOptions = {}) {
    const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);
    const [labelId, setLabelId] = React.useState<string | undefined>();
    const [descriptionId, setDescriptionId] = React.useState<string | undefined>();

    const open = controlledOpen ?? uncontrolledOpen;
    const setOpen = setControlledOpen ?? setUncontrolledOpen;
    /** Subscribe this component to the <FloatingTree /> */
    const nodeId = useFloatingNodeId();

    const context = useFloating({
        open,
        onOpenChange: setOpen,
        /** Pass the subscribed `nodeId` to useFloating() */
        nodeId
    });

    return React.useMemo(
        () => ({
            open,
            setOpen,
            ...context,
            labelId,
            descriptionId,
            setLabelId,
            setDescriptionId,
            nodeId
        }),
        [open, setOpen, context, labelId, descriptionId, nodeId]
    );
}

const DialogContext = React.createContext<ContextType>(null);

export const useDialogContext = () => {
    const context = React.useContext(DialogContext);

    if (context === null) {
        throw new Error('Dialog components must be wrapped in <Dialog />');
    }

    return context;
};

export const useDialogHeaderContext = () => {
    const context = React.useContext(DialogContext);

    return context !== null ? context : undefined;
};

export function DialogComponent({ initialOpen, open, onOpenChange, children }: React.PropsWithChildren<DialogOptions>) {
    const dialog = useDialog({ initialOpen, open, onOpenChange });

    return <DialogContext.Provider value={dialog}>{children}</DialogContext.Provider>;
}

export function DialogProvider({ initialOpen, open, onOpenChange, children }: React.PropsWithChildren<DialogOptions>) {
    return (
        <FloatingTree>
            <DialogComponent initialOpen={initialOpen} open={open} onOpenChange={onOpenChange}>
                {children}
            </DialogComponent>
        </FloatingTree>
    );
}

export const DialogHeader = React.forwardRef<HTMLHeadingElement, React.PropsWithChildren<DialogHeaderProps>>(
    /* eslint prefer-arrow-callback: [ "error", { "allowNamedFunctions": true } ] */
    function DialogHeaderComponent(
        {
            labelId,
            descriptionId,
            onClose,
            title,
            description,
            titleAlign = 'left',
            classes,
            hideCloseButton,
            onBackButtonClick,
            focusOnClose,
            focusOnGoBack,
            children
        },
        ref
    ) {
        // TODO: Replace with React.useId after update React v18
        const uniqueLabelId = useId();
        const uniqueDescriptionId = useId();

        const context = useDialogHeaderContext();
        const { setOpen, setLabelId, setDescriptionId } = context ?? {};

        const closeBtnRef = React.useRef<HTMLButtonElement | null>(null);
        const backBtnRef = React.useRef<HTMLButtonElement | null>(null);

        const {
            root,
            container,
            backButton,
            title: titleClass,
            description: descriptionClass,
            closeButton
        } = classes ?? {};

        const headerClasses = cn(
            styles.HeaderTitle,
            styles[`HeaderTitleAlign-${titleAlign}`],
            onBackButtonClick && styles.HeaderTitleWithBackButton,
            !hideCloseButton && styles.HeaderTitleWithCloseButton,
            titleClass
        );

        const handleClose = () => {
            setOpen?.(false);
            onClose?.();
        };
        /** Only sets `aria-labelledby` on the Dialog root element if this component is mounted inside it. */
        React.useLayoutEffect(() => {
            if (setLabelId) setLabelId(labelId ?? uniqueLabelId);
            if (setDescriptionId) setDescriptionId(descriptionId ?? uniqueDescriptionId);

            return () => {
                if (setLabelId) setLabelId(undefined);
                if (setDescriptionId) setDescriptionId(undefined);
            };
        }, [labelId, descriptionId, setLabelId, setDescriptionId, uniqueLabelId, uniqueDescriptionId]);

        React.useEffect(() => {
            if (closeBtnRef?.current && focusOnClose) {
                closeBtnRef.current.focus();
                return;
            }

            if (backBtnRef?.current && focusOnGoBack) backBtnRef.current.focus();
        }, [focusOnClose, focusOnGoBack]);

        return (
            <div className={cn(styles.Header, root)} ref={ref}>
                {children}
                <div className={cn(styles.HeaderContainer, container)}>
                    {onBackButtonClick && (
                        <Button
                            classes={{ root: cn(styles.HeaderBackButton, backButton) }}
                            iconStart={<ChevronLeftIcon />}
                            variant="text"
                            color="gray"
                            type="button"
                            isRound
                            onClick={onBackButtonClick}
                            ref={backBtnRef}
                            size="medium"
                        />
                    )}
                    {title && (
                        <h3 className={headerClasses} id={labelId ?? uniqueLabelId}>
                            {title}
                        </h3>
                    )}
                    {!hideCloseButton && (
                        <div>
                            <Button
                                type="button"
                                classes={{ root: cn(styles.CloseButton, closeButton) }}
                                iconStart={<CrossIcon />}
                                variant="text"
                                color="gray"
                                isRound
                                onClick={handleClose}
                                ref={closeBtnRef}
                                size="medium"
                            />
                        </div>
                    )}
                </div>
                {description && (
                    <div
                        className={cn(styles.HeaderDescription, descriptionClass)}
                        id={descriptionId ?? uniqueDescriptionId}
                    >
                        {description}
                    </div>
                )}
            </div>
        );
    }
);

export const DialogBody = React.forwardRef<HTMLDivElement, React.PropsWithChildren<{ className?: string }>>(
    /* eslint prefer-arrow-callback: [ "error", { "allowNamedFunctions": true } ] */
    function DialogBodyComponent({ children, className }, ref) {
        return (
            <div className={cn(styles.Body, className)} ref={ref}>
                {children}
            </div>
        );
    }
);

export const DialogFooter = React.forwardRef<
    HTMLDivElement,
    React.PropsWithChildren<{
        className?: string;
    }>
>(
    /* eslint prefer-arrow-callback: [ "error", { "allowNamedFunctions": true } ] */
    function DialogFooterComponent({ children, className }, ref) {
        return (
            <div className={cn(styles.Footer, className)} ref={ref}>
                {children}
            </div>
        );
    }
);

export const DialogContent = React.forwardRef<HTMLDivElement, DialogProps>(
    /* eslint prefer-arrow-callback: [ "error", { "allowNamedFunctions": true } ] */
    function DialogContentComponent(
        {
            classes,
            disableOutsideClick,
            disableEscapeKeyDown,
            fullScreen,
            fullWidth,
            scroll,
            maxWidth,
            transition,
            onUnmount,
            children
        },
        propRef
    ) {
        const { context: floatingContext, ...otherContext } = useDialogContext();
        const ref = useMergeRefs([otherContext.refs.setFloating, propRef]);
        const { isMounted, status } = useTransitionStatus(floatingContext, { duration: 500 });
        const click = useClick(floatingContext);
        const { root, overlay, wrapper, container } = classes ?? {};

        const dismiss = useDismiss(floatingContext, {
            outsidePressEvent: 'mousedown',
            outsidePress: disableOutsideClick
                ? false
                : /** Close only if left button pressed */
                  (e) => Boolean(e.button === 0),
            escapeKey: !disableEscapeKeyDown
        });

        const role = useRole(floatingContext);
        const interactions = useInteractions([click, dismiss, role]);

        React.useEffect(() => {
            if (status === 'unmounted' && onUnmount) onUnmount();
        }, [onUnmount, status]);
        /** Fixes the auto-scroll bug on iOS when closing the modal. */
        React.useEffect(() => {
            const os = OSDetection();

            if (!isMounted || os !== 'iOS') return;

            if (os === 'iOS' && status !== 'unmounted') {
                document.documentElement.style.scrollBehavior = 'auto';
            } else {
                document.documentElement.style.scrollBehavior = 'smooth';
            }
        }, [isMounted, status]);

        return (
            /** Wrap the rendered element(s) in a `<FloatingNode />`, passing in the subscribed `nodeId`. */
            <FloatingNode id={otherContext.nodeId}>
                {isMounted ? (
                    <FloatingPortal>
                        <div className={cn(styles.Root, root)}>
                            <FloatingOverlay className={cn(styles.Overlay, overlay)} lockScroll data-status={status} />
                            <div
                                className={cn(
                                    styles.Wrapper,
                                    styles[`Transition-${transition}`],
                                    styles[`WrapperScroll-${scroll}`],
                                    fullScreen && styles.WrapperFullScreen,
                                    wrapper
                                )}
                                data-status={status}
                            >
                                <FloatingFocusManager context={floatingContext}>
                                    <div
                                        className={cn(
                                            styles.Container,
                                            !fullScreen && maxWidth !== false && styles[`MaxWidth-${maxWidth}`],
                                            fullWidth && styles.ContainerFullWidth,
                                            fullScreen && styles.ContainerFullScreen,
                                            container
                                        )}
                                        ref={ref}
                                        aria-labelledby={otherContext.labelId}
                                        aria-describedby={otherContext.descriptionId}
                                        {...interactions.getFloatingProps()}
                                    >
                                        {children}
                                    </div>
                                </FloatingFocusManager>
                            </div>
                        </div>
                    </FloatingPortal>
                ) : null}
            </FloatingNode>
        );
    }
);

export default function Dialog({
    children,
    classes,
    disableOutsideClick,
    disableEscapeKeyDown,
    fullScreen,
    fullWidth = true,
    initialOpen,
    scroll = 'body',
    onOpenChange,
    open,
    maxWidth = 'sm',
    transition = 'bounce-up',
    onUnmount
}: DialogProps) {
    return (
        <DialogProvider initialOpen={initialOpen} open={open} onOpenChange={onOpenChange}>
            <DialogContent
                disableOutsideClick={disableOutsideClick}
                disableEscapeKeyDown={disableEscapeKeyDown}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                scroll={scroll}
                maxWidth={maxWidth}
                transition={transition}
                classes={classes}
                onUnmount={onUnmount}
            >
                {children}
            </DialogContent>
        </DialogProvider>
    );
}
