import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeeplinkNode, useEmitCardMutation } from 'apollo/main/generated';
import { numToPrecisionStr } from 'utils/roundNumber/roundNumber';
import addThousandsSeparator from 'utils/addThousandsSeparator/addThousandsSeparator';
import { getErrorData, useGlobalError, useIsTouchableDevice } from 'helpers';
import { Confirm, Expired, Method, Payment, Pending } from '../VirtualCardManageViews/VirtualCardManageViews';
import { View, WalletName } from '../VirtualCardManageViews/VirtualCardManageViews.d';
import { VirtualCardIssueProcessProps } from './VirtualCardIssueProcess.d';

const formatValue = (value?: string) => addThousandsSeparator(numToPrecisionStr(Number(value), 2));

export default function VirtualCardIssueProcess({
    amountGive,
    amountReceive,
    cardholderName,
    currency = '',
    deepLinks = [],
    issueFees,
    memo = '',
    onClose,
    receiver = '',
    setView,
    time = '',
    view
}: VirtualCardIssueProcessProps) {
    const [t] = useTranslation();
    const onGlobalError = useGlobalError();
    const isTouchableDevice = useIsTouchableDevice();

    const [currentMemo, setCurrentMemo] = React.useState(memo);
    const [currentCurrency, setCurrentCurrency] = React.useState(currency);
    const [currentReceiver, setCurrentReceiver] = React.useState(receiver);
    const [currentTime, setCurrentTime] = React.useState(time);
    const [currentDeepLinks, setCurrentDeepLinks] = React.useState<(DeeplinkNode | null)[]>(deepLinks);

    const { issuerRate, issuerFixedFee } = issueFees ?? {};

    const [issueCardMutation, { loading: issueCardLoading }] = useEmitCardMutation();

    const handleConfirmSubmit = () => {
        if (!amountReceive || !cardholderName) {
            onGlobalError();

            return;
        }

        issueCardMutation({
            variables: {
                input: {
                    amount: amountReceive,
                    cardCurrency: 'usd',
                    paymentCurrency: 'usdcash',
                    cardholderName
                }
            }
        })
            .then(({ data }) => {
                const issueCard = data?.emitCard;

                if (issueCard?.__typename === 'CardActionNode') {
                    const { paymentParams } = issueCard ?? {};

                    const {
                        currency: dataCurrency,
                        receiver: dataReceiver,
                        memo: dataMemo,
                        deeplinks,
                        expirationDate
                    } = paymentParams ?? {};

                    setCurrentMemo(dataMemo ?? '');
                    setCurrentCurrency(dataCurrency ?? '');
                    setCurrentReceiver(dataReceiver ?? '');
                    setCurrentTime(expirationDate ?? '');
                    setCurrentDeepLinks(deeplinks ?? []);

                    setView(isTouchableDevice ? View.Method : View.Payment);
                } else {
                    const { __typename, errorMessage = '' } = issueCard ?? {};

                    onGlobalError(t([`pageVirtualCard.topUp.error.${__typename}`, errorMessage]));
                }
            })
            .catch((err) => onGlobalError(getErrorData(err).message));
    };

    const handleAppClick = (wallet: WalletName) => {
        const deeplink = currentDeepLinks.find((dp) => dp?.name === WalletName[wallet].toLowerCase());

        if (deeplink?.link) {
            window.location.href = deeplink?.link;
        }
    };

    if (view === View.Method)
        return (
            <Method
                memo={currentMemo}
                setView={setView}
                title={t('pageVirtualCard.issue.title')}
                description={t('pageVirtualCard.payment.app.description_issue')}
                onClick={handleAppClick}
            />
        );

    if (view === View.Payment)
        return (
            <Payment
                amount={amountGive}
                currency={currentCurrency}
                memo={currentMemo}
                receiver={currentReceiver}
                setView={setView}
                time={currentTime}
                title={t('pageVirtualCard.issue.title')}
                onBackClick={isTouchableDevice ? () => setView(View.Method) : undefined}
            />
        );

    if (view === View.Pending)
        return (
            <Pending
                give={`${amountGive} USDCASH`}
                receive={`$${amountReceive}`}
                rate={`1 USDCASH = ${issuerRate} USD`}
                onClose={onClose}
            />
        );

    if (view === View.Expired)
        return (
            <Expired onClose={onClose} description={t('pageVirtualCard.expired.description', { context: 'issue' })} />
        );

    return (
        <Confirm
            title={t('pageVirtualCard.issue.title')}
            description={t('pageVirtualCard.issue.confirm.description')}
            valueName={cardholderName}
            rate={`1 USDCASH = ${formatValue(issuerRate)} USD`}
            valueIssue={`$${formatValue(issuerFixedFee)}`}
            give={`${formatValue(amountGive)} USDCASH`}
            receive={`$${formatValue(amountReceive)}`}
            onSubmit={handleConfirmSubmit}
            loading={issueCardLoading}
        />
    );
}
