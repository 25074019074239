import React from 'react';
import { useTranslation } from 'react-i18next';
import addThousandsSeparator from 'utils/addThousandsSeparator/addThousandsSeparator';
import { numToPrecisionStr } from 'utils/roundNumber/roundNumber';
import { useDialog } from 'ui/Dialog/Dialog';
import Skeleton from 'ui/Skeleton/Skeleton';
import Button from 'ui/Button/Button';
import VirtualCardAccount from '../VirtualCardAccount/VirtualCardAccount';
import VirtualCardPlastic from '../VirtualCardPlastic/VirtualCardPlastic';
import VirtualCardTermsModal from '../VirtualCardTermsModal/VirtualCardTermsModal';
import VirtualCardTopUpModal from '../VirtualCardTopUpModal/VirtualCardTopUpModal';
import { VirtualCardInfoProps } from './VirtualCardInfo.d';
import styles from './VirtualCardInfo.module.scss';

const formatValue = (value?: string | number | null) => addThousandsSeparator(numToPrecisionStr(Number(value), 2));

export default function VirtualCardInfo({
    account,
    balance,
    cardId,
    cardholderName,
    issueFees,
    privateInfo,
    topUpFees
}: VirtualCardInfoProps) {
    const [t] = useTranslation();

    const termsModal = useDialog();
    const topUpModal = useDialog();

    const isIssued = privateInfo?.cardNumber;

    const { available } = balance ?? {};

    const { issuerFixedFee: issueFixedFee, issuerRate: issueRate, minimalAmount: issueAmountMin } = issueFees ?? {};

    const currentIssueFee = issueRate && !Number.isNaN(issueRate) ? (1 - Number(issueRate)) * 100 : '-';

    // const handleTermsClick = () => {
    //     termsModal.context.dataRef.current = { issueFixedFee, issueAmountMin, issueFee: currentIssueFee };
    //     termsModal.setOpen(true);
    // };

    const handleTopUpClick = () => {
        topUpModal.context.dataRef.current = { cardId, issueFees, topUpFees };
        topUpModal.setOpen(true);
    };

    return (
        <>
            <div className={styles.Root}>
                <div className={styles.Account}>
                    <VirtualCardAccount account={account} />
                </div>
                <div>
                    {balance && (
                        <div className={styles.BalanceContainer}>
                            <div className={styles.Balance}>
                                <div className={styles.BalanceLabel}>{t('pageVirtualCard.card.balance')}</div>
                                <div className={styles.BalanceValue}>${available}</div>
                            </div>
                            <Button onClick={handleTopUpClick}>{t('pageVirtualCard.button.topUp')}</Button>
                        </div>
                    )}
                    <div className={styles.Plastic}>
                        <VirtualCardPlastic cardholderName={cardholderName} privateInfo={privateInfo} />

                        {isIssued && (
                            <div className={styles.PlasticDescription}>{t('pageVirtualCard.card.description')}</div>
                        )}
                    </div>

                    {!isIssued && (
                        <div>
                            <div className={styles.Charge}>
                                <div>{t('pageVirtualCard.issue.title')}</div>
                                <div className={styles.ChargeValue}>
                                    {issueFixedFee ? (
                                        `$${formatValue(issueFixedFee)}`
                                    ) : (
                                        <Skeleton variant="text" width={60} />
                                    )}
                                </div>
                            </div>

                            {currentIssueFee && (
                                <div className={styles.Charge}>
                                    <div>{t('pageVirtualCard.issue.terms.topUp')}</div>
                                    <div className={styles.ChargeValue}>{formatValue(currentIssueFee)}%</div>
                                </div>
                            )}

                            {issueAmountMin && (
                                <div className={styles.Charge}>
                                    <div>{t('pageVirtualCard.issue.terms.topUpMin')}</div>
                                    <div className={styles.ChargeValue}>${formatValue(issueAmountMin)}</div>
                                </div>
                            )}
                        </div>
                    )}
                </div>

                {/* <div className={styles.ButtonContainer}> */}
                {/*    <Button color="secondary" fullWidth onClick={handleTermsClick}> */}
                {/*        {t('pageVirtualCard.button.terms')} */}
                {/*    </Button> */}
                {/* </div> */}
            </div>

            <VirtualCardTermsModal {...termsModal} />
            <VirtualCardTopUpModal {...topUpModal} />
        </>
    );
}
