import React from 'react';
import { throttle } from 'lodash';

/**
 * Custom React hook for tracking window resize and updating with optional throttle.
 *
 * @param {number} [throttleTime=250] - Optional throttle time in milliseconds. If provided, the window size updates will be throttled.
 * @param {Function | undefined} [callback] - Optional callback function to be called on window resize.
 *
 * @returns {{ width: number, height: number }} - An object containing the current window width and height.
 */
export default function useWindowResize(
    callback?: (width: number, height: number) => void,
    throttleTime: number = 250
): {
    width: number;
    height: number;
} {
    const windowSize = React.useRef({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const update = React.useCallback(() => {
        const nextWidth = window.innerWidth;
        const nextHeight = window.innerHeight;

        windowSize.current = {
            width: nextWidth,
            height: nextHeight
        };

        callback?.(nextWidth, nextHeight);
    }, [callback]);

    const handleResize = React.useMemo(
        () => (throttleTime ? throttle(update, throttleTime) : update),
        [throttleTime, update]
    );

    React.useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    return windowSize.current;
}
