import React from 'react';

/**
 * Gets the current remaining time.
 *
 * @param {number} timeout - Timeout in seconds.
 * @param {number} timestamp - End time of the countdown in milliseconds.
 *
 * @returns {number}
 */
const getTimeLeft = (timeout: number, timestamp?: number): number => {
    if (timestamp) {
        const timeLeft = Math.round((timestamp - Date.now()) / 1_000);

        return timeLeft < 0 ? 0 : timeLeft;
    }

    return timeout < 0 ? 0 : timeout - 1;
};

/**
 * Ticking counter hook.
 *
 * @param {number} timeout - Timeout in seconds.
 * @param {number} timestamp - End time of the countdown in milliseconds.
 *
 * @returns {{timeLeft: number, stop: () => void, reset: () => void}}
 *
 * @example
 * const timer = useCountdownTimer(60);
 *
 * if (resendTimer.timeLeft <= 0) return <div>Expired</div>
 */
export default function useCountdownTimer(
    timeout: number = 0,
    timestamp?: number
): {
    timeLeft: number;
    stop: () => void;
    reset: () => void;
} {
    const [timeLeft, setTimeLeft] = React.useState(() => getTimeLeft(timeout, timestamp));

    React.useEffect(() => {
        const timeoutId = setTimeout(() => {
            const nextTimeLeft = getTimeLeft(timeLeft, timestamp);

            if (nextTimeLeft >= 0) setTimeLeft(nextTimeLeft);
        }, 1_000);

        return () => clearTimeout(timeoutId);
    }, [timeLeft, timestamp]);

    return {
        timeLeft,
        stop: () => setTimeLeft(0),
        reset: () => setTimeLeft(getTimeLeft(timeout, timestamp))
    };
}
