import { useEosAccountQuery } from 'apollo/main/generated';
import classNames from 'classnames/bind';
import BookmarkBtn from 'components/BookmarkBtn/BookmarkBtn';
import Button from 'ui/Button_DEPRECATED/Button';
import { RefreshIcon } from 'ui/Icons/Icons';
import PageState from 'components/PageState/PageState';
import { BREAKPOINT_LAPTOP } from 'constant';
import { useWidthCondition } from 'helpers';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { RouteComponentProps } from 'react-router';
import CashStatistics from './CashStatistics/CashStatistics';
import Banner from '../AccountBanner/AccountBanner';
import MLNKIncomeSection from '../AccountMLNKIncomeSection/AccountMLNKIncomeSection';
// eslint-disable-next-line import/no-cycle
import HeldPools from './HeldPools';
// eslint-disable-next-line import/no-cycle
import HeldTokens from './HeldTokens';
import InvestmentSection from './InvestmentSection';
import SectionTransactions from './SectionTransactions';
import styles from './AccountDetails.module.scss';

const cx = classNames.bind(styles);

export const COLORS = [
    // [
    // 	'rgba(28, 150, 238, 1)',
    // 	'rgba(28, 150, 238, 0.50)',
    // 	// 'rgba(28, 150, 238, 0.75)',
    // ],
    [
        'rgba(57, 28, 238, 1)',
        'rgba(57, 28, 238, 0.50)'
        // 'rgba(57, 28, 238, 0.75)',
    ],
    [
        'rgba(255, 66, 144, 1)',
        'rgba(255, 66, 144, 0.50)'
        // 'rgba(255, 66, 144, 0.75)',
    ],
    [
        'rgba(17, 182, 139, 1)',
        'rgba(17, 182, 139, 0.50)'
        // 'rgba(17, 182, 139, 0.75)',
    ],
    [
        'rgba(255, 78, 78, 1)',
        'rgba(255, 78, 78, 0.50)'
        // 'rgba(255, 78, 78, 0.75)',
    ],
    [
        'rgba(156, 191, 5, 1)',
        'rgba(156, 191, 5, 0.50)'
        // 'rgba(156, 191, 5, 0.75)',
    ],
    [
        'rgba(120, 130, 255, 1)',
        'rgba(120, 130, 255, 0.50)'
        // 'rgba(120, 130, 255, 0.75)',
    ],
    [
        'rgba(240, 122, 43, 1)',
        'rgba(240, 122, 43, 0.50)'
        // 'rgba(240, 122, 43, 0.75)',
    ],

    [
        'rgba(130, 71, 229, 1)',
        'rgba(130, 71, 229, 0.50)'
        // 'rgba(130, 71, 229, 0.75)',
    ],

    [
        'rgba(83, 204, 182, 1)',
        'rgba(83, 204, 182, 0.50)'
        // 'rgba(83, 204, 182, 0.75)',
    ],
    [
        'rgba(250, 173, 22, 1)',
        'rgba(250, 173, 22, 0.50)'
        // 'rgba(250, 173, 22, 0.75)',
    ],
    [
        'rgba(251, 146, 113, 1)',
        'rgba(251, 146, 113, 0.50)'
        // 'rgba(251, 146, 113, 0.75)',
    ]
];

export const getPieceColor = (
    pieceIndex: number,
    code: string | number,
    hoveredCode: string | number,
    selectedCode: string | number
) =>
    // const hasSelected = !!selectedCode
    // const hasHovered = !!hoveredCode
    // const isAllActive = !hasHovered && !hasSelected

    COLORS[pieceIndex % COLORS.length][
        // selectedCode === code ? 2 : hoveredCode === code ? 1 : 0
        !selectedCode || selectedCode === code ? 0 : 1
    ];

function AccountDetails({
    match: {
        params: { account }
    }
}: RouteComponentProps<{ account: string }>) {
    const [t] = useTranslation();
    const [MLNKBalanceLoading, setMLNKBalanceLoading] = useState(true);
    const [isLaptopOrLess] = useWidthCondition((w) => w < BREAKPOINT_LAPTOP);
    const eosAccountQuery = useEosAccountQuery({
        variables: { name: account },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only'
    });

    const accountData = eosAccountQuery.data?.eosAccount;

    if (eosAccountQuery.error || (!eosAccountQuery.loading && !accountData))
        return (
            <PageState.Error title={t('pageAccount.error.title')} description={t('pageAccount.error.description')}>
                <Button
                    color="tertairy-green"
                    disabled={eosAccountQuery.loading}
                    onClick={() => eosAccountQuery.refetch()}
                    startIcon={<RefreshIcon />}
                >
                    {t('pageAccount.error.btn')}
                </Button>
            </PageState.Error>
        );

    if (eosAccountQuery.loading) return <PageState.Loading />;

    return (
        <main className={cx('Component')}>
            <div className={cx('Header')}>
                <h1 className={cx('Title')}>
                    {t('pageAccount.title')}
                    <span>{account}</span>
                </h1>
                {isLaptopOrLess && (
                    <BookmarkBtn isFavorite={accountData!.isFavorite} type="account" eosAccount={account} />
                )}
            </div>

            <div className={cx('Main')}>
                <div className={cx('Charts')}>
                    <InvestmentSection account={account} />
                    <MLNKIncomeSection readyToLoad={!MLNKBalanceLoading} account={account} />
                </div>

                <CashStatistics account={account} />
                {!!accountData?.heldTokens?.length && (
                    <section className={cx('TokenDetails')}>
                        <HeldTokens heldTokens={accountData.heldTokens!} />
                        <HeldPools account={account} heldPools={accountData.heldPools!} />
                    </section>
                )}
                <SectionTransactions eosAccount={account} />
            </div>

            <Banner
                onChangeLoad={setMLNKBalanceLoading}
                isFavorite={accountData?.isFavorite}
                mlnkCollectParams={accountData!.mlnkCollectParams!}
                account={account}
            />
        </main>
    );
}

export default memo(AccountDetails);
