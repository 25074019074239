import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthGenerateDeeplinkMutation, useWaitAccessTokenSubscription } from 'apollo/auth/generated';
import { ReactComponent as KalynaIcon } from 'assets/images/app-kalyna.svg';
import { ReactComponent as MalinkaIcon } from 'assets/images/app-malinka.svg';
import { ReactComponent as PaycashIcon } from 'assets/images/app-paycash.svg';
import { WalletName } from 'helpers/getAppLink/getAppLink';
import { getErrorData, useGlobalError } from 'helpers';
import useAuth from 'hooks/useAuth/useAuth';
import { RefreshIcon, WarningShieldIcon } from 'ui/Icons/Icons';
import Preloader from 'ui/Preloader/Preloader';
import Button from 'ui/Button/Button';
import Tabs from 'ui/Tabs/Tabs';
import QR from '../QR/QR';
import { AuthQrProps, ErrorProps } from './AuthQr.d';
import styles from './AuthQr.module.scss';

function Error({ onClick }: ErrorProps) {
    const [t] = useTranslation();

    return (
        <div className={styles.Error}>
            <div className={styles.ErrorContainer}>
                <WarningShieldIcon className={styles.ErrorIcon} />
                <div className={styles.ErrorText}>{t('pageVirtualCard.auth.qr.error.failLoading')}</div>
                <Button onClick={onClick} iconStart={<RefreshIcon />} color="secondary" variant="outlined">
                    {t('pageVirtualCard.auth.qr.reload')}
                </Button>
            </div>
        </div>
    );
}

const isTest =
    process.env.NODE_ENV === 'development' ||
    window.location.hostname.includes('dev') ||
    window.location.hostname.includes('test');

const aud = isTest ? 'https://api.test.paycashswap.com' : 'https://api.paycashswap.com';

export default function AuthQr({ wallet = WalletName.Paycash, onWalletChange }: AuthQrProps) {
    const [t] = useTranslation();
    const onGlobalError = useGlobalError();
    const { login } = useAuth();

    const [mount, setMount] = React.useState(false);
    const [currentDeeplink, setCurrentDeeplink] = React.useState('');
    const [currentRequestId, setCurrentRequestId] = React.useState('');
    // Assuming you need to keep the same controller around for every render.
    const controllerRef = React.useRef(new AbortController());

    const [authGenerateDeeplinkMutation, { loading: authGenerateDeeplinkLoading, error: authGenerateDeeplinkError }] =
        useAuthGenerateDeeplinkMutation();

    const cancel = () => {
        controllerRef.current.abort();
        controllerRef.current = new AbortController();
    };

    const getDeeplink = React.useCallback(
        (walletName: WalletName) => {
            authGenerateDeeplinkMutation({
                variables: {
                    app: walletName,
                    aud,
                    siteName: 'paycashswap.com'
                },
                context: {
                    fetchOptions: {
                        signal: controllerRef.current.signal
                    },
                    clientName: 'auth'
                }
            })
                .then(({ data: authGenerateDeeplinkData }) => {
                    const authGenerateDeeplink = authGenerateDeeplinkData?.authGenerateDeeplink;

                    if (authGenerateDeeplink?.__typename === 'AuthDeeplinkResponse') {
                        const { deeplink, requestId } = authGenerateDeeplink ?? {};

                        setCurrentDeeplink(deeplink);
                        setCurrentRequestId(requestId);
                    } else if (authGenerateDeeplink?.__typename === 'ErrorResponse') {
                        const { statusCode, detail } = authGenerateDeeplink ?? {};

                        onGlobalError(`${statusCode}, ${detail}`);
                    } else {
                        onGlobalError(t('pageVirtualCard.auth.error.deepLinkError'));
                    }
                })
                .catch((err) => {
                    /** Hide Apollo errors "The user aborted a request." */
                    if (err.networkError.code !== 20) {
                        onGlobalError(getErrorData(err).message);
                    }
                });
        },
        [authGenerateDeeplinkMutation, onGlobalError, t]
    );
    /** After generating QR code and verificationCode awaiting response from server */
    useWaitAccessTokenSubscription({
        skip: !currentRequestId,
        variables: { authRequestId: currentRequestId },
        context: {
            clientName: 'auth'
        },
        onData: ({ data: { data } }) => {
            const waitAccessToken = data?.waitAccessToken;

            if (waitAccessToken?.__typename === 'AuthTokenResponse') {
                const { accessToken = '', accessTokenExpiresAt, refreshToken } = waitAccessToken;

                if (accessToken) {
                    login(accessToken, accessTokenExpiresAt, refreshToken);
                }
            } else if (waitAccessToken?.__typename === 'ErrorResponse') {
                const { statusCode, detail } = waitAccessToken ?? {};

                onGlobalError(`${statusCode}, ${detail}`);
            } else {
                onGlobalError(t('pageVirtualCard.auth.error.getTokenError'));
            }
        }
    });

    const handleWalletChange = (nextWalletName: WalletName) => {
        onWalletChange?.(nextWalletName);
        cancel();
        getDeeplink(nextWalletName);
    };

    const handleWalletReload = () => getDeeplink(wallet);

    React.useEffect(() => {
        if (!mount) {
            getDeeplink(wallet);
            setMount(true);
        }
    }, [getDeeplink, mount, wallet]);

    return (
        <div className={styles.Root}>
            <div className={styles.Content}>
                {authGenerateDeeplinkError && <Error onClick={handleWalletReload} />}

                {authGenerateDeeplinkLoading && (
                    <div className={styles.Preloader}>
                        <Preloader size={40} />
                    </div>
                )}

                {!authGenerateDeeplinkLoading && !authGenerateDeeplinkError && currentDeeplink && (
                    <QR data={currentDeeplink} size={220} className={styles.Qr} color="#2c3146" />
                )}
            </div>
            <div className={styles.TabsContainer}>
                <Tabs
                    tabList={[
                        {
                            label: <PaycashIcon className={styles.TabsIcon} />,
                            value: WalletName.Paycash
                        },
                        {
                            label: <KalynaIcon className={styles.TabsIcon} />,
                            value: WalletName.Kalyna
                        },
                        {
                            label: <MalinkaIcon className={styles.TabsIcon} />,
                            value: WalletName.Malinka
                        }
                    ]}
                    value={wallet}
                    onChange={handleWalletChange}
                    isDark={false}
                    fullWidth
                />
                <div className={styles.TabsDescription}>{t('pageVirtualCard.app.description')}</div>
            </div>
        </div>
    );
}
