import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { RouteComponentProps } from 'react-router-dom';
import Intro from '../../components/AboutProjectIntro/AboutProjectIntro';
import About from '../../components/AboutProject/AboutProject';
import Statistics from '../../components/AboutProjectStatistics/AboutProjectStatistics';
import Fee from '../../components/AboutProjectFee/AboutProjectFee';
import AboutMLNK from '../../components/AboutProjectMLNK/AboutProjectMLNK';
import Start from '../../components/AboutProjectStart/AboutProjectStart';
import PoolsSection from '../../components/AboutProjectPoolsSection/AboutProjectPoolsSection';
import IncomeDescription from '../../components/AboutProjectIncomeDescription/AboutProjectIncomeDescription';
import styles from './AboutProject.module.scss';

const cx = classNames.bind(styles);

function PageAboutProject({ location, history }: RouteComponentProps) {
    useEffect(() => {
        if (location.hash) {
            const target = document.getElementById(location.hash.slice(1));
            if (target) {
                setTimeout(() => {
                    window.scrollTo({
                        left: 0,
                        top: target.offsetTop,
                        behavior: 'smooth'
                    });
                }, 200);

                history.replace({
                    ...location,
                    hash: ''
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <main className={cx('Component')}>
            <Intro />
            <About />
            <Statistics />
            <Fee />
            <IncomeDescription />
            <AboutMLNK />
            <PoolsSection />
            <Start />
        </main>
    );
}

export default PageAboutProject;
