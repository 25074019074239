import React from 'react';
import VirtualCardHistorySkeleton from '../VirtualCardHistory/VirtualCardHistorySkeleton';
import VirtualCardInfoSkeleton from '../VirtualCardInfo/VirtualCardInfoSkeleton';
import styles from './VirtualCard.module.scss';

export default function VirtualCardSkeleton() {
    return (
        <div className={styles.Root}>
            <div className={styles.Left}>
                <VirtualCardInfoSkeleton />
            </div>
            <div className={styles.Right}>
                <div>
                    <VirtualCardHistorySkeleton />
                </div>
            </div>
        </div>
    );
}
