import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCardFeesQuery, useMyCardAccountQuery } from 'apollo/main/generated';
import useAuth from 'hooks/useAuth/useAuth';
import { ExitIcon, RefreshIcon } from 'ui/Icons/Icons';
import Button from 'ui/Button/Button';
import VirtualCardInfo from '../VirtualCardInfo/VirtualCardInfo';
import VirtualCardStatus from '../VirtualCardStatus/VirtualCardStatus';
import VirtualCardHistory from '../VirtualCardHistory/VirtualCardHistory';
import VirtualCardIssue from '../VirtualCardIssue/VirtualCardIssue';
import VirtualCardAuth from '../VirtualCardAuth/VirtualCardAuth';
import VirtualCardSkeleton from './VirtualCardSkeleton';
import styles from './VirtualCard.module.scss';

export default function VirtualCard() {
    const [t] = useTranslation();
    const { isAuth, logout } = useAuth();

    const {
        data: myCardAccountData,
        loading: myCardAccountLoading,
        error: myCardAccountError,
        refetch: myCardAccountRefetch
    } = useMyCardAccountQuery({
        skip: !isAuth,
        fetchPolicy: 'network-only'
    });

    const { eosAccount, activeCard, action, emissionStatus } = myCardAccountData?.myCardAccount ?? {};

    const { id, cardholderName, status, privateInfo, balance } = activeCard ?? {};

    const {
        data: cardFeesData,
        loading: cardFeesLoading,
        error: cardFeesError,
        refetch: cardFeesRefetch
    } = useCardFeesQuery({
        skip: !isAuth
    });

    const { emission: issue, replenishment: topUp } = cardFeesData?.cardFees ?? {};

    const loading = myCardAccountLoading || cardFeesLoading;

    const handleLogoutClick = () => logout();

    const handleRefetchClick = async () => {
        await myCardAccountRefetch();
        await cardFeesRefetch();
    };

    if (!isAuth) return <VirtualCardAuth />;

    if (myCardAccountError || cardFeesError)
        return (
            <VirtualCardStatus eosAccount={eosAccount}>
                <div className={styles.Error}>
                    <h1>{t('pageVirtualCard.card.error.title')}</h1>
                    <div className={styles.ErrorDescription}>{t('pageVirtualCard.card.error.description')}</div>
                    <div className={styles.ErrorButtonContainer}>
                        <Button type="button" iconStart={<RefreshIcon />} onClick={handleRefetchClick} fullWidth>
                            {t('pageVirtualCard.button.refresh')}
                        </Button>
                    </div>
                    <div className={styles.ErrorButtonContainer}>
                        <Button
                            type="button"
                            iconStart={<ExitIcon />}
                            onClick={handleLogoutClick}
                            fullWidth
                            color="secondary"
                        >
                            {t('pageVirtualCard.auth.button.logout')}
                        </Button>
                    </div>
                </div>
            </VirtualCardStatus>
        );

    if (emissionStatus && emissionStatus !== 'success' && !loading)
        return (
            <VirtualCardStatus
                eosAccount={eosAccount}
                action={action as any}
                emissionStatus={emissionStatus}
                onClose={() => myCardAccountRefetch()}
            />
        );

    if (loading) return <VirtualCardSkeleton />;

    return (
        <div className={styles.Root}>
            <div className={styles.Left}>
                <VirtualCardInfo
                    account={eosAccount}
                    balance={balance}
                    cardId={id}
                    cardholderName={cardholderName}
                    issueFees={issue}
                    privateInfo={privateInfo}
                    topUpFees={topUp}
                />
            </div>
            <div className={styles.Right}>
                {activeCard && status && status === 'active' ? (
                    <VirtualCardHistory cardId={id} />
                ) : (
                    <VirtualCardIssue issueFees={issue} onReload={() => myCardAccountRefetch()} />
                )}
            </div>
        </div>
    );
}
