import { useReactiveVar } from '@apollo/client';
import client from 'apollo';
import { AuthRefreshTokenDocument } from 'apollo/auth/generated';
import {
    isAuthVar,
    LOCAL_STORAGE_KEY_TOKEN_AUTH,
    LOCAL_STORAGE_KEY_TOKEN_REFRESH,
    LOCAL_STORAGE_KEY_TOKEN_EXPIRATION
} from 'apollo/cache';
import { login, logout } from 'helpers/Auth/Auth';

export const getToken = () => window.localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN_AUTH);
export const getTokenRefresh = () => window.localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN_REFRESH);
export const getTokenExpiration = () => window.localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN_EXPIRATION);

export const getIsTokenExpired = () =>
    Boolean(new Date(getTokenExpiration() || Date.now()).getTime() - Date.now() <= 0);

export const refreshAuthToken = async () => {
    const tokenRefresh = getTokenRefresh();

    try {
        const { data } = await client.mutate({
            mutation: AuthRefreshTokenDocument,
            variables: { refreshToken: tokenRefresh },
            context: { clientName: 'auth' }
        });

        const authRefreshToken = data?.authRefreshToken;

        if (authRefreshToken?.__typename === 'AuthTokenResponse') {
            const { accessToken, refreshToken: rt, accessTokenExpiresAt } = authRefreshToken ?? {};

            login(accessToken, accessTokenExpiresAt, rt);

            return accessToken;
        }

        // if (authRefreshToken?.__typename === 'ErrorResponse') {
        //     const { detail } = authRefreshToken ?? {};
        //
        //     if (detail === 'Refresh token was expired or in invalid state') {
        //         logout();
        //     }
        //
        //     return null;
        // }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log('FAILED TO REFRESH TOKEN:', error);

        return null;
    }

    return null;
};

/**
 * Authentication hook.
 *
 * @returns {{
 *     isAuth: boolean,
 *     logout: () => void,
 *     login: (token: string, expiration: string, refresh: string) => void
 * }}
 */
export default function useAuth(): {
    isAuth: boolean;
    logout: () => void;
    login: (token: string, expiration: string, refresh: string) => void;
} {
    const isAuth = useReactiveVar(isAuthVar);

    return {
        isAuth,
        login: (token: string, expiration: string, refresh: string) => {
            login(token, expiration, refresh);
        },
        logout: async () => {
            logout();
            await client.cache.reset();
        }
    };
}
