import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTotalDepositStatisticQuery } from 'apollo/main/generated';
import { PairsIcon } from 'ui/Icons/Icons';
import getTokenLogo from 'helpers/getTokenLogo/getTokenLogo';
import styles from './TransactionTokenFilter.module.scss';

type TransactionTokenFilterProps = {
    className?: string;
    onChange?: (t?: number | null) => void;
    active?: number | null;
};

export default function TransactionTokenFilter({ onChange, active, className = '' }: TransactionTokenFilterProps) {
    const [t] = useTranslation();

    const { data } = useTotalDepositStatisticQuery();

    return (
        <div className={cn(styles.Component, className)}>
            <button
                type="button"
                className={cn(styles.Button, !active && styles.ButtonActive)}
                onClick={() => onChange?.(null)}
            >
                <PairsIcon className={cn(styles.Icon, styles.IconAll)} /> {t(`global.transactionType.all`)}
            </button>
            {data?.totalDepositStatistic?.map((item) => (
                <button
                    type="button"
                    className={cn(styles.Button, active === item?.tokenOut.id && styles.ButtonActive)}
                    onClick={() => onChange?.(item?.tokenOut.id)}
                    key={item?.tokenOut.id}
                >
                    <img
                        className={styles.Icon}
                        src={getTokenLogo(item?.tokenOut.logo, item?.tokenOut.code, item?.tokenOut.smartContract)}
                        alt={item?.tokenOut.code}
                    />
                    {item?.tokenOut.code}
                </button>
            ))}
        </div>
    );
}
