export function isTouchableDevice() {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}

export enum OS {
    MacOS = 'MacOS',
    iOS = 'iOS',
    Windows = 'Windows',
    Android = 'Android',
    Linux = 'Linux'
}

export function OSDetection(): OS | undefined {
    /** SSR */
    if (typeof window === 'undefined') return undefined;
    /** Browser */
    const { userAgent } = window.navigator;

    switch (true) {
        case /Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent):
            return OS.MacOS;
        case /iPhone|iPad|iPod/.test(userAgent):
            return OS.iOS;
        case /Win32|Win64|Windows|WinCE/.test(userAgent):
            return OS.Windows;
        case /Android/.test(userAgent):
            return OS.Android;
        case /Linux/.test(userAgent):
            return OS.Linux;
        default:
            return undefined;
    }
}
