import { SearchEosAccountsQueryVariables, useSearchEosAccountsQuery } from 'apollo/main/generated';
import { ReactComponent as IconLoader } from 'assets/images/loader.svg';
import classNames from 'classnames/bind';
import BookmarkBtn from 'components/BookmarkBtn/BookmarkBtn';
import { SearchIcon, SadFaceFilledIcon } from 'ui/Icons/Icons';
import Pagination from 'ui/Pagination/Pagination';
import SearchField from 'components/SearchField/SearchField';
import { ROUTES_MAP } from 'constant';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import highlightSubstring from 'helpers/highlightSubstring';
import styles from './SearchAccount.module.scss';

const cx = classNames.bind(styles);

const STEP = 10;

function PageSearchAccount({ location, history }: RouteComponentProps) {
    const [t] = useTranslation();

    const [variables, setVariables] = useState<SearchEosAccountsQueryVariables>({
        skip: 0,
        part: new URLSearchParams(location.search).get('query') ?? '',
        limit: STEP
    });

    const { loading, data, previousData } = useSearchEosAccountsQuery({
        variables
    });

    const searchEosAccounts = data?.searchEosAccounts ?? previousData?.searchEosAccounts;
    const { total, accounts } = searchEosAccounts ?? {};
    const totalCount = total ?? 0;
    const showResult = !!variables.part;
    const contentReady = Boolean(searchEosAccounts || !loading);

    const onChangeSearch = (part: string) => {
        setVariables({
            ...variables,
            part,
            skip: 0
        });

        history.replace({
            pathname: ROUTES_MAP.searchAccount,
            search: `query=${part}`
        });
    };

    return (
        <main className={cx('Component')}>
            <h1 className={cx('Title')}>{t('pageSearchAccount.title')}</h1>
            <p className={cx('SubTitle')}>
                {t('pageSearchAccount.subTitle', {
                    count: totalCount ?? 0,
                    search: variables?.part
                })}
            </p>

            <SearchField
                className={cx('SearchField')}
                inputClassName={cx('SearchFieldInput')}
                searchIconClassName={cx('SearchFieldIcon')}
                clearClassName={cx('SearchFieldBtnClear')}
                value={variables.part}
                toLowerCase
                onChange={onChangeSearch}
                inputProps={{
                    placeholder: t('searchAccount.placeholder')
                }}
            />

            <div className={cx('Content')}>
                {loading && (
                    <div className={cx('Loader')}>
                        <IconLoader />
                    </div>
                )}
                {showResult ? (
                    contentReady &&
                    (accounts?.length ? (
                        <>
                            <ul className={cx('List', loading && 'loading')}>
                                {accounts.map(
                                    (account) =>
                                        account && (
                                            <li key={account.name} className={cx('ListItem')}>
                                                <Link to={ROUTES_MAP.account(account.name)}>
                                                    {/* {account.name} */}
                                                    {highlightSubstring(account.name, [0, variables.part.length])}
                                                </Link>

                                                <BookmarkBtn
                                                    isFavorite={account.isFavorite}
                                                    isSmall
                                                    type="account"
                                                    eosAccount={account.name}
                                                    className={cx(account.isFavorite && 'BtnBookmark')}
                                                />
                                            </li>
                                        )
                                )}
                            </ul>
                            {totalCount > STEP && (
                                <Pagination
                                    className={cx('Pagination')}
                                    onChangePage={(page) =>
                                        setVariables({
                                            ...variables,
                                            skip: page * STEP
                                        })
                                    }
                                    page={variables.skip! / STEP}
                                    pageCount={Math.ceil(totalCount / STEP)}
                                />
                            )}
                        </>
                    ) : (
                        <div className={cx('EmptyScreen', loading && 'loading')}>
                            <SadFaceFilledIcon className={styles.SadIcon} />

                            <h6>{t('pageSearchAccount.empty.title')}</h6>
                            <p>{t('pageSearchAccount.empty.description')}</p>
                        </div>
                    ))
                ) : (
                    <div className={cx('InitScreen', loading && 'loading')}>
                        <SearchIcon className={styles.SearchIcon} />

                        <p>{t('pageSearchAccount.init.title')}</p>
                    </div>
                )}
            </div>
        </main>
    );
}

export default PageSearchAccount;
