import { TokenSortEnum, TokensQueryVariables, useTokensQuery } from 'apollo/main/generated';
import classNames from 'classnames/bind';
import BookmarkBtn from 'components/BookmarkBtn/BookmarkBtn';
import Button from 'ui/Button_DEPRECATED/Button';
import Changed from 'components/Changed/Changed';
import FormatNumber from 'components/FormatNumber/FormatNumber';
import { RefreshIcon } from 'ui/Icons/Icons';
import PageState from 'components/PageState/PageState';
import Pagination from 'ui/Pagination/Pagination';
import SearchField from 'components/SearchField/SearchField';
import SortDropdown from 'components/SortDropdown/SortDropdown';
import Table from 'ui/Table/Table';
import TokenCard from 'components/TokenCard/TokenCard';
import { BREAKPOINT_IPAD, BREAKPOINT_LANDSCAPE, ROUTES_MAP } from 'constant';
import { useWidthCondition } from 'helpers';
import update from 'immutability-helper';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { RouteComponentProps } from 'react-router';
import styles from './Tokens.module.scss';

const cx = classNames.bind(styles);

const STEP = 10;

const SORT_TYPES = {
    Code: {
        ASC: TokenSortEnum.Code,
        DESC: TokenSortEnum.CodeDesc
    },
    Liquidity: {
        ASC: TokenSortEnum.Liquidity,
        DESC: TokenSortEnum.LiquidityDesc
    },
    Volume: {
        ASC: TokenSortEnum.Volume,
        DESC: TokenSortEnum.VolumeDesc
    },
    Price: {
        ASC: TokenSortEnum.Price,
        DESC: TokenSortEnum.PriceDesc
    },
    PriceChange: {
        ASC: TokenSortEnum.PriceChange,
        DESC: TokenSortEnum.PriceChangeDesc
    }
};

type SortType = (typeof SORT_TYPES)[keyof typeof SORT_TYPES];

function PageTokens({ location, history }: RouteComponentProps) {
    const [t] = useTranslation();
    const [isIpadOrLess] = useWidthCondition((w) => w < BREAKPOINT_IPAD);
    const [isLandscapeOrLess] = useWidthCondition((w) => w < BREAKPOINT_LANDSCAPE);

    const [variables, setVariables] = useState<TokensQueryVariables>({
        skip: 0,
        filters: {
            liquidity: { from: '100' },
            code: new URLSearchParams(location.search).get('query') ?? ''
        },
        sort: [TokenSortEnum.LiquidityDesc],
        limit: STEP
    });
    const sort = variables.sort![0] as TokenSortEnum;

    const { loading, data, previousData, error, ...tokensQuery } = useTokensQuery({
        variables,
        notifyOnNetworkStatusChange: true
    });

    const result = data?.tokens ?? previousData?.tokens;
    const { pageInfo, tokens } = result ?? {};
    const totalCount = pageInfo?.totalFiltered ?? 0;
    const showErrorScreen = !!error;
    const contentReady = (!!result || !loading) && !showErrorScreen;
    const isFirstLoading = !result && loading && !showErrorScreen;

    const onChangeSearch = (code: string) => {
        setVariables(
            update(variables, {
                filters: {
                    code: () => code
                },
                skip: () => 0
            })
        );

        history.replace({
            pathname: ROUTES_MAP.tokens,
            search: `query=${code}`
        });
    };

    const createOnClickSort =
        ({ ASC, DESC }: SortType) =>
        () => {
            setVariables({
                ...variables,
                skip: 0,
                // eslint-disable-next-line no-nested-ternary
                sort: [sort === ASC ? DESC : sort === DESC ? ASC : ASC]
            });
        };

    const getSortType = ({ ASC, DESC }: SortType) =>
        // eslint-disable-next-line no-nested-ternary
        sort === ASC ? 'ASC' : sort === DESC ? 'DESC' : null;
    return (
        <main className={cx('Component')}>
            <div className={cx('HeaderRow')}>
                <h1 className={cx('Title')}>{t('pageTokens.title')}</h1>

                {contentReady && (
                    <div className={cx('Filters')}>
                        <SearchField
                            className={cx('SearchField')}
                            inputProps={{ placeholder: t('global.search') }}
                            value={variables.filters?.code ?? ''}
                            onChange={onChangeSearch}
                        />
                        {isIpadOrLess && (
                            <SortDropdown<TokenSortEnum>
                                options={[
                                    {
                                        label: t('pageTokens.table.header.token.title_ASC'),
                                        value: TokenSortEnum.Code
                                    },
                                    {
                                        label: t('pageTokens.table.header.token.title_DESC'),
                                        value: TokenSortEnum.CodeDesc
                                    },
                                    {
                                        label: t('pageTokens.table.header.liquidity.title_ASC'),
                                        value: TokenSortEnum.Liquidity
                                    },
                                    {
                                        label: t('pageTokens.table.header.liquidity.title_DESC'),
                                        value: TokenSortEnum.LiquidityDesc
                                    },
                                    {
                                        label: t('pageTokens.table.header.volume.title_ASC'),
                                        value: TokenSortEnum.Volume
                                    },
                                    {
                                        label: t('pageTokens.table.header.volume.title_DESC'),
                                        value: TokenSortEnum.VolumeDesc
                                    },
                                    {
                                        label: t('pageTokens.table.header.price.title_ASC'),
                                        value: TokenSortEnum.Price
                                    },
                                    {
                                        label: t('pageTokens.table.header.price.title_DESC'),
                                        value: TokenSortEnum.PriceDesc
                                    },
                                    {
                                        label: t('pageTokens.table.header.priceChange.title_ASC'),
                                        value: TokenSortEnum.PriceChange
                                    },
                                    {
                                        label: t('pageTokens.table.header.priceChange.title_DESC'),
                                        value: TokenSortEnum.PriceChangeDesc
                                    }
                                ]}
                                label={t('global.sort')}
                                className={cx('SortDropdown')}
                                active={sort!}
                                onChange={(type) => {
                                    setVariables({
                                        ...variables,
                                        skip: 0,
                                        sort: [type]
                                    });
                                }}
                            />
                        )}
                    </div>
                )}
            </div>

            {showErrorScreen && (
                <PageState.Error title={t('pageTokens.error.title')} description={t('pageTokens.error.description')}>
                    <Button
                        disabled={loading}
                        onClick={() => tokensQuery.refetch()}
                        color="tertairy-green"
                        startIcon={<RefreshIcon />}
                    >
                        {t('pageTokens.error.btn')}
                    </Button>
                </PageState.Error>
            )}
            {isFirstLoading && <PageState.Loading />}

            {contentReady && (
                <div className={cx('Main', loading && 'loading', !tokens?.length && 'full-size')}>
                    {loading && <PageState.Loading className={cx('LoaderWrapper')} />}

                    {tokens?.length ? (
                        <>
                            {!isIpadOrLess && (
                                <Table
                                    colls={[
                                        {
                                            sort: getSortType(SORT_TYPES.Code),
                                            label: t('pageTokens.table.header.token.title'),
                                            onClick: createOnClickSort(SORT_TYPES.Code)
                                        },
                                        {
                                            sort: getSortType(SORT_TYPES.Liquidity),
                                            label: t('pageTokens.table.header.liquidity.title'),
                                            onClick: createOnClickSort(SORT_TYPES.Liquidity)
                                        },
                                        {
                                            sort: getSortType(SORT_TYPES.Volume),
                                            label: t('pageTokens.table.header.volume.title'),
                                            onClick: createOnClickSort(SORT_TYPES.Volume)
                                        },
                                        {
                                            sort: getSortType(SORT_TYPES.Price),
                                            label: t('pageTokens.table.header.price.title'),
                                            onClick: createOnClickSort(SORT_TYPES.Price)
                                        },
                                        {
                                            sort: getSortType(SORT_TYPES.PriceChange),
                                            label: t('pageTokens.table.header.priceChange.title'),
                                            onClick: createOnClickSort(SORT_TYPES.PriceChange)
                                        },
                                        {
                                            label: null
                                        }
                                    ]}
                                >
                                    {tokens?.map(
                                        (token) =>
                                            token && (
                                                <tr
                                                    onClick={() => history.push(ROUTES_MAP.token(token.id))}
                                                    className={cx('TableRow')}
                                                    key={token.id}
                                                >
                                                    <td>
                                                        <TokenCard
                                                            {...token}
                                                            highlight={variables.filters?.code ?? ''}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormatNumber value={token.liquidity} prefix="$" />
                                                        <Changed period="noText" growth={token.liquidityGrowth} />
                                                    </td>
                                                    <td>
                                                        <FormatNumber value={token.volume24h} prefix="$" />
                                                        <Changed period="noText" growth={token.volume24hGrowth} />
                                                    </td>
                                                    <td>
                                                        <FormatNumber value={token.rateToUsd} prefix="$" />
                                                    </td>
                                                    <td>
                                                        <Changed
                                                            className={cx('PriceChange')}
                                                            period="noText"
                                                            growth={token.rateGrowth}
                                                        />
                                                    </td>
                                                    <td align="right">
                                                        <BookmarkBtn
                                                            type="token"
                                                            isSmall
                                                            tokenId={token.id}
                                                            isFavorite={token.isFavorite}
                                                            className={cx(token.isFavorite && 'BtnBookmark')}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                    )}
                                </Table>
                            )}

                            {isIpadOrLess && (
                                <ul className={cx('List')}>
                                    {tokens?.map(
                                        (token) =>
                                            token && (
                                                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                                                <li
                                                    onClick={() => history.push(ROUTES_MAP.token(token.id))}
                                                    className={cx('ListItem')}
                                                    key={token.id}
                                                >
                                                    <div className={cx('ListItemHeader')}>
                                                        <TokenCard
                                                            {...token}
                                                            className={cx('ListItemTokenCard')}
                                                            highlight={variables.filters?.code ?? ''}
                                                            logoSize={48}
                                                        />

                                                        {!isLandscapeOrLess && (
                                                            <BookmarkBtn
                                                                type="token"
                                                                isSmall
                                                                tokenId={token.id}
                                                                isFavorite={token.isFavorite}
                                                                className={cx(token.isFavorite && 'BtnBookmark')}
                                                            />
                                                        )}
                                                    </div>

                                                    <div className={cx('ListItemParamGroup')}>
                                                        <p className={cx('ListItemParam')}>
                                                            <span className={cx('ListItemParamLabel')}>
                                                                {t('pageTokens.table.header.liquidity.title')}
                                                            </span>
                                                            <span className={cx('ListItemParamValue')}>
                                                                <FormatNumber value={token.liquidity} prefix="$" />
                                                                <Changed
                                                                    period="noText"
                                                                    growth={token.liquidityGrowth}
                                                                />
                                                            </span>
                                                        </p>
                                                        <p className={cx('ListItemParam')}>
                                                            <span className={cx('ListItemParamLabel')}>
                                                                {t('pageTokens.table.header.volume.title')}
                                                            </span>
                                                            <span className={cx('ListItemParamValue')}>
                                                                <FormatNumber value={token.volume24h} prefix="$" />
                                                                <Changed
                                                                    period="noText"
                                                                    growth={token.volume24hGrowth}
                                                                />
                                                            </span>
                                                        </p>
                                                        <p className={cx('ListItemParam')}>
                                                            <span className={cx('ListItemParamLabel')}>
                                                                {t('pageTokens.table.header.price.title')}
                                                            </span>
                                                            <span className={cx('ListItemParamValue')}>
                                                                <FormatNumber value={token.rateToUsd} prefix="$" />
                                                            </span>
                                                        </p>
                                                        <p className={cx('ListItemParam')}>
                                                            <span className={cx('ListItemParamLabel')}>
                                                                {t('pageTokens.table.header.priceChange.title')}
                                                            </span>
                                                            <span className={cx('ListItemParamValue')}>
                                                                <Changed
                                                                    className={cx('PriceChange')}
                                                                    period="noText"
                                                                    growth={token.rateGrowth}
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    {isLandscapeOrLess && (
                                                        <BookmarkBtn
                                                            type="token"
                                                            className={cx(
                                                                'ListItemBtnBookmark',
                                                                token.isFavorite && 'BtnBookmark'
                                                            )}
                                                            tokenId={token.id}
                                                            isFavorite={token.isFavorite}
                                                        />
                                                    )}
                                                </li>
                                            )
                                    )}
                                </ul>
                            )}

                            <Pagination
                                className={cx('Pagination')}
                                page={variables.skip! / STEP}
                                pageCount={Math.ceil(totalCount / STEP)}
                                onChangePage={(page) =>
                                    setVariables({
                                        ...variables,
                                        skip: page * STEP
                                    })
                                }
                            />
                        </>
                    ) : (
                        <PageState.Error
                            title={t('pageTokens.empty.title')}
                            description={t('pageTokens.empty.description')}
                        />
                    )}
                </div>
            )}
        </main>
    );
}

export default PageTokens;
