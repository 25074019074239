import classNames from 'classnames/bind';
import React, { PropsWithChildren, useState } from 'react';
import { usePopper } from 'react-popper';
import styles from './Tooltip.module.scss';

const cx = classNames.bind(styles);

type PopperOptions = Parameters<typeof usePopper>[2];

type Props = PropsWithChildren<{
    popperReference: Parameters<typeof usePopper>[0];
    popperOptions?(defaultConfig: PopperOptions): PopperOptions;
    isActive: boolean;
    className?: string;
    setShow: (v: boolean) => void;
    hide: () => void;
    show: () => void;
}>;

export const useTooltip = () => {
    const [popperReference, setPopperReference] = useState<HTMLElement | null>(null);
    const [isActive, setIsActive] = useState(false);

    return {
        setPopperReference,
        popperReference,
        isActive,
        setShow: setIsActive,
        show: () => setIsActive(true),
        hide: () => setIsActive(false)
    };
};
/**
 * @todo fix bug with invalid position of tooltip on mount, and then return animation
 */
function Tooltip({ popperReference, popperOptions, children, className = '', isActive }: Props) {
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
    const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);

    const defaultConfig: PopperOptions = {
        placement: 'bottom',
        modifiers: [
            { name: 'preventOverflow' },
            { name: 'arrow', options: { element: arrowElement } },
            {
                name: 'offset',
                options: {
                    offset: [0, 10]
                }
            }
        ]
    };

    const { styles: popperStyles, attributes: popperAttributes } = usePopper(
        popperReference,
        popperElement,
        popperOptions?.(defaultConfig) ?? defaultConfig
    );

    if (!isActive) return null;

    return (
        <span
            style={popperStyles.popper}
            {...popperAttributes.popper}
            ref={setPopperElement}
            className={cx('Tooltip', className)}
        >
            <span className={cx('Content')}>{children}</span>
            <span ref={setArrowElement} style={popperStyles.arrow} className={cx('TooltipArrow')} />
        </span>
    );
}

export default Tooltip;
