import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import Button from 'ui/Button_DEPRECATED/Button';
import { PAYCASH_LANDING } from 'constant';
import getImage from 'helpers/getImage/getImage';
import ButtonDownload from './ButtonDownload/ButtonDownload';
import styles from './AboutProjectStart.module.scss';

const cx = classNames.bind(styles);

export const ID = 'how-to-start';
const STEPS = 8;

function StepDetails({ step = 1 }: any) {
    const [t] = useTranslation();
    return (
        <>
            <h3 className={cx('StepTitle')}>
                <span className={cx('StepCount')}>{t('pageInfo.start.step', { count: step })}</span>
                {t(`pageInfo.start.step${step}.title`)}
            </h3>
            <p className={cx('StepDescription')}>{t(`pageInfo.start.step${step}.text`)}</p>
        </>
    );
}

function StepList({ text }: { text: string[] }) {
    return (
        <ol className={cx('List')}>
            {text.map((t, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={i}>
                    <i>{i + 1}</i>
                    <span>{t}</span>
                </li>
            ))}
        </ol>
    );
}

// const BtnVideo = ({ href = '' }) => {
//     const [t] = useTranslation();
//
//     return (
//         <Button
//             startIcon={<IconVideoCamera />}
//             color="secondary"
//             tag="a"
//             className={cx('BtnVideo')}
//             target="_blank"
//             href={href}
//         >
//             {t('pageInfo.start.btnVideo')}
//         </Button>
//     );
// };

function Start() {
    const [t, { language }] = useTranslation();
    const [step, setStep] = useState(1);

    const next = () => {
        const nextStep = step + 1;
        setStep(nextStep > STEPS ? 1 : nextStep);
    };

    const back = () => {
        const nextStep = step - 1;
        setStep(nextStep < 1 ? STEPS : nextStep);
    };

    return (
        <section id={ID} className={cx('Component')}>
            <div className={cx('Container')}>
                <h2 className={cx('Title')}>{t('pageInfo.start.title')}</h2>

                <div className={cx('StepsContainer')}>
                    <div className={cx('StepsContent')}>
                        {step === 1 && (
                            <div>
                                <StepDetails step={1} />
                                <div className={cx('LinkGroup')}>
                                    <ButtonDownload color="dark" os="android" />
                                    <ButtonDownload color="dark" os="ios" />
                                </div>

                                <Button
                                    className={cx('Link')}
                                    color="link"
                                    tag="a"
                                    target="_blank"
                                    href={PAYCASH_LANDING}
                                >
                                    {t('pageInfo.start.step1.btnSite')}
                                </Button>
                            </div>
                        )}
                        {step === 2 && (
                            <div>
                                <StepDetails step={2} />
                            </div>
                        )}
                        {step === 3 && (
                            <div>
                                <StepDetails step={3} />
                            </div>
                        )}
                        {step === 4 && (
                            <div>
                                <StepDetails step={4} />
                                <StepList
                                    text={
                                        t('pageInfo.start.step4.list', {
                                            returnObjects: true
                                        }) as string[]
                                    }
                                />
                            </div>
                        )}
                        {step === 5 && (
                            <div>
                                <StepDetails step={5} />
                            </div>
                        )}
                        {step === 6 && (
                            <div>
                                <StepDetails step={6} />
                            </div>
                        )}
                        {step === 7 && (
                            <div>
                                <StepDetails step={7} />
                                <StepList
                                    text={
                                        t('pageInfo.start.step7.list', {
                                            returnObjects: true
                                        }) as string[]
                                    }
                                />
                            </div>
                        )}
                        {step === 8 && (
                            <div>
                                <StepDetails step={8} />
                                <StepList
                                    text={
                                        t('pageInfo.start.step8.list', {
                                            returnObjects: true
                                        }) as string[]
                                    }
                                />
                            </div>
                        )}

                        <div className={cx('Nav')}>
                            {step !== 1 && (
                                <Button color="tertairy-green" onClick={back}>
                                    {t('pageInfo.start.btnBack')}
                                </Button>
                            )}
                            {step !== STEPS && (
                                <Button color="primary" onClick={next}>
                                    {t('pageInfo.start.btnNext')}
                                </Button>
                            )}
                        </div>
                    </div>

                    <div className={cx('StepsImgWrapper')}>
                        <img
                            srcSet={getImage('about-project_start', language, 2, step)}
                            src={getImage('about-project_start', language, 1, step)}
                            alt="About Project Start"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Start;
