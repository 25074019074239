import { ResponsivePie } from '@nivo/pie';
import {
    ActionSortEnum,
    ActionsQueryVariables,
    TxnType,
    // useAccountPoolProfitChartQuery,
    useActionsQuery,
    useEosAccountPoolQuery
} from 'apollo/main/generated';
import classNames from 'classnames/bind';
import BookmarkBtn from 'components/BookmarkBtn/BookmarkBtn';
import Button from 'ui/Button_DEPRECATED/Button';
import Card, { CardValue } from 'components/Card/Card';
import Chart, { COLOR_SCHEME } from 'components/Chart/Chart';
import FormatNumber from 'components/FormatNumber/FormatNumber';
import { ChevronLeftIcon, CrossIcon, RefreshIcon } from 'ui/Icons/Icons';
import PageState from 'components/PageState/PageState';
import TokenCard from 'components/TokenCard/TokenCard';
import TransactionsTable from 'components/TransactionsTable/TransactionsTable';
import TransactionTypeFilter from 'components/TransactionTypeFilter/TransactionTypeFilter';
import { BREAKPOINT_LAPTOP, ROUTES_MAP, USDT_MIN_MOVE, USDT_PRECISION } from 'constant';
import { toFixedNumberInString, useWidthCondition } from 'helpers';
import addThousandsSeparator from 'utils/addThousandsSeparator/addThousandsSeparator';
import update from 'immutability-helper';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { Link, RouteComponentProps } from 'react-router-dom';
import Banner from '../AccountBanner/AccountBanner';
import MLNKIncomeSection from '../AccountMLNKIncomeSection/AccountMLNKIncomeSection';
import styles from './AccountPoolDetails.module.scss';

const cx = classNames.bind(styles);

function PoolDetails({
    match: {
        params: { account, pool: code }
    }
}: RouteComponentProps<{ account: string; pool: string }>) {
    const [t] = useTranslation();
    const [isLaptopOrLess] = useWidthCondition((w) => w < BREAKPOINT_LAPTOP);
    const [MLNKBalanceLoading, setMLNKBalanceLoading] = useState(true);

    const eosAccountQuery = useEosAccountPoolQuery({
        variables: { name: account, poolCode: code },
        notifyOnNetworkStatusChange: true
    });

    if (eosAccountQuery.error || (!eosAccountQuery.loading && !eosAccountQuery.data))
        return (
            <PageState.Error title={t('pageAccount.error.title')} description={t('pageAccount.error.description')}>
                <Button
                    color="tertairy-green"
                    disabled={eosAccountQuery.loading}
                    onClick={() => eosAccountQuery.refetch()}
                    startIcon={<RefreshIcon />}
                >
                    {t('pageAccount.error.btn')}
                </Button>
            </PageState.Error>
        );

    if (eosAccountQuery.loading) return <PageState.Loading />;

    const accountData = eosAccountQuery.data!.eosAccount;
    const { accountPoolProfitDetails } = eosAccountQuery.data!;
    const { accountPoolProfitChart } = eosAccountQuery.data!;
    const pool = accountData?.heldPool;

    if (!accountData || !pool)
        return (
            <PageState.Error
                title={t('pageAccount.poolDetails.empty.title', { account })}
                description={t('pageAccount.poolDetails.empty.description')}
            >
                <Button
                    color="tertairy-green"
                    tag="link"
                    to={ROUTES_MAP.account(account)}
                    startIcon={<ChevronLeftIcon />}
                >
                    {t('pageAccount.poolDetails.empty.btn', { account })}
                </Button>
            </PageState.Error>
        );

    const tokens = [
        {
            tokenAmount: pool?.token1Amount,
            inUsd: pool?.token1UsdAmount,
            ...pool?.token1
        },
        {
            tokenAmount: pool?.token2Amount,
            inUsd: pool?.token2UsdAmount,
            ...pool?.token2
        }
    ];
    const share = Number(pool?.shareInPool ?? 0);

    return (
        <main className={cx('Component')}>
            <div className={cx('Header')}>
                <Link className={cx('BtnBack')} to={ROUTES_MAP.account(account)}>
                    <ChevronLeftIcon />
                </Link>
                <h1 className={cx('Title')}>
                    {t('pageAccount.poolDetails.title')}
                    <span>
                        {accountData?.name} : {tokens.map((token) => token.code).join(' - ')}
                    </span>
                </h1>
                {isLaptopOrLess && (
                    <BookmarkBtn isFavorite={accountData!.isFavorite} type="account" eosAccount={account} />
                )}
            </div>
            <div className={cx('Main')}>
                <section className={cx('ListTokenCards')}>
                    {tokens.map((token, i) => (
                        <>
                            <div className={cx('TokenCard')}>
                                <span className={cx('TokenCardBg')} />
                                <TokenCard publicNameClassName={cx('TokenCardLabel')} logoSize={42} {...token} />

                                <div className={cx('TokenCardParam')}>
                                    <span>{t('pageAccount.poolDetails.addedToPool')}</span>
                                    <b>
                                        {addThousandsSeparator(token.tokenAmount)} {token.code}
                                    </b>
                                    <FormatNumber
                                        className={cx('TokenCardParamSmall')}
                                        prefix="$"
                                        value={token.inUsd}
                                    />
                                </div>
                            </div>
                            {i !== tokens.length - 1 && (
                                <div className={cx('ListTokenCardsDivider')}>
                                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                                    <CrossIcon className={styles.Icon} />
                                </div>
                            )}
                        </>
                    ))}
                </section>

                <section className={cx('IncomeSection')}>
                    <Card
                        headerClassName={cx('CardHeader')}
                        titleClassName={cx('CardTitle')}
                        title={
                            <div className={cx('CardValues')}>
                                <CardValue
                                    changed={{
                                        growth: accountPoolProfitChart!.percentageChange24h ?? 0,
                                        period: '24h'
                                    }}
                                >
                                    <span className={cx('CardValueLabel')}>
                                        {t('pageAccount.poolDetails.totalIncome.title')}
                                    </span>
                                    <FormatNumber value={accountPoolProfitDetails?.total24hProfit ?? 0} prefix="$" />
                                </CardValue>

                                <CardValue>
                                    <span className={cx('CardValueLabel')}>
                                        {t('pageAccount.poolDetails.totalIncome.title', {
                                            context: 'total'
                                        })}
                                    </span>
                                    <FormatNumber value={accountPoolProfitChart!.totalProfit ?? 0} prefix="$" />
                                </CardValue>
                            </div>
                        }
                        mainClassName={cx('CardIncomeMain')}
                        helpText={t('pageAccount.poolDetails.totalIncome.helpText')}
                    >
                        <ul className={cx('IncomeDetails')}>
                            <li>
                                <span>{t('pageAccount.poolDetails.totalIncome.params.mlnk')}</span>{' '}
                                <FormatNumber prefix="$" value={accountPoolProfitDetails?.mlnkUsd} />
                            </li>
                            <li>
                                <span>
                                    {t('pageAccount.poolDetails.totalIncome.params.turnover', {
                                        token: pool.token1.code
                                    })}
                                </span>{' '}
                                <FormatNumber prefix="$" value={accountPoolProfitDetails?.token1Usd} />{' '}
                            </li>
                            <li>
                                <span>
                                    {t('pageAccount.poolDetails.totalIncome.params.turnover', {
                                        token: pool.token2.code
                                    })}
                                </span>{' '}
                                <FormatNumber prefix="$" value={accountPoolProfitDetails?.token2Usd} />
                            </li>
                        </ul>
                    </Card>

                    {/* <PoolProfitChart account={account} poolCode={code} /> */}

                    <Card title={null}>
                        <Chart
                            data={{
                                type: 'histogram',
                                data:
                                    accountPoolProfitChart?.points.map((p) => ({
                                        value: Number(p?.value),
                                        time: p!.timestamp
                                    })) ?? [],
                                options: {
                                    ...COLOR_SCHEME.green.histogram,
                                    priceFormat: {
                                        precision: USDT_PRECISION,
                                        minMove: USDT_MIN_MOVE
                                    }
                                }
                            }}
                        />
                    </Card>
                </section>

                <div className={cx('Charts')}>
                    <Card
                        title={t('pageAccount.poolDetails.liquidityTokens.title')}
                        helpText={t('pageAccount.poolDetails.liquidityTokens.helpText')}
                    >
                        <CardValue>
                            <FormatNumber prefix="$" value={pool?.usdEquivalent} />
                        </CardValue>

                        <div className={cx('LiquidityTokensChartWrapper')}>
                            <div className={cx('LiquidityTokensChart')}>
                                <ResponsivePie
                                    data={[
                                        {
                                            id: 'c',
                                            label: 'c',
                                            value: share > 1 ? share : 1,
                                            color: 'rgb(17, 182, 138)'
                                        },
                                        {
                                            id: 'sass',
                                            label: 'sass',
                                            value: 100 - (share > 1 ? share : 1),
                                            color: 'rgba(17, 182, 138, 0.2)'
                                        }
                                    ]}
                                    isInteractive={false}
                                    innerRadius={0.6}
                                    colors={(d) => d.data.color}
                                    layers={[
                                        'arcs',
                                        // eslint-disable-next-line @typescript-eslint/no-use-before-define
                                        CenteredMetric(
                                            t('pageAccount.poolDetails.liquidityTokens.%Pool'),

                                            share < 0.1 ? '<0.1' : toFixedNumberInString(share.toString())
                                        )
                                    ]}
                                />
                            </div>

                            <ul>
                                <li className={cx('LiquidityTokensParam')}>
                                    <span>
                                        {t('pageAccount.poolDetails.liquidityTokens.account', {
                                            account: accountData?.name
                                        })}
                                    </span>
                                    <b>
                                        {addThousandsSeparator(pool?.myLiquidityTokens)} {pool?.code}
                                    </b>
                                </li>
                                <li className={cx('LiquidityTokensParam')}>
                                    <span>{t('pageAccount.poolDetails.liquidityTokens.total')}</span>
                                    <b>
                                        {addThousandsSeparator(pool?.totalLiquidityTokens)} {pool?.code}
                                    </b>
                                </li>
                            </ul>
                        </div>
                    </Card>

                    <MLNKIncomeSection readyToLoad={!MLNKBalanceLoading} poolCode={code} account={account} />
                </div>

                {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
                <SectionTransacactions poolCode={pool.code} eosAccount={account} />
            </div>
            <Banner
                onChangeLoad={setMLNKBalanceLoading}
                mlnkCollectParams={accountData!.mlnkCollectParams!}
                account={account}
            />
        </main>
    );
}

// const PoolProfitChart = ({
// 	account,
// 	poolCode,
// }: {
// 	poolCode: string
// 	account: string
// }) => {
// 	const query = useAccountPoolProfitChartQuery({
// 		variables: { name: account, poolCode },
// 	})
// 	const accountPoolProfitChart = query.data?.accountPoolProfitChart

// 	return (
// 		<Card title={null}>
// 			<Chart
// 				loading={query.loading}
// 				data={{
// 					type: 'histogram',
// 					data:
// 						accountPoolProfitChart?.points.map((p) => ({
// 							value: Number(p?.value),
// 							time: p!.timestamp,
// 						})) ?? [],
// 					options: {
// 						...COLOR_SCHEME.green.histogram,
// 						priceFormat: {
// 							precision: USDCASH_PRESICION,
// 							minMove: USDCASH_MIN_MOVE,
// 						},
// 					},
// 				}}
// 			/>
// 		</Card>
// 	)
// }

function SectionTransacactions({ eosAccount = '', poolCode }: { poolCode: string; eosAccount: string }) {
    const STEP = 10;
    const { ref, inView } = useInView({ triggerOnce: true, threshold: 0 });
    const initialTxnTypeFilter = [TxnType.AddLiquidity, TxnType.RemoveLiquidity, TxnType.Swap, TxnType.Transfer];

    const [t] = useTranslation();
    const [variables, setVariables] = useState<ActionsQueryVariables>({
        skip: 0,
        limit: STEP,
        sort: [ActionSortEnum.TimestampDesc],
        filters: {
            eosAccount,
            txnType: initialTxnTypeFilter,
            poolCode
        }
    });

    const isInitialTxnTypeFilterSelected = initialTxnTypeFilter.join('') === variables.filters?.txnType?.join('');
    const txnType = variables.filters?.txnType?.[0] ?? null;

    const { loading, data, previousData, error, ...actionsQuery } = useActionsQuery({
        skip: !inView,
        notifyOnNetworkStatusChange: true,
        variables
    });
    const result = data?.actions ?? previousData?.actions;
    const { pageInfo, actions } = result ?? {};
    const totalCount = pageInfo?.totalFiltered ?? 0;
    const showErrorScreen = !!error;
    const contentReady = (!!result || !loading) && !showErrorScreen;
    const isFirstLoading = !result && loading && !showErrorScreen;

    const onChangeTxnType = (newType: TxnType | null) => {
        setVariables(
            update(variables, {
                skip: () => 0,
                filters: {
                    txnType: () => (newType ? [newType] : undefined)
                }
            })
        );
    };

    return (
        <section ref={ref} className={cx('Transactions')}>
            <header className={cx('TransactionsHeader')}>
                <h2 className={cx('TransactionsTitle')}>{t('transactionsTable.title')}</h2>

                {contentReady && (
                    <TransactionTypeFilter
                        className={cx('TransactionsFilters')}
                        includes={initialTxnTypeFilter}
                        active={isInitialTxnTypeFilterSelected || !txnType ? null : txnType}
                        onChange={onChangeTxnType}
                    />
                )}
            </header>

            <TransactionsTable
                loading={loading}
                className={cx('TransactionsTable')}
                actions={actions}
                isFirstLoading={isFirstLoading}
                onClickRefetch={() => actionsQuery.refetch()}
                showErrorScreen={showErrorScreen}
                page={variables.skip! / STEP}
                pageCount={Math.ceil(totalCount / STEP)}
                onChangePage={(page) =>
                    setVariables({
                        ...variables,
                        skip: page * STEP
                    })
                }
            />
        </section>
    );
}

// eslint-disable-next-line func-names
const CenteredMetric = (label: string, share: string | number) =>
    // eslint-disable-next-line func-names
    function ({ centerX, centerY }: any) {
        return (
            <text x={centerX} y={centerY} textAnchor="middle" dominantBaseline="central">
                <tspan
                    style={{
                        fill: '#EBEFFF',
                        fontFamily: ' Manrope, sans-serif',
                        fontSize: 24,
                        lineHeight: 29,
                        fontWeight: 600
                    }}
                    x={centerX}
                    dy="-0.2em"
                >
                    {share}%
                </tspan>
                <tspan
                    style={{
                        fill: '#98A1C3',
                        fontFamily: ' Manrope, sans-serif',
                        fontSize: 14,
                        lineHeight: 21,
                        fontWeight: 500
                    }}
                    x={centerX}
                    dy="1.5em"
                >
                    {label}
                </tspan>
            </text>
        );
    };

export default PoolDetails;
