import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import Exchange from 'components/Exchange/Exchange';
import ExchangeStatusPage from './ExchangeStatusPage';
import { ROUTES_MAP } from '../constant';

export default function ExchangePage({
    match: {
        params: { id }
    }
}: RouteComponentProps<{ id: string }>) {
    return (
        <main style={{ flexGrow: 1, overflowX: 'hidden' }}>
            <Route exact component={Exchange} path={ROUTES_MAP.exchange} />
            <Route component={ExchangeStatusPage} path={ROUTES_MAP.exchangeStatus(id)} />
        </main>
    );
}
