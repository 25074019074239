import { ApolloClient, ApolloLink, createHttpLink, FetchResult, from, Observable, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { getIsTokenExpired, getToken, refreshAuthToken } from 'hooks/useAuth/useAuth';
import { cache } from './cache';
// import {login, logout} from "../helpers/Auth/Auth";
// import {useAuthRefreshTokenMutation} from "./auth/generated";
// import {login, logout} from "../helpers/Auth/Auth";

// const retryLink = new RetryLink();

// const errorLink = onError(({ networkError, forward, operation }) => {
//     console.log('ERROR LINK ', networkError);
//
//     return forward(operation);
// });

const swapLink = createHttpLink({
    credentials: 'include',
    uri: process.env.REACT_APP_API_ENDPOINT
    // includeExtensions?: boolean;
    // fetch?: WindowOrWorkerGlobalScope["fetch"];
    // headers?: Record<string, string>;
    // preserveHeaderCase?: boolean;
    // fetchOptions?: any;
    // useGETForQueries?: boolean;
    // includeUnusedVariables?: boolean;
    // print?: Printer;
});

export const swapWsLink = new WebSocketLink({
    uri: process.env.REACT_APP_WS_ENDPOINT as string,
    options: {
        reconnect: true,
        lazy: true
        // connectionParams: () => {
        //     const params = {} as { [key: string]: string };
        //     const token = getToken();
        //
        //     if (token) {
        //         params.AUTHORIZATION = token;
        //     }
        //
        //     return params;
        // }
    }
});

// Using link splitting capability you can send data on each link depending on which operation is sent.
const swapSplitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);

        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    swapWsLink,
    swapLink
);

const authLink = createHttpLink({
    // credentials: 'include',
    uri: process.env.REACT_APP_AUTH_API_ENDPOINT
});

export const authWsLink = new WebSocketLink({
    uri: process.env.REACT_APP_AUTH_WS_ENDPOINT as string,
    options: {
        reconnect: true,
        lazy: true
        // connectionParams: () => {
        //     console.log(555);
        //     const params = {} as { [key: string]: string };
        //     const token = 'tt'; //getToken()
        //     if (token) {
        //         params.AUTHORIZATION = token;
        //     }
        //     return params;
        // }
    }
});

// Using link splitting capability you can send data on each link depending on which operation is sent.
const authSplitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);

        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    authWsLink,
    authLink
);

/**
 * Condition for selecting the first API endpoint.
 *
 * @example useFiatMethodsQuery({ context: { clientName: 'store' } });
 */
const splitLink = split((operation) => operation.getContext().clientName === 'auth', authSplitLink, swapSplitLink);

/**
 * We have to trigger reconnect for all WS sockets,
 * because we need to update JWT token for the connection
 */
// export const reconnectWS = () => {
//     const client = (authWsLink as any).subscriptionClient;
//     const operations = { ...client.operations };
//
//     client.close(true);
//     client.connect();
//     Object.keys(operations).forEach((id) => client.sendMessage(id, 'start', operations[id].options));
//     client.operations = operations;
// };

const authMiddleware = new ApolloLink((operation, forward) => {
    const requestsWithAuth = [
        'CardFees',
        'MyCardAccount',
        'MyCardHistory',
        'CalculateCardEmission',
        'CalculateCardReplenishment',
        'EmitCard',
        'ReplenishCard'
    ];

    if (requestsWithAuth.includes(operation.operationName)) {
        return new Observable<FetchResult>((observer) => {
            (async () => {
                const token = getIsTokenExpired() ? (await refreshAuthToken()) || getToken() : getToken();
                const { headers = {} } = operation.getContext();

                if (token) {
                    operation.setContext({
                        headers: {
                            Authorization: `JWT ${token}`,
                            ...headers
                        }
                    });

                    forward(operation).subscribe({
                        next: (value) => observer.next(value),
                        error: (err) => observer.error(err),
                        complete: () => observer.complete()
                    });
                } else {
                    observer.error(new Error('Token is missing'));
                }
            })();
        });
    }

    return forward(operation);
});

// If you provide a link chain to ApolloClient, you don't provide the `uri` option.
const client = new ApolloClient({
    // The `from` function combines an array of individual links into a link chain.
    link: from([
        // retryLink,
        authMiddleware,
        // errorLink,
        // tokenLink,
        splitLink
    ]),
    cache
});

export default client;
