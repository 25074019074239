import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ExchangeStatus from 'components/ExchangeStatus/ExchangeStatus';

export default function ExchangeStatusPage({
    match: {
        params: { id }
    }
}: RouteComponentProps<{ id: string }>) {
    return (
        <main style={{ flexGrow: 1, overflowX: 'hidden' }}>
            <ExchangeStatus id={id} />
        </main>
    );
}
