import classNames from 'classnames/bind';
import PopupBase, { PopupBaseProps } from 'components/PopupBase/PopupBase';
import React from 'react';
import styles from './BottomSheet.module.scss';

const cx = classNames.bind(styles);

type Props = PopupBaseProps;

function BottomSheet({ className = '', wrapperClassName = '', ...props }: Props) {
    return (
        <PopupBase
            wrapperClassName={cx('Wrapper', wrapperClassName)}
            isOpenWrapperClassName={cx('isOpen')}
            className={cx('Component', className)}
            {...props}
        />
    );
}

export default BottomSheet;
