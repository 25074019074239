import removeSpaces from '../removeSpaces/removeSpaces';

/**
 * Removes non-numeric characters from a string, optionally allowing negative numbers.
 *
 * @param {string} str - The input string to clean.
 * @param {boolean} allowNegative - If `true`, allows negative numbers like '-1', '-1 000'. By default, `true`.
 * @param {boolean} removeLeadingZeros - If `true`, leading zeros will be removed. By default, `true`.
 *
 * @returns {string} The cleaned string containing only numeric characters, commas, and minus sign (if allowed).
 */
export default function removeNonNumeric(
    str: string,
    allowNegative: boolean = true,
    removeLeadingZeros: boolean = true
): string {
    return (
        removeSpaces(str)
            /** replace commas with dots */
            .replace(',', '.')
            /** remove multiple dots */
            .replace(/(\..*)\./g, '$1')
            /** remove the minus from the middle */
            .replace(/(?!^)-/g, '')
            /** remove everything except number, commas and minus sign */
            .replace(allowNegative ? /[^0-9.-]/g : /[^0-9.]/g, '')
            /** remove multiple leading zeros */
            .replace(removeLeadingZeros ? /^0+(\d)/gm : /^$/, '$1')
    );
}
