import React from 'react';
import Skeleton from 'ui/Skeleton/Skeleton';
import styles from './VirtualCardHistory.module.scss';

function ItemSkeleton() {
    return (
        <div className={styles.ItemRoot}>
            <div className={styles.ItemStatus}>
                <div className={styles.ItemIconContainer}>
                    <Skeleton variant="circular" width={36} height={36} />
                </div>
                <div className={styles.ItemInfo}>
                    <div className={styles.ItemAction}>
                        <Skeleton variant="text" width={100} />
                    </div>
                    <div className={styles.ItemTime}>
                        <Skeleton variant="text" width={35} />
                    </div>
                </div>
            </div>
            <div className={styles.ItemValue}>
                <Skeleton variant="text" width={55} />
            </div>
        </div>
    );
}

export function ListSkeleton({ items }: { items: string[][] }) {
    return (
        <div className={styles.List}>
            {items.map((group, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={idx} className={styles.ListItem}>
                    <div className={styles.Date}>
                        <Skeleton width={80} variant="text" />
                    </div>
                    {group.map((_, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <ItemSkeleton key={i} />
                    ))}
                </div>
            ))}
        </div>
    );
}

export default function VirtualCardHistorySkeleton() {
    return (
        <div className={styles.Root}>
            <h3 className={styles.Title}>
                <Skeleton variant="text" width="60%" />
            </h3>
            <div className={styles.ListContainer}>
                <ListSkeleton
                    items={[
                        ['', '', '', ''],
                        ['', '']
                    ]}
                />
            </div>
        </div>
    );
}
