import React from 'react';
import { ReactComponent as IconTokenUsdcash } from 'assets/images/tokens/usdcash.svg';
import { ReactComponent as IconTokenTether } from 'assets/images/tokens/tether.svg';
import { ReactComponent as IconTokenRubles } from 'assets/images/tokens/rubles.svg';
import { ReactComponent as IconTokenRubcash } from 'assets/images/tokens/rubcash.svg';
import { ReactComponent as IconTokenRub } from 'assets/images/tokens/rub.svg';
import { ReactComponent as IconTokenEuro } from 'assets/images/tokens/euro.svg';
import { ReactComponent as IconTokenEos } from 'assets/images/tokens/eos.svg';
import { ReactComponent as IconTokenAed } from 'assets/images/tokens/aed.svg';

export default function getCurrencyIcon(currency: string, className?: string) {
    switch (currency) {
        case 'USDCASH':
            return <IconTokenUsdcash className={className} />;
        case 'TETHER':
        case 'USDT':
        case 'USDT\u00A0(TRC\u201120)':
            return <IconTokenTether className={className} />;
        case 'RUBLES':
            return <IconTokenRubles className={className} />;
        case 'RUBCASH':
            return <IconTokenRubcash className={className} />;
        case 'RUB':
            return <IconTokenRub className={className} />;
        case 'EUR':
            return <IconTokenEuro className={className} />;
        case 'AED':
            return <IconTokenAed className={className} />;
        default:
            return <IconTokenEos className={className} />;
    }
}
