import React, { memo } from 'react';
import classNames from 'classnames/bind';
import { useTotalVolumeQuery } from 'apollo/main/generated';
import Card, { CardError, CardLoader, CardValue } from 'components/Card/Card';
import Chart, { COLOR_SCHEME } from 'components/Chart/Chart';
import FormatNumber from 'components/FormatNumber/FormatNumber';
import { useTranslation } from 'react-i18next';
import styles from './HomeTotalVolume.module.scss';

const cx = classNames.bind(styles);

const TotalVolume = memo(({ className = '' }: { className?: string }) => {
    const query = useTotalVolumeQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-only'
    });
    const [t] = useTranslation();
    return (
        <Card
            headerClassName={cx('CardHeader')}
            titleClassName={cx('CardTitle')}
            title={
                <div className={cx('CardValues')}>
                    <CardValue
                        changed={
                            !query.data
                                ? undefined
                                : {
                                      growth: query.data?.totalVolumeChart!.percentageChange24h ?? 0,
                                      period: '24h'
                                  }
                        }
                    >
                        <span className={cx('CardValueLabel')}>{t('pageHome.volume.title')}</span>
                        {query.data && <FormatNumber value={query.data?.totalVolumeChart!.value24h ?? 0} prefix="$" />}
                    </CardValue>

                    <CardValue>
                        <span className={cx('CardValueLabel')}>{t('pageHome.volume.title', { context: 'week' })}</span>
                        {query.data && (
                            <FormatNumber value={query.data?.totalVolumeChart!.lastWeekValue ?? 0} prefix="$" />
                        )}
                    </CardValue>
                </div>
            }
            helpText={t('pageHome.volume.helpText')}
            className={className}
        >
            {/* eslint-disable-next-line no-nested-ternary */}
            {query.error ? (
                <CardError resetError={query.refetch} disabled={query.loading} />
            ) : query.loading ? (
                <CardLoader />
            ) : (
                <Chart
                    autoHeight
                    data={{
                        type: 'histogram',
                        data:
                            query.data?.totalVolumeChart?.points.map((point) => ({
                                time: point!.timestamp,
                                value: Number(point!.value)
                            })) ?? [],
                        options: COLOR_SCHEME.green.histogram
                    }}
                />
            )}
        </Card>
    );
});

export default TotalVolume;
