import classNames from 'classnames/bind';
import BottomSheet from 'components/BottomSheet/BottomSheet';
import Button from 'ui/Button_DEPRECATED/Button';
import { ChevronDownIcon } from 'ui/Icons/Icons';
import { BREAKPOINT_LANDSCAPE, LOCAL_STORAGE_LNG_KEY } from 'constant';
import { useDropdown, useWidthCondition } from 'helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './LanguageSwitcher.module.scss';

const cx = classNames.bind(styles);

const LANGUAGES = [
    { code: 'RU', label: 'Русский' },
    { code: 'EN', label: 'English' }
];

function LanguageSwitcher() {
    const [t, i18n] = useTranslation();
    const [isLandscapeOrLess] = useWidthCondition((w) => w < BREAKPOINT_LANDSCAPE);
    const dropdown = useDropdown({ handleOutsideClick: !isLandscapeOrLess });
    const onChangeLng = (code: string) => {
        dropdown.setOpen(false);
        i18n.changeLanguage(code.toLowerCase());
        window.localStorage.setItem(LOCAL_STORAGE_LNG_KEY, code.toLowerCase());
    };
    const currentLng = LANGUAGES.find((lng) => lng.code.toLowerCase() === i18n.language)!;

    return (
        <div ref={dropdown.ref} className={cx('Component')}>
            <Button
                onClick={dropdown.toggle}
                isActive={dropdown.open}
                color="tertairy-white"
                endIcon={!isLandscapeOrLess && <ChevronDownIcon className={cx(dropdown.open && 'Icon_rotate')} />}
                className={cx('Controller')}
            >
                {currentLng.code}
            </Button>

            {!isLandscapeOrLess && (
                <div
                    ref={dropdown.popperRef}
                    style={dropdown.popper.styles.popper}
                    {...dropdown.popper.attributes.popper}
                    className={cx('Content', dropdown.open && 'visible')}
                >
                    {LANGUAGES.map(({ label, code }) => (
                        <button
                            disabled={currentLng.code === code}
                            key={code}
                            type="button"
                            onClick={() => onChangeLng(code)}
                        >
                            {label}
                        </button>
                    ))}
                </div>
            )}

            {isLandscapeOrLess && (
                <BottomSheet className={cx('BottomSheet')} isOpen={dropdown.open} close={() => dropdown.setOpen(false)}>
                    <p className={cx('Title')}>{t('header.language')}</p>
                    {LANGUAGES.map(({ label, code }) => (
                        <button
                            disabled={currentLng.code === code}
                            key={code}
                            type="button"
                            onClick={() => onChangeLng(code)}
                        >
                            {label}
                        </button>
                    ))}
                </BottomSheet>
            )}
        </div>
    );
}

export default LanguageSwitcher;
