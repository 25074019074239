import * as clipboard from 'clipboard-polyfill/text';
import merge from 'lodash/merge';
import maxSize from 'popper-max-size-modifier';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import { Id as ToastId, toast } from 'react-toastify';
import { DateTime } from 'luxon';
import { DOWNLOAD_APP } from 'constant';
import addThousandsSeparator from '../utils/addThousandsSeparator/addThousandsSeparator';

export const getErrorData = (e: any) => ({
    message: e?.networkError?.result?.errors?.[0]?.extensions || e?.graphQLErrors?.[0]?.message,
    code: e?.graphQLErrors?.[0]?.code as undefined | number | null
});

export const useGlobalError = () => {
    const [t] = useTranslation();
    // eslint-disable-next-line @typescript-eslint/no-shadow,@typescript-eslint/no-use-before-define
    const toast = useToast();

    return (msg?: string, tst = toast) => {
        tst.error(`${t('errors.globalError')}${msg ? `: ${msg}` : ''}`);
    };
};

type UseDropdownParams = {
    onClose?(): void;
    handleOutsideClick?: boolean;
    popperOptions?: Parameters<typeof usePopper>[2];
};

/**
 *
 * @description
 * Helpful hook to create fully controlled dropdown component
 * 1) pass `ref` to dropdown wrapper
 * 2) use `toggle` or `setOpen` actions to control visibility
 * 3) use `open` flag to display or hide dropdowns content
 * 4) for better content position use `popperRef` to select
 * dropdown content and `popper` object to pass correct position
 * @example
 * const dropdown = useDropdown()
 *
 * <div ref={dropdown.ref}>
 *     <button onClick={dropdown.toggle}>toggle content</div>
 *
 *     {dropdown.open && (
 *         <div
 *             ref={dropdown.popperRef}
 *             style={dropdown.popper.styles.popper}
 *             {...dropdown.popper.attributes.popper}
 *         >
 *             content
 *         </div>
 *     )}
 * </div>
 */
export function useDropdown({ onClose, popperOptions, handleOutsideClick = true }: UseDropdownParams = {}) {
    const [open, setOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const popperWrapperRef = useRef<HTMLDivElement>(null);
    const [popperRef, setPopperRef] = useState<HTMLUListElement | HTMLDivElement | null>(null);

    const defaultPopperOptions: Parameters<typeof usePopper>[2] = useMemo(
        () => ({
            placement: 'bottom-start',

            modifiers: [
                {
                    name: 'preventOverflow'
                },
                {
                    name: 'offset',
                    options: {
                        offset: [0, 4]
                    }
                },

                maxSize,
                {
                    name: 'applyMaxSize',
                    enabled: true,
                    phase: 'beforeWrite' as const,
                    requires: ['maxSize'],
                    fn({ state }: any) {
                        // eslint-disable-next-line no-param-reassign
                        state.styles.popper = {
                            ...state.styles.popper,
                            maxHeight: `${state.modifiersData.maxSize.height - 8}px`
                        };
                    }
                }
            ]
        }),
        []
    );
    const popper = usePopper(
        popperWrapperRef.current ?? ref.current,
        popperRef,
        merge(defaultPopperOptions, popperOptions)
    );

    const toggle = () => setOpen(!open);

    useEffect(() => {
        if (!handleOutsideClick) return;

        const mousedownListener = (e: MouseEvent) => {
            if (ref.current && e.target && !ref.current.contains(e.target as Node)) {
                document.removeEventListener('mousedown', mousedownListener);
                setOpen(false);
            }
        };

        const keydownListener = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                setOpen(false);
                e.preventDefault();
                e.stopPropagation();
            }
        };

        if (open) {
            document.addEventListener('mousedown', mousedownListener);
            document.addEventListener('keydown', keydownListener);

            // eslint-disable-next-line consistent-return
            return () => {
                document.removeEventListener('keydown', keydownListener);
                document.removeEventListener('mousedown', mousedownListener);
            };
        }
    }, [open, handleOutsideClick]);

    useEffect(() => {
        if (!open) onClose?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return {
        open,
        toggle,
        ref,
        popperRef: setPopperRef,
        setOpen,
        popper,
        popperWrapperRef
    };
}

export const useWidthCondition = (conditionFn: (width: number) => boolean) => {
    const check = () => conditionFn(window.innerWidth);
    const [condition, setCondition] = useState(check());

    const handleResize = () => {
        setCondition(check);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [condition, handleResize] as const;
};

export const useOverflowController = () => {
    const id = useRef(String(Math.random())).current;
    const blockScroll = () => {
        window.overflowController = window.overflowController ?? {};
        window.overflowController[id] = true;
        document.body.style.overflow = 'hidden';
    };

    const unblockScroll = () => {
        window.overflowController = window.overflowController ?? {};
        delete window.overflowController[id];
        const hasOverflowElements = Object.values(window.overflowController).some(Boolean);

        if (!hasOverflowElements) {
            document.body.style.overflow = '';
        }
    };

    return {
        blockScroll,
        unblockScroll
    };
};

/**
 * Helper to control need we close sidebar on click/tap overlay or sidebar directly
 * @param close will fire on direct click on overlay
 *
 * @example
 * const overlayClickHandler = useOverlayClickHandler(close)
 *
 * <div {...overlayClickHandler.overlayProps} className='overlay'>
 *     <div {...overlayClickHandler.componentProps}>
 *         content
 *     </div>
 * </div>
 */
export const useOverlayClickHandler = (close?: () => void) => {
    const shouldCloseRef = useRef<null | boolean>(null);

    const setShouldClose = (v: null | boolean) => {
        shouldCloseRef.current = v;
    };

    const handleOverlayOnClick = () => {
        if (shouldCloseRef.current === null) {
            setShouldClose(true);
        }

        if (shouldCloseRef.current) {
            close?.();
        }

        setShouldClose(null);
    };

    return {
        overlayProps: { onClick: handleOverlayOnClick },
        componentProps: {
            onMouseDown: () => setShouldClose(false),
            onMouseUp: () => setShouldClose(false),
            onClick: () => setShouldClose(false)
        }
    };
};

export const createEosTransactionLink = (trxid = '') => `https://bloks.io/transaction/${trxid}`;

// export const createEosAccountLink = (account = '') => `https://bloks.io/account/${account}`;

export const useToast = (baseToastId?: string) => {
    const toastRef = useRef<ToastId>('');
    const baseToastIdRef = useRef(baseToastId ?? String(Math.random()));
    const tid = useRef<NodeJS.Timeout>();

    type ToastKeys = keyof typeof toast;

    return new Proxy(toast, {
        get(target, prop: ToastKeys) {
            const PROXY_KEYS = ['info', 'success', 'error', 'warn'] as const;

            if (PROXY_KEYS.includes(prop as any)) {
                const toastFn = target[prop as (typeof PROXY_KEYS)[number]];
                const proxy: typeof toastFn = (content, options?) => {
                    clearTimeout(tid.current as any as number);
                    // eslint-disable-next-line no-param-reassign
                    options = options ?? {};
                    // eslint-disable-next-line no-param-reassign
                    options.toastId = options.toastId ?? baseToastIdRef.current;

                    if (options?.toastId === toastRef.current) {
                        toast.update(toastRef.current, {
                            ...options,
                            render: content
                        });
                    } else {
                        toast.dismiss(toastRef.current);
                    }

                    toastRef.current = toastFn(content, {
                        ...options,
                        onClose: (props) => {
                            toastRef.current = '';
                            options?.onClose?.(props);
                        }
                    });
                    return toastRef.current;
                };

                return proxy;
            }

            return target[prop];
        }
    });
};

export const useCopy = () => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const toast = useToast();

    return (text: string, success: string, error: string, onCopied?: () => void) => {
        clipboard
            .writeText(text)
            .then(() => {
                toast.success(success);
                if (onCopied) {
                    onCopied();
                }
            })
            .catch(() => {
                toast.error(error);
            });
    };
};

type UseModalResult<T> = {
    isOpen: boolean;
    close(): void;
    open(v?: T): void;
    payload: T | null;
};

export const useModal = <T extends any>(initialState = false): UseModalResult<T> => {
    const [isOpen, setIsOpen] = useState<boolean>(initialState);
    const [payload, setPayload] = useState<null | T>(null);

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const open = (payload?: T) => {
        setIsOpen(true);
        setPayload(payload ?? null);
    };

    const close = () => {
        setIsOpen(false);
    };

    return { isOpen, open, close, payload };
};

export const useFormatDate = () => {
    const {
        i18n: { language }
    } = useTranslation();

    return (date: string, format: string) =>
        DateTime.fromISO(`${date}Z`, { zone: 'local' }).setLocale(language).toFormat(format);
};

// Helper, that skips expensive Math.pow()
const PRECISION_MAP = {
    0: 1,
    1: 10,
    2: 100,
    3: 1_000,
    4: 10_000,
    5: 100_000,
    6: 1_000_000,
    7: 10_000_000,
    8: 100_000_000,
    9: 1_000_000_000,
    10: 10_000_000_000,
    11: 100_000_000_000,
    12: 1_000_000_000_000,
    13: 10_000_000_000_000,
    14: 100_000_000_000_000,
    15: 1_000_000_000_000_000,
    16: 10_000_000_000_000_000
};

export const toFixedNumberInString = (
    value: string,
    expectedPrecision = 2,
    /**
     * If number is float, and it has many zeros in start of float part (ex 0.00000123)
     * we tell how much symbols after zeros have to be visible
     */
    floatZerosOffset = 2
) => {
    const dotIndex = value.indexOf('.');
    const floatZerosCount = (dotIndex < 0 ? '' : value.slice(dotIndex + 1)).match(/^0*/)?.[0]?.length || 0;

    const precisionIndex = expectedPrecision
        ? Math.max(expectedPrecision, floatZerosCount > 0 ? floatZerosCount + floatZerosOffset : 0)
        : expectedPrecision;

    const precision = PRECISION_MAP[precisionIndex as keyof typeof PRECISION_MAP];
    return Number(Math.round(Number(value) * precision) / precision).toString();
};

export const formatBigNumbers = (
    value?: string | null,
    precision?: number,
    floatZerosOffset?: number,
    compress = true
) => {
    const number = Number(value);

    if (Number.isNaN(number)) return '';

    if (Math.abs(number) >= 1e9 && compress) {
        return `${(number / 1e9).toFixed(1)}B`;
    }

    if (Math.abs(number) >= 1e6 && compress) {
        return `${(number / 1e6).toFixed(1)}M`;
    }

    if (Math.abs(number) >= 1e3 && compress) {
        return `${(number / 1e3).toFixed(1)}K`;
    }

    return addThousandsSeparator(toFixedNumberInString(`${value}`.toString(), precision, floatZerosOffset));
};

export const useIsTouchableDevice = () =>
    useMemo(
        () =>
            'ontouchstart' in window ||
            window.navigator.maxTouchPoints > 0 ||
            (window.navigator as any).msMaxTouchPoints > 0,
        []
    );

export const usePixelRatio = () => useMemo(() => (window.devicePixelRatio > 1 ? 2 : 1), []);

const iOS = () =>
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

const android = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('android') > -1;
};

export const isMobilePlatform = () => iOS() || android();

export const getDownloadLink = () =>
    // eslint-disable-next-line no-nested-ternary
    iOS() ? DOWNLOAD_APP.ios : android() ? DOWNLOAD_APP.android : null;

export const scrollInto = (e: React.MouseEvent, id: string, offset = 0, behavior: ScrollBehavior = 'smooth') => {
    e.preventDefault();

    window.scrollTo({
        left: 0,
        top: (document.getElementById(id)?.offsetTop ?? 0) + offset,
        behavior
    });
};

export const useWhitePaperLink = () => {
    const {
        i18n: { language }
    } = useTranslation();

    return `${window.location.origin}/whitepaper/WhitePaper_${language === 'ru' ? 'ru' : 'en'}.pdf`;
};
