import React from 'react';
import { Trans } from 'react-i18next';
import classNames from 'classnames/bind';
import { DOWNLOAD_APP } from 'constant';
import GooglePlayDark from 'assets/icons/google-play-dark.svg';
import GooglePlayLight from 'assets/icons/google-play-light.svg';
import AppleDark from 'assets/icons/apple-dark.svg';
import AppleLight from 'assets/icons/apple-light.svg';
import styles from './ButtonDownload.module.scss';

const cx = classNames.bind(styles);

type Props = {
    color: 'light' | 'dark';
    os: 'android' | 'ios';
};

function ButtonDownload({ color, os }: Props) {
    if (os === 'android') {
        return (
            <a className={cx('Component', color)} target="_blank" href={DOWNLOAD_APP.android} rel="noreferrer">
                <img src={color === 'dark' ? GooglePlayDark : GooglePlayLight} alt="Google Play Logo" />
                <span>
                    <Trans i18nKey="global.btnDownload.btnAndroid">
                        <small>.</small>
                    </Trans>
                </span>
            </a>
        );
    }

    return (
        <a className={cx('Component', color)} target="_blank" href={DOWNLOAD_APP.ios} rel="noreferrer">
            <img src={color === 'dark' ? AppleDark : AppleLight} alt="Apple Logo" />
            <span>
                <Trans i18nKey="global.btnDownload.btnIOS">
                    <small>.</small>
                </Trans>
            </span>
        </a>
    );
}

export default ButtonDownload;
