import { InMemoryCache, ReactiveVar, makeVar, defaultDataIdFromObject } from '@apollo/client';
import auth from './auth/generated';
import main from './main/generated';

export const LOCAL_STORAGE_KEY_TOKEN_AUTH = 'tokenAuth';
export const LOCAL_STORAGE_KEY_TOKEN_EXPIRATION = 'tokenExpiration';
export const LOCAL_STORAGE_KEY_TOKEN_REFRESH = 'tokenRefresh';

const isAuth =
    typeof window !== 'undefined' ? Boolean(window.localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN_AUTH)) : false;

export const isAuthVar: ReactiveVar<boolean> = makeVar(isAuth);
export const errorVar: ReactiveVar<any> = makeVar(null);

export const cache: InMemoryCache = new InMemoryCache({
    ...auth,
    ...main,
    typePolicies: {
        ChartNode: {
            merge: false
        },
        Query: {
            fields: {
                isAuth: {
                    read() {
                        return isAuthVar();
                    }
                },
                error: {
                    read() {
                        return errorVar();
                    }
                }
            }
        }
    },
    dataIdFromObject(responseObject) {
        switch (responseObject.__typename) {
            case 'PoolNode':
                return `PoolNode:${responseObject.code}`;
            case 'AccountNode':
                return `AccountNode:${responseObject.name}`;
            default:
                return defaultDataIdFromObject(responseObject);
        }
    }
});
