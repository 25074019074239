import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Dialog, { DialogBody, DialogHeader } from 'ui/Dialog/Dialog';
import Button from 'ui/Button/Button';
import AuthQr from '../AuthQr/AuthQr';
import AuthApp from '../AuthApp/AuthApp';
import { View } from '../AuthApp/AuthApp.d';
import { AuthModalProps } from './AuthModal.d';
import styles from './AuthModal.module.scss';

export default function AuthModal({ open, setOpen, context, wallet, onWalletChange, ...props }: AuthModalProps) {
    const [t] = useTranslation();

    const [view, setView] = React.useState(View.App);

    const handleChangeView = () => setView(View.App);
    const handleClose = () => setOpen(false);
    const handleUnmount = () => setView(View.App);

    return (
        <Dialog
            classes={{ container: styles.Container }}
            open={open}
            onOpenChange={handleClose}
            onUnmount={handleUnmount}
            fullScreen
            transition="slide-down"
            {...props}
        >
            {view === View.App && (
                <>
                    <DialogHeader title={t('pageVirtualCard.auth.app.title')} />
                    <DialogBody>
                        <div className={styles.AppContainer}>
                            <div className={styles.AppDescription}>{t('pageVirtualCard.auth.app.description')}</div>
                            <AuthApp onChangeView={setView} />
                        </div>
                    </DialogBody>
                </>
            )}

            {view === View.Qr && (
                <>
                    <DialogHeader title={t('pageVirtualCard.auth.app.qrButton')} />
                    <DialogBody>
                        <div className={styles.QrContainer}>
                            <div className={styles.QrDescription}>
                                <Trans i18nKey="pageVirtualCard.auth.qr.description">
                                    <span />
                                </Trans>
                            </div>
                            <div className={styles.QrWrapper}>
                                <AuthQr wallet={wallet} onWalletChange={onWalletChange} />
                            </div>
                            <Button color="gray" onClick={handleChangeView} fullWidth>
                                {t('pageVirtualCard.auth.qr.appButton')}
                            </Button>
                        </div>
                    </DialogBody>
                </>
            )}
        </Dialog>
    );
}
