import React from 'react';
import classNames from 'classnames/bind';
import { Trans, useTranslation } from 'react-i18next';
import AboutMLNKImage from 'assets/images/about-project-mlnk.svg';
import MLNKLogo from 'assets/images/tokens/mlnk.svg';
import styles from './AboutProjectMLNK.module.scss';

const cx = classNames.bind(styles);

function AboutMLNK() {
    const [t] = useTranslation();
    return (
        <section className={cx('Component')}>
            <div className={cx('Container')}>
                <div className={cx('Details')}>
                    <h2 className={cx('Title')}>
                        <Trans i18nKey="pageInfo.aboutMLNK.title">
                            <span />
                            <b />
                        </Trans>
                    </h2>
                    <div>
                        {(t('pageInfo.aboutMLNK.description', { returnObjects: true }) as string[]).map((text, i) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <p className={cx('Description')} key={i}>
                                {text}
                            </p>
                        ))}
                        <p className={cx('Description')}>
                            <Trans i18nKey="pageInfo.aboutMLNK.fee">
                                <b />
                            </Trans>
                        </p>
                        <p className={cx('Description')}>
                            <Trans i18nKey="pageInfo.aboutMLNK.result">
                                <b />
                            </Trans>
                        </p>
                    </div>
                </div>

                <div className={cx('BannerContainer')}>
                    <img src={AboutMLNKImage} alt="Malinka" />

                    <div className={cx('Banner')}>
                        <p className={cx('BannerText')}>
                            <Trans i18nKey="pageInfo.aboutMLNK.banner.text">
                                <b />
                                <span />
                            </Trans>
                        </p>

                        <p className={cx('BannerAccount')}>{t('pageInfo.aboutMLNK.banner.account')}</p>

                        {/* eslint-disable-next-line react/button-has-type */}
                        <button className={cx('BannerBtn')}>
                            {t('pageInfo.aboutMLNK.banner.btn')}
                            <img src={MLNKLogo} alt="Malinka" />
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutMLNK;
