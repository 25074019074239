import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import Card, { CardError, CardLoader } from 'components/Card/Card';
import Tooltip, { useTooltip } from 'ui/Tooltip_DEPRECATED/Tooltip';
import update from 'immutability-helper';
import Button from 'ui/Button_DEPRECATED/Button';
import { BREAKPOINT_LANDSCAPE, ROUTES_MAP } from 'constant';
import { useWidthCondition } from 'helpers';
import addThousandsSeparator from 'utils/addThousandsSeparator/addThousandsSeparator';
import { TopPoolsQuery, useTopPoolsQuery } from 'apollo/main/generated';
import { Link } from 'react-router-dom';
import PoolCard from 'components/PoolCard/PoolCard';
import FormatNumber from 'components/FormatNumber/FormatNumber';
import ScrollBar from '../HomeScrollBar/HomeScrollBar';
import styles from './HomeTopPools.module.scss';

const cx = classNames.bind(styles);

function Item({ data, size }: { data: NonNullable<TopPoolsQuery['topProfitPools']>['pools'][number]; size: number }) {
    const [t] = useTranslation();
    const tooltip = useTooltip();

    if (!data) return null;

    return (
        <li className={cx('Item')}>
            <div className={cx('ItemContent')}>
                <Link
                    to={ROUTES_MAP.pool(data.code)}
                    onMouseEnter={tooltip.show}
                    onMouseLeave={tooltip.hide}
                    ref={tooltip.setPopperReference}
                    className={cx('ItemLink')}
                    style={{ height: size }}
                >
                    <PoolCard
                        className={cx('ItemPoolCard')}
                        iconsClassName={cx('ItemPoolCardIcons')}
                        labelsClassName={cx('ItemPoolCardLabels')}
                        tokens={[
                            {
                                name: data.token1.code,
                                smartContract: data.token1.smartContract,
                                icon: data.token1.logo ?? ''
                            },
                            {
                                name: data.token2.code,
                                smartContract: data.token2.smartContract,
                                icon: data.token2.logo ?? ''
                            }
                        ]}
                    />
                </Link>
                <p className={cx('ItemPrice')}>
                    <span className={cx('ItemPriceLabel')}>{t('pageHome.topPools.params.profitability')}</span>

                    <FormatNumber
                        className={cx('ItemPriceChange', +data.relativeAnnualProfit < 0 && 'down')}
                        prefix={+data.relativeAnnualProfit >= 0 ? '+' : '-'}
                        suffix="%"
                        value={Math.abs(+data.relativeAnnualProfit)}
                    />
                </p>
            </div>

            <Tooltip
                {...tooltip}
                popperOptions={(defaultConfig) =>
                    update(defaultConfig, {
                        placement: () => 'top',
                        modifiers: {
                            1: {
                                options: {
                                    offset: () => [0, 36]
                                }
                            }
                        }
                    })
                }
            >
                <div className={cx('TooltipContent')}>
                    <div className={cx('Params')}>
                        <span>
                            {t('pageHome.topPools.params.price', {
                                currency: data.token1.code
                            })}
                        </span>
                        <span>
                            {t('pageHome.topPools.params.price', {
                                currency: data.token2.code
                            })}
                        </span>
                        <span>{t('pageHome.topPools.params.liquidity')}</span>
                        <span>{t('pageHome.topPools.params.volume')}</span>
                    </div>
                    <div className={cx('Value')}>
                        <span>${addThousandsSeparator(data.token1.rateToUsd)}</span>
                        <span>${addThousandsSeparator(data.token2.rateToUsd)}</span>
                        <span>${addThousandsSeparator(data.liquidity)}</span>
                        <span>${addThousandsSeparator(data.volume24h)}</span>
                    </div>
                </div>
            </Tooltip>
        </li>
    );
}

function SectionTopPools() {
    const [t] = useTranslation();
    const listRef = useRef<HTMLUListElement>(null);
    const [isLandscapeOrLess] = useWidthCondition((w) => w < BREAKPOINT_LANDSCAPE);
    const query = useTopPoolsQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-only'
    });

    const topProfitPools = query.data?.topProfitPools;
    const amounts = topProfitPools?.pools?.map((item) => +(item?.relativeAnnualProfit ?? 0)) ?? [];
    const min = Math.min(...amounts);
    const max = Math.max(...amounts);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const base_size = 238;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const min_size = 117;
    const range = base_size - min_size;
    const normalized = amounts.map((x) => (x - min) / (max - min));
    const sizes = normalized.map((x) =>
        // eslint-disable-next-line no-restricted-globals
        !x || isNaN(x) ? min_size : min_size + range * x
    );

    return (
        <Card
            className={cx('Component')}
            mainClassName={cx('Main')}
            title={t('pageHome.topPools.title')}
            subTitle={t('pageHome.topPools.subTitle')}
            helpText={t('pageHome.topPools.helpText')}
        >
            {/* eslint-disable-next-line no-nested-ternary */}
            {query.error ? (
                <CardError resetError={query.refetch} disabled={query.loading} />
            ) : query.loading ? (
                <CardLoader />
            ) : (
                <ul ref={listRef} className={cx('List')}>
                    {topProfitPools?.pools?.map((data, i) => <Item size={sizes[i]} data={data} key={data?.code} />)}
                </ul>
            )}
            {isLandscapeOrLess && <ScrollBar targetRef={listRef} />}

            <Button
                tag="link"
                to={ROUTES_MAP.pools}
                className={cx('BtnViewAll')}
                color={isLandscapeOrLess ? 'link' : 'secondary'}
            >
                {t('pageHome.topPools.btnViewAll')}
            </Button>
        </Card>
    );
}

export default SectionTopPools;
