import React from 'react';
import { isTouchableDevice, OSDetection } from 'utils/featuresDetection/featuresDetection';

export default function FeaturesDetection() {
    React.useEffect(() => {
        const { documentElement } = document;
        const os = OSDetection();
        const isTouch = isTouchableDevice();

        if (documentElement) {
            if (os) documentElement.classList.add(os);

            if (isTouch) {
                documentElement.classList.add('touch');
            } else {
                documentElement.classList.add('no-touch');
            }
        }
    }, []);

    return null;
}
