import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ResponsivePie } from '@nivo/pie';
import classNames from 'classnames/bind';
import { useMlnkCollectedQuery } from 'apollo/main/generated';
import { BREAKPOINT_PHONE } from 'constant';
import { useWidthCondition } from 'helpers';
import addThousandsSeparator from 'utils/addThousandsSeparator/addThousandsSeparator';
import { UserIcon } from 'ui/Icons/Icons';
import MLNKLogo from 'assets/images/tokens/mlnk.svg';
import Card, { CardError, CardLoader, CardValue } from 'components/Card/Card';
import styles from './HomeMLNKCollected.module.scss';

const cx = classNames.bind(styles);

function FloatNumber({ amount = '' }: any) {
    const formatted = addThousandsSeparator(amount);
    const [int, float] = formatted.split('.');

    return (
        <span className={cx('FloatNumber')}>
            {int}
            <small>.{float}</small>
        </span>
    );
}

const MLNKCollected = memo(({ className = '' }: { className?: string }) => {
    const [t] = useTranslation();
    const query = useMlnkCollectedQuery();
    const [isPhone] = useWidthCondition((w) => w < BREAKPOINT_PHONE);
    const mlnkStatistic = query.data?.mlnkStatistic;

    const DATA = [
        {
            id: '1',
            label: t('pageHome.mlnkCollected.burned'),
            value: mlnkStatistic?.burned,
            color: '#FAAD16'
        },
        {
            id: 'c',
            label: t('pageHome.mlnkCollected.collected'),
            value: mlnkStatistic?.collected,
            color: 'rgb(255, 66, 144)'
        },
        {
            id: 'd',
            label: t('pageHome.mlnkCollected.left'),
            value: mlnkStatistic?.left,
            color: 'rgba(255, 66, 144, 0.2)'
        }
    ];

    const [burned, collectedData, left] = DATA;
    const holdersCounter = query.data?.mlnkStatistic?.mlnkHoldersNum;
    const Holders = holdersCounter && (
        <span className={cx('Holders')}>
            <UserIcon />
            {t('pageHome.mlnkCollected.holders', {
                count: holdersCounter,
                number: addThousandsSeparator(holdersCounter.toString())
            })}
        </span>
    );

    return (
        <Card
            className={cx(className, 'Card')}
            title={
                <>
                    {t('pageHome.mlnkCollected.collected')}
                    <span className={cx('ParamRibbon')} style={{ backgroundColor: collectedData.color }} />
                </>
            }
            headerAdditionalContent={!isPhone && Holders}
            titleClassName={cx('CardTitle')}
            helpText={t('pageHome.mlnkCollected.helpText')}
        >
            {/* eslint-disable-next-line no-nested-ternary */}
            {query.error ? (
                <CardError resetError={query.refetch} disabled={query.loading} />
            ) : query.loading ? (
                <CardLoader />
            ) : (
                <div className={cx('Details')}>
                    <CardValue className={cx('CardValue')}>
                        <FloatNumber amount={mlnkStatistic?.collected} />
                        {isPhone && Holders}
                    </CardValue>
                    <div className={cx('Main')}>
                        <ul className={cx('Params')}>
                            <li key={left.label} className={cx('Param')}>
                                <span>
                                    {left.label}
                                    <span className={cx('ParamRibbon')} style={{ backgroundColor: left.color }} />
                                </span>
                                <b>
                                    <FloatNumber amount={left.value} />
                                </b>
                            </li>
                            <li key={burned.label} className={cx('Param')}>
                                <span>
                                    {burned.label}
                                    <span className={cx('ParamRibbon')} style={{ backgroundColor: burned.color }} />
                                </span>
                                <b>
                                    <FloatNumber amount={burned.value} />
                                </b>
                            </li>
                            <li className={cx('Param')}>
                                <span>{t('pageHome.mlnkCollected.total')}</span>
                                <b>
                                    <FloatNumber amount={mlnkStatistic?.total} />
                                </b>
                            </li>
                            <li className={cx('Param', 'small')}>
                                <span>
                                    {t('pageHome.mlnkCollected.speed', {
                                        context: 'day'
                                    })}
                                </span>
                                <b>
                                    <FloatNumber amount={mlnkStatistic?.mlnkInLast24h} />
                                </b>
                            </li>
                            <li className={cx('Param', 'small')}>
                                <span>
                                    {t('pageHome.mlnkCollected.speed', {
                                        blockNum: addThousandsSeparator(
                                            mlnkStatistic?.lastBlockNum?.toString()
                                        ).replace(/\s/g, '\u00A0')
                                    })}
                                </span>
                                <b>
                                    <FloatNumber amount={mlnkStatistic?.mlnkInLastBlock} />
                                </b>
                            </li>
                        </ul>
                        <div className={cx('Chart')}>
                            <ResponsivePie
                                data={DATA}
                                isInteractive={false}
                                innerRadius={0.6}
                                colors={(d) => d.data.color}
                                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                                layers={['arcs', CenteredMetric]}
                            />
                        </div>
                    </div>
                </div>
            )}
        </Card>
    );
});

function CenteredMetric({ centerX, centerY }: any) {
    return (
        <g transform={`translate(${centerX}, ${centerY})`}>
            <image width={64.29} height={65.42} transform="translate(-26.15,-32.71)" href={MLNKLogo} />
        </g>
    );
}

export default MLNKCollected;
