import React, { PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useWidthCondition } from 'helpers';
import { BREAKPOINT_IPAD } from 'constant';
import Button from 'ui/Button_DEPRECATED/Button';
import styles from './SectionBloksIoInfo.module.scss';

const cx = classNames.bind(styles);

export function Param({ label, value }: { label: ReactNode; value: ReactNode }) {
    return (
        <div className={cx('InfoParam')}>
            <span>{label}</span>
            <b>{value}</b>
        </div>
    );
}

function SectionBloksIoInfo({
    title,
    href,
    children
}: PropsWithChildren<{
    title: ReactNode;
    href: string;
}>) {
    const [isIpadOrLess] = useWidthCondition((w) => w < BREAKPOINT_IPAD);
    const [t] = useTranslation();

    const Link = (
        <Button tag="a" color="link" className={cx('InfoLink')} target="_blank" href={href} rel="noopener noreferrer">
            {t('global.bloksIoLink')}
        </Button>
    );

    return (
        <section className={cx('Info')}>
            <header className={cx('InfoHeader')}>
                <h2 className={cx('InfoTitle')}>{title}</h2>
                {!isIpadOrLess && Link}
            </header>
            <main className={cx('InfoMain')}>
                <div className={cx('InfoParamList')}>{children}</div>

                {isIpadOrLess && Link}
            </main>
        </section>
    );
}

export default SectionBloksIoInfo;
