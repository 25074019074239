import React from 'react';
import cn from 'classnames';
import update from 'immutability-helper';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { ActionSortEnum, ActionsQueryVariables, TxnType, useActionsQuery } from 'apollo/main/generated';
import { BREAKPOINT_IPAD } from 'constant';
import { useWidthCondition } from 'helpers';
import SortDropdown from 'components/SortDropdown/SortDropdown';
import TransactionsTable from 'components/TransactionsTable/TransactionsTable';
import TransactionTypeFilter from 'components/TransactionTypeFilter/TransactionTypeFilter';
import TransactionTokenFilter from 'components/TransactionTokenFilter/TransactionTokenFilter';
import styles from './TransactionsPage.module.scss';

const STEP = 10;

export default function PageTransactions() {
    const [t] = useTranslation();
    const [isIpadOrLess] = useWidthCondition((w) => w < BREAKPOINT_IPAD);
    const location = useLocation();
    const history = useHistory();
    const queryParameters = new URLSearchParams(location.search);
    const queryType = queryParameters.get('type') as TxnType | null;
    const queryId = queryParameters.get('id');

    const setUrlParams = (id?: string | number | null, type?: string | null) => {
        const params = new URLSearchParams();

        if (id) params.append('id', String(id));
        if (type) params.append('type', type);

        return params.toString();
    };

    const [token, setToken] = React.useState<number | null>(!Number.isNaN(queryId) ? Number(queryId) : null);

    const [variables, setVariables] = React.useState<ActionsQueryVariables>({
        skip: 0,
        limit: STEP,
        sort: [ActionSortEnum.TimestampDesc],
        filters: {
            cashTokenId: token || undefined,
            txnType: queryType ? [queryType] : undefined
        }
    });

    const sort = (variables.sort as ActionSortEnum[] | undefined)?.[0] ?? null;
    const txnType = queryType || variables.filters?.txnType?.[0] || null;

    const { loading, data, previousData, error, ...actionsQuery } = useActionsQuery({
        notifyOnNetworkStatusChange: true,
        variables
    });

    const result = data?.actions ?? previousData?.actions;
    const { pageInfo, actions } = result ?? {};
    const totalCount = pageInfo?.totalFiltered ?? 0;
    const showErrorScreen = Boolean(error);
    const contentReady = (Boolean(result) || !loading) && !showErrorScreen;
    const isFirstLoading = !result && loading && !showErrorScreen;

    const onChangeSort = (s: ActionSortEnum) => {
        setVariables(
            update(variables, {
                skip: () => 0,
                sort: () => [s]
            })
        );
    };

    const onChangeTxnType = (newType: TxnType | null) => {
        if (newType !== TxnType.CashIssue && newType !== TxnType.CashRetire) {
            setVariables(
                update(variables, {
                    skip: () => 0,
                    filters: {
                        cashTokenId: () => undefined,
                        txnType: () => (newType ? [newType] : undefined)
                    }
                })
            );

            setToken(null);

            history.push({
                search: setUrlParams(null, newType)
            });
        } else {
            setVariables(
                update(variables, {
                    skip: () => 0,
                    filters: {
                        cashTokenId: () => token || undefined,
                        txnType: () => (newType ? [newType] : undefined)
                    }
                })
            );

            history.push({
                search: setUrlParams(token, newType)
            });
        }
    };

    const onChangeToken = (id?: number | null) => {
        setVariables(
            update(variables, {
                skip: () => 0,
                filters: {
                    cashTokenId: () => id || undefined,
                    txnType: () => (txnType ? [txnType] : undefined)
                }
            })
        );

        setToken(id ?? null);

        history.push({
            search: setUrlParams(id, txnType)
        });
    };

    return (
        <main className={styles.Component}>
            <div className={styles.HeaderRow}>
                <h1 className={styles.Title}>{t('transactionsTable.title')}</h1>
                {contentReady && (
                    <>
                        {isIpadOrLess && (
                            <SortDropdown<ActionSortEnum>
                                options={[
                                    {
                                        label: t('transactionsTable.params.account.title_ASC'),
                                        value: ActionSortEnum.EosAccount
                                    },
                                    {
                                        label: t('transactionsTable.params.account.title_DESC'),
                                        value: ActionSortEnum.EosAccountDesc
                                    },
                                    {
                                        label: t('transactionsTable.params.date.title_ASC'),
                                        value: ActionSortEnum.Timestamp
                                    },
                                    {
                                        label: t('transactionsTable.params.date.title_DESC'),
                                        value: ActionSortEnum.TimestampDesc
                                    }
                                ]}
                                label={t('global.sort')}
                                className={styles.SortDropdown}
                                active={sort!}
                                onChange={(type) => {
                                    setVariables({
                                        ...variables,
                                        skip: 0,
                                        sort: [type]
                                    });
                                }}
                            />
                        )}
                        <div className={styles.Filters}>
                            <TransactionTypeFilter
                                className={cn(styles.Filter, styles.FilterType)}
                                active={txnType}
                                onChange={onChangeTxnType}
                            />
                            {(txnType === TxnType.CashIssue || txnType === TxnType.CashRetire) && (
                                <TransactionTokenFilter
                                    className={cn(styles.Filter, styles.FilterToken)}
                                    active={token}
                                    onChange={onChangeToken}
                                />
                            )}
                        </div>
                    </>
                )}
            </div>

            <TransactionsTable
                className={styles.TransactionsTable}
                loading={loading}
                actions={actions}
                sort={sort}
                isFirstLoading={isFirstLoading}
                onClickRefetch={() => actionsQuery.refetch()}
                showErrorScreen={showErrorScreen}
                page={variables.skip! / STEP}
                pageCount={Math.ceil(totalCount / STEP)}
                onChangePage={(page) =>
                    setVariables({
                        ...variables,
                        skip: page * STEP
                    })
                }
                onChangeSort={onChangeSort}
            />
        </main>
    );
}
