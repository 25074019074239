import React from 'react';
import ReactPaginate from 'react-paginate';
import classNames from 'classnames/bind';
import { useWidthCondition } from 'helpers';
import { BREAKPOINT_LANDSCAPE } from 'constant';
import { ChevronLeftIcon, ChevronRightIcon } from 'ui/Icons/Icons';
import styles from './Pagination.module.scss';

const cx = classNames.bind(styles);

type Props = {
    pageCount: number;
    onChangePage(page: number): void;
    page: number;
    className?: string;
};

function Pagination(props: Props) {
    const [isLandscapeOrLess] = useWidthCondition((w) => w < BREAKPOINT_LANDSCAPE);

    // eslint-disable-next-line react/destructuring-assignment
    if (props.pageCount < 2) return null;

    return (
        <ReactPaginate
            // eslint-disable-next-line react/destructuring-assignment
            containerClassName={cx('Component', props.className ?? '')}
            // eslint-disable-next-line react/destructuring-assignment
            pageCount={props.pageCount}
            previousLabel={<ChevronLeftIcon className={styles.Icon} />}
            previousClassName={cx('BtnPage', 'BtnPrevious')}
            pageClassName={cx('BtnPage')}
            breakClassName={cx('BtnPage')}
            activeClassName={cx('active')}
            nextClassName={cx('BtnPage', 'BtnNext')}
            nextLabel={<ChevronRightIcon className={styles.Icon} />}
            // eslint-disable-next-line react/destructuring-assignment
            onPageChange={({ selected }) => props.onChangePage(selected)}
            // eslint-disable-next-line react/destructuring-assignment
            forcePage={props.page}
            marginPagesDisplayed={isLandscapeOrLess ? 1 : 1}
            pageRangeDisplayed={isLandscapeOrLess ? 1 : 5}
        />
    );
}

export default Pagination;
