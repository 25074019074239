import classNames from 'classnames/bind';
import PopupBase, { PopupBaseProps } from 'components/PopupBase/PopupBase';
import React, { PropsWithChildren } from 'react';
import styles from './Sidebar.module.scss';

const cx = classNames.bind(styles);

// export const SidebarHeader = ({ className, children }: PropsWithChildren<{ className?: string }>) => {
//     return <header className={cx('Header', className)}>{children}</header>;
// };

export function SidebarContent({ children, className }: PropsWithChildren<{ className?: string }>) {
    return <main className={cx('Content', className)}>{children}</main>;
}

type Props = PopupBaseProps;

function Sidebar({ className = '', wrapperClassName = '', ...props }: Props) {
    return (
        <PopupBase
            wrapperClassName={cx('Wrapper', wrapperClassName)}
            isOpenWrapperClassName={cx('isOpen')}
            className={cx('Component', className)}
            {...props}
        />
    );
}

export default Sidebar;
