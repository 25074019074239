import React, { PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import { TxnType } from 'apollo/main/generated';
import { useTranslation } from 'react-i18next';
import styles from './TransactionTag.module.scss';

const cx = classNames.bind(styles);

function TransactionTag({ type }: PropsWithChildren<{ type: TxnType }>) {
    const [t] = useTranslation();
    return (
        <span
            className={cx('Component', {
                green: type === TxnType.AddLiquidity || type === TxnType.CashIssue,
                blue: type === TxnType.RemoveLiquidity,
                pink: type === TxnType.MlnkCollect,
                yellow: type === TxnType.Swap || type === TxnType.CashRetire,
                peach: type === TxnType.Transfer
            })}
        >
            {t(`global.transactionType.${type}`)}
        </span>
    );
}

export default TransactionTag;
