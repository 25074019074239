import AboutProjectIntroEn1x from 'assets/images/about-project-intro-en@1x.png';
import AboutProjectIntroRu1x from 'assets/images/about-project-intro-ru@1x.png';
import AboutProjectIntroEn2x from 'assets/images/about-project-intro-en@2x.png';
import AboutProjectIntroRu2x from 'assets/images/about-project-intro-ru@2x.png';

import AboutProjectLiquidityEn from 'assets/images/about-project-liquidity-en.svg';
import AboutProjectLiquidityRu from 'assets/images/about-project-liquidity-ru.svg';

import AboutProjectStart1en1x from 'assets/images/about-project-start-1-en@1x.png';
import AboutProjectStart1en2x from 'assets/images/about-project-start-1-en@2x.png';
import AboutProjectStart1ru1x from 'assets/images/about-project-start-1-ru@1x.png';
import AboutProjectStart1ru2x from 'assets/images/about-project-start-1-ru@2x.png';
import AboutProjectStart2en1x from 'assets/images/about-project-start-2-en@1x.png';
import AboutProjectStart2en2x from 'assets/images/about-project-start-2-en@2x.png';
import AboutProjectStart2ru1x from 'assets/images/about-project-start-2-ru@1x.png';
import AboutProjectStart2ru2x from 'assets/images/about-project-start-2-ru@2x.png';
import AboutProjectStart3en1x from 'assets/images/about-project-start-3-en@1x.png';
import AboutProjectStart3en2x from 'assets/images/about-project-start-3-en@2x.png';
import AboutProjectStart3ru1x from 'assets/images/about-project-start-3-ru@1x.png';
import AboutProjectStart3ru2x from 'assets/images/about-project-start-3-ru@2x.png';
import AboutProjectStart4en1x from 'assets/images/about-project-start-4-en@1x.png';
import AboutProjectStart4en2x from 'assets/images/about-project-start-4-en@2x.png';
import AboutProjectStart4ru1x from 'assets/images/about-project-start-4-ru@1x.png';
import AboutProjectStart4ru2x from 'assets/images/about-project-start-4-ru@2x.png';
import AboutProjectStart5en1x from 'assets/images/about-project-start-5-en@1x.png';
import AboutProjectStart5en2x from 'assets/images/about-project-start-5-en@2x.png';
import AboutProjectStart5ru1x from 'assets/images/about-project-start-5-ru@1x.png';
import AboutProjectStart5ru2x from 'assets/images/about-project-start-5-ru@2x.png';
import AboutProjectStart6en1x from 'assets/images/about-project-start-6-en@1x.png';
import AboutProjectStart6en2x from 'assets/images/about-project-start-6-en@2x.png';
import AboutProjectStart6ru1x from 'assets/images/about-project-start-6-ru@1x.png';
import AboutProjectStart6ru2x from 'assets/images/about-project-start-6-ru@2x.png';
import AboutProjectStart7en1x from 'assets/images/about-project-start-7-en@1x.png';
import AboutProjectStart7en2x from 'assets/images/about-project-start-7-en@2x.png';
import AboutProjectStart7ru1x from 'assets/images/about-project-start-7-ru@1x.png';
import AboutProjectStart7ru2x from 'assets/images/about-project-start-7-ru@2x.png';
import AboutProjectStart8en1x from 'assets/images/about-project-start-8-en@1x.png';
import AboutProjectStart8en2x from 'assets/images/about-project-start-8-en@2x.png';
import AboutProjectStart8ru1x from 'assets/images/about-project-start-8-ru@1x.png';
import AboutProjectStart8ru2x from 'assets/images/about-project-start-8-ru@2x.png';

import TutorialBurn1ru1x from 'assets/images/about-cash-tokens/tutorial-burn-1@1x.png';
import TutorialBurn1ru2x from 'assets/images/about-cash-tokens/tutorial-burn-1@2x.png';
import TutorialBurn2ru1x from 'assets/images/about-cash-tokens/tutorial-burn-2@1x.png';
import TutorialBurn2ru2x from 'assets/images/about-cash-tokens/tutorial-burn-2@2x.png';
import TutorialBurn3ru1x from 'assets/images/about-cash-tokens/tutorial-burn-3@1x.png';
import TutorialBurn3ru2x from 'assets/images/about-cash-tokens/tutorial-burn-3@2x.png';
import TutorialBurn4ru1x from 'assets/images/about-cash-tokens/tutorial-burn-4@1x.png';
import TutorialBurn4ru2x from 'assets/images/about-cash-tokens/tutorial-burn-4@2x.png';

import TutorialBuy1ru1x from 'assets/images/about-cash-tokens/tutorial-buy-1@1x.png';
import TutorialBuy1ru2x from 'assets/images/about-cash-tokens/tutorial-buy-1@2x.png';
import TutorialBuy2ru1x from 'assets/images/about-cash-tokens/tutorial-buy-2@1x.png';
import TutorialBuy2ru2x from 'assets/images/about-cash-tokens/tutorial-buy-2@2x.png';
import TutorialBuy3ru1x from 'assets/images/about-cash-tokens/tutorial-buy-3@1x.png';
import TutorialBuy3ru2x from 'assets/images/about-cash-tokens/tutorial-buy-3@2x.png';
import TutorialBuy4ru1x from 'assets/images/about-cash-tokens/tutorial-buy-4@1x.png';
import TutorialBuy4ru2x from 'assets/images/about-cash-tokens/tutorial-buy-4@2x.png';

import TutorialIssue1ru1x from 'assets/images/about-cash-tokens/tutorial-issue-1@1x.png';
import TutorialIssue1ru2x from 'assets/images/about-cash-tokens/tutorial-issue-1@2x.png';
import TutorialIssue2ru1x from 'assets/images/about-cash-tokens/tutorial-issue-2@1x.png';
import TutorialIssue2ru2x from 'assets/images/about-cash-tokens/tutorial-issue-2@2x.png';
import TutorialIssue3ru1x from 'assets/images/about-cash-tokens/tutorial-issue-3@1x.png';
import TutorialIssue3ru2x from 'assets/images/about-cash-tokens/tutorial-issue-3@2x.png';
import TutorialIssue4ru1x from 'assets/images/about-cash-tokens/tutorial-issue-4@1x.png';
import TutorialIssue4ru2x from 'assets/images/about-cash-tokens/tutorial-issue-4@2x.png';
import TutorialIssue5ru1x from 'assets/images/about-cash-tokens/tutorial-issue-5@1x.png';
import TutorialIssue5ru2x from 'assets/images/about-cash-tokens/tutorial-issue-5@2x.png';

import DepositEosWhitebit1ru1x from 'assets/images/deposit-exchange/deposit-eos-whitebit-1-ru@1x.png';
import DepositEosWhitebit1ru2x from 'assets/images/deposit-exchange/deposit-eos-whitebit-1-ru@2x.png';
import DepositEosWhitebit1en1x from 'assets/images/deposit-exchange/deposit-eos-whitebit-1-en@1x.png';
import DepositEosWhitebit1en2x from 'assets/images/deposit-exchange/deposit-eos-whitebit-1-en@2x.png';

import DepositEosWhitebit2ru1x from 'assets/images/deposit-exchange/deposit-eos-whitebit-2-ru@1x.png';
import DepositEosWhitebit2ru2x from 'assets/images/deposit-exchange/deposit-eos-whitebit-2-ru@2x.png';
import DepositEosWhitebit2en1x from 'assets/images/deposit-exchange/deposit-eos-whitebit-2-en@1x.png';
import DepositEosWhitebit2en2x from 'assets/images/deposit-exchange/deposit-eos-whitebit-2-en@2x.png';

import DepositEosWhitebit3ru1x from 'assets/images/deposit-exchange/deposit-eos-whitebit-3-ru@1x.png';
import DepositEosWhitebit3ru2x from 'assets/images/deposit-exchange/deposit-eos-whitebit-3-ru@2x.png';
import DepositEosWhitebit3en1x from 'assets/images/deposit-exchange/deposit-eos-whitebit-3-en@1x.png';
import DepositEosWhitebit3en2x from 'assets/images/deposit-exchange/deposit-eos-whitebit-3-en@2x.png';

import DepositEosWhitebit4_1ru1x from 'assets/images/deposit-exchange/deposit-eos-whitebit-4-1-ru@1x.png';
import DepositEosWhitebit4_1ru2x from 'assets/images/deposit-exchange/deposit-eos-whitebit-4-1-ru@2x.png';
import DepositEosWhitebit4_1en1x from 'assets/images/deposit-exchange/deposit-eos-whitebit-4-1-en@1x.png';
import DepositEosWhitebit4_1en2x from 'assets/images/deposit-exchange/deposit-eos-whitebit-4-1-en@2x.png';

import DepositEosWhitebit4_2ru1x from 'assets/images/deposit-exchange/deposit-eos-whitebit-4-2-ru@1x.png';
import DepositEosWhitebit4_2ru2x from 'assets/images/deposit-exchange/deposit-eos-whitebit-4-2-ru@2x.png';
import DepositEosWhitebit4_2en1x from 'assets/images/deposit-exchange/deposit-eos-whitebit-4-2-en@1x.png';
import DepositEosWhitebit4_2en2x from 'assets/images/deposit-exchange/deposit-eos-whitebit-4-2-en@2x.png';

import DepositEosWhitebit5ru1x from 'assets/images/deposit-exchange/deposit-eos-whitebit-5-ru@1x.png';
import DepositEosWhitebit5ru2x from 'assets/images/deposit-exchange/deposit-eos-whitebit-5-ru@2x.png';
import DepositEosWhitebit5en1x from 'assets/images/deposit-exchange/deposit-eos-whitebit-5-en@1x.png';
import DepositEosWhitebit5en2x from 'assets/images/deposit-exchange/deposit-eos-whitebit-5-en@2x.png';

import DepositMLNKWhitebit4_2ru1x from 'assets/images/deposit-exchange/deposit-mlnk-whitebit-4-2-ru@1x.png';
import DepositMLNKWhitebit4_2ru2x from 'assets/images/deposit-exchange/deposit-mlnk-whitebit-4-2-ru@2x.png';
import DepositMLNKWhitebit4_2en1x from 'assets/images/deposit-exchange/deposit-mlnk-whitebit-4-2-en@1x.png';
import DepositMLNKWhitebit4_2en2x from 'assets/images/deposit-exchange/deposit-mlnk-whitebit-4-2-en@2x.png';

import DepositMLNKWhitebit5ru1x from 'assets/images/deposit-exchange/deposit-mlnk-whitebit-5-ru@1x.png';
import DepositMLNKWhitebit5ru2x from 'assets/images/deposit-exchange/deposit-mlnk-whitebit-5-ru@2x.png';
import DepositMLNKWhitebit5en1x from 'assets/images/deposit-exchange/deposit-mlnk-whitebit-5-en@1x.png';
import DepositMLNKWhitebit5en2x from 'assets/images/deposit-exchange/deposit-mlnk-whitebit-5-en@2x.png';

import DepositTetherWhitebit4_2ru1x from 'assets/images/deposit-exchange/deposit-tether-whitebit-4-2-ru@1x.png';
import DepositTetherWhitebit4_2ru2x from 'assets/images/deposit-exchange/deposit-tether-whitebit-4-2-ru@2x.png';
import DepositTetherWhitebit4_2en1x from 'assets/images/deposit-exchange/deposit-tether-whitebit-4-2-en@1x.png';
import DepositTetherWhitebit4_2en2x from 'assets/images/deposit-exchange/deposit-tether-whitebit-4-2-en@2x.png';

import DepositTetherWhitebit5ru1x from 'assets/images/deposit-exchange/deposit-tether-whitebit-5-ru@1x.png';
import DepositTetherWhitebit5ru2x from 'assets/images/deposit-exchange/deposit-tether-whitebit-5-ru@2x.png';
import DepositTetherWhitebit5en1x from 'assets/images/deposit-exchange/deposit-tether-whitebit-5-en@1x.png';
import DepositTetherWhitebit5en2x from 'assets/images/deposit-exchange/deposit-tether-whitebit-5-en@2x.png';

import WithdrawMLNKWhitebit5ru1x from 'assets/images/deposit-exchange/withdraw-mlnk-whitebit-5-ru@1x.png';
import WithdrawMLNKWhitebit5ru2x from 'assets/images/deposit-exchange/withdraw-mlnk-whitebit-5-ru@2x.png';
import WithdrawMLNKWhitebit5en1x from 'assets/images/deposit-exchange/withdraw-mlnk-whitebit-5-en@1x.png';
import WithdrawMLNKWhitebit5en2x from 'assets/images/deposit-exchange/withdraw-mlnk-whitebit-5-en@2x.png';

import WithdrawEOSWhitebit5ru1x from 'assets/images/deposit-exchange/withdraw-eos-whitebit-5-ru@1x.png';
import WithdrawEOSWhitebit5ru2x from 'assets/images/deposit-exchange/withdraw-eos-whitebit-5-ru@2x.png';
import WithdrawEOSWhitebit5en1x from 'assets/images/deposit-exchange/withdraw-eos-whitebit-5-en@1x.png';
import WithdrawEOSWhitebit5en2x from 'assets/images/deposit-exchange/withdraw-eos-whitebit-5-en@2x.png';

import WithdrawTetherWhitebit5ru1x from 'assets/images/deposit-exchange/withdraw-tether-whitebit-5-ru@1x.png';
import WithdrawTetherWhitebit5ru2x from 'assets/images/deposit-exchange/withdraw-tether-whitebit-5-ru@2x.png';
import WithdrawTetherWhitebit5en1x from 'assets/images/deposit-exchange/withdraw-tether-whitebit-5-en@1x.png';
import WithdrawTetherWhitebit5en2x from 'assets/images/deposit-exchange/withdraw-tether-whitebit-5-en@2x.png';

import WithdrawEOSBinance3ru1x from 'assets/images/deposit-exchange/withdraw-eos-binance-3-ru@1x.png';
import WithdrawEOSBinance3ru2x from 'assets/images/deposit-exchange/withdraw-eos-binance-3-ru@2x.png';
import WithdrawEOSBinance3en1x from 'assets/images/deposit-exchange/withdraw-eos-binance-3-en@1x.png';
import WithdrawEOSBinance3en2x from 'assets/images/deposit-exchange/withdraw-eos-binance-3-en@2x.png';
import WithdrawEOSBinance4ru1x from 'assets/images/deposit-exchange/withdraw-eos-binance-4-ru@1x.png';
import WithdrawEOSBinance4ru2x from 'assets/images/deposit-exchange/withdraw-eos-binance-4-ru@2x.png';
import WithdrawEOSBinance4en1x from 'assets/images/deposit-exchange/withdraw-eos-binance-4-en@1x.png';
import WithdrawEOSBinance4en2x from 'assets/images/deposit-exchange/withdraw-eos-binance-4-en@2x.png';
import WithdrawEOSBinance5_1ru1x from 'assets/images/deposit-exchange/withdraw-eos-binance-5-1-ru@1x.png';
import WithdrawEOSBinance5_1ru2x from 'assets/images/deposit-exchange/withdraw-eos-binance-5-1-ru@2x.png';
import WithdrawEOSBinance5_1en1x from 'assets/images/deposit-exchange/withdraw-eos-binance-5-1-en@1x.png';
import WithdrawEOSBinance5_1en2x from 'assets/images/deposit-exchange/withdraw-eos-binance-5-1-en@2x.png';
import WithdrawEOSBinance5_2ru1x from 'assets/images/deposit-exchange/withdraw-eos-binance-5-2-ru@1x.png';
import WithdrawEOSBinance5_2ru2x from 'assets/images/deposit-exchange/withdraw-eos-binance-5-2-ru@2x.png';
import WithdrawEOSBinance5_2en1x from 'assets/images/deposit-exchange/withdraw-eos-binance-5-2-en@1x.png';
import WithdrawEOSBinance5_2en2x from 'assets/images/deposit-exchange/withdraw-eos-binance-5-2-en@2x.png';
import WithdrawEOSBinance5_3ru1x from 'assets/images/deposit-exchange/withdraw-eos-binance-5-3-ru@1x.png';
import WithdrawEOSBinance5_3ru2x from 'assets/images/deposit-exchange/withdraw-eos-binance-5-3-ru@2x.png';
import WithdrawEOSBinance5_3en1x from 'assets/images/deposit-exchange/withdraw-eos-binance-5-3-en@1x.png';
import WithdrawEOSBinance5_3en2x from 'assets/images/deposit-exchange/withdraw-eos-binance-5-3-en@2x.png';
import WithdrawEOSBinance6_1ru1x from 'assets/images/deposit-exchange/withdraw-eos-binance-6-1-ru@1x.png';
import WithdrawEOSBinance6_1ru2x from 'assets/images/deposit-exchange/withdraw-eos-binance-6-1-ru@2x.png';
import WithdrawEOSBinance6_1en1x from 'assets/images/deposit-exchange/withdraw-eos-binance-6-1-en@1x.png';
import WithdrawEOSBinance6_1en2x from 'assets/images/deposit-exchange/withdraw-eos-binance-6-1-en@2x.png';
import WithdrawEOSBinance6_2ru1x from 'assets/images/deposit-exchange/withdraw-eos-binance-6-2-ru@1x.png';
import WithdrawEOSBinance6_2ru2x from 'assets/images/deposit-exchange/withdraw-eos-binance-6-2-ru@2x.png';
import WithdrawEOSBinance6_2en1x from 'assets/images/deposit-exchange/withdraw-eos-binance-6-2-en@1x.png';
import WithdrawEOSBinance6_2en2x from 'assets/images/deposit-exchange/withdraw-eos-binance-6-2-en@2x.png';

import DepositEOSBinance1ru1x from 'assets/images/deposit-exchange/deposit-eos-binance-1-ru@1x.png';
import DepositEOSBinance1ru2x from 'assets/images/deposit-exchange/deposit-eos-binance-1-ru@2x.png';
import DepositEOSBinance1en1x from 'assets/images/deposit-exchange/deposit-eos-binance-1-en@1x.png';
import DepositEOSBinance1en2x from 'assets/images/deposit-exchange/deposit-eos-binance-1-en@2x.png';
import DepositEOSBinance2ru1x from 'assets/images/deposit-exchange/deposit-eos-binance-2-ru@1x.png';
import DepositEOSBinance2ru2x from 'assets/images/deposit-exchange/deposit-eos-binance-2-ru@2x.png';
import DepositEOSBinance2en1x from 'assets/images/deposit-exchange/deposit-eos-binance-2-en@1x.png';
import DepositEOSBinance2en2x from 'assets/images/deposit-exchange/deposit-eos-binance-2-en@2x.png';
import DepositEOSBinance3ru1x from 'assets/images/deposit-exchange/deposit-eos-binance-3-ru@1x.png';
import DepositEOSBinance3ru2x from 'assets/images/deposit-exchange/deposit-eos-binance-3-ru@2x.png';
import DepositEOSBinance3en1x from 'assets/images/deposit-exchange/deposit-eos-binance-3-en@1x.png';
import DepositEOSBinance3en2x from 'assets/images/deposit-exchange/deposit-eos-binance-3-en@2x.png';
import DepositEOSBinance4ru1x from 'assets/images/deposit-exchange/deposit-eos-binance-4-ru@1x.png';
import DepositEOSBinance4ru2x from 'assets/images/deposit-exchange/deposit-eos-binance-4-ru@2x.png';
import DepositEOSBinance4en1x from 'assets/images/deposit-exchange/deposit-eos-binance-4-en@1x.png';
import DepositEOSBinance4en2x from 'assets/images/deposit-exchange/deposit-eos-binance-4-en@2x.png';
import DepositEOSBinance5_1ru1x from 'assets/images/deposit-exchange/deposit-eos-binance-5-1-ru@1x.png';
import DepositEOSBinance5_1ru2x from 'assets/images/deposit-exchange/deposit-eos-binance-5-1-ru@2x.png';
import DepositEOSBinance5_1en1x from 'assets/images/deposit-exchange/deposit-eos-binance-5-1-en@1x.png';
import DepositEOSBinance5_1en2x from 'assets/images/deposit-exchange/deposit-eos-binance-5-1-en@2x.png';
import DepositEOSBinance5_2ru1x from 'assets/images/deposit-exchange/deposit-eos-binance-5-2-ru@1x.png';
import DepositEOSBinance5_2ru2x from 'assets/images/deposit-exchange/deposit-eos-binance-5-2-ru@2x.png';
import DepositEOSBinance5_2en1x from 'assets/images/deposit-exchange/deposit-eos-binance-5-2-en@1x.png';
import DepositEOSBinance5_2en2x from 'assets/images/deposit-exchange/deposit-eos-binance-5-2-en@2x.png';
import DepositEOSBinance6ru1x from 'assets/images/deposit-exchange/deposit-eos-binance-6-ru@1x.png';
import DepositEOSBinance6ru2x from 'assets/images/deposit-exchange/deposit-eos-binance-6-ru@2x.png';
import DepositEOSBinance6en1x from 'assets/images/deposit-exchange/deposit-eos-binance-6-en@1x.png';
import DepositEOSBinance6en2x from 'assets/images/deposit-exchange/deposit-eos-binance-6-en@2x.png';
import DepositEOSBinance7_1ru1x from 'assets/images/deposit-exchange/deposit-eos-binance-7-1-ru@1x.png';
import DepositEOSBinance7_1ru2x from 'assets/images/deposit-exchange/deposit-eos-binance-7-1-ru@2x.png';
import DepositEOSBinance7_1en1x from 'assets/images/deposit-exchange/deposit-eos-binance-7-1-en@1x.png';
import DepositEOSBinance7_1en2x from 'assets/images/deposit-exchange/deposit-eos-binance-7-1-en@2x.png';
import DepositEOSBinance7_2ru1x from 'assets/images/deposit-exchange/deposit-eos-binance-7-2-ru@1x.png';
import DepositEOSBinance7_2ru2x from 'assets/images/deposit-exchange/deposit-eos-binance-7-2-ru@2x.png';
import DepositEOSBinance7_2en1x from 'assets/images/deposit-exchange/deposit-eos-binance-7-2-en@1x.png';
import DepositEOSBinance7_2en2x from 'assets/images/deposit-exchange/deposit-eos-binance-7-2-en@2x.png';
import DepositEOSBinance7_3ru1x from 'assets/images/deposit-exchange/deposit-eos-binance-7-3-ru@1x.png';
import DepositEOSBinance7_3ru2x from 'assets/images/deposit-exchange/deposit-eos-binance-7-3-ru@2x.png';
import DepositEOSBinance7_3en1x from 'assets/images/deposit-exchange/deposit-eos-binance-7-3-en@1x.png';
import DepositEOSBinance7_3en2x from 'assets/images/deposit-exchange/deposit-eos-binance-7-3-en@2x.png';
import DepositEOSBinance7_4ru1x from 'assets/images/deposit-exchange/deposit-eos-binance-7-4-ru@1x.png';
import DepositEOSBinance7_4ru2x from 'assets/images/deposit-exchange/deposit-eos-binance-7-4-ru@2x.png';
import DepositEOSBinance7_4en1x from 'assets/images/deposit-exchange/deposit-eos-binance-7-4-en@1x.png';
import DepositEOSBinance7_4en2x from 'assets/images/deposit-exchange/deposit-eos-binance-7-4-en@2x.png';

import DepositTutorial1ru1x from 'assets/images/deposit-by-bank-card-tutorial-1@1x.png';
import DepositTutorial1ru2x from 'assets/images/deposit-by-bank-card-tutorial-1@2x.png';
import DepositTutorial2_1ru1x from 'assets/images/deposit-by-bank-card-tutorial-2-1@1x.png';
import DepositTutorial2_1ru2x from 'assets/images/deposit-by-bank-card-tutorial-2-1@2x.png';
import DepositTutorial2_2ru1x from 'assets/images/deposit-by-bank-card-tutorial-2-2@1x.png';
import DepositTutorial2_2ru2x from 'assets/images/deposit-by-bank-card-tutorial-2-2@2x.png';
import DepositTutorial2_3ru1x from 'assets/images/deposit-by-bank-card-tutorial-2-3@1x.png';
import DepositTutorial2_3ru2x from 'assets/images/deposit-by-bank-card-tutorial-2-3@2x.png';
import DepositTutorial2_4ru1x from 'assets/images/deposit-by-bank-card-tutorial-2-4@1x.png';
import DepositTutorial2_4ru2x from 'assets/images/deposit-by-bank-card-tutorial-2-4@2x.png';
import DepositTutorial2_5ru1x from 'assets/images/deposit-by-bank-card-tutorial-2-5@1x.png';
import DepositTutorial2_5ru2x from 'assets/images/deposit-by-bank-card-tutorial-2-5@2x.png';
import DepositTutorial3ru1x from 'assets/images/deposit-by-bank-card-tutorial-3@1x.png';
import DepositTutorial3ru2x from 'assets/images/deposit-by-bank-card-tutorial-3@2x.png';
import DepositTutorial4ru1x from 'assets/images/deposit-by-bank-card-tutorial-4@1x.png';
import DepositTutorial4ru2x from 'assets/images/deposit-by-bank-card-tutorial-4@2x.png';
import DepositTutorial5_1ru1x from 'assets/images/deposit-by-bank-card-tutorial-5-1@1x.png';
import DepositTutorial5_1ru2x from 'assets/images/deposit-by-bank-card-tutorial-5-1@2x.png';
import DepositTutorial5_2ru1x from 'assets/images/deposit-by-bank-card-tutorial-5-2@1x.png';
import DepositTutorial5_2ru2x from 'assets/images/deposit-by-bank-card-tutorial-5-2@2x.png';
import DepositTutorial6ru1x from 'assets/images/deposit-by-bank-card-tutorial-6@1x.png';
import DepositTutorial6ru2x from 'assets/images/deposit-by-bank-card-tutorial-6@2x.png';
import DepositTutorial7_1ru1x from 'assets/images/deposit-by-bank-card-tutorial-7-1@1x.png';
import DepositTutorial7_1ru2x from 'assets/images/deposit-by-bank-card-tutorial-7-1@2x.png';
import DepositTutorial7_2ru1x from 'assets/images/deposit-by-bank-card-tutorial-7-2@1x.png';
import DepositTutorial7_2ru2x from 'assets/images/deposit-by-bank-card-tutorial-7-2@2x.png';

interface Step {
    1?: string;
    2?: string;
    3?: string;
    4?: string;
    5?: string;
    6?: string;
    7?: string;
    8?: string;
}

interface Project {
    1: Step | string;
    2: Step | string;
}

interface Image {
    [language: string]: Project | string;
}

interface Images {
    [key: string]: Image;
}

export default function getImage(name: string, lang: string, pixelRatio: 1 | 2, number?: number) {
    const images: Images = {
        'about-project_intro': {
            en: {
                1: AboutProjectIntroEn1x,
                2: AboutProjectIntroEn2x
            },
            ru: {
                1: AboutProjectIntroRu1x,
                2: AboutProjectIntroRu2x
            }
        },
        'about-project_liquidity': {
            en: {
                1: AboutProjectLiquidityEn,
                2: ''
            },
            ru: {
                1: AboutProjectLiquidityRu,
                2: ''
            }
        },
        'about-project_start': {
            en: {
                1: {
                    1: AboutProjectStart1en1x,
                    2: AboutProjectStart2en1x,
                    3: AboutProjectStart3en1x,
                    4: AboutProjectStart4en1x,
                    5: AboutProjectStart5en1x,
                    6: AboutProjectStart6en1x,
                    7: AboutProjectStart7en1x,
                    8: AboutProjectStart8en1x
                },
                2: {
                    1: AboutProjectStart1en2x,
                    2: AboutProjectStart2en2x,
                    3: AboutProjectStart3en2x,
                    4: AboutProjectStart4en2x,
                    5: AboutProjectStart5en2x,
                    6: AboutProjectStart6en2x,
                    7: AboutProjectStart7en2x,
                    8: AboutProjectStart8en2x
                }
            },
            ru: {
                1: {
                    1: AboutProjectStart1ru1x,
                    2: AboutProjectStart2ru1x,
                    3: AboutProjectStart3ru1x,
                    4: AboutProjectStart4ru1x,
                    5: AboutProjectStart5ru1x,
                    6: AboutProjectStart6ru1x,
                    7: AboutProjectStart7ru1x,
                    8: AboutProjectStart8ru1x
                },
                2: {
                    1: AboutProjectStart1ru2x,
                    2: AboutProjectStart2ru2x,
                    3: AboutProjectStart3ru2x,
                    4: AboutProjectStart4ru2x,
                    5: AboutProjectStart5ru2x,
                    6: AboutProjectStart6ru2x,
                    7: AboutProjectStart7ru2x,
                    8: AboutProjectStart8ru2x
                }
            }
        },
        'tutorial-burn': {
            ru: {
                1: {
                    1: TutorialBurn1ru1x,
                    2: TutorialBurn2ru1x,
                    3: TutorialBurn3ru1x,
                    4: TutorialBurn4ru1x
                },
                2: {
                    1: TutorialBurn1ru2x,
                    2: TutorialBurn2ru2x,
                    3: TutorialBurn3ru2x,
                    4: TutorialBurn4ru2x
                }
            }
        },
        'tutorial-buy': {
            ru: {
                1: {
                    1: TutorialBuy1ru1x,
                    2: TutorialBuy2ru1x,
                    3: TutorialBuy3ru1x,
                    4: TutorialBuy4ru1x
                },
                2: {
                    1: TutorialBuy1ru2x,
                    2: TutorialBuy2ru2x,
                    3: TutorialBuy3ru2x,
                    4: TutorialBuy4ru2x
                }
            }
        },
        'tutorial-issue': {
            ru: {
                1: {
                    1: TutorialIssue1ru1x,
                    2: TutorialIssue2ru1x,
                    3: TutorialIssue3ru1x,
                    4: TutorialIssue4ru1x,
                    5: TutorialIssue5ru1x
                },
                2: {
                    1: TutorialIssue1ru2x,
                    2: TutorialIssue2ru2x,
                    3: TutorialIssue3ru2x,
                    4: TutorialIssue4ru2x,
                    5: TutorialIssue5ru2x
                }
            }
        },
        'deposit-EOS-whitebit-1': {
            en: {
                1: DepositEosWhitebit1en1x,
                2: DepositEosWhitebit1en2x
            },
            ru: {
                1: DepositEosWhitebit1ru1x,
                2: DepositEosWhitebit1ru2x
            }
        },
        'deposit-EOS-whitebit-2': {
            en: {
                1: DepositEosWhitebit2en1x,
                2: DepositEosWhitebit2en2x
            },
            ru: {
                1: DepositEosWhitebit2ru1x,
                2: DepositEosWhitebit2ru2x
            }
        },
        'deposit-EOS-whitebit-3': {
            en: {
                1: DepositEosWhitebit3en1x,
                2: DepositEosWhitebit3en2x
            },
            ru: {
                1: DepositEosWhitebit3ru1x,
                2: DepositEosWhitebit3ru2x
            }
        },
        'deposit-EOS-whitebit-4-1': {
            en: {
                1: DepositEosWhitebit4_1en1x,
                2: DepositEosWhitebit4_1en2x
            },
            ru: {
                1: DepositEosWhitebit4_1ru1x,
                2: DepositEosWhitebit4_1ru2x
            }
        },
        'deposit-EOS-whitebit-4-2': {
            en: {
                1: DepositEosWhitebit4_2en1x,
                2: DepositEosWhitebit4_2en2x
            },
            ru: {
                1: DepositEosWhitebit4_2ru1x,
                2: DepositEosWhitebit4_2ru2x
            }
        },
        'deposit-MLNK-whitebit-4-2': {
            en: {
                1: DepositMLNKWhitebit4_2en1x,
                2: DepositMLNKWhitebit4_2en2x
            },
            ru: {
                1: DepositMLNKWhitebit4_2ru1x,
                2: DepositMLNKWhitebit4_2ru2x
            }
        },
        'deposit-EOS-whitebit-5': {
            en: {
                1: DepositEosWhitebit5en1x,
                2: DepositEosWhitebit5en2x
            },
            ru: {
                1: DepositEosWhitebit5ru1x,
                2: DepositEosWhitebit5ru2x
            }
        },
        'deposit-MLNK-whitebit-5': {
            en: {
                1: DepositMLNKWhitebit5en1x,
                2: DepositMLNKWhitebit5en2x
            },
            ru: {
                1: DepositMLNKWhitebit5ru1x,
                2: DepositMLNKWhitebit5ru2x
            }
        },
        'deposit-TETHER-whitebit-4-2': {
            en: {
                1: DepositTetherWhitebit4_2en1x,
                2: DepositTetherWhitebit4_2en2x
            },
            ru: {
                1: DepositTetherWhitebit4_2ru1x,
                2: DepositTetherWhitebit4_2ru2x
            }
        },
        'deposit-TETHER-whitebit-5': {
            en: {
                1: DepositTetherWhitebit5en1x,
                2: DepositTetherWhitebit5en2x
            },
            ru: {
                1: DepositTetherWhitebit5ru1x,
                2: DepositTetherWhitebit5ru2x
            }
        },
        'withdraw-MLNK-whitebit-5': {
            en: {
                1: WithdrawMLNKWhitebit5en1x,
                2: WithdrawMLNKWhitebit5en2x
            },
            ru: {
                1: WithdrawMLNKWhitebit5ru1x,
                2: WithdrawMLNKWhitebit5ru2x
            }
        },
        'withdraw-EOS-whitebit-5': {
            en: {
                1: WithdrawEOSWhitebit5en1x,
                2: WithdrawEOSWhitebit5en2x
            },
            ru: {
                1: WithdrawEOSWhitebit5ru1x,
                2: WithdrawEOSWhitebit5ru2x
            }
        },
        'withdraw-TETHER-whitebit-5': {
            en: {
                1: WithdrawTetherWhitebit5en1x,
                2: WithdrawTetherWhitebit5en2x
            },
            ru: {
                1: WithdrawTetherWhitebit5ru1x,
                2: WithdrawTetherWhitebit5ru2x
            }
        },
        'deposit-EOS-binance-1': {
            en: {
                1: DepositEOSBinance1en1x,
                2: DepositEOSBinance1en2x
            },
            ru: {
                1: DepositEOSBinance1ru1x,
                2: DepositEOSBinance1ru2x
            }
        },
        'deposit-EOS-binance-2': {
            en: {
                1: DepositEOSBinance2en1x,
                2: DepositEOSBinance2en2x
            },
            ru: {
                1: DepositEOSBinance2ru1x,
                2: DepositEOSBinance2ru2x
            }
        },
        'deposit-EOS-binance-3': {
            en: {
                1: DepositEOSBinance3en1x,
                2: DepositEOSBinance3en2x
            },
            ru: {
                1: DepositEOSBinance3ru1x,
                2: DepositEOSBinance3ru2x
            }
        },
        'deposit-EOS-binance-4': {
            en: {
                1: DepositEOSBinance4en1x,
                2: DepositEOSBinance4en2x
            },
            ru: {
                1: DepositEOSBinance4ru1x,
                2: DepositEOSBinance4ru2x
            }
        },
        'deposit-EOS-binance-5-1': {
            en: {
                1: DepositEOSBinance5_1en1x,
                2: DepositEOSBinance5_1en2x
            },
            ru: {
                1: DepositEOSBinance5_1ru1x,
                2: DepositEOSBinance5_1ru2x
            }
        },
        'deposit-EOS-binance-5-2': {
            en: {
                1: DepositEOSBinance5_2en1x,
                2: DepositEOSBinance5_2en2x
            },
            ru: {
                1: DepositEOSBinance5_2ru1x,
                2: DepositEOSBinance5_2ru2x
            }
        },
        'deposit-EOS-binance-6': {
            en: {
                1: DepositEOSBinance6en1x,
                2: DepositEOSBinance6en2x
            },
            ru: {
                1: DepositEOSBinance6ru1x,
                2: DepositEOSBinance6ru2x
            }
        },
        'deposit-EOS-binance-7-1': {
            en: {
                1: DepositEOSBinance7_1en1x,
                2: DepositEOSBinance7_1en2x
            },
            ru: {
                1: DepositEOSBinance7_1ru1x,
                2: DepositEOSBinance7_1ru2x
            }
        },
        'deposit-EOS-binance-7-2': {
            en: {
                1: DepositEOSBinance7_2en1x,
                2: DepositEOSBinance7_2en2x
            },
            ru: {
                1: DepositEOSBinance7_2ru1x,
                2: DepositEOSBinance7_2ru2x
            }
        },
        'deposit-EOS-binance-7-3': {
            en: {
                1: DepositEOSBinance7_3en1x,
                2: DepositEOSBinance7_3en2x
            },
            ru: {
                1: DepositEOSBinance7_3ru1x,
                2: DepositEOSBinance7_3ru2x
            }
        },
        'deposit-EOS-binance-7-4': {
            en: {
                1: DepositEOSBinance7_4en1x,
                2: DepositEOSBinance7_4en2x
            },
            ru: {
                1: DepositEOSBinance7_4ru1x,
                2: DepositEOSBinance7_4ru2x
            }
        },
        'withdraw-EOS-binance-3': {
            en: {
                1: WithdrawEOSBinance3en1x,
                2: WithdrawEOSBinance3en2x
            },
            ru: {
                1: WithdrawEOSBinance3ru1x,
                2: WithdrawEOSBinance3ru2x
            }
        },
        'withdraw-EOS-binance-4': {
            en: {
                1: WithdrawEOSBinance4en1x,
                2: WithdrawEOSBinance4en2x
            },
            ru: {
                1: WithdrawEOSBinance4ru1x,
                2: WithdrawEOSBinance4ru2x
            }
        },
        'withdraw-EOS-binance-5-1': {
            en: {
                1: WithdrawEOSBinance5_1en1x,
                2: WithdrawEOSBinance5_1en2x
            },
            ru: {
                1: WithdrawEOSBinance5_1ru1x,
                2: WithdrawEOSBinance5_1ru2x
            }
        },
        'withdraw-EOS-binance-5-2': {
            en: {
                1: WithdrawEOSBinance5_2en1x,
                2: WithdrawEOSBinance5_2en2x
            },
            ru: {
                1: WithdrawEOSBinance5_2ru1x,
                2: WithdrawEOSBinance5_2ru2x
            }
        },
        'withdraw-EOS-binance-5-3': {
            en: {
                1: WithdrawEOSBinance5_3en1x,
                2: WithdrawEOSBinance5_3en2x
            },
            ru: {
                1: WithdrawEOSBinance5_3ru1x,
                2: WithdrawEOSBinance5_3ru2x
            }
        },
        'withdraw-EOS-binance-6-1': {
            en: {
                1: WithdrawEOSBinance6_1en1x,
                2: WithdrawEOSBinance6_1en2x
            },
            ru: {
                1: WithdrawEOSBinance6_1ru1x,
                2: WithdrawEOSBinance6_1ru2x
            }
        },
        'withdraw-EOS-binance-6-2': {
            en: {
                1: WithdrawEOSBinance6_2en1x,
                2: WithdrawEOSBinance6_2en2x
            },
            ru: {
                1: WithdrawEOSBinance6_2ru1x,
                2: WithdrawEOSBinance6_2ru2x
            }
        },
        'deposit-by-bank-card_tutorial-1': {
            ru: {
                1: DepositTutorial1ru1x,
                2: DepositTutorial1ru2x
            }
        },
        'deposit-by-bank-card_tutorial-2-1': {
            ru: {
                1: DepositTutorial2_1ru1x,
                2: DepositTutorial2_1ru2x
            }
        },
        'deposit-by-bank-card_tutorial-2-2': {
            ru: {
                1: DepositTutorial2_2ru1x,
                2: DepositTutorial2_2ru2x
            }
        },
        'deposit-by-bank-card_tutorial-2-3': {
            ru: {
                1: DepositTutorial2_3ru1x,
                2: DepositTutorial2_3ru2x
            }
        },
        'deposit-by-bank-card_tutorial-2-4': {
            ru: {
                1: DepositTutorial2_4ru1x,
                2: DepositTutorial2_4ru2x
            }
        },
        'deposit-by-bank-card_tutorial-2-5': {
            ru: {
                1: DepositTutorial2_5ru1x,
                2: DepositTutorial2_5ru2x
            }
        },
        'deposit-by-bank-card_tutorial-3': {
            ru: {
                1: DepositTutorial3ru1x,
                2: DepositTutorial3ru2x
            }
        },
        'deposit-by-bank-card_tutorial-4': {
            ru: {
                1: DepositTutorial4ru1x,
                2: DepositTutorial4ru2x
            }
        },
        'deposit-by-bank-card_tutorial-5-1': {
            ru: {
                1: DepositTutorial5_1ru1x,
                2: DepositTutorial5_1ru2x
            }
        },
        'deposit-by-bank-card_tutorial-5-2': {
            ru: {
                1: DepositTutorial5_2ru1x,
                2: DepositTutorial5_2ru2x
            }
        },
        'deposit-by-bank-card_tutorial-6': {
            ru: {
                1: DepositTutorial6ru1x,
                2: DepositTutorial6ru2x
            }
        },
        'deposit-by-bank-card_tutorial-7-1': {
            ru: {
                1: DepositTutorial7_1ru1x,
                2: DepositTutorial7_1ru2x
            }
        },
        'deposit-by-bank-card_tutorial-7-2': {
            ru: {
                1: DepositTutorial7_2ru1x,
                2: DepositTutorial7_2ru2x
            }
        }
    };

    return (
        // @ts-ignore
        images?.[name]?.[lang]?.[pixelRatio]?.[number] ||
        images?.[name]?.[lang]?.[pixelRatio] ||
        images?.[name]?.[lang] ||
        images?.[name]
    );
}
