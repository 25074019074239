import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EosIcon } from 'assets/images/tokens/eos.svg';
import useAuth from 'hooks/useAuth/useAuth';
import Button from 'ui/Button/Button';
import { ExitIcon } from 'ui/Icons/Icons';
import { VirtualCardAccountProps } from './VirtualCardAccount.d';
import styles from './VirtualCardAccount.module.scss';

export default function VirtualCardAccount({ account }: VirtualCardAccountProps) {
    const [t] = useTranslation();
    const { logout } = useAuth();

    const handleLogoutClick = () => logout();

    return (
        <div className={styles.Root}>
            <div className={styles.Info}>
                <div className={cn(styles.IconContainer, styles.IconContainerBackground)}>
                    <EosIcon className={styles.Icon} />
                </div>
                <div className={styles.IconContent}>
                    <div className={styles.Label}>{t('pageVirtualCard.card.account')}</div>
                    <div className={styles.Value}>
                        <span>{account}</span>
                    </div>
                </div>
            </div>
            <Button
                color="gray"
                iconStart={<ExitIcon />}
                classes={{ root: styles.ExitButton }}
                size="medium"
                onClick={handleLogoutClick}
            />
        </div>
    );
}
