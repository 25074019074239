import React from 'react';
import cn from 'classnames';
import { formatBigNumbers } from 'helpers';
import addThousandsSeparator from 'utils/addThousandsSeparator/addThousandsSeparator';
import Tooltip, { useTooltip } from 'ui/Tooltip_DEPRECATED/Tooltip';
import styles from './FormatNumber.module.scss';

type FormatNumberProps = Omit<React.JSX.IntrinsicElements['span'], 'children' | 'ref'> & {
    prefix?: string;
    suffix?: string;
    value?: number | string | null;
    precision?: number;
    floatZerosOffset?: number;
    compress?: boolean;
};

export default function FormatNumber({
    prefix,
    suffix,
    value,
    className,
    precision,
    compress,
    floatZerosOffset,
    ...props
}: FormatNumberProps) {
    const tooltip = useTooltip();

    const formattedValue = React.useMemo(
        () => formatBigNumbers(value?.toString() ?? '', precision, floatZerosOffset, compress),

        [compress, floatZerosOffset, precision, value]
    );

    return (
        <span
            onMouseEnter={tooltip.show}
            onMouseLeave={tooltip.hide}
            ref={tooltip.setPopperReference}
            className={cn(styles.Component, className)}
            {...props}
        >
            {prefix}
            {formattedValue}
            {suffix}

            <Tooltip {...tooltip}>
                {prefix}
                {value && Number.isNaN(value) ? addThousandsSeparator(`${value}`) : value}
                {suffix}
            </Tooltip>
        </span>
    );
}
