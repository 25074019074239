import React from 'react';
import { UseFormField } from './useFormField.d';

export type UseFormFieldReturn<T = string> = ReturnType<typeof useFormField<T>>;

/**
 * Form field hook.
 *
 * @param {T} initialValue Field initialization value.
 * @param {string} initialHelperText Initial helper text.
 *
 * @returns {{
 *     ref,
 *     value,
 *     change: (newValue: T) => setValue(newValue),
 *     error,
 *     errorChange: (isError) => setError(isError),
 *     helperText,
 *     helperTextChange: (newHelperText) => setHelperText(newHelperText)
 * }}
 */
export default function useFormField<T>(initialValue: T, initialHelperText: string = ''): UseFormField<T> {
    const [value, setValue] = React.useState(initialValue);
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState(initialHelperText);

    const ref = React.useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);

    const updateHelperText = (newHelperText?: string) => {
        if (newHelperText) {
            setHelperText(newHelperText);
        } else {
            setHelperText(initialHelperText);
        }
    };

    const handleChange = (newValue: T, newHelperText?: string) => {
        setError(false);
        setValue(newValue);
        updateHelperText(newHelperText);
    };

    const handleErrorChange = (isError: boolean, newHelperText?: string) => {
        setError(isError);
        updateHelperText(newHelperText);
    };

    return {
        ref,
        value,
        change: handleChange,
        error,
        errorChange: handleErrorChange,
        helperText,
        helperTextChange: setHelperText
    };
}
