import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import Button from 'ui/Button_DEPRECATED/Button';
import { ROUTES_MAP } from 'constant';
import Error404Image from 'assets/images/error-404.svg';
import Error500Image from 'assets/images/error-500.svg';
import styles from './Error.module.scss';

const cx = classNames.bind(styles);

type Props = {
    error?: 404 | 500;
    onClickBtn?: () => void;
};

function PageError({ error = 404, onClickBtn }: Props) {
    const [t] = useTranslation();
    return (
        <main className={cx('Component')}>
            <div className={cx('Content')}>
                <code>
                    {t('pageError.error')} {error}
                </code>
                <h1>{t(`pageError.${error}.title`)}</h1>
                <p>{t(`pageError.${error}.description`)}</p>
                <Button onClick={onClickBtn} tag="link" to={ROUTES_MAP.home} color="primary">
                    {t('pageError.btnHome')}
                </Button>
            </div>
            <div className={cx('ImgWrapper')}>
                <img className={cx('Img')} src={error === 404 ? Error404Image : Error500Image} alt="Error" />
            </div>
        </main>
    );
}

export default PageError;
