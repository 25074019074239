import React from 'react';
import VirtualCard from 'components/VirtualCard/VirtualCard';

export default function VirtualCardPage() {
    return (
        <main style={{ flexGrow: 1, overflowX: 'hidden' }}>
            <VirtualCard />
        </main>
    );
}
