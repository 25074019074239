import React from 'react';
import { Trans } from 'react-i18next';
import CommissionsImage1x from 'assets/images/about-cash-tokens/comission@1x.png';
import CommissionsImage2x from 'assets/images/about-cash-tokens/comission@2x.png';
import styles from './AboutCashTokensCommissions.module.scss';

export default function AboutCashTokensCommissions() {
    return (
        <section id="statistics" className={styles.Component}>
            <div className={styles.Container}>
                <div className={styles.Wrapper}>
                    <div className={styles.ImageWrapper}>
                        <img
                            className={styles.Image}
                            srcSet={`${CommissionsImage2x} 2x`}
                            src={CommissionsImage1x}
                            alt="0% commission"
                        />
                    </div>
                    <div className={styles.Description}>
                        <h2 className={styles.Text}>
                            <Trans i18nKey="pageAboutCashTokens.commission.text">
                                <b />
                            </Trans>
                        </h2>
                        <p className={styles.Subtext}>
                            <Trans i18nKey="pageAboutCashTokens.commission.subtext" />
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}
