import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChipIcon } from 'assets/images/chip.svg';
import { ReactComponent as VisaIcon } from 'assets/images/logo-visa.svg';
import formatShrinkString from 'utils/formatShrinkString/formatShrinkString';
import { useCopy } from 'helpers';
import { CopyIcon } from 'ui/Icons/Icons';
import Button from 'ui/Button/Button';
import { VirtualCardPlasticProps } from './VirtualCardPlastic.d';
import styles from './VirtualCardPlastic.module.scss';

function getCardExpirationDate(dateString?: string): string {
    if (!dateString || dateString.length < 10) return '';

    const parts = dateString.split('-');

    if (parts.length < 3) return '';

    return `${parts[1]}/${parts[0].slice(2)}`;
}

export const formatCard = (string: string) => string.replace(/(\d{4})(?=\d)/g, '$1 ');

export default function VirtualCardPlastic({ cardholderName, privateInfo }: VirtualCardPlasticProps) {
    const [t] = useTranslation();
    const copy = useCopy();

    const [isFront, setIsFront] = React.useState(true);

    const { cardNumber, cvv, expirationDate, pinCode } = privateInfo ?? {};

    const onClickCopy = (value?: string) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();

        if (value) copy(value.toString(), t('global.copied'), t('errors.globalError'));
    };

    const handleClick = () => {
        if (cardNumber) {
            setIsFront(!isFront);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            setIsFront(!isFront);
        }
    };

    return (
        <div className={styles.Root}>
            <div
                className={cn(
                    styles.Container,
                    styles.ContainerTransition,
                    isFront ? styles.ContainerFront : styles.ContainerBack
                )}
                onClick={handleClick}
                onKeyDown={handleKeyDown}
                role="button"
                tabIndex={0}
            >
                <div className={cn(styles.Front, styles.FrontBackground, styles.FrontTransition)}>
                    <div className={styles.Chip}>
                        <ChipIcon className={styles.ChipIcon} />
                    </div>

                    {cardNumber && (
                        <div className={styles.Number}>{formatShrinkString(cardNumber, 0, 4, ' •••• ')}</div>
                    )}

                    <div className={styles.System}>
                        <VisaIcon className={styles.SystemIcon} />
                    </div>
                </div>
                <div className={cn(styles.Back, styles.BackBackground, styles.BackTransition)}>
                    <div className={styles.Top}>
                        <div className={styles.Label}>{t('pageVirtualCard.card.name')}</div>
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                        <div className={styles.Value} onClick={(e) => e.stopPropagation()}>
                            <div className={styles.Uppercase}>{cardholderName}</div>
                            <Button
                                type="button"
                                variant="text"
                                size="small"
                                color="gray"
                                isRound
                                onClick={onClickCopy(cardholderName?.toUpperCase())}
                                iconStart={<CopyIcon className={styles.Icon} />}
                            />
                        </div>
                    </div>
                    <div className={styles.Middle}>
                        <div className={styles.Label}>{t('pageVirtualCard.card.number')}</div>
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                        <div className={styles.Value} onClick={(e) => e.stopPropagation()}>
                            <div>{formatCard(cardNumber ?? '')}</div>
                            <Button
                                type="button"
                                variant="text"
                                color="gray"
                                size="small"
                                isRound
                                onClick={onClickCopy(cardNumber)}
                                iconStart={<CopyIcon className={styles.Icon} />}
                            />
                        </div>
                    </div>
                    <div className={styles.Bottom}>
                        <div className={styles.BottomItem}>
                            <div className={styles.Label}>{t('pageVirtualCard.card.expiration')}</div>
                            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                            <div
                                className={cn(styles.Value, styles.ValueExpiration)}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <div>{getCardExpirationDate(expirationDate)}</div>
                            </div>
                        </div>
                        <div className={styles.BottomItem}>
                            <div className={styles.Label}>СVV</div>
                            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                            <div className={styles.Value} onClick={(e) => e.stopPropagation()}>
                                <div>{cvv}</div>
                                <Button
                                    type="button"
                                    variant="text"
                                    size="small"
                                    color="gray"
                                    isRound
                                    onClick={onClickCopy(cvv)}
                                    iconStart={<CopyIcon className={styles.Icon} />}
                                />
                            </div>
                        </div>
                        <div className={styles.BottomItem}>
                            <div className={styles.Label}>PIN</div>
                            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                            <div className={styles.Value} onClick={(e) => e.stopPropagation()}>
                                <div>{pinCode}</div>
                                <Button
                                    type="button"
                                    variant="text"
                                    size="small"
                                    color="gray"
                                    isRound
                                    onClick={onClickCopy(pinCode)}
                                    iconStart={<CopyIcon className={styles.Icon} />}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
