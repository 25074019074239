import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import {
    ExchangeStatusEnum,
    ExchangeTypeEnum,
    LanguageEnum,
    useExchangeQuery,
    useExchangeStatusWsSubscription,
    useSendExchangeEmailMutation
} from 'apollo/main/generated';
import { ReactComponent as BlockIcon } from 'assets/images/block.svg';
import { ReactComponent as BlockTronIcon } from 'assets/images/block-tron.svg';
import { ReactComponent as EosIcon } from 'assets/images/tokens/eos.svg';
import { ReactComponent as TrxIcon } from 'assets/images/tokens/tron.svg';
import { ROUTES_MAP } from 'constant';
import { getErrorData, useFormatDate, useGlobalError, useToast } from 'helpers';
import Button from 'ui/Button_DEPRECATED/Button';
import {
    CardIcon,
    CheckIcon,
    ChevronRightIcon,
    ClockIcon,
    CrossIcon,
    RefreshIcon,
    TelegramIcon,
    ReloadIcon
} from 'ui/Icons/Icons';
import useFormField from 'hooks/useFormField/useFormField';
import TextField from 'ui/TextField/TextField';
import Skeleton from 'ui/Skeleton/Skeleton';
import styles from './ExchangeStatus.module.scss';

export enum Direction {
    Input,
    Output
}

const getTronScan = (txId?: string | null) => {
    const isTestnet = process.env.NODE_ENV === 'development' || window.location.hostname.includes('dev');

    return `https://${isTestnet ? 'shasta.' : ''}tronscan.org/#/transaction/${txId}`;
};
const getEosScan = (txId?: string | null) => `https://bloks.io/transaction/${txId}`;

function StatusIcon({ state, className }: { state?: ExchangeStatusEnum; className?: string }) {
    switch (state) {
        case ExchangeStatusEnum.Success:
            return <CheckIcon style={{ fontSize: '2rem' }} className={className} />;
        case ExchangeStatusEnum.Failed:
            return <CrossIcon className={className} />;
        case ExchangeStatusEnum.Refunded:
            return <ReloadIcon className={className} />;

        default:
            return <ClockIcon className={className} />;
    }
}

function StatusText(state?: ExchangeStatusEnum, lang?: string) {
    switch (state) {
        case ExchangeStatusEnum.Success:
            return lang === 'ru' ? 'Оплачено' : 'Paid';
        case ExchangeStatusEnum.Failed:
            return lang === 'ru' ? 'Ошибка' : 'Failed';
        case ExchangeStatusEnum.Expired:
            return lang === 'ru' ? 'Заявка не оплачена' : 'Application not paid';
        case ExchangeStatusEnum.Refunded:
            return lang === 'ru' ? 'Возврат средств' : 'Refund';

        default:
            return lang === 'ru' ? 'Обработка' : 'Pending';
    }
}

function formatShortString(input?: string | null) {
    if (!input) return '';

    if (input?.length < 13) return input;

    const prefix = input.slice(0, 5);
    const suffix = input.slice(-7);

    return `${prefix} ··· ${suffix}`;
}

export default function ExchangeStatus({ id }: { id: string }) {
    const [t, { language }] = useTranslation();
    const formatDate = useFormatDate();
    const onGlobalError = useGlobalError();
    const toast = useToast();

    const email = useFormField('');

    const { data, error, loading } = useExchangeQuery({ variables: { id }, fetchPolicy: 'network-only' });
    const { created, status, type, receiver, refund, input, output } = data?.exchange ?? {};

    const direction =
        type !== ExchangeTypeEnum.FiatCrypto && input?.name !== 'USDT' ? Direction.Output : Direction.Input;

    const getCurrencyName = (name?: string) => (name === 'USDT' ? 'USDT (TRC-20)' : name);

    useExchangeStatusWsSubscription({ variables: { exchangeId: id } });

    const [sendExchangeEmailMutation, { loading: sendEmailLoading }] = useSendExchangeEmailMutation();

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => email.change(e.target.value);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        sendExchangeEmailMutation({
            variables: {
                input: {
                    email: email.value,
                    exchangeId: id,
                    language: language === 'ru' ? LanguageEnum.Ru : LanguageEnum.En
                }
            }
        })
            .then(({ data: sendEmailData }) => {
                const sendExchangeEmail = sendEmailData?.sendExchangeEmail;

                if (sendExchangeEmail?.__typename === 'SendExchangeEmailSuccess') {
                    toast.success(t('pageStatus.success'));
                } else if (sendExchangeEmail?.__typename === 'ExchangeNotFoundError') {
                    toast.error(t('pageStatus.error.ExchangeNotFoundError'));
                }
            })
            .catch((err) => onGlobalError(getErrorData(err).message));
    };

    if (error) {
        return (
            <div className={styles.Root}>
                <div className={styles.Top}>
                    <div className={styles.Status}>
                        <div
                            className={cn(
                                styles.StatusIconContainer,
                                styles[`StatusIconContainer-${ExchangeStatusEnum.Failed}`]
                            )}
                        >
                            <StatusIcon state={ExchangeStatusEnum.Failed} className={styles.StatusIcon} />
                        </div>
                        <h2 className={styles.StatusTitle}>{StatusText(ExchangeStatusEnum.Failed, language)}</h2>
                        <div className={styles.StatusText} style={{ whiteSpace: 'normal' }}>
                            {error.message}
                        </div>
                    </div>
                </div>
                <div className={styles.Bottom}>
                    <Button
                        className={styles.AgainButton}
                        color="primary"
                        tag="link"
                        to={ROUTES_MAP.exchange}
                        startIcon={<RefreshIcon />}
                    >
                        {t('pageStatus.refresh')}
                    </Button>
                    <Button
                        className={styles.SupportButton}
                        classes={{ root: styles.SupportButton, iconStart: styles.SupportButtonIconStart }}
                        tag="a"
                        color="tertairy-white"
                        href="https://t.me/paycashchat"
                        target="_blank"
                        rel="noreferrer"
                        startIcon={<TelegramIcon className={styles.SupportIcon} />}
                    >
                        {t('pageStatus.support')}
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.Root}>
            <div className={styles.Top}>
                <div className={styles.Status}>
                    {loading ? (
                        <Skeleton variant="circular" width={80} height={80} style={{ margin: 'auto' }} />
                    ) : (
                        <div className={cn(styles.StatusIconContainer, styles[`StatusIconContainer-${status}`])}>
                            <StatusIcon state={status} className={styles.StatusIcon} />
                        </div>
                    )}
                    <h2 className={styles.StatusTitle}>
                        {loading ? (
                            <Skeleton variant="text" width={120} style={{ margin: 'auto' }} />
                        ) : (
                            StatusText(status, language)
                        )}
                    </h2>
                    <div className={styles.StatusText}>
                        {loading ? (
                            <Skeleton variant="text" width={200} style={{ margin: 'auto' }} />
                        ) : (
                            <>
                                {direction === Direction.Input ? t('pageStatus.deposit') : t('pageStatus.withdraw')} ·{' '}
                                {formatDate(created, 'FF')}
                            </>
                        )}
                    </div>
                    {status === ExchangeStatusEnum.Failed && (
                        <div className={styles.StatusText}>{t('pageStatus.error.status')}</div>
                    )}

                    {status === ExchangeStatusEnum.Expired && (
                        <div className={styles.StatusText}>{t('pageStatus.error.expired')}</div>
                    )}

                    {status === ExchangeStatusEnum.Refunded && (
                        <div className={styles.StatusText}>{t('pageStatus.info.refunded')}</div>
                    )}
                </div>

                <div className={styles.Account}>
                    {loading ? (
                        <Skeleton variant="circular" width={34} height={34} style={{ marginRight: '0.75rem' }} />
                    ) : (
                        <div className={styles.AccountIconContainer}>
                            {direction === Direction.Input ? (
                                <EosIcon className={styles.AccountIcon} />
                            ) : (
                                <>
                                    {output?.name === 'USDT' && <TrxIcon className={styles.AccountIcon} />}

                                    {output?.name !== 'USDT' && status !== ExchangeStatusEnum.Refunded && !refund && (
                                        <CardIcon className={styles.AccountIcon} />
                                    )}

                                    {status === ExchangeStatusEnum.Refunded && refund && (
                                        <EosIcon className={styles.AccountIcon} />
                                    )}
                                </>
                            )}
                        </div>
                    )}

                    <div className={styles.AccountIconContent}>
                        {loading ? (
                            <Skeleton variant="text" height={19} width={140} />
                        ) : (
                            <div className={styles.AccountLabel}>
                                {direction === Direction.Input ||
                                output?.name === 'USDT' ||
                                (status === ExchangeStatusEnum.Refunded && refund)
                                    ? t('pageStatus.receiverAccount')
                                    : t('pageStatus.receiverCard')}
                            </div>
                        )}

                        {loading ? (
                            <Skeleton variant="text" height={22} width={80} />
                        ) : (
                            <div className={styles.AccountValue}>
                                {status !== ExchangeStatusEnum.Refunded &&
                                    type === ExchangeTypeEnum.CryptoFiat &&
                                    '•• '}

                                {status === ExchangeStatusEnum.Refunded && refund ? (
                                    refund.receiver
                                ) : (
                                    <span title={receiver ?? ''}>{receiver}</span>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                <div className={styles.Info}>
                    {loading ? (
                        <>
                            <div className={styles.InfoItem}>
                                <div className={styles.InfoLabel}>
                                    <Skeleton variant="text" width={80} />
                                </div>
                                <div className={styles.InfoValue}>
                                    <Skeleton variant="text" width={120} />
                                </div>
                            </div>
                            <div className={styles.InfoItem}>
                                <div className={styles.InfoLabel}>
                                    <Skeleton variant="text" width={80} />
                                </div>
                                <div className={styles.InfoValue}>
                                    <Skeleton variant="text" width={120} />
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            {status !== ExchangeStatusEnum.Failed && status !== ExchangeStatusEnum.Expired && (
                                <div className={styles.InfoItem}>
                                    <div className={styles.InfoLabel}>
                                        {status === ExchangeStatusEnum.Success && t('pageStatus.received')}
                                        {status === ExchangeStatusEnum.Refunded && t('pageStatus.refunded')}
                                        {status !== ExchangeStatusEnum.Success &&
                                            status !== ExchangeStatusEnum.Refunded &&
                                            t('pageStatus.willBeReceived')}
                                    </div>
                                    <div className={cn(styles.InfoValue, styles.InfoValueAccent)}>
                                        {status === ExchangeStatusEnum.Refunded && refund
                                            ? `${refund.amount} ${getCurrencyName(input?.name)}`
                                            : `${output?.amount} ${getCurrencyName(output?.name)}`}
                                    </div>
                                </div>
                            )}

                            {output?.txid && (
                                <>
                                    {output?.name === 'USDT' && (
                                        <div className={styles.InfoItem}>
                                            <div className={styles.InfoLabel}>
                                                <span style={{ marginRight: '0.5rem' }}>
                                                    {t('pageStatus.transactionId')}
                                                </span>
                                                <BlockTronIcon />
                                            </div>
                                            <a
                                                className={styles.InfoValue}
                                                href={getTronScan(output?.txid)}
                                                title={output?.txid ?? ''}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {formatShortString(output?.txid)}
                                            </a>
                                        </div>
                                    )}

                                    {output?.name !== 'USDT' && type !== ExchangeTypeEnum.CryptoFiat && (
                                        <div className={styles.InfoItem}>
                                            <div className={styles.InfoLabel}>
                                                <span style={{ marginRight: '0.5rem' }}>
                                                    {t('pageStatus.transactionId')}
                                                </span>
                                                <BlockIcon />
                                            </div>

                                            <a
                                                className={styles.InfoValue}
                                                href={getEosScan(output?.txid)}
                                                title={output?.txid ?? ''}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {formatShortString(output?.txid)}
                                            </a>
                                        </div>
                                    )}
                                </>
                            )}

                            {status !== ExchangeStatusEnum.Expired && (
                                <div className={styles.InfoItem}>
                                    <div className={styles.InfoLabel}>{t('pageStatus.paid')}</div>
                                    <div className={styles.InfoValue}>
                                        {input?.amount} {getCurrencyName(input?.name)}
                                    </div>
                                </div>
                            )}

                            {input?.txid && (
                                <>
                                    {input?.name === 'USDT' && (
                                        <div className={styles.InfoItem}>
                                            <div className={styles.InfoLabel}>
                                                <span style={{ marginRight: '0.5rem' }}>
                                                    {t('pageStatus.transactionId')}
                                                </span>
                                                <BlockTronIcon />
                                            </div>
                                            <a
                                                className={styles.InfoValue}
                                                href={getTronScan(input?.txid)}
                                                title={input?.txid ?? ''}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {formatShortString(input?.txid)}
                                            </a>
                                        </div>
                                    )}

                                    {input?.name !== 'USDT' && type !== ExchangeTypeEnum.FiatCrypto && (
                                        <div className={styles.InfoItem}>
                                            <div className={styles.InfoLabel}>
                                                <span style={{ marginRight: '0.5rem' }}>
                                                    {t('pageStatus.transactionId')}
                                                </span>
                                                <BlockIcon />
                                            </div>
                                            <a
                                                className={styles.InfoValue}
                                                href={getEosScan(input?.txid)}
                                                title={input?.txid ?? ''}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {formatShortString(input?.txid)}
                                            </a>
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>

            <div className={styles.Bottom}>
                {status !== ExchangeStatusEnum.Success &&
                    status !== ExchangeStatusEnum.Expired &&
                    status !== ExchangeStatusEnum.Refunded && (
                        <>
                            <div className={styles.Email}>
                                <div className={styles.EmailText}>{t('pageStatus.emailText')}</div>
                                <form onSubmit={handleSubmit} className={styles.EmailForm}>
                                    <TextField
                                        classes={{ root: styles.EmailTextField }}
                                        placeholder={t('pageStatus.email')}
                                        type="email"
                                        pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                                        value={email.value}
                                        inputMode="email"
                                        isError={email.error}
                                        helperText={email.helperText}
                                        onChange={handleEmailChange}
                                        autoComplete="email"
                                        disabled={loading}
                                        required
                                    />
                                    <Button
                                        className={styles.EmailSubmitButton}
                                        color="primary"
                                        type="submit"
                                        disabled={!email.value || loading || sendEmailLoading}
                                        startIcon={<ChevronRightIcon />}
                                    />
                                </form>
                            </div>
                            <div className={styles.Divider} />
                        </>
                    )}

                {(status === ExchangeStatusEnum.Success ||
                    status === ExchangeStatusEnum.Expired ||
                    status === ExchangeStatusEnum.Refunded) && (
                    <Button
                        className={styles.AgainButton}
                        color="primary"
                        tag="link"
                        to={ROUTES_MAP.exchange}
                        startIcon={<RefreshIcon />}
                    >
                        {t('pageStatus.refresh')}
                    </Button>
                )}

                <Button
                    className={styles.SupportButton}
                    classes={{ root: styles.SupportButton, iconStart: styles.SupportButtonIconStart }}
                    tag="a"
                    color="tertairy-white"
                    href="https://t.me/paycashchat"
                    target="_blank"
                    rel="noreferrer"
                    startIcon={<TelegramIcon className={styles.SupportIcon} />}
                >
                    {t('pageStatus.support')}
                </Button>
            </div>
        </div>
    );
}
