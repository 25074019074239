import React from 'react';
import classNames from 'classnames/bind';
import styles from './Background.module.scss';

const cx = classNames.bind(styles);

function Background({ className = '' }: any) {
    return <div className={cx('Component', className)} />;
}

export default Background;
