import {
    isAuthVar,
    LOCAL_STORAGE_KEY_TOKEN_AUTH,
    LOCAL_STORAGE_KEY_TOKEN_EXPIRATION,
    LOCAL_STORAGE_KEY_TOKEN_REFRESH
} from 'apollo/cache';

export const login = (token: string, expiration: string, refresh: string) => {
    window.localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN_AUTH, token);
    window.localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN_EXPIRATION, expiration);
    window.localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN_REFRESH, refresh);
    isAuthVar(true);
};

export const logout = () => {
    window.localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN_AUTH);
    window.localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN_EXPIRATION);
    window.localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN_REFRESH);
    isAuthVar(false);
};
