import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckIcon } from 'ui/Icons/Icons';
import GuarantiesImage1x from 'assets/images/about-cash-tokens/guarantees@1x.png';
import GuarantiesImage2x from 'assets/images/about-cash-tokens/guarantees@2x.png';
import styles from './AboutCashTokensGuarantees.module.scss';

export default function Benefits() {
    const [t] = useTranslation();

    return (
        <section id="guarantees" className={styles.Root}>
            <div className={styles.Container}>
                <div className={styles.Wrapper}>
                    <h2 className={styles.Title}>{t('pageAboutCashTokens.guarantees.title')}</h2>
                    <p className={styles.Text}>{t('pageAboutCashTokens.guarantees.description')}</p>
                    <ul className={styles.List}>
                        {(
                            t('pageAboutCashTokens.guarantees.list', {
                                returnObjects: true
                            }) as string[]
                        )?.map((str) => (
                            <li key={str} className={styles.ListItem}>
                                <CheckIcon className={styles.ListIcon} />
                                <span>{str}</span>
                            </li>
                        ))}
                    </ul>
                    <p className={styles.Subtext}>{t('pageAboutCashTokens.guarantees.subtext')}</p>
                </div>
                <div className={styles.ImageWrapper}>
                    <img
                        className={styles.Image}
                        srcSet={`${GuarantiesImage2x} 2x`}
                        src={GuarantiesImage1x}
                        alt="Tokens guaranties"
                    />
                </div>
            </div>
        </section>
    );
}
