import React, { PropsWithChildren, ReactNode } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import TooltipQuestion from 'components/TooltipQuestion/TooltipQuestion';
import Changed, { Props as ChangedProps } from 'components/Changed/Changed';
import Loader from 'components/Loader/Loader';
import Button from 'ui/Button_DEPRECATED/Button';
import { RefreshIcon } from 'ui/Icons/Icons';
import FormatNumber from '../FormatNumber/FormatNumber';
import styles from './Card.module.scss';

type Props = PropsWithChildren<{
    title: ReactNode;
    helpText?: ReactNode;
    subTitle?: ReactNode;
    className?: string;
    headerAdditionalContent?: ReactNode;
    mainClassName?: string;
    headerClassName?: string;
    titleClassName?: string;
}>;

function Card({
    title,
    subTitle,
    headerAdditionalContent,
    children,
    helpText,
    className = '',
    mainClassName = '',
    headerClassName = '',
    titleClassName = ''
}: Props) {
    const showHeader = !!(title || subTitle || helpText);

    return (
        <div className={cn(styles.Card, className)}>
            {showHeader && (
                <header className={cn(styles.CardHeader, headerClassName)}>
                    <h2 className={cn(styles.CardTitle, titleClassName)}>
                        {title} {subTitle && <span className={cn(styles.CardSubTitle)}>{subTitle}</span>}
                    </h2>
                    {headerAdditionalContent}
                    {helpText && <TooltipQuestion>{helpText}</TooltipQuestion>}
                </header>
            )}
            <div className={cn(styles.CardMain, mainClassName)}>{children}</div>
        </div>
    );
}

export interface VolatilityItem {
    label?: string;
    value?: string;
}

export interface VolatilityProps {
    items?: VolatilityItem[];
}

function Volatility({ items }: VolatilityProps) {
    const [t] = useTranslation();
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);

    if (!items?.some((item) => item.value !== undefined)) return null;

    return (
        <div className={styles.Volatility}>
            {items?.map((item) => {
                const { label, value } = item ?? {};
                const numericValue = Number(value ?? 0);
                const moveClass = numericValue > 0 ? styles.VolatilityValueUp : styles.VolatilityValueDown;

                return (
                    <div className={styles.VolatilityItem}>
                        <span className={styles.VolatilityLabel}>
                            {isMobileOrLess && <span>{t('pageHome.mlnkPrice.change')}</span>} {label}
                        </span>
                        <span className={cn(styles.VolatilityValue, numericValue !== 0 && moveClass)}>
                            <FormatNumber
                                // eslint-disable-next-line no-nested-ternary
                                prefix={numericValue === 0 ? '' : numericValue > 0 ? '+' : '-'}
                                value={Math.abs(numericValue)}
                                suffix="%"
                            />
                        </span>
                    </div>
                );
            })}
        </div>
    );
}

export function CardValue({
    children,
    className = '',
    changed,
    volatility
}: PropsWithChildren<{
    className?: string;
    changed?: ChangedProps;
    volatility?: VolatilityItem[];
}>) {
    return (
        <div className={cn(styles.CardValueWrapper, className)}>
            <p className={cn(styles.CardValue)}>{children}</p>
            {changed && <Changed {...changed} className={cn(styles.CardValueChanged, changed.className || '')} />}
            {volatility && <Volatility items={volatility} />}
        </div>
    );
}

export function CardLoader() {
    return (
        <div className={cn(styles.Loader)}>
            <Loader />
        </div>
    );
}

export function CardError({ resetError, disabled }: { disabled?: boolean; resetError(): void }) {
    const [t] = useTranslation();

    return (
        <div className={cn(styles.Error)}>
            <p>{t('global.cardError.text')}</p>
            <Button color="tertairy-green" disabled={disabled} onClick={() => resetError()} startIcon={<RefreshIcon />}>
                {t('global.cardError.btn')}
            </Button>
        </div>
    );
}

export default Card;
