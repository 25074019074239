import { SearchEosAccountsQueryVariables, useSearchEosAccountsQuery } from 'apollo/main/generated';
import classNames from 'classnames/bind';
import BookmarkBtn from 'components/BookmarkBtn/BookmarkBtn';
import Button from 'ui/Button_DEPRECATED/Button';
import { SadFaceFilledIcon } from 'ui/Icons/Icons';
import SearchField from 'components/SearchField/SearchField';
import { ROUTES_MAP } from 'constant';
import { useAppContext } from 'components/Helpers/AppContext';
import { useOverflowController, useOverlayClickHandler } from 'helpers';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import ReactFocusLock from 'react-focus-lock';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import highlightSubstring from 'helpers/highlightSubstring';
import styles from './SearchAccount.module.scss';

const cx = classNames.bind(styles);

function SearchAccount() {
    const overflowController = useOverflowController();
    const isFirstRenderRef = useRef(true);
    const portal = useRef(document.createElement('div')).current;
    const [isVisible, setIsVisible] = useState(false);
    const { isOpen, close } = useAppContext().searchAccount;

    const hidden = !isOpen && !isVisible;
    const shown = isOpen && isVisible;

    useEffect(
        () => () => portal && portal.remove(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const hide = () => {
        if (isOpen) setIsVisible(false);

        setTimeout(() => {
            if (!isOpen) setIsVisible(false);
            close();
            overflowController.unblockScroll();
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            portal && portal.remove();
        }, 200);
    };

    const overlayClickHandler = useOverlayClickHandler(hide);

    useEffect(() => {
        if (isOpen) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            portal && document.body.appendChild(portal);
            setTimeout(() => {
                setIsVisible(true);
                overflowController.blockScroll();
            });
        } else if (isFirstRenderRef.current) {
            isFirstRenderRef.current = false;
        } else {
            hide();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    if (hidden) return null;

    return createPortal(
        <ReactFocusLock>
            <aside {...overlayClickHandler.overlayProps} className={cx('Wrapper', shown && 'isOpen')}>
                <form
                    {...overlayClickHandler.componentProps}
                    onSubmit={(e) => e.preventDefault()}
                    className={cx('Component')}
                >
                    {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
                    <Main />
                </form>
            </aside>
        </ReactFocusLock>,
        portal
    );
}

function Main() {
    const [t] = useTranslation();
    const resultRef = useRef<HTMLDivElement>(null);
    const { close } = useAppContext().searchAccount;
    const inputValueController = useState('');
    const [variables, setVariables] = useState<SearchEosAccountsQueryVariables>({
        skip: 0,
        part: '',
        limit: 5
    });

    const { loading, data, previousData } = useSearchEosAccountsQuery({
        variables,
        skip: !variables.part,
        notifyOnNetworkStatusChange: true
    });

    const searchEosAccounts = data?.searchEosAccounts ?? previousData?.searchEosAccounts;
    const { total, accounts } = searchEosAccounts ?? {};
    const totalCount = total ?? 0;
    const isAppliedSearch = variables.part && inputValueController[0] === variables.part;
    const contentReady = Boolean(searchEosAccounts && !loading && isAppliedSearch);
    useEffect(() => {
        if (!resultRef.current) return;

        if (!variables.part) {
            resultRef.current.style.height = '0';
        } else {
            resultRef.current.style.height = `${resultRef.current.children[0]?.clientHeight ?? 0}px`;
        }
    }, [variables.part, accounts, contentReady]);

    const onChangeSearch = (part: string) => {
        setVariables({
            ...variables,
            part,
            skip: 0
        });
    };

    return (
        <>
            <SearchField
                value={variables.part}
                onChange={onChangeSearch}
                controlledInputValue={inputValueController}
                loading={loading}
                delay={100}
                toLowerCase
                className={cx('SearchField')}
                inputClassName={cx('SearchFieldInput')}
                searchIconClassName={cx('SearchFieldIcon')}
                clearClassName={cx('SearchFieldBtnClear')}
                inputProps={{
                    placeholder: t('searchAccount.placeholder')
                }}
            />
            <div ref={resultRef} className={cx('Result')}>
                {contentReady && (
                    <div className={cx('ResultContent')}>
                        {accounts?.length ? (
                            <>
                                <h6 className={cx('ResultTitle')}>{t('searchAccount.result.title')}</h6>
                                <p className={cx('ResultDescription')}>
                                    {t('searchAccount.result.description', {
                                        count: totalCount
                                    })}
                                </p>

                                <ul className={cx('List')}>
                                    {accounts.map(
                                        (account) =>
                                            account && (
                                                <li key={account.name} className={cx('ListItem')}>
                                                    <Link onClick={close} to={ROUTES_MAP.account(account.name)}>
                                                        {/* {account.name} */}
                                                        {highlightSubstring(account.name, [0, variables.part.length])}
                                                    </Link>

                                                    <BookmarkBtn
                                                        isFavorite={account.isFavorite}
                                                        type="account"
                                                        isSmall
                                                        eosAccount={account.name}
                                                        className={cx(
                                                            Boolean(account.isFavorite) && 'BtnBookmarkActive'
                                                        )}
                                                    />
                                                </li>
                                            )
                                    )}
                                </ul>

                                {(accounts?.length ?? 0) > 4 && (
                                    <Button
                                        onClick={close}
                                        color="link"
                                        className={cx('ResultBtnView')}
                                        to={{
                                            pathname: ROUTES_MAP.searchAccount,
                                            search: `query=${variables.part}`
                                        }}
                                        tag="link"
                                    >
                                        {t('searchAccount.result.btnViewAll')}
                                    </Button>
                                )}
                            </>
                        ) : (
                            <div className={cx('EmptyScreen')}>
                                <SadFaceFilledIcon className={styles.SadIcon} />

                                <h6>{t('searchAccount.empty.title')}</h6>
                                <p>{t('searchAccount.empty.description')}</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
}

export default SearchAccount;
