import React from 'react';
import cn from 'classnames';
import useIsTouchableDevice from 'hooks/useIsTouchableDevice/useIsTouchableDevice';
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery';
import Dialog, { useDialog } from 'ui/Dialog/Dialog';
import VirtualCardIssueProcess from '../VirtualCardIssueProcess/VirtualCardIssueProcess';
import { View } from '../VirtualCardManageViews/VirtualCardManageViews.d';
import styles from './VirtualCardIssueModal.module.scss';

type VirtualCardIssueModalProps = ReturnType<typeof useDialog> & {
    onClose?: () => void;
    view?: View;
};

export default function VirtualCardIssueModal({
    open,
    setOpen,
    context,
    view = View.TopUp,
    onClose,
    ...props
}: VirtualCardIssueModalProps) {
    const isMobileOrLess = useMediaQuery((breakpoints) => breakpoints.down.sm);
    const isTouchableDevice = useIsTouchableDevice();

    const [currentView, setCurrentView] = React.useState(view);

    const handleClose = () => {
        setOpen(false);
        onClose?.();
    };

    const handleUnmount = () => {
        setCurrentView(view);
    };

    return (
        <Dialog
            classes={{ container: cn(styles.Container, isTouchableDevice && styles.ContainerIsTouch) }}
            open={open}
            onOpenChange={handleClose}
            onUnmount={handleUnmount}
            fullScreen={isMobileOrLess || isTouchableDevice}
            transition={isMobileOrLess || isTouchableDevice ? 'slide-down' : undefined}
            {...props}
        >
            <VirtualCardIssueProcess
                amountGive={context.dataRef.current?.amountGive ?? ''}
                amountReceive={context.dataRef.current?.amountReceive ?? ''}
                issueFees={context.dataRef.current?.issueFees ?? {}}
                cardholderName={context.dataRef.current?.cardholderName ?? ''}
                time={context.dataRef.current?.time ?? ''}
                memo={context.dataRef.current?.memo ?? ''}
                receiver={context.dataRef.current?.receiver ?? ''}
                deepLinks={context.dataRef.current?.deepLinks ?? []}
                currency={context.dataRef.current?.currency ?? ''}
                setView={setCurrentView}
                view={currentView}
                onClose={handleClose}
            />
        </Dialog>
    );
}
