import React from 'react';
import cn from 'classnames';
import Preloader from 'ui/Preloader/Preloader';
import { ButtonPolymorphicComponent, ButtonPropsWithRef, ButtonRef, ButtonProps } from './Button.d';
import styles from './Button.module.scss';

const Button: ButtonPolymorphicComponent = React.forwardRef(
    <C extends React.ElementType = 'button'>(
        {
            as,
            children,
            classes,
            color = 'primary',
            disabled,
            fullWidth,
            iconEnd,
            iconStart,
            isActive,
            isRound,
            loading,
            size = 'large',
            variant = 'contained',
            ...props
        }: ButtonPropsWithRef<C, ButtonProps>,
        ref: ButtonRef<C>
    ) => {
        const Component = as || 'button';
        const { root, preloader, iconStart: iconStartClass, iconEnd: iconEndClass } = classes ?? {};

        const classesRoot = cn(
            styles.Root,
            styles[`Variant-${variant}`],
            styles[`Color-${color}`],
            styles[`Size-${size}`],
            (disabled || loading) && styles.Disabled,
            loading && styles.Loading,
            fullWidth && styles.FullWidth,
            isRound && styles.Round,
            isActive && styles.Active,
            !children && styles.OnlyIcon,
            root
        );

        return (
            <Component
                className={classesRoot}
                disabled={disabled}
                tabIndex={disabled ? -1 : undefined}
                {...props}
                ref={ref}
            >
                <>
                    {loading && <Preloader size="1.5em" classes={{ root: cn(styles.Preloader, preloader) }} />}
                    {iconStart && (
                        <span className={cn(styles.Icon, styles.IconStart, iconStartClass)}>{iconStart}</span>
                    )}
                    {children}
                    {iconEnd && <span className={cn(styles.Icon, styles.IconEnd, iconEndClass)}>{iconEnd}</span>}
                </>
            </Component>
        );
    }
);

export default Button;
