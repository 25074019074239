/**
 * Formats a numeric string with thousands separators.
 *
 * @param {string | undefined} numericString - The numeric string to format.
 * @param {string} thousandsSeparator - The separator to use for thousands (default is space).
 *
 * @returns {string} The formatted numeric string.
 */
export default function addThousandsSeparator(numericString?: string | null, thousandsSeparator: string = ' '): string {
    // Check if the input is a valid string
    if (typeof numericString !== 'string') return '';
    // Replace commas with dots for consistent decimal separators
    const formattedString = numericString.replace(',', '.');
    // Split the string into integer and decimal parts
    const parts = formattedString.split('.');
    // Add thousands separators to the integer part
    parts[0] = parts[0].replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, `$&${thousandsSeparator}`);
    // Join parts with a dot and return
    return parts.join('.');
}
