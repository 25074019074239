import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { BREAKPOINT_LANDSCAPE } from 'constant';
import getCurrencyIcon from 'helpers/getCurrencyIcon/getCurrencyIcon';
import { useDropdown, useWidthCondition } from 'helpers';
import { ChevronDownIcon } from 'ui/Icons/Icons';
import BottomSheet from '../BottomSheet/BottomSheet';
import styles from './TokensDropdown.module.scss';

export type TokensDropdownProps<T> = {
    active?: T;
    className?: string;
    onChange(v: T): void;
    options?: { value: T; label?: string | null; disabled?: boolean }[];
};

function getMethodDropdownLabel(method?: string | null, lang?: string, disabled?: boolean) {
    switch (method) {
        case 'USDT\u00A0(TRC\u201120)':
            return disabled ? (
                <span className={cn(styles.Label, styles.LabelWithDescription)}>
                    {getCurrencyIcon('USDT\u00A0(TRC\u201120)', styles.LabelIcon)}USDT
                    <span className={styles.LabelSubtext}>
                        {lang === 'ru' ? '(TRC\u201120) блокчейн' : '(TRC\u201120) blockchain'}
                    </span>
                    <span className={styles.LabelDescription}>
                        {lang === 'ru'
                            ? 'Чтобы вывести на данный блокчейн, выберите USDCASH'
                            : 'To withdraw to this blockchain, select USDCASH'}
                    </span>
                </span>
            ) : (
                <span className={styles.Label}>
                    {getCurrencyIcon('USDT\u00A0(TRC\u201120)', styles.LabelIcon)}
                    {'USDT\u00A0(TRC\u201120)'}
                    <span className={styles.LabelSubtext}>{lang === 'ru' ? 'блокчейн' : 'blockchain'}</span>
                </span>
            );
        case 'USDT':
            return (
                <span className={styles.Label}>
                    {getCurrencyIcon('USDT', styles.LabelIcon)}
                    USDT
                </span>
            );
        case 'RUBCASH':
            return disabled ? (
                <span className={cn(styles.Label, styles.LabelWithDescription)}>
                    {getCurrencyIcon('RUBCASH', styles.LabelIcon)}RUBCASH{' '}
                    <span className={styles.LabelDescription}>
                        {lang === 'ru'
                            ? 'RUBCASH невозможно пополнить.\nЧтобы пополнить кошелек, выберите USDCASH.'
                            : 'It is not possible to top up RUBCASH.\nTo fund your wallet, please choose USDCASH'}
                    </span>
                </span>
            ) : (
                <span className={styles.Label}>{getCurrencyIcon('RUBCASH', styles.LabelIcon)}RUBCASH</span>
            );
        case 'RUBLES':
            return <span className={styles.Label}>{getCurrencyIcon('RUBLES', styles.LabelIcon)}RUBLES</span>;
        case 'USDCASH':
            return <span className={styles.Label}>{getCurrencyIcon('USDCASH', styles.LabelIcon)}USDCASH</span>;
        case 'RUB':
            return (
                <span className={styles.Label}>
                    {getCurrencyIcon('RUB', styles.LabelIcon)}RUB{' '}
                    <span className={styles.LabelSubtext}>{lang === 'ru' ? 'Российский банк' : 'Russian bank'}</span>
                </span>
            );
        case 'EUR':
            return (
                <span className={styles.Label}>
                    {getCurrencyIcon('EUR', styles.LabelIcon)}EUR{' '}
                    <span className={styles.LabelSubtext}>
                        {lang === 'ru' ? 'Международный банк' : 'International bank'}
                    </span>
                </span>
            );
        case 'AED':
            return (
                <span className={styles.Label}>
                    {getCurrencyIcon('AED', styles.LabelIcon)}AED{' '}
                    <span className={styles.LabelSubtext}>
                        {lang === 'ru' ? 'Международный е-ваучер' : 'International e-voucher'}
                    </span>
                </span>
            );
        default:
            return method;
    }
}

export default function TokensDropdown<T extends string>({
    active,
    onChange,
    options = [{ label: 'No options', value: 'empty' as T }],
    className = ''
}: TokensDropdownProps<T>) {
    const [t, { language }] = useTranslation();
    const [isLandscapeOrLess] = useWidthCondition((w) => w < BREAKPOINT_LANDSCAPE);

    const dropdown = useDropdown({
        popperOptions: {
            placement: 'bottom-end'
        },
        handleOutsideClick: !isLandscapeOrLess
    });

    return (
        <div ref={dropdown.ref} className={cn(styles.Root, className)}>
            <button
                type="button"
                onClick={dropdown.toggle}
                className={cn(styles.Control, dropdown.open && styles.ControlActive)}
            >
                <span>{getMethodDropdownLabel(options.find((option) => option.value === active)?.label)}</span>
                <ChevronDownIcon className={styles.ControlArrow} />
            </button>

            {!isLandscapeOrLess && (
                <div
                    ref={dropdown.popperRef}
                    style={dropdown.popper.styles.popper}
                    {...dropdown.popper.attributes.popper}
                    className={cn(styles.Content, dropdown.open && styles.ContentShow)}
                >
                    {options.map(({ label: optionLabel, value, disabled: optionDisabled }) => (
                        <button
                            className={styles.OptionButton}
                            key={value}
                            type="button"
                            disabled={optionDisabled || active === value}
                            onClick={() => {
                                dropdown.toggle();
                                onChange(value);
                            }}
                        >
                            {getMethodDropdownLabel(optionLabel, language, optionDisabled)}
                        </button>
                    ))}
                </div>
            )}

            {isLandscapeOrLess && (
                <BottomSheet
                    className={styles.BottomSheet}
                    isOpen={dropdown.open}
                    close={() => dropdown.setOpen(false)}
                >
                    <div className={styles.BottomSheetTitle}>{t('pageExchange.currency')}</div>
                    {options.map(({ label: optionLabel, value, disabled: optionDisabled }) => (
                        <button
                            className={styles.OptionButton}
                            key={value}
                            type="button"
                            disabled={optionDisabled || active === value}
                            onClick={() => {
                                dropdown.toggle();
                                onChange(value);
                            }}
                        >
                            {getMethodDropdownLabel(optionLabel, language, optionDisabled)}
                        </button>
                    ))}
                </BottomSheet>
            )}
        </div>
    );
}
