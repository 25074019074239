import { useEosAccountInvestmentChartQuery } from 'apollo/main/generated';
import Card, { CardLoader, CardValue } from 'components/Card/Card';
import Chart, { COLOR_SCHEME } from 'components/Chart/Chart';
import FormatNumber from 'components/FormatNumber/FormatNumber';
import { USDT_MIN_MOVE, USDT_PRECISION } from 'constant';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import styles from './AccountDetails.module.scss';

const cx = classNames.bind(styles);

const InvestmentSection = memo(({ account }: { account: string }) => {
    const [t] = useTranslation();

    const query = useEosAccountInvestmentChartQuery({
        variables: {
            name: account
        },
        fetchPolicy: 'network-only'
    });

    const data = query.data?.accountInvestmentChart;

    return (
        <Card
            title={t('pageAccount.investments.title')}
            helpText={t('pageAccount.investments.helpText')}
            className={cx('Income')}
        >
            {query.loading ? (
                <CardLoader />
            ) : (
                <>
                    <CardValue
                        changed={{
                            period: '24h',
                            growth: data?.percentageChange24h
                        }}
                    >
                        <FormatNumber value={data?.value24h} prefix="$" />
                    </CardValue>
                    <Chart
                        loading={query.loading}
                        data={{
                            type: 'area',
                            data:
                                data?.points.map((p) => ({
                                    value: Number(p?.value),
                                    time: p!.timestamp
                                })) ?? [],
                            options: {
                                ...COLOR_SCHEME.blue.area,
                                priceFormat: {
                                    type: 'price',
                                    precision: USDT_PRECISION,
                                    minMove: USDT_MIN_MOVE
                                }
                            }
                        }}
                    />
                </>
            )}
        </Card>
    );
});

export default InvestmentSection;
