import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { TxnType, useTotalDepositStatisticQuery } from 'apollo/main/generated';
import MalinkaIcon from 'assets/images/tokens/mlnk.svg';
import { BREAKPOINT_LANDSCAPE, ROUTES_MAP } from 'constant';
import { useWidthCondition } from 'helpers';
import getTokenLogo from 'helpers/getTokenLogo/getTokenLogo';
import { PairsIcon, CrossIcon } from 'ui/Icons/Icons';
import Button from 'ui/Button_DEPRECATED/Button';
import Card, { CardError, CardLoader } from '../Card/Card';
import FormatNumber from '../FormatNumber/FormatNumber';
import styles from './HomeCashStatistics.module.scss';

export default function CashStatistics() {
    const [t] = useTranslation();
    const [isMobileOrLess] = useWidthCondition((w) => w < BREAKPOINT_LANDSCAPE);

    const { data, loading, error, refetch } = useTotalDepositStatisticQuery({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only'
    });

    return (
        <Card
            title={t('pageHome.cashStatistics.title')}
            className={styles.Root}
            headerClassName={styles.Header}
            mainClassName={styles.Component}
            headerAdditionalContent={
                !isMobileOrLess && (
                    <Button tag="link" to={ROUTES_MAP.aboutCashTokens} color="secondary">
                        {t('pageHome.cashStatistics.btnDetails')}
                    </Button>
                )
            }
        >
            {error && !loading && <CardError resetError={refetch} disabled={loading} />}
            {!error && loading && <CardLoader />}

            {!error && !loading && (
                <>
                    <div className={styles.Row}>
                        {data?.totalDepositStatistic?.map((item) => {
                            const {
                                annualRelativeProfit,
                                depositedToken,
                                depositedMlnk,
                                issuedCash,
                                tokenIn,
                                tokenOut
                            } = item ?? {};

                            return (
                                <div className={styles.Column} key={tokenOut?.id}>
                                    <div className={styles.Card}>
                                        <div className={styles.CardTop}>
                                            <div className={styles.Issued}>
                                                <img
                                                    className={styles.IssuedIcon}
                                                    src={getTokenLogo(
                                                        tokenOut?.logo,
                                                        tokenOut?.code,
                                                        tokenOut?.smartContract
                                                    )}
                                                    alt="Token"
                                                />
                                                <div className={styles.Info}>
                                                    <div className={styles.Text}>{tokenOut?.code}</div>
                                                    <FormatNumber
                                                        className={styles.Subtext}
                                                        value={issuedCash}
                                                        precision={5}
                                                        floatZerosOffset={0}
                                                        compress
                                                    />
                                                </div>
                                            </div>
                                            <div className={styles.Yield}>
                                                <div className={styles.YieldIconWrapper}>
                                                    <PairsIcon className={styles.YieldIcon} />
                                                </div>
                                                <div className={styles.Info}>
                                                    <div className={styles.Text}>
                                                        {t('pageHome.cashStatistics.yield')}
                                                    </div>
                                                    <FormatNumber
                                                        value={annualRelativeProfit}
                                                        precision={2}
                                                        className={styles.Subtext}
                                                        floatZerosOffset={0}
                                                        suffix="%"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.CardBottom}>
                                            <div className={styles.Backing}>
                                                <div className={cn(styles.Text, styles.BackingText)}>
                                                    {t('pageHome.cashStatistics.backing')}
                                                </div>
                                                <div className={styles.BackingInfo}>
                                                    <div className={styles.BackingInfoItem}>
                                                        <img
                                                            className={styles.BackingIcon}
                                                            src={MalinkaIcon}
                                                            alt="MLNK"
                                                        />
                                                        <FormatNumber
                                                            className={cn(styles.Subtext, styles.SubtextSmall)}
                                                            value={depositedMlnk}
                                                            precision={8}
                                                            floatZerosOffset={0}
                                                            compress
                                                        />
                                                    </div>
                                                    <CrossIcon className={styles.BackingInfoItemDivider} />
                                                    <div className={styles.BackingInfoItem}>
                                                        <img
                                                            className={styles.BackingIcon}
                                                            src={getTokenLogo(
                                                                tokenIn?.logo,
                                                                tokenIn?.code,
                                                                tokenIn?.smartContract
                                                            )}
                                                            alt="Token"
                                                        />
                                                        <FormatNumber
                                                            className={cn(styles.Subtext, styles.SubtextSmall)}
                                                            value={depositedToken}
                                                            precision={4}
                                                            floatZerosOffset={0}
                                                            compress
                                                        />
                                                    </div>
                                                </div>
                                                <div className={styles.BackingLinkWrapper}>
                                                    <Button
                                                        tag="link"
                                                        to={{
                                                            pathname: ROUTES_MAP.transactions,
                                                            search: `?id=${tokenOut?.id}&type=${TxnType.CashIssue}`
                                                        }}
                                                        color="link"
                                                        className={styles.BackingLink}
                                                    >
                                                        {t('pageHome.cashStatistics.btnHistory')}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {isMobileOrLess && (
                        <div className={styles.LinkWrapper}>
                            <Button tag="link" color="link" to={ROUTES_MAP.aboutCashTokens}>
                                {t('pageHome.cashStatistics.btnDetails')}
                            </Button>
                        </div>
                    )}
                </>
            )}
        </Card>
    );
}
