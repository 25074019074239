type RouteParam = string | number | undefined | null;

export const ROUTES_MAP = {
    home: '/',
    tokens: '/tokens',
    info: '/info',
    aboutCashTokens: '/about-cash-tokens',
    openLetters: '/open-letters',
    virtualCard: '/virtual-card',

    exchange: '/exchange',
    exchangeStatus: (id: RouteParam = ':id') => `/exchange/${id}`,
    // depositByBankCard: '/deposit-by-bank-card',
    depositExchange: '/deposit-exchange',

    token: (token: RouteParam = ':token') => `/token/${token}`,
    pools: '/pools',
    pool: (pool: RouteParam = ':pool') => `/pool/${pool}`,
    transactions: '/transactions',
    searchAccount: `/search-account`,
    account: (account: RouteParam = ':account') => `/account/${account}`,
    accountPool: (account: RouteParam = ':account', pool: RouteParam = ':pool') => `/account/${account}/pools/${pool}`
};

export const DOWNLOAD_APP = {
    ios: 'https://apps.apple.com/app/paycash/id1557721025',
    android: 'https://play.google.com/store/apps/details?id=online.paycash.app'
};

export const SOCIALS = {
    youtube: 'https://www.youtube.com/PayCashLog',
    telegram: 'https://t.me/paycashlog',
    telegramChat: 'https://t.me/paycashchat',
    fb: 'https://www.facebook.com/paycashlog',
    instagram: 'https://www.instagram.com/paycashlog/',
    twitter: 'https://twitter.com/PayCashTweet'
};

export const LOCAL_STORAGE_LNG_KEY = 'defaultLng';

/**
 * -----------------------------
 * Screen size breakpoints
 * -----------------------------
 */
export const BREAKPOINT_PHONE = 576;
export const BREAKPOINT_LANDSCAPE = 768;
export const BREAKPOINT_TABLET = 1024;
export const BREAKPOINT_IPAD = 1280;
export const BREAKPOINT_LAPTOP = 1440;

export const USDT_PRECISION = 4;
export const USDT_MIN_MOVE = 0.0001;
export const USDCASH_PRECISION = 5;
export const USDCASH_MIN_MOVE = 0.00001;
export const MLNK_PRECISION = 8;
export const MLNK_MIN_MOVE = 0.00000001;

export const PAYCASH_LANDING = 'https://paycash.app';
export const PC4STORE_LANDING = 'https://pc4.store';
export const KALYNA_LANDING = 'https://kalyna.online/';
export const MALINA_APP_LANDING = 'https://malinka.life/';
export const CONTACT_EMAIL = 'info@paycash.app';

export const TAPPLIVE = 'https://tapplive.com/';
