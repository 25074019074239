import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import getImage from 'helpers/getImage/getImage';
import styles from './AboutProject.module.scss';

const cx = classNames.bind(styles);

export const ID = 'about-section';

function About() {
    const [t, { language }] = useTranslation();
    return (
        <section id={ID} className={cx('Component')}>
            <div className={cx('Container')}>
                <div className={cx('ImgWrapper')}>
                    <img src={getImage('about-project_liquidity', language, 1)} alt="Liquidity" />
                </div>

                <div className={cx('Details')}>
                    <h2 className={cx('Title')}>{t('pageInfo.about.title')}</h2>

                    <div>
                        {(t('pageInfo.about.text', { returnObjects: true }) as string[]).map((text, i) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <p className={cx('Description')} key={i}>
                                {text}
                            </p>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
